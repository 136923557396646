import React, { useState } from 'react';
import './Checkbox.scss';
import classnames from 'classnames';
import { IconCheckmarkFill } from '@zoom/icons-react';

export interface IProps {
    text?: string;
    defaultChecked?: boolean;

    // make it controlled component
    checked?: boolean;
    onChange?: (e: boolean) => void;

    disabled?: boolean;
    containerClass?: string;
    children?: React.ReactNode;
    autoControlled?: boolean;
}

const Checkbox = ({
    children,
    text,
    checked,
    defaultChecked,
    disabled,
    onChange,
    containerClass,
    autoControlled,
}: IProps) => {
    const [focused, setFocused] = useState(false);
    const [isChecked, setIsChecked] = useState(() => {
        if (defaultChecked !== undefined) {
            return defaultChecked;
        } else {
            return !!checked;
        }
    });

    const finalChecked = defaultChecked !== undefined ? isChecked : checked;

    const onFocus = () => {
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
    };

    const onCheck = () => {
        const check = !finalChecked;
        if (onChange) {
            onChange(check);
        }
        setIsChecked(check);
    };

    const _containerCls = classnames({
        'zm-pwa-checkbox': true,
        'checkbox--checked': finalChecked,
        'checkbox--focused': focused,
        'checkbox--disabled': disabled,
        containerClass: !!containerClass,
    });

    return (
        <label className={_containerCls}>
            <input
                className="checkbox__input"
                type="checkbox"
                checked={autoControlled ? finalChecked : checked}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onCheck}
            />
            <span className="checkbox__mark">{finalChecked ? <IconCheckmarkFill aria-hidden={true} /> : null}</span>
            <span className="checkbox__text">{text ? text : children}</span>
        </label>
    );
};

export default Checkbox;
