import { useEffect } from 'react';
import { useAppSelector } from '../../store/store-hooks';
import { ZOOM } from '../../resource';
import { isUndefined } from 'lodash-es';

const DEFAULT_DOC_TITLE = ZOOM;

const setDocumentTitle = (title: string) => {
    window.document.title = title;
};

/**
 * we assume meeting't topic can not be empty
 */
export default () => {
    const caption = useAppSelector((state) => {
        return state.meeting.meetingInfo?.caption;
    });

    // update title
    useEffect(() => {
        if (!isUndefined(caption)) {
            setDocumentTitle(caption);
        }
    }, [caption]);

    // restore title after meeting ends
    useEffect(() => {
        return () => {
            setDocumentTitle(DEFAULT_DOC_TITLE);
        };
    }, []);
};
