import { WebimEventType } from '@zoom/pwa-webim';
import { setPresence } from '../../../features/Contacts/redux/contact-store';
import { getUserIdFromJid } from '../../../utils';
import serviceContainer from '../../container';
import { ZpnsStatus } from '../zpns-defs';
import PresencePolicyStore from './PresencePolicyStore';
import { PolicyName, PresencePolicy } from './presence-policy-types';
import { ContactPresence, PresenceType, UserJid, ZpnsPresenceHandler } from '@zoom/zpns-presence-handler';
import { PWAMeetingEvent } from '../../../features/Meeting/meeting-agent-msg-type';
import PolicyController from './PolicyTimer/PolicyTimerController';
import { waitUntilZpnsConnected } from './presence-manager-decorator';
import { isDisableInternalPresence } from '../../../store/common/common-utils';

export const Default_Sub_Pres_Duration_In_MilliSeconds = 1000 * 60 * 60 * 2; // 2 hours

export default class PresenceManager {
    presencePolicyStore: PresencePolicyStore = null;
    zpnsPresenceHandler: ZpnsPresenceHandler = null;
    policyTimerController: PolicyController = null;

    constructor() {
        this.presencePolicyStore = new PresencePolicyStore();
        this.zpnsPresenceHandler = null;
        this.policyTimerController = new PolicyController({
            unsetPolicy: this.updatePresenceWhenPolicyTimeout,
        });
        const eventBus = serviceContainer.getEventBus();

        eventBus.zpns.connectStatus.subscribe((status) => {
            // zpns may reconnect many times
            if (status !== ZpnsStatus.Connected) {
                return;
            }
            console.log('tttuuuuuuuuuu', 'zpns inited');
            this.onZpnsConnected();
        });

        eventBus.xmpp.xmppReady.subscribe(() => {
            console.log('tttuuuuuuuuuu', 'xmpp inited');
            this.onXmppConnected();
        });
    }

    private onZpnsConnected = () => {
        // Todo, zpns reconnect
        if (this.zpnsPresenceHandler) {
            return;
        }

        const reduxStore = serviceContainer.getReduxStore();
        const eventBus = serviceContainer.getEventBus();
        const xmppAgent = serviceContainer.getXmppAgent();

        this.zpnsPresenceHandler = new ZpnsPresenceHandler({
            jid: reduxStore.getState().common.userInfo.jid,
            shouldAckReceivedMessage: false,
            OnReconnectedStrategy: {
                reSubscribePresenceStrategy: 60,
            },
        });

        this.zpnsPresenceHandler.setPresenceUpdateCallback(this.onContactPresenceUpdate);

        this.zpnsPresenceHandler.setSendZpnsMessage((message) => {
            const zpns = serviceContainer.getZpns();
            if (zpns.status !== ZpnsStatus.Connected) {
                return;
            }
            zpns.send(message);
        });

        // it's most likely to be available
        // but if webclient is loaded first, then xmpp is connected, we will have zoommeeting
        this.updatePresenceNormally();

        eventBus.zpns.message.subscribe((message: any) => {
            if (!this.zpnsPresenceHandler) {
                return;
            }
            this.zpnsPresenceHandler.handleZpnsMessage(message);
        });

        eventBus.zpns.connectStatus.subscribe((status: ZpnsStatus) => {
            switch (status) {
                case ZpnsStatus.Connected:
                    // when connected server only pushes other decices' presence
                    // you have to report yours
                    this.updatePresenceNormally();
                    this.zpnsPresenceHandler.handleZpnsStatusChange({ status: 'connected' });
                    return;
                case ZpnsStatus.Disconnected:
                    this.zpnsPresenceHandler.handleZpnsStatusChange({ status: 'disconnected' });
                    return;
            }
        });

        // zpns connected, at this moment， if xmpp was connected before, it must be waiting zpns
        // so we should try to init xmpp
        if (xmppAgent.isConnected()) {
            this.onXmppConnected();
        }
    };

    subscribePresence(jids: UserJid | UserJid[]) {
        if (isDisableInternalPresence()) {
            return null;
        }

        if (!this.zpnsPresenceHandler) {
            return null;
        }

        jids = Array.isArray(jids) ? jids : [jids];

        const subs = jids.map((jid) => {
            return {
                userId: getUserIdFromJid(jid),
                durationInMilliSeconds: Default_Sub_Pres_Duration_In_MilliSeconds,
            };
        });

        return this.zpnsPresenceHandler.subscribePresence(subs);
    }

    private onContactPresenceUpdate = (list: Array<ContactPresence>) => {
        const reduxStore = serviceContainer.getReduxStore();
        const eventBus = serviceContainer.getEventBus();

        for (const p of list) {
            console.log(`[uuuu-presence] [zpns] uuuuuuu-userId: ${p.jid}`, ' : ', p.presenceType);
        }

        reduxStore.dispatch(setPresence({ data: list }));

        eventBus.zpns.presenceChange.dispatchEvent(list);
    };

    onXmppConnected = () => {
        const zpns = serviceContainer.getZpns();
        // we wait until zpns connected
        if (!zpns.isConnected()) {
            return;
        }

        const xmppAgent = serviceContainer.getXmppAgent();

        // Todo, xmpp may connected many times
        xmppAgent.webimInstance.on(WebimEventType.PresencePolicy, (e: any) => {
            const { data } = e;
            this.receivePolicyFromServer(data);
        });

        xmppAgent.webimInstance.getAllPresencePocliies();
    };

    receivePolicyFromServer = (policy: PresencePolicy) => {
        const policyNameToPresence = {
            [PolicyName.busy]: PresenceType.busy,
            [PolicyName.dnd]: PresenceType.dnd,
            [PolicyName.snooze]: PresenceType.dnd,
            [PolicyName.ooo]: PresenceType.ooo,
        };

        // 1. update/unset local policy
        const isUnset = this.presencePolicyStore.update(policy);

        // 2. notify UI about policy change
        this.notifyPresencePolicyChange(policy, isUnset);

        // 3. report the same *** presence *** to server
        if (!isUnset) {
            this.zpnsPresenceHandler.updateMyPresence({
                presence: policyNameToPresence[policy.name],
                manual: 0,
            });
        }
    };

    private reportPolicyToServer(policy: PresencePolicy) {
        const xmppAgent = serviceContainer.getXmppAgent();
        xmppAgent.webimInstance.setPresencePolicy(policy);
    }

    private notifyPresencePolicyChange(policy: PresencePolicy, unset: boolean) {
        const eventBus = serviceContainer.getEventBus();
        eventBus.xmpp.policyChange.dispatchEvent(policy);

        if (policy.name === PolicyName.snooze) {
            if (unset) {
                this.policyTimerController.unsetSnoozeTimer();
            } else {
                this.policyTimerController.setSnoozeTimer(policy.value);
            }
        }
    }

    private unsetAllLocalPresencePolicy() {
        const policies = this.presencePolicyStore.getAll();
        const policyNames = Object.keys(policies);
        for (const policyName of policyNames) {
            // unset all other policies
            this.unsetPresencePolicy(policyName as PolicyName);
        }
    }

    private unsetPresencePolicy(name: PolicyName, isTimeout = false) {
        this.presencePolicyStore.del(name);

        if (PolicyName.busy === name) {
            const policy = {
                name,
                value: { duration: 0 },
            };
            this.reportPolicyToServer(policy);
            this.notifyPresencePolicyChange(policy, true);
        }

        if (PolicyName.snooze === name) {
            const now = Math.floor(Date.now() / 1000);
            const policy = {
                name,
                value: { duration: 0, from: now, to: now },
            };
            this.reportPolicyToServer(policy);
            this.notifyPresencePolicyChange(policy, true);
        }

        if (PolicyName.dnd === name) {
            const policy = {
                name,
                value: { from: '', to: '' },
            };
            if (!isTimeout) {
                this.reportPolicyToServer(policy);
            }
            this.notifyPresencePolicyChange(policy, true);
        }

        if (PolicyName.ooo === name) {
            const policy = {
                name,
                value: { duration: 0 },
            };
            this.reportPolicyToServer(policy);
            this.notifyPresencePolicyChange(policy, true);
        }
    }

    private setPresencePolicy(policy: PresencePolicy) {
        if (!policy?.name) {
            return null;
        }

        const { name } = policy;
        const policies = this.presencePolicyStore.getAll();
        const policyNames = Object.keys(policies);

        const isUnset = this.presencePolicyStore.update(policy);
        this.reportPolicyToServer(policy);

        for (const policyName of policyNames) {
            if (policyName === name) {
                continue;
            }
            // unset all other policies
            this.unsetPresencePolicy(policyName as PolicyName);
        }

        this.notifyPresencePolicyChange(policy, isUnset);
        return isUnset;
    }

    updatePresenceToXmppServer(presenceType: PresenceType, manual: boolean) {
        const xmppAgent = serviceContainer.getXmppAgent();
        xmppAgent.changePresence(presenceType as any, manual);
    }

    /**
     * user clicks to set presence form popup dialog from your avatar
     * @param presenceType only available/busy/dnd/away/ooo
     * @param policy
     */
    updatePresenceFromUserProfile(presenceType: PresenceType, policy?: PresencePolicy) {
        // native client only set manual = 1, when it available or away
        const isOnlineOrAway = presenceType === PresenceType.available || presenceType === PresenceType.away;
        const hasPolicyBefore = Object.keys(this.presencePolicyStore.getAll()).length !== 0;

        // update policy if any
        if (isOnlineOrAway) {
            // 1. clear local policy (and notify UI)
            // 2. unset policy to server
            this.unsetAllLocalPresencePolicy();

            // set presence from zpns
            this.zpnsPresenceHandler.updateMyPresence({
                presence: presenceType,
                manual: isOnlineOrAway ? 1 : 0,
            });

            this.updatePresenceToXmppServer(presenceType, isOnlineOrAway);
        } else {
            // 1. clear other policies
            // 2. set this policy locally (and notify UI)
            // 3. set this policy to server
            const isUnset = this.setPresencePolicy(policy);
            const hasPolicyNow = Object.keys(this.presencePolicyStore.getAll()).length !== 0;

            if (isUnset && hasPolicyBefore && !hasPolicyNow) {
                presenceType = this.calculateMyCurrentPresence();
            }

            this.zpnsPresenceHandler.updateMyPresence({
                presence: presenceType,
                manual: 0,
            });
            this.updatePresenceToXmppServer(presenceType, false);
        }
    }

    /**
     * why we add this decorator for this function
     * {@link src/services/zpns/PresneceManager/presenceManager.md}
     */
    @waitUntilZpnsConnected(2000)
    updatePresence_Meeting_Start() {
        this.updatePresenceNormally();
    }

    updatePresence_Meeting_End() {
        this.updatePresenceNormally();
    }

    updatePresence_MeetingPresenting_Start() {
        this.updatePresenceNormally();
    }

    updatePresence_MeetingPresenting_End() {
        this.updatePresenceNormally();
    }

    updatePresence_Phone_Start() {
        this.updatePresenceNormally();
    }

    updatePresence_Phone_End() {
        // Todo: currently it's called from callingbar in effect
        // maybe it's not approprivate, it'll call on component mounted
        this.updatePresenceNormally();
    }

    updatePresence_Calendar_Start() {
        this.updatePresenceNormally();
    }

    updatePresence_Calendar_End() {
        this.updatePresenceNormally();
    }

    updatePresenceWhenPolicyTimeout = (policyName: PolicyName) => {
        // if dnd policy times out, we don't need to report to server,
        // just clear local cache
        this.unsetPresencePolicy(policyName, true);
        this.updatePresenceNormally();
    };

    private updatePresenceNormally() {
        if (!this.zpnsPresenceHandler) {
            return;
        }

        const presenceType = this.calculateMyCurrentPresence();
        this.zpnsPresenceHandler.updateMyPresence({
            presence: presenceType,
            manual: 0,
        });
        this.updatePresenceToXmppServer(presenceType, false);
    }

    private isInMeetingPresenting() {
        const reduxStore = serviceContainer.getReduxStore();
        const {
            meeting: { isPresenting },
        } = reduxStore.getState();
        return isPresenting;
    }

    private isInMeeting() {
        const reduxStore = serviceContainer.getReduxStore();
        const {
            meeting: { meetingInfo },
        } = reduxStore.getState();
        return (
            meetingInfo &&
            meetingInfo.meetingNo &&
            (PWAMeetingEvent.JOINING === meetingInfo.meetingStatus ||
                PWAMeetingEvent.SUCCESS === meetingInfo.meetingStatus)
        );
    }

    private isInPhoneCall() {
        const reduxStore = serviceContainer.getReduxStore();
        const { phone } = reduxStore.getState();
        return Boolean(phone?.currentSessionId);
    }

    private isInCalendar() {
        // Todo
        return false;
    }

    private isInOOO() {
        return this.presencePolicyStore.get(PolicyName.ooo);
    }

    private isInDnd() {
        return this.presencePolicyStore.get(PolicyName.dnd) || this.presencePolicyStore.get(PolicyName.snooze);
    }

    private isInBusy() {
        return this.presencePolicyStore.get(PolicyName.busy);
    }

    private calculateMyCurrentPresence() {
        /**
         * if statements order is very important !!!
         */

        if (this.isInOOO()) {
            return PresenceType.ooo;
        }

        if (this.isInDnd()) {
            return PresenceType.dnd;
        }

        if (this.isInMeetingPresenting()) {
            return PresenceType.presenting;
        }

        if (this.isInMeeting()) {
            return PresenceType.zoommeeting;
        }

        if (this.isInPhoneCall()) {
            return PresenceType.pbx;
        }

        if (this.isInCalendar()) {
            return PresenceType.calendar;
        }

        if (this.isInBusy()) {
            return PresenceType.busy;
        }

        return PresenceType.available;

        // no away presence;
    }
}
