import { ReactElement } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '../../../routes';

interface IProps {
    enabled: boolean;
    renderDirectly?: boolean; // should render without waiting for user firs manually switch to certain route
    render: () => ReactElement;
    path: string;
    exact?: boolean;
    redirectPath?: string;
}

const routeVisitRecord: { [key: string]: boolean } = {};

export default (props: IProps) => {
    const { path = '', exact = true, enabled = false, redirectPath = RoutePath.Home, render, renderDirectly } = props;
    const location = useLocation();
    const history = useHistory();

    const isMatch = matchPath(location.pathname, {
        path,
        exact,
    });

    const visited = routeVisitRecord[path];

    if (!enabled) {
        if (isMatch) {
            history.push({
                pathname: redirectPath,
            });
        }
        return null;
    }

    // enabled

    if (!isMatch) {
        if (visited) {
            // if microapps are rendered before, we can not unmount it
            // micro app itself will set display to 'block' if route doesn't match
            return render();
        } else {
            if (renderDirectly) {
                return render();
            } else {
                return null;
            }
        }
    }

    // route matched
    routeVisitRecord[path] = true;
    return render();
};
