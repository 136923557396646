export class I18nLoader {
    constructor(props) {
        this.load(props);
    }

    dict = {};

    load = (resource) => {
        this.dict = resource;
    };

    get = (...args) => {
        const [key, ...rest] = args;
        const { print, dict } = this;
        let value = '';
        if (dict && dict.hasOwnProperty(key)) {
            value = this.dict[key];
        }
        if (args.length === 1) {
            return value;
        }
        return print(value, ...rest);
    };

    print = (...args) => {
        const [value, arrayParams] = args;
        if (args.length < 2) {
            return value;
        }
        const replaceParams = Array.isArray(arrayParams) ? arrayParams : args.slice(1);
        return value.replace(/\{(\d+)\}/g, (match, index) => {
            if (replaceParams[index] !== undefined) {
                return replaceParams[index];
            }
            return match;
        });
    };
}
