import { isFunction } from 'lodash-es';
import { ReactNode } from 'react';

type StubType = string | ((input: string) => ReactNode | string);

const splitByLinks = (i18nString: string) => {
    const linkReg = /<a href={\d+}>.+?<\/a>/g;
    let lastMatchEnd = linkReg.lastIndex;
    const segments = [];
    let match;
    while ((match = linkReg.exec(i18nString)) !== null) {
        const { index, input } = match;
        const preSeg = input.slice(lastMatchEnd, index);
        lastMatchEnd = linkReg.lastIndex;
        const curSeg = input.slice(index, lastMatchEnd);
        segments.push(preSeg);
        segments.push(curSeg);
    }

    if (lastMatchEnd < i18nString.length) {
        segments.push(i18nString.slice(lastMatchEnd));
    }
    return segments;
};

// eg. `xx {0} xxx`
const processStringSeg = (input: string, stubs: Array<StubType>) => {
    const reg = /{(\d+)}/g;
    return input.replace(reg, (match, p1) => {
        try {
            const index = parseInt(p1);
            return stubs[index] as string;
        } catch (e) {
            return match;
        }
    });
};

// eg. `<a href={1}>yyy {2}</a>`
const processLinkSeg = (seg: string, stubs: Array<StubType>) => {
    const linkReg2 = /<a href={(\d+)}>(.+)<\/a>/;
    const match = seg.match(linkReg2);
    if (match) {
        const id = match[1];
        const content = match[2];
        try {
            const idIndex = parseInt(id);
            const callback = stubs[idIndex];
            if (!isFunction(callback)) {
                throw Error('');
            }
            return callback(processStringSeg(content, stubs));
        } catch (e) {
            return seg;
        }
    } else {
        return seg;
    }
};

export const i18nWithLink = (i18nString: string, stubs: Array<StubType>) => {
    const segments = splitByLinks(i18nString);
    return segments.map((seg) => {
        const reg = /<a href={\d+}>.+<\/a>/;
        if (reg.test(seg)) {
            return processLinkSeg(seg, stubs);
        } else {
            return processStringSeg(seg, stubs);
        }
    });
};
