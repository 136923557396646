import React, { Component } from 'react';

const asyncComponent = (importComponent: any, canLoad: boolean) => {
    return class extends Component {
        state: any = {
            component: null,
        };

        componentDidMount() {
            canLoad &&
                importComponent().then((cmp: any) => {
                    this.setState({ component: cmp });
                });
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props} /> : null;
        }
    };
};

export default asyncComponent;
