import { IUCSGroupCompact } from '../services/ucs-types';
import { xmppAgent } from '../../../app-init';
import { XmppAgentEvents } from '../../../services/xmpp/XmppAgent';
import { WebimServerGroup } from '@zoom/pwa-webim';
import { isGroupZoomRoom, isXmppGroupCoworkers } from './contact-utils';
import { EXTERNAL_GROUP_ID } from '../utils/stringUtils';

let ucsGroups: Array<any> = [];
let xmppGroups: Array<any> = [];
let isUcsReady = false;
let isXmppReady = false;

function fetchGroups() {
    if (isUcsReady && isXmppReady) {
        const groups = [].concat(ucsGroups);
        xmppGroups.forEach((g) => {
            if (!ucsGroups.find((_) => _.id === g.id)) {
                groups.push(g);
            }
        });

        if (groups.length > 0) {
            // external group id is default
            xmppAgent.externalGroup = groups.filter((item) => item.id === EXTERNAL_GROUP_ID);
            xmppAgent.getGroupMembers(groups);
        }

        isUcsReady = false;
        isXmppReady = false;
        xmppGroups = [];
        ucsGroups = [];
    }
}

// groupId from ucs server
// send xmpp mesage get group info
export function onGetUcsServerGroups(groups: Array<IUCSGroupCompact>): void {
    const __groups = groups.map((item) => {
        const { groupId, type } = item;
        return {
            id: groupId,
            version: -1,
            type,
        };
    });
    ucsGroups = __groups;
    isUcsReady = true;
    fetchGroups();
}

// groupId from xmpp mesage
// send xmpp mesage get group info
export function onGetXmppServerGroups(groups: Array<WebimServerGroup>) {
    const __groups = groups
        .filter((g) => {
            // coworkers (company contacts) should be fetched from ucs;
            // coworkers group's type is 0 from ucs
            // but 1 from xmpp
            return !(isXmppGroupCoworkers(g) || isGroupZoomRoom(g));
        })
        .map((g) => {
            const { id, type } = g;
            return {
                id,
                version: -1,
                type,
            };
        });
    xmppGroups = __groups;
    isXmppReady = true;
    fetchGroups();
}

let isListened = false;
export const listenXmppEvents = () => {
    if (!isListened) {
        xmppAgent.emitter.on(XmppAgentEvents.SERVER_GROUPS as unknown as string, onGetXmppServerGroups);
        isListened = true;
    }
};
