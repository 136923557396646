import { get, set, unset } from 'lodash-es';
import { AddUserIdToKey } from './userid-decorators';

export default class Storage {
    static STORAGE_KEY_BASE = 'PWA-STORE';
    static STORAGE_COUNT = 0;

    data: any;
    saveAfterUpdate = true;
    storageKey = `${Storage.STORAGE_KEY_BASE}-${Storage.STORAGE_COUNT++}`;

    constructor() {
        this.data = {};
    }

    // derived class could overload this function
    save() {}

    load() {}

    clear() {}

    @AddUserIdToKey
    easyGet(key: string, defaultValue?: any) {
        return get(this.data, key, defaultValue);
    }

    @AddUserIdToKey
    easySet(key: string, value: any) {
        set(this.data, key, value);
        if (this.saveAfterUpdate) {
            this.save();
        }
    }

    @AddUserIdToKey
    easyRemove(key: string) {
        unset(this.data, key);
        if (this.saveAfterUpdate) {
            this.save();
        }
    }
}
