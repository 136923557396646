// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './ImageContainer.scss';

const ImageContainer = ({ url, alt = '', placeHolder, imgClass, ...rest }) => {
    const { onImageLoaded } = rest;

    // show placeHolder first;
    // when load image successfully, remove placeholder and show image.
    let [showImage, setShowImage] = useState(false);
    let [isLoading, setIsLoading] = useState(!!url);

    useEffect(() => {
        setIsLoading(!!url);
    }, [url]);

    let onLoad = () => {
        onImageLoaded && onImageLoaded();
        setIsLoading(false);
        setShowImage(true);
    };

    let onError = () => {
        setIsLoading(false);
        setShowImage(false);
    };

    let imageElement = (
        <img
            alt={alt}
            className={classnames('_image', imgClass)}
            src={url}
            style={{ visibility: showImage ? 'visible' : 'hidden' }}
            onError={onError}
            onLoad={onLoad}
            data-testid="profilePic"
            loading="lazy"
        />
    );

    // Attention
    // Image maybe not display in some scene( as avatar in somewhere) even if it has url,  then, you can try add image style  with 'position: absolute'.
    return (
        <>
            {(isLoading || !showImage) && placeHolder}
            {(isLoading || showImage) && imageElement}
        </>
    );
};

export default ImageContainer;
