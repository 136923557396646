import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { selectContactByJid } from '../redux/contact-selector';
import { fetchContactsProfileThunk } from '../redux/contact-thunk';

export const useIsExternalContact = (jid: string, forceFetch = false) => {
    const dispatch = useAppDispatch();
    const isExternal = useAppSelector((state) => {
        const contact = selectContactByJid(state, jid);
        return Boolean(contact?.isExternal);
    });

    useEffect(() => {
        if (forceFetch) {
            dispatch(fetchContactsProfileThunk([{ jid }], forceFetch));
        }
    }, [forceFetch]);

    return isExternal;
};
