import axios, { Method, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ERR_401, ERR_INVALID_OR_EXPIRED, ERR_NEED_LOGIN, ERR_TOKEN_EXPIRE, ERR_TOKEN_REVOKED } from './errcode';

export interface IRequestConfig extends AxiosRequestConfig {
    needToken?: boolean;
    hasTried?: boolean;
}

export default class HttpClient {
    tokenMethod: Method = 'post';
    tokenUrl = '/nws/common/1.0/zak';
    token = '';
    fetchTokenPromise: Promise<any> = null;
    axiosInstance: AxiosInstance = null;

    getAxiosInstance(config: IRequestConfig) {
        if (!this.axiosInstance) {
            this.axiosInstance = this.createAxios(config);
        }
        return this.axiosInstance;
    }

    injectToken(config: IRequestConfig, token: any): IRequestConfig {
        config.headers = {
            ...config.headers,
            zak: token,
        };
        return config;
    }

    extractToken(data: any) {
        return data;
    }

    fetchToken() {
        const config = {
            method: this.tokenMethod,
            url: this.tokenUrl,
            params: {
                service: 'imcs',
            },
        };
        return axios(config).then((response) => {
            this.fetchTokenPromise = null;
            if (response.status === 200) {
                this.token = this.extractToken(response.data);
                return this.token;
            } else {
                throw Error(response.data || 'get nws token failed');
            }
        });
    }

    private createAxios(config: AxiosRequestConfig) {
        const instance = axios.create(config);
        instance.interceptors.request.use(this.requestBeforeHandler as any, this.requestErrorHandler);
        instance.interceptors.response.use(this.responseSuccessHandler, this.responseErrorHandler);
        return instance;
    }

    requestBeforeHandler = (config: IRequestConfig) => {
        if (!config.needToken) {
            return Promise.resolve(config);
        } else {
            if (!this.token && !this.fetchTokenPromise) {
                this.fetchTokenPromise = this.fetchToken();
            }
            if (this.fetchTokenPromise) {
                return this.fetchTokenPromise.then((token) => {
                    return this.injectToken(config, token);
                });
            } else {
                return this.injectToken(config, this.token);
            }
        }
    };

    requestErrorHandler = (error: AxiosError) => {
        return Promise.reject(error);
    };

    responseSuccessHandler = (response: AxiosResponse) => {
        return response;
    };

    responseErrorHandler = (error: AxiosError<{ code: number }>) => {
        if (error.response) {
            const { status, data } = error.response;
            if (ERR_401 === status) {
                const { code } = data;
                if (ERR_NEED_LOGIN === code) {
                    this.handleNeedLogin();
                }

                if (ERR_INVALID_OR_EXPIRED === code || ERR_TOKEN_EXPIRE === code || ERR_TOKEN_REVOKED === code) {
                    return this.response401Handler(error);
                }
            }
        }
        return Promise.reject(error);
    };

    response401Handler = (error: AxiosError) => {
        const config: IRequestConfig = error.config;
        if (config.hasTried) {
            throw error;
        }

        if (!this.fetchTokenPromise) {
            this.fetchTokenPromise = this.fetchToken();
        }

        return this.fetchTokenPromise
            .then(() => {
                config.hasTried = true;
                return this.axiosInstance(config);
            })
            .catch((e) => {
                throw e;
            });
    };

    handleNeedLogin = () => {
        console.error('you need to (re)login');
    };
}
