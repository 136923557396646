import React, { useState, useRef } from 'react';
import FeedbackThumb from '../components/feedback-thumb';
import { sendFeedbackToWebAction } from '../redux/feedback-thunk-actions';
import FeedbackHadissues from './feedback-hadissues';
import SeeFeedbackTip from '../components/see-feedback-tip';
import {
    ThumbsUpDefault,
    ThumbsUpHover,
    ThumbsUpPressed,
    ThumbsDownDefault,
    ThumbsDownHover,
    ThumbsDownPressed,
} from '../icons';
import { feedbackEveryoneTitle, great, hadIssues } from '../resource';
import { useAppDispatch, useAppSelector } from '../../../Phone/types';
import { DOM_KEY_CODE } from '../../../../utils/constants';
import Captcha from '../components/captcha';
import './feedback-everyone.scss';
import { setModal } from '../../../../store/modal/modal-store';

const thumbsUpStatus = {
    Default: ThumbsUpDefault,
    Hover: ThumbsUpHover,
    Active: ThumbsUpPressed,
};
const thumbsDownStatus = {
    Default: ThumbsDownDefault,
    Hover: ThumbsDownHover,
    Active: ThumbsDownPressed,
};

export default function FeedbackEveryone() {
    const dispatch = useAppDispatch();
    const [showhad, setShowhad] = useState(false);
    const captchaRef = useRef<any>();
    const userInfo = useAppSelector((state) => state.common.userInfo);

    function clickGreat() {
        const recaptcha = captchaRef.current?.getCaptchaValidateResult();
        dispatch(sendFeedbackToWebAction({ quality: 'GOOD', recaptcha, email: userInfo?.email }));
        dispatch(setModal({ name: 'feedback', data: { show: false } }));
    }

    function handleKeyDown(keyCode: DOM_KEY_CODE, handler: () => void) {
        if (keyCode === DOM_KEY_CODE.SPACE || keyCode === DOM_KEY_CODE.ENTER) {
            handler();
        }
    }

    return (
        <>
            {showhad ? (
                <FeedbackHadissues />
            ) : (
                <div className="feedback-everyone__container">
                    <div className="feedback-everyone">
                        <div style={{ display: 'none' }}>
                            <Captcha ref={captchaRef} />
                        </div>
                        <div className="feedback-everyone__title">{feedbackEveryoneTitle}</div>
                        <div className="feedback-everyone__thumbs">
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    handleKeyDown(e.keyCode, clickGreat);
                                }}
                                onClick={() => {
                                    clickGreat();
                                }}
                            >
                                <FeedbackThumb status={thumbsUpStatus} />
                                <div className="feedback-everyone__thumbs__text">{great}</div>
                            </div>
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    handleKeyDown(e.keyCode, () => {
                                        setShowhad(true);
                                    });
                                }}
                                onClick={() => {
                                    setShowhad(true);
                                }}
                            >
                                <FeedbackThumb status={thumbsDownStatus} />
                                <div className="feedback-everyone__thumbs__text">{hadIssues}</div>
                            </div>
                        </div>
                    </div>
                    <SeeFeedbackTip />
                </div>
            )}
        </>
    );
}
