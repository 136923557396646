import React, { useState } from 'react';
import './ContactList.scss';
import { GROUP_ID, IContact, JID, CONTACT_MEMBER_OPTIONS_FROM } from '../../types';
import ContactMemberOperation from './ContactMemberOperation/ContactMemberOperation';
import classnames from 'classnames';
import { useAppSelector } from '../../../../store';
import { A11Y_POLICY } from '../../a11y';
import Avatar from '../../../../components/Avatar/Avatar';
import { PENDING, LEVEL_F, NOT_SELECTED, CONTACT_INDEX_OF_F } from '../../../../resource';

interface Props {
    contact: IContact;
    style: React.CSSProperties;
    setContact(jid: JID, groupId?: GROUP_ID): void;
    groupId: GROUP_ID;

    a11yIndex?: string;
    a11yGroupIndex?: string;
    renderIndex?: number;
    groupTotal?: number;
    contactInGroupIndex?: number;
}

const PendingContact = ({
    contact,
    style,
    setContact,
    groupId,
    a11yIndex,
    a11yGroupIndex,
    renderIndex,
    groupTotal,
    contactInGroupIndex,
}: Props) => {
    const [showActions, setShowActions] = useState(false);
    const { jid, email } = contact;

    const isSelected = useAppSelector((state) => {
        const selectedItem = state.contacts.selectedItem;
        const isSelected =
            selectedItem && 'jid' in selectedItem && selectedItem.jid === jid && selectedItem.groupId === groupId;
        return isSelected;
    });

    const displayName = `${email} (${PENDING})`;

    const setCurrentContact = () => {
        setContact(contact.jid, groupId);
    };

    const getAriaLabelText = () => {
        const level = LEVEL_F(3);
        const selectText = isSelected ? '' : NOT_SELECTED;
        const positionText = CONTACT_INDEX_OF_F(contactInGroupIndex, groupTotal);

        return `${displayName}, ${level}, ${positionText}, ${selectText}`;
    };

    return (
        <div
            className="contacts-contact"
            style={style}
            onClick={setCurrentContact}
            role="treeitem"
            tabIndex={isSelected ? 0 : -1}
            aria-label={getAriaLabelText()}
            aria-selected={isSelected}
            data-a-render-index={renderIndex}
            data-a-l={a11yIndex}
            data-a-own-group={a11yGroupIndex}
            data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_ITEM_POLICY}
        >
            <div className={classnames('contact-container', { 'pending-contact-selected ': isSelected })}>
                <Avatar extraClass="contact-avatar" displayName={email} isDeactive={true} />
                <span className={classnames('display-name contact-pending-name')}>{displayName}</span>
                <div className={classnames('actions-btns', { 'show-actions': showActions })}>
                    <ContactMemberOperation
                        jid={jid}
                        groupId={groupId}
                        name={displayName}
                        email={email}
                        showActions={setShowActions}
                        from={CONTACT_MEMBER_OPTIONS_FROM.PENDIND_CONTACT}
                    />
                </div>
            </div>
        </div>
    );
};

export default PendingContact;
