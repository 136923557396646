import { useEffect } from 'react';
import BrowserNotification, { NOTIFICATION_TAGS } from '../utils/BrowserNotification';
import useTimeout from './useTimeout';
export interface IProps {
    timeout?: number;
    onTimeout(): void;
    notificationConfig: {
        tag: NOTIFICATION_TAGS;
        title: string;
        body: string;
    };
}

export default function useInvitationNotification(props: IProps) {
    const {
        timeout = 60 * 1000,
        onTimeout,
        notificationConfig: { tag, title, body },
    } = props;

    // timeout timer
    useTimeout(onTimeout, timeout);

    // browser notifiction
    useEffect(() => {
        let notification: Notification = null;
        if (!BrowserNotification.isSupport()) {
            return () => {};
        }

        const onDocumentVisibilityChange = () => {
            if (!window.document.hidden && notification) {
                notification.close();
            }
        };

        if (document.hidden) {
            notification = new BrowserNotification().notify({
                tag,
                title,
                body,
                onClick: () => {
                    window.focus();
                },
                onClose: () => {
                    window.removeEventListener('visibilitychange', onDocumentVisibilityChange);
                },
            });

            window.addEventListener('visibilitychange', onDocumentVisibilityChange);
        }
        return () => {
            // meeting ends or call timeout
            if (notification) {
                notification.close();
            }
            window.removeEventListener('visibilitychange', onDocumentVisibilityChange);
        };
    }, []);
}
