import React, { KeyboardEventHandler, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { connect } from 'react-redux';
import { CONTACTS_DND, HOURS_TEXT_F, MINUTES_TEXT_F, TURN_OFF } from '../../../resource';
import { RootState } from '../../../store';
import { makeA11yListInfoInjector } from '../../../utils';
import { A11Y_FOCUS_WALKER_POLICY } from '../../../utils/constants';
import { PresenceIcon } from '../../../components/Presence';

type IDndPanel = {
    containerDataAL: string;
    snoozeDurationInSeconds: number;
    setStatusToSnooze: (durationInMinute: number) => void;
};

const DndPanel = ({ containerDataAL, snoozeDurationInSeconds, setStatusToSnooze }: IDndPanel) => {
    const containerRef = useRef(null);
    const [showDuration, setShowDuration] = useState(false);
    const { dataALGenerator } = makeA11yListInfoInjector(containerDataAL, containerRef);

    const formatMinutes = (seconds: number) => {

        // if it's 120 min, we should show 2m
        // if your timer is 1 second long, then in next secons you have 119 ans show 1m
        // but as our implementation, our timer is 10 seconds long, so until 110, timer is performed
        // so if time is x minutes, we subtract by 1, and show (x - 1)m
        if(seconds % 60 === 0) {
            seconds -= 1;
        }

        // when it's less 1 min, native client don't show it.
        const minutes = Math.floor(seconds / 60);

        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        return (h <= 0 ? '' : `${h}h `) + (m <= 0 ? '' : `${m}m`);
    };

    const parentA11yOption = {
        'data-a-l': containerDataAL,
        'data-a-walk-policy': A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY,
    };

    const handleESCPressed: KeyboardEventHandler = (e) => {
        if (e.key === 'Escape') {
            setShowDuration(false);
            e.stopPropagation();
        }
    };

    return (
        <div
            className="dnd-duration"
            onMouseEnter={() => setShowDuration(true)}
            onMouseLeave={() => setShowDuration(false)}
        >
            <button
                key="dnd-duration__title"
                className="dnd-duration__title"
                aria-label={CONTACTS_DND}
                onClick={() => setShowDuration(!showDuration)}
                {...(showDuration ? {} : parentA11yOption)}
            >
                <div className="dnd-duration__title__text">
                    <PresenceIcon presenceType={'dnd'} className={'presence-icon'} />
                    {CONTACTS_DND}
                </div>

                {/* if it's less than one minute, native client doesn't show it*/}
                {+snoozeDurationInSeconds > 60 ? (
                    <span className="dnd-duration__title__duration">{formatMinutes(snoozeDurationInSeconds)}</span>
                ) : null}
            </button>
            {showDuration && (
                <FocusLock disabled returnFocus={true}>
                    <div
                        className="dnd-duration__list"
                        onKeyDown={handleESCPressed}
                        ref={containerRef}
                        {...parentA11yOption}
                    >
                        {+snoozeDurationInSeconds > 0 ? (
                            <>
                                <button
                                    onClick={() => setStatusToSnooze(0)}
                                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                    data-a-l={dataALGenerator()}
                                >
                                    {TURN_OFF}
                                </button>
                                <div className="info-panel-divider"></div>
                            </>
                        ) : null}
                        <button
                            onClick={() => setStatusToSnooze(20)}
                            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                            data-a-l={dataALGenerator()}
                        >
                            {MINUTES_TEXT_F(20)}
                        </button>
                        {[1, 2, 4, 8, 24].map((hours) => (
                            <button
                                onClick={() => setStatusToSnooze(hours * 60)}
                                key={hours}
                                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                data-a-l={dataALGenerator()}
                            >
                                {HOURS_TEXT_F(hours)}
                            </button>
                        ))}
                    </div>
                </FocusLock>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const {
        contacts: { myPresencePolicyInfo },
    } = state;
    return {
        snoozeDurationInSeconds: myPresencePolicyInfo?.snooze.duration || 0,
    };
};

export default connect(mapStateToProps)(DndPanel);
