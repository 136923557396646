import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
    status: 'loading' | 'success';
}
const initialState: InitialState = {
    status: 'loading',
};

const DocsSlice = createSlice({
    name: 'docs',
    initialState,
    reducers: {
        setStatus(state, { payload }: PayloadAction<'loading' | 'success'>) {
            state.status = payload;
        },
    },
});

const { setStatus } = DocsSlice.actions;

export const actions = DocsSlice.actions;

export { setStatus };

export default DocsSlice.reducer;
