import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../routes/routePath';
import Whiteboard from './Whiteboard';
import WhiteboardLoading from './WhiteboardLoading';

export default () => {
    const isMatch = useRouteMatch(RoutePath.Whiteboards);

    const style = {
        display: isMatch ? 'block' : 'none',
    };

    return (
        <div className="pwa-wb" style={style}>
            <WhiteboardLoading />
            <Whiteboard />
        </div>
    );
};
