import { WebclientMessage } from '../../features/Meeting/MeetingAgent';
import EventEmitter from '../event-emitter';

export interface IMeetingEvent {
    meetingNumber: number | string;
    upgradePhoneCallToken?: number;
}

export interface IMeetingEndEvent {
    meetingInfo: any;
    reason: { reason: string; data?: any };
    hasWaitingToJoin: boolean;
}

export const meetingEvents = {
    success: new EventEmitter<IMeetingEvent>({ name: 'start a new meeting successfuly' }),
    end: new EventEmitter<IMeetingEndEvent>({ name: 'meeting ends. maybe you leave or host ends it' }),
    receiveWebclientMessage: new EventEmitter<WebclientMessage>({ name: 'webclient posts message' }),
};
