import { checkLanguageInCookie } from './utils/Cookies/utils';

window.PwaConfig = window.config();
Object.freeze(window.PwaConfig);

if (window.chatInitConfig) {
    Object.freeze(window.chatInitConfig);
}

// eslint-disable-next-line
__webpack_public_path__ = window.PwaConfig.cdnPath + '/';

/**
 * webclient iframe uses window.parent.name=== 'PWA' to decide if it's embeded in PWA.
 */
Object.defineProperty(window, 'name', {
    value: 'PWA',
    writable: false,
    configurable: false,
    enumerable: true,
});

/**
 * never load pwa in iframe
 * after we join/start meeting in an iframe, user closes(not shutdown) his computer for a long time, so that os put pwa asleep.
 * when user opens his computer next time, webclient resumes and reload itself. but since web login session has expired. we are redirected to login page.
 * after user login, web return back to pwa's index in the iframe. it is weired.
 */
if (window.top !== window.self) {
    window.stop();
    const pwaPath = window.encodeURI(`${window.location.href}`);
    window.top.location.href = pwaPath;
}

// this may reload current page;
checkLanguageInCookie();
