import React from 'react';
import classnames from 'classnames';
import { CalendarEvent, MeetingItem } from './types';
import useMeetingItem from './useMeetingItem';
import { Private_Text, Not_Zoom_Meeting_Text_1 } from '../LanguageResource';
import { A11Y_FOCUS_WALKER_POLICY, DOM_KEY_CODE } from '../../../utils/constants';

interface IProp {
    data: MeetingItem | CalendarEvent;
    selectedMeetingItem: MeetingItem | CalendarEvent;
    onSelect(arg: MeetingItem | CalendarEvent): void;
    a11yIndex: string;
}

export default ({ data, selectedMeetingItem, onSelect, a11yIndex }: IProp) => {
    const { topicTxt, timeTxt, hostTxt, meetingNoTxt, isWithZoomMeeting, isPrivateMeeting, canViewDetail } =
        useMeetingItem({ item: data });

    const mnTxt = isWithZoomMeeting ? meetingNoTxt : Not_Zoom_Meeting_Text_1;
    const isSelected = selectedMeetingItem && selectedMeetingItem.id === data.id;

    const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === DOM_KEY_CODE.ENTER || e.keyCode === DOM_KEY_CODE.SPACE) {
            e.preventDefault();
            onSelect(data);
        }
    };

    const onClickItem = () => {
        onSelect(data);
    };

    const clsname = classnames({
        'meetings__meeting-item': true,
        'meetings__meeting-item--selected': isSelected,
    });
    const showPrivate = isPrivateMeeting && !canViewDetail;
    return (
        <div
            className={clsname}
            onKeyDown={onInputKeyDown}
            onClick={onClickItem}
            data-select-id={data.id}
            tabIndex={isSelected ? 0 : -1}
            role="option"
            aria-selected={isSelected}
            data-a-l={a11yIndex}
            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
        >
            <div className="meetings__meeting-item-topic">{topicTxt}</div>
            {showPrivate ? <span>{Private_Text}</span> : null}
            {timeTxt ? <div className="meetings__meeting-item-time">{timeTxt}</div> : null}
            {hostTxt ? <div className="meetings__meeting-item-host overflow-ellipsis-1">{hostTxt}</div> : null}
            {mnTxt ? <div className="meetings__meeting-item-meeting-id">{mnTxt}</div> : null}
        </div>
    );
};
