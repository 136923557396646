import React, { useEffect } from 'react';
import FeedbackEveryone from './feedback-everyone';
import FeedbackRandomly from './feedback-randomly';
import { feedbackOptions } from '../enum';
import { useAppDispatch, useAppSelector } from '../../../Phone/types';
import { Modal } from 'Modal';
import './feedback-container.scss';
import { setModal } from '../../../../store/modal/modal-store';
import { goToPostAttendeeUrl, removeParams } from '../utils';

export default function Feedback() {
    const { webclientEnableFeedback, postAttendeeUrl } = useAppSelector(
        ({ meeting: { webclientEnableFeedback, postAttendeeUrl } }) => {
            return {
                webclientEnableFeedback,
                postAttendeeUrl,
            };
        },
    );
    const dispatch = useAppDispatch();
    const onClose = () => {
        dispatch(setModal({ name: 'feedback', data: { show: false } }));
        goToPostAttendeeUrl(postAttendeeUrl);
    };
    useEffect(() => {
        return () => {
            removeParams('feedback');
        }
    }, [])
    return (
        <Modal isOpen={true} isDraggable={false} needBackdrop={true}>
            <div className="feedback-container">
                <i className="modal-dialog__close-icon" tabIndex={0} onClick={onClose}></i>
                {webclientEnableFeedback === feedbackOptions.FEEDBACK_EVERYONE ? (
                    <FeedbackEveryone />
                ) : (
                    <FeedbackRandomly />
                )}
            </div>
        </Modal>
    );
}
