import React, { useState } from 'react';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';
import { composeContactSearchListDataSelector } from '../../redux';
import { IContact, JID } from '../../types';
import { FetchStatus } from '../../../../utils/constants';
import { CONTACTS_NO_RESULT } from '../../../../resource';
import Contact from '../ContactList/Contact';
import { getContactsInfoThunk } from '../../redux/contact-thunk';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { withA11y, keyBoardEventHandler, A11Y_POLICY } from '../../a11y';

const a11yBase = '0';
const ID = 'contactListWrapper';

const Contact_Height = 40;
const Contact_List_Overscan_Count = 10;

interface IProps {
    [setContact: string]: (jid: JID) => void;
}

const ContactSearchList = ({ setContact }: IProps) => {
    const dispatch = useAppDispatch();
    const totalList = useAppSelector(composeContactSearchListDataSelector);
    const searchStatus = useAppSelector((state) => state.contacts.searchStatus);
    const [currentRowRenderStartIndex, setCurrentRowRenderStartIndex] = useState(0);

    const getRowHeight = () => {
        return Contact_Height;
    };

    const noRowsRenderer = () => {
        let empty = null;
        if (FetchStatus.loading === searchStatus) {
            empty = (
                <div className="contacts-lists-loading ">
                    <i></i>
                </div>
            );
        }

        if (
            FetchStatus.succeeded === searchStatus ||
            FetchStatus.failed === searchStatus ||
            FetchStatus.idle === searchStatus
        ) {
            empty = <div className="contacts-lists-empty">{CONTACTS_NO_RESULT}</div>;
        }

        return empty;
    };

    const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
        const renderIndex = index - currentRowRenderStartIndex;
        const item = totalList[index];
        return (
            <Contact
                style={style}
                key={key}
                contact={item as IContact}
                setContact={setContact}
                a11yIndex={`${a11yBase}-${index}`}
                renderIndex={renderIndex}
                contactInGroupIndex={index + 1}
                groupTotal={totalList.length}
            />
        );
    };

    const onRowsRendered = ({ startIndex = 0, stopIndex = 0 }) => {
        setCurrentRowRenderStartIndex(startIndex);
        const showListData = totalList.slice(startIndex, stopIndex + 1).filter((_) => _.jid && _.jid.length > 0);
        // query contact presence status and profile info
        dispatch(getContactsInfoThunk(showListData));
    };

    return (
        <div
            className="contacts-list"
            id={ID}
            tabIndex={0}
            data-a-l={a11yBase}
            data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_POLICY}
        >
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        className="contacts-list-container"
                        tabIndex={-1}
                        height={height - 1} // reason same as why 'width - 1'
                        defaultWidth={true}
                        width={width - 1} // browser will have 77.45px, but react-virtualized will have 78px. scrollbar will appear
                        overscanRowCount={Contact_List_Overscan_Count}
                        noRowsRenderer={noRowsRenderer}
                        rowCount={totalList.length}
                        rowHeight={getRowHeight}
                        rowRenderer={rowRenderer}
                        onRowsRendered={onRowsRendered}
                    />
                )}
            </AutoSizer>
        </div>
    );
};

export default withA11y(ContactSearchList, ID, keyBoardEventHandler);
