import { useState, useRef } from 'react';

import { useAppSelector } from '../../..//store/store-hooks';
import { setUserInfoThunk } from '../../../store/common/common-store';
import { fetchSessionStatus, sessionExpireRelogin } from '../../../services/http/apis';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { getAsyncPhoneModule } from '../../../asyncModulesStore';

import useNetworkState from '../../../hooks/useNetworkState';
import usePageVisibility from '../../../hooks/usePageVisibility';
import useInterval from '../../../hooks/useInterval';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
dayjs.extend(Duration);

const STATUS_INTERVAL_TIME = 60 * 1000; // 60 seconds
const SHOW_BAR_TIME = 5 * 60 * 1000; // 5 minutes

export const formatExpiredTime = (secondsLong: number): string => {
    // > 1d
    if (secondsLong > 24 * 60 * 60) {
        return dayjs.duration(secondsLong, 's').format('DD:HH:mm:ss');
    }
    // > 1h
    else if (secondsLong > 60 * 60) {
        return dayjs.duration(secondsLong, 's').format('HH:mm:ss');
    } else {
        return dayjs.duration(secondsLong, 's').format('mm:ss');
    }
};

const useSessionExpireTimeControl = (): [number, boolean, () => void] => {
    const dispatch = useAppDispatch();
    const {
        common: { userInfo, isPhoneModuleLoaded },
    } = useAppSelector((state) => state);

    const latestDurationRef = useRef(userInfo.sessionExpireTime * 1000 || 0);
    const [duration, setDuration] = useState(latestDurationRef.current);

    // < 5 min, show
    const isDisplay = duration < SHOW_BAR_TIME && duration > 0;

    const hideBannerAndModal = () => {
        dispatch(setUserInfoThunk({ ...userInfo, sessionExpireTime: 0 }));
        dispatch(setModal({ name: 'sessionExpireTimeModal', data: { show: false, hasClickExpiredModalLater: false } }));
    };

    const relogin = () => {
        sessionExpireRelogin()
            .then((data) => {
                const { status, result } = data;
                // result: https://zoomdev.us/signin?from=pwa
                if (status && result) {
                    // clean user info, then page will route to index page
                    dispatch(setUserInfoThunk(null));
                    // if has phone, need clean
                    if (isPhoneModuleLoaded) {
                        getAsyncPhoneModule().then(({ cleanUpPhone }) => {
                            dispatch(cleanUpPhone());
                        });
                    }
                } else {
                    hideBannerAndModal();
                }
            })
            .catch((error) => {
                console.error('sessionExpireReloginThunk error ==>', error);
                hideBannerAndModal();
            });
    };

    const checkStatus = () => {
        fetchSessionStatus()
            .then((data) => {
                const { status, result } = data;
                if (status && result) {
                    const { hasSessionExpireTime, duration } = result;
                    if (hasSessionExpireTime) {
                        latestDurationRef.current = duration * 1000 || 0;
                        setDuration(latestDurationRef.current);
                    } else {
                        hideBannerAndModal();
                    }
                }
            })
            .catch((error) => {
                console.error('getSessionStatusThunk error ==>', error);
                hideBannerAndModal();
            });
    };

    usePageVisibility({
        onShow: () => {
            if (duration > 0) {
                checkStatus();
            }
        },
    });

    useNetworkState({
        onLine: () => {
            if (duration > 0) {
                checkStatus();
            }
        },
    });

    useInterval((clear) => {
        const nextDuration = duration - 1000;
        setDuration(nextDuration);
        const diff = (latestDurationRef.current - duration) % STATUS_INTERVAL_TIME;
        // Every 60s call check status api to correct time.
        if (duration > 0 && latestDurationRef.current !== duration && diff === 0) {
            checkStatus();
        }

        // 59 * 60 * 1000
        if (nextDuration <= 0) {
            relogin();
            // clear interval
            clear();
        }
    }, 1000);

    return [duration, isDisplay, relogin];
};

export default useSessionExpireTimeControl;
