import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { navigationMetrics } from '../logger/pwa-loggers';

const useRouteNavigationForLog = () => {
    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;
        navigationMetrics.switchRoute(pathname);
    }, [location]);

    useEffect(() => {
        return () => {
            navigationMetrics.updateWhenDestroy();
        };
    }, []);
};

export default useRouteNavigationForLog;
