/**
 * this is shown if user hasn't loged in.
 * he can just
 *  1. login
 *  2. join a meeting
 */

import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import './SignedIn.scss';
import Header from './Header/Header';
import Home from '../Home/Home';
import Contacts from '../Contacts/Contacts';
import Meetings from '../Meetings';
import Whiteboard from '../Whiteboard';

import { RoutePath } from '../../routes/routePath';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';

import { usePhoneCleanUp } from './hooksAboutPhone';
import Chat from '../Chat/Chat';
import CloudRecordingHint from '../CloudRecordingHint';
import { useChatInit } from '../Chat/chat-hooks';
import Banner from './Banner';
import { useGetWebSettings, useInitMeetingList, useInitXmpp, useInitZpns, useShowJoinOnlyToast } from './init-hooks';
import { useObserveWebLoginSession } from '../../utils/observe-web-login-session';
import { shallowEqual } from 'react-redux';
import { isCalendarEnabled, isChatEnabled, isDocsEnabled, isWhiteBoardEnabled } from '../../utils/featureOptions';
import PhoneRoute from './PhoneRoute';
import { shouldWeHidePhoneRoute } from '../../store/appModules/app-modules-selector';
import ContactCenterRoute from './ContactCenterRoute';
import DocsApp from '../Docs';
import MicroAppRouteGuard from './MicroAppRouteGuard';
import Calendar from '../Calendar/Calendar';

export default function SignedIn() {
    const dispatch = useAppDispatch();

    const { userInfo, hashedUserId } = useAppSelector((state) => {
        const {
            common: { userInfo, hashedUserId },
        } = state;
        return { userInfo, hashedUserId };
    }, shallowEqual);

    const showPhoneRoute = !useAppSelector(shouldWeHidePhoneRoute);

    const props = { dispatch, userInfo, hashedUserId };

    useInitMeetingList(props);
    useShowJoinOnlyToast(props);
    useInitXmpp(props);
    useGetWebSettings(props);
    useInitZpns(props);
    useObserveWebLoginSession(props);
    usePhoneCleanUp();
    useChatInit();

    return (
        <div className="home">
            <Header userInfo={userInfo} />
            <Banner />
            <Suspense fallback={null}>
                <Switch>
                    <Route exact path={RoutePath.Home}>
                        <CloudRecordingHint />
                        <Home />
                    </Route>

                    {showPhoneRoute ? (
                        <Route exact path={RoutePath.Phone}>
                            <PhoneRoute />
                        </Route>
                    ) : null}

                    <Route exact path={RoutePath.Meetings}>
                        <CloudRecordingHint />
                        <Meetings />
                    </Route>

                    <Route exact path={RoutePath.Contacts}>
                        <Contacts />
                    </Route>
                </Switch>
            </Suspense>

            <MicroAppRouteGuard
                render={() => <Whiteboard />}
                path={RoutePath.Whiteboards}
                enabled={isWhiteBoardEnabled()}
                key={RoutePath.Whiteboards}
            />

            <MicroAppRouteGuard
                render={() => <Chat />}
                path={RoutePath.Chat}
                enabled={isChatEnabled()}
                key={RoutePath.Chat}
                renderDirectly={true}
            />

            <MicroAppRouteGuard
                render={() => <DocsApp />}
                path={RoutePath.Docs}
                enabled={isDocsEnabled()}
                key={RoutePath.Docs}
                renderDirectly={true}
            />
             <MicroAppRouteGuard
                render={() => <Calendar />}
                path={RoutePath.Calendar}
                enabled={isCalendarEnabled()}
                key={RoutePath.Calendar}
                renderDirectly={true}
            />

            <MicroAppRouteGuard
                render={() => <ContactCenterRoute />}
                path={RoutePath.ContactCenter}
                enabled={true} // true is a tricky, exect value will be calculated inside component
                key={RoutePath.ContactCenter}
            />
        </div>
    );
}
