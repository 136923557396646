import { DBManager, STORE_NAME_MEETING_INFO } from '../../utils/db';
import { historyItem } from './MeetingHistory/MeetingHistory';

export const JoinMeetingDBManager = {
    key: 'meetingNoAndTopic',
    maxLen: 10,
    async addHistoryMeeting(meetingNo: number | string, topic: string) {
        const _data = await DBManager.getDataFromeDB(this.key, STORE_NAME_MEETING_INFO);
        const newData = { meetingNo, topic };

        if (_data) {
            // we need to merge the data with already existed data.
            const rawData = await DBManager.load(this.key, STORE_NAME_MEETING_INFO);
            if (!rawData) {
                DBManager.save([newData], this.key, STORE_NAME_MEETING_INFO);
                return;
            }
            const data = rawData.filter((item: historyItem) => item.meetingNo !== meetingNo);
            // limit the item number to 9
            if (data.length === this.maxLen) {
                data.pop();
            }
            data.unshift(newData);
            DBManager.save(data, this.key, STORE_NAME_MEETING_INFO);
        } else {
            // if new data, we just encrypt it and add to db
            DBManager.save([newData], this.key, STORE_NAME_MEETING_INFO);
        }
    },
    async getHistoryMeetingList() {
        const _data = await DBManager.getDataFromeDB(this.key, STORE_NAME_MEETING_INFO);
        if (!_data) {
            return [];
        }
        return await DBManager.load(this.key, STORE_NAME_MEETING_INFO);
    },
    clear() {
        DBManager.clear(this.key, STORE_NAME_MEETING_INFO);
    },
};
