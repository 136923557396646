import { useEffect, useState, useRef } from 'react';
import { useAppSelector } from '../store/store-hooks';
import { asyncModuleCollection, AsyncModule } from './asyncModules';

export interface IProps {
    onLoad?: (module: any) => void;
    onUnload?: () => void;
}

export function usePhoneModule(props?: IProps) {
    const [phone, setPhone] = useState<any>({});

    const {
        common: { isPhoneModuleLoaded },
    } = useAppSelector((_) => _);

    const lastLoadedRef = useRef(isPhoneModuleLoaded);

    useEffect(() => {
        if (isPhoneModuleLoaded) {
            const phoneM = asyncModuleCollection.getModule(AsyncModule.Phone);
            setPhone(phoneM);
            props?.onLoad && props.onLoad(phoneM);
        }

        if (!isPhoneModuleLoaded) {
            if (lastLoadedRef.current) {
                setPhone({});
                props?.onUnload && props.onUnload();
            }
        }

        lastLoadedRef.current = isPhoneModuleLoaded;
    }, [isPhoneModuleLoaded]);

    return phone;
}
