import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownProps } from 'react-bootstrap/Dropdown';
import { DropdownToggleProps } from 'react-bootstrap/DropdownToggle';
import { DropdownMenuProps } from 'react-bootstrap/DropdownMenu';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';

import { IconChevronDownSmallFill } from '@zoom/icons-react';
import './DropdownSelect.scss';

interface option {
    label: string | number;
    value: string;
}

type SelectOptions = Array<option>;

interface IProps {
    value: string;
    options: SelectOptions;

    onSelect?: (key: string, options: SelectOptions) => void;
    selectTitle?: (options: SelectOptions) => string | any;

    dropdownToggleProps?: DropdownToggleProps;
    dropdownMenuProps?: DropdownMenuProps;
    dropdownItemProps?: DropdownItemProps;
}

const DropdownSelect = (props: Omit<IProps & DropdownProps, 'children'>) => {
    const { options, value, selectTitle, onSelect, dropdownToggleProps, dropdownMenuProps, dropdownItemProps } = props;
    const [selectValue, setSelectValue] = useState(value || '');
    const [isOpen, setIsOpen] = useState(false);

    const hasOptions = options?.length > 0 || false;

    const _toogleTitle = () => {
        if (selectTitle) {
            return selectTitle(options);
        }

        const option = options.find((option) => option.value === selectValue) || { label: '', value: '' };
        return option.label || '';
    };

    const _onSelect = (eventKey: string) => {
        setSelectValue(eventKey);
        onSelect && onSelect(eventKey, options);
    };

    const _onToggle = (isOpen: boolean) => {
        setIsOpen(isOpen);
    };

    const arrowIconCls = isOpen
        ? 'dropdown__select-toggle-arrow dropdown__rotate-180deg'
        : 'dropdown__select-toggle-arrow';

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    return (
        <Dropdown onToggle={_onToggle} onSelect={_onSelect}>
            <Dropdown.Toggle className="dropdown__select-toggle" as="button" {...dropdownToggleProps}>
                <div className="dropdown__select-toggle-container">
                    <span className="dropdown__select-toggle-title">{_toogleTitle()}</span>
                    {hasOptions && <IconChevronDownSmallFill className={arrowIconCls} />}
                </div>
            </Dropdown.Toggle>

            {hasOptions && (
                <Dropdown.Menu className="dropdown__select-menu" {...dropdownMenuProps}>
                    {(options as SelectOptions).map(({ label, value }) => {
                        const selected = value === selectValue;
                        return (
                            <Dropdown.Item
                                className="dropdown__select-menu-item"
                                as="button"
                                key={value}
                                eventKey={value}
                                active={selected}
                                {...dropdownItemProps}
                            >
                                <span>{label}</span>
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            )}
        </Dropdown>
    );
};

export default DropdownSelect;
