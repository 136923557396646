import { getChromeOSAdminPolicy } from './get-policy';

export enum CheckLoginResult {
    Pass = 0, // allowed login method
    Reject, // current login method is rejected
    RejectAll, // all login methods are rejected
}

export enum LoginMethod {
    Facebook = 0,
    Google = 1, //do not support now.
    GoogleOAuth = 2,
    Phone = 11,
    Wechat = 21,
    QQ = 22,
    ALiPay = 23,
    Apple = 24,
    Zdm = 26,
    Microsoft = 27,
    Zoom = 100,
    SSO = 101,
}

export const maybeAutoLoginWithGoogle = async () => {
    const policy = await getChromeOSAdminPolicy();
    if (!policy?.AutoLoginWithChromeBookUserAccount || policy?.DisableGoogleLogin) {
        return false;
    }
    window.location.href = `${window.location.origin}/google_oauth_signin?from=pwa`;
    return true;
};

/**
 * refer to: https://support.zoom.us/hc/en-us/articles/203806069
 *
 */

interface ICheckProps {
    stype: LoginMethod;
}

export async function checkUserLoginMethodWithChromePolicy(props: ICheckProps): Promise<CheckLoginResult> {
    const { stype } = props;

    const policy = await getChromeOSAdminPolicy();
    if (!policy) return CheckLoginResult.Pass;

    const {
        DisableGoogleLogin,
        DisableFacebookLogin,
        DisableLoginWithSSO,
        DisableLoginWithEmail,
        DisableAppleLogin,
        ForceLoginWithSSO,
    } = policy;

    // all methods has been disabled
    if (
        DisableAppleLogin &&
        DisableFacebookLogin &&
        DisableGoogleLogin &&
        DisableLoginWithEmail &&
        DisableLoginWithSSO &&
        !ForceLoginWithSSO
    ) {
        return CheckLoginResult.RejectAll;
    }

    if (DisableGoogleLogin && stype === LoginMethod.Google) {
        return CheckLoginResult.Reject;
    }

    if (DisableFacebookLogin && stype === LoginMethod.Facebook) {
        return CheckLoginResult.Reject;
    }

    if (DisableLoginWithSSO && !ForceLoginWithSSO && stype === LoginMethod.SSO) {
        return CheckLoginResult.Reject;
    }

    if (DisableLoginWithEmail && stype === LoginMethod.Zoom) {
        return CheckLoginResult.Reject;
    }

    if (DisableAppleLogin && stype === LoginMethod.Apple) {
        return CheckLoginResult.Reject;
    }

    return CheckLoginResult.Pass;
}
