import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { groupedMeetingsSelector, selectedMeetingItemSelector, setSelectedMeetingItemId } from './redux/meetings-store';
import { MeetingItem as IMeetingItem } from './types';
import MeetingItem from './MeetingItem';
import './Meetings.scss';
import { No_Upcoming_Meetings_Text } from '../LanguageResource';
import { withFocusWalker } from '../../../hoc/withFocusWalker';
import { keyBoardEventHandler } from '../../../utils/keyboard-event';
import { A11Y_FOCUS_WALKER_POLICY } from '../../../utils/constants';

import { makeA11yListInfoInjector } from '../../../utils';

const ID = 'meetingsMeetingGroup';
const MeetingGroups = () => {
    const dispatch = useAppDispatch();

    const groupedMeetings = useAppSelector(groupedMeetingsSelector);
    const selectedMeetingItem = useAppSelector(selectedMeetingItemSelector);

    const a11yBase = '0';
    const containerRef = useRef(null);
    const { dataALGenerator } = makeA11yListInfoInjector(a11yBase, containerRef);

    const onSelect = (item: IMeetingItem) => {
        dispatch(setSelectedMeetingItemId(item.id));
    };

    const empty = <div className="meetings__groups-empty">{No_Upcoming_Meetings_Text}</div>;

    const groups = groupedMeetings.map((group, index) => {
        const { label, items } = group;
        return (
            <React.Fragment key={group.label}>
                <div className="meetings__group">
                    <div className="meetings__group-label">{label}</div>
                    {items.map((item) => {
                        return (
                            <MeetingItem
                                key={item.id}
                                data={item}
                                selectedMeetingItem={selectedMeetingItem}
                                onSelect={onSelect}
                                a11yIndex={dataALGenerator()}
                            />
                        );
                    })}
                </div>
                {index === groupedMeetings.length - 1 ? null : <div className="meetings__groups-divider" />}
            </React.Fragment>
        );
    });

    return (
        <div
            className="meetings__groups"
            role="listbox"
            tabIndex={0}
            id={ID}
            ref={containerRef}
            data-a-l={a11yBase}
            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_POLICY}
        >
            {groupedMeetings.length > 0 ? groups : empty}
        </div>
    );
};

export default withFocusWalker(MeetingGroups, ID, () => true, ['.meetings__groups'], keyBoardEventHandler);
