import React, { MouseEventHandler, useRef, useState } from 'react';
import { IconPlusSmall } from '@zoom/icons-react';
import { OverlayTrigger } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import OperationMenu from './OperationMenu';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { ADD_A_CONTACT } from '../../../../resource';
import './ContactOperation.scss';
import { DOM_KEY_CODE } from '../../../../utils/constants';

const OperationButton = () => {
    const [show, setShow] = useState(false);
    const ariaId = 'contact-operation-menu';
    const buttonRef = useRef(null);

    const onToggle: MouseEventHandler = (e) => {
        e?.stopPropagation();
        setShow(!show);
    };

    const closePouup = () => {
        setShow(false);
    };

    const rednerPopup = (props: OverlayInjectedProps) => {
        return <OperationMenu close={closePouup} ariaId={ariaId} {...props} />;
    };

    const popperConfig = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [24, 5],
                },
            },
        ],
    };

    const menuRef = useClickOutside({
        callback: (flag: boolean) => {
            if (flag) {
                setShow(false);
            }
        },
    });

    // if menu opened, intercept click except ESC and SPACE key.
    const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (!show) return;

        if (e.keyCode === DOM_KEY_CODE.ESC) {
            buttonRef.current?.focus();
            setShow(false);

            return;
        }

        if (e.keyCode === DOM_KEY_CODE.SPACE || e.keyCode === DOM_KEY_CODE.TAB) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div ref={menuRef} onKeyDown={onKeyDown}>
            <OverlayTrigger
                overlay={rednerPopup}
                placement="bottom-start"
                trigger={['click']}
                show={show}
                rootClose={true}
                popperConfig={popperConfig}
            >
                <button
                    title={ADD_A_CONTACT}
                    aria-haspopup="menu"
                    aria-expanded={show}
                    className="contact-operation__button"
                    ref={buttonRef}
                    onClick={onToggle}
                >
                    <IconPlusSmall aria-hidden="true" className="contact-operation__button-icon" />
                </button>
            </OverlayTrigger>
        </div>
    );
};

export default OperationButton;
