import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppModuleName = 'contact-center' | 'zoom-phone';

interface IAppModule {
    async: boolean; // if it's a sync or async chunk
    willLoad: boolean; // this module may not be loaded 1) user did not buy licence; 2) op flags; 3) platform, eg. tesla; 4) others
    willLoadChecked: boolean; // we MAY need call apis to check willLoad
    loaded: boolean; // after module loaded, all it's exports could be used
}

const DefaultModule: IAppModule = {
    async: true,
    willLoad: false,
    willLoadChecked: false,
    loaded: false,
};

interface IZoomPhoneModule extends IAppModule {
    hasZoomPhonePlan: boolean | undefined;
    pwaFlagEnabled: boolean | undefined;
}

const getZoomPhoneModuleDefault = (): IZoomPhoneModule => {
    return Object.assign({}, DefaultModule, {
        hasZoomPhonePlan: undefined,
        pwaFlagEnabled: undefined,
    });
};

interface IState {
    'contact-center': IAppModule;
    'zoom-phone': IZoomPhoneModule;
}

const initialState: IState = {
    'contact-center': DefaultModule,
    'zoom-phone': getZoomPhoneModuleDefault(),
};

const appModulesSlice = createSlice({
    name: 'app-modules',
    initialState,
    reducers: {
        setAppModule(
            state,
            { payload }: PayloadAction<{ module: AppModuleName; data: Partial<IZoomPhoneModule | IAppModule> }>,
        ) {
            const { module, data } = payload;
            Object.assign(state[module], data);
        },
    },
});

export const { setAppModule } = appModulesSlice.actions;

export default appModulesSlice.reducer;
