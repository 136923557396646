import React from 'react';
import { CalendarEvent, MeetingItem } from '../Meetings/types';
import useMeetingItem from '../Meetings/useMeetingItem';
import MeetingDetailTime from '../Meetings/MeetingDetailTime';
import UpcomingActions from './UpcomingActions';
import { Not_Zoom_Meeting_Text_1, Private_Text } from '../LanguageResource';
import { isTeslaMode } from '../../../utils';
import UpcomingActionsTesla from './UpcomingActionsTesla';
interface IPropMeeting {
    data: MeetingItem | CalendarEvent;
    toView(arg: string): void;
}

export default function UpcomingDetailMeeting({ data, toView }: IPropMeeting): JSX.Element {
    const { isWithZoomMeeting, topicTxt, timeTxt, hostTxt, meetingNoTxt, isPrivateMeeting, canViewDetail } =
        useMeetingItem({
            item: data,
        });
    const { id } = data;
    const isTesla = isTeslaMode();
    const meetigNumberTxt = isWithZoomMeeting ? meetingNoTxt : Not_Zoom_Meeting_Text_1;

    const showActions = !isTesla && (!isPrivateMeeting || canViewDetail);
    return (
        <div className="upcoming__detail">
            <div className="upcoming__detail-topic-wrapper">
                <span className="upcoming__detail-topic" title={topicTxt} onClick={() => toView(data.id)}>
                    {topicTxt}
                </span>
                {showActions ? <UpcomingActions data={data} /> : null}
            </div>
            {isPrivateMeeting ? (
                <span className="upcoming__detail-topic-private">
                    <span>{Private_Text}</span>
                    <i className="black-lock-icon"></i>
                </span>
            ) : null}
            {timeTxt ? <MeetingDetailTime data={data} timeTxt={timeTxt} key={id} /> : null}
            {meetigNumberTxt ? <div className="upcoming__detail-mn">{meetigNumberTxt}</div> : null}
            {hostTxt ? (
                <div className="upcoming__detail-host overflow-ellipsis-1" title={hostTxt}>
                    {hostTxt}
                </div>
            ) : null}
            {isTesla ? <UpcomingActionsTesla data={data} /> : null}
        </div>
    );
}
