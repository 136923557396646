import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '../../../utils/constants';
import { getContactSortKey, getContactDisplayName, putGroupIntoThisLabel, sortLabelGroups } from './contact-utils';
import {
    DisplayType,
    GROUP_ID,
    IContact,
    IContactState,
    IGroup,
    IGroupLoadingState,
    IPresence,
    LABLE_GROUP,
    ISelectedContact,
} from '../types';
import { isPendingContactJid, isExternalGroup, CAMERA_CONTROL_GROUP_ID } from '../utils/stringUtils';

interface IGroupUtilStateValue {
    sorted: boolean;
}

const groupUtilState: Record<GROUP_ID, IGroupUtilStateValue> = {};

export const initialState: IContactState = {
    currentDisplayType: DisplayType.contact,
    presences: {},

    myPresencePolicyInfo: {
        snooze: { duration: 0, from: 0, to: 0 },
    },

    isReceivedGroupsData: false,

    groups: {},
    contacts: {},
    groupToContactTable: {},
    labelToGroupTable: {},
    ui: {
        groups: {},
    },

    selectedContact: null, // only contact jid
    selectedItem: null, // contact jid or group id

    groupsLoadingState: {},

    searchStatus: FetchStatus.idle,
    searchKey: '',
    searchResult: [],
    scrollTop: 0,

    pendingContacts: [],
};

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        setCurrentDisplayType(state, { payload }) {
            state.currentDisplayType = payload;
            if (DisplayType.contact === payload) {
                state.searchStatus = FetchStatus.idle;
                state.searchResult = [];
                state.searchKey = '';
            }
        },

        setGroups(state, { payload }: PayloadAction<{ groups: Array<IGroup> }>) {
            const { groups } = payload;
            const changedLabels = {};
            console.time('setGroups uuuuuuuuuuuuuuu');
            groups.forEach((group) => {
                // put into propery label array;
                const { id, groupFrom } = group;
                const putWhere = putGroupIntoThisLabel(group);
                if (!state.labelToGroupTable[putWhere]) {
                    state.labelToGroupTable[putWhere] = [];
                }
                const putList = state.labelToGroupTable[putWhere];
                if (!putList.find((p) => p.id === id)) {
                    putList.push({
                        id,
                        groupFrom,
                    });
                    changedLabels[putWhere] = true;
                }

                // put into groups (entities);
                if (!state.groups[id]) {
                    state.groups[id] = group;
                    state.ui.groups[id] = { folded: true };
                } else {
                    Object.assign(state.groups[id], group);
                }
            });

            // sort
            Object.keys(changedLabels).forEach((labelId) => {
                const result = sortLabelGroups(labelId as LABLE_GROUP, state.labelToGroupTable[labelId], state.groups);
                if (result) {
                    state.labelToGroupTable[labelId] = result;
                }
            });
            console.timeEnd('setGroups uuuuuuuuuuuuuuu');
        },

        setThisGroupContacts(state, { payload }: PayloadAction<{ groupId: GROUP_ID; contacts: Array<IContact> }>) {
            const { groupId, contacts } = payload;
            const groupContactIdList = state.groupToContactTable[groupId] || [];

            // add/update contact
            contacts.forEach((contact) => {
                const { jid } = contact;
                if (!state.contacts[jid]) {
                    state.contacts[jid] = contact;
                } else {
                    Object.assign(state.contacts[jid], contact);
                }
            });

            // update group-contact table
            // Maybe produce duplicate data when pull same group data many times, so dedup.
            state.groupToContactTable[groupId] = [
                ...new Set(groupContactIdList.concat(contacts.map((con) => con.jid))),
            ];

            let needSort = false;
            if (!groupUtilState[groupId]) {
                needSort = true;
                groupUtilState[groupId] = { sorted: false };
            }

            // for the first request, state.ui.groups[groupId] may not exist
            if (state.ui.groups[groupId]?.folded) {
                needSort = true;
            }

            if (needSort) {
                state.groupToContactTable[groupId].sort((left, right) => {
                    const leftContact = state.contacts[left];
                    const rightContact = state.contacts[right];
                    return getContactDisplayName(leftContact).localeCompare(getContactDisplayName(rightContact));
                });
            }
            groupUtilState[groupId].sorted = needSort;
        },

        updateContacts(state, { payload }: PayloadAction<{ contacts: Array<IContact> }>) {
            const { contacts } = payload;
            // add/update contact
            contacts.forEach((contact) => {
                const { jid } = contact;
                if (!state.contacts[jid]) {
                    state.contacts[jid] = contact;
                } else {
                    Object.assign(state.contacts[jid], contact);
                }
            });
        },

        setScrollTop(state, { payload }) {
            state.scrollTop = payload;
        },

        setPresence(state, { payload }) {
            const { data: pList = [] } = payload as { data: Array<IPresence> };
            pList.forEach((p) => {
                const { jid, presenceType } = p;
                state.presences[jid] = { ...state.presences[jid], jid, presenceType };
            });
        },

        setMySnoozePresenceInfo(state, { payload }) {
            state.myPresencePolicyInfo['snooze'] = payload;
        },

        setGroupFolded(state, { payload }) {
            const { id, flag } = payload;
            const prevFolded = state.ui.groups[id].folded;
            // after you folded, we may need to sort current group's members
            if (!prevFolded && flag && !groupUtilState[id]?.sorted) {
                state.groupToContactTable[id].sort((left, right) => {
                    const leftContact = state.contacts[left];
                    const rightContact = state.contacts[right];
                    return getContactDisplayName(leftContact).localeCompare(getContactDisplayName(rightContact));
                });
                groupUtilState[id].sorted = true;
            }
            state.ui.groups[id].folded = flag;
            state.selectedItem = { groupId: id };
        },

        setGroupsLoadingState(state, { payload }: PayloadAction<Partial<IGroupLoadingState>>) {
            const { id } = payload;
            const gState = state.groupsLoadingState[id];
            if (!gState) {
                state.groupsLoadingState[id] = payload as IGroupLoadingState;
            } else {
                Object.assign(state.groupsLoadingState[id], payload);
            }
        },

        setSelectedContact(state, { payload }: PayloadAction<ISelectedContact>) {
            state.selectedContact = payload;
            state.selectedItem = payload;
        },

        setSearchStatus(state, { payload }) {
            state.searchStatus = payload;
        },

        setSearchKey(state, { payload }) {
            state.searchKey = payload;
        },

        setSearchResult(state, { payload }: PayloadAction<{ key: string; from: string; result: Array<IContact> }>) {
            // i do not cancel search http request.
            // this is for requests that sent earlier but get back later.
            const { key, from, result = [] } = payload;
            if (key === state.searchKey) {
                if (from === 'web') {
                    const temp = result.filter((i) => !state.searchResult.find((j) => j.jid === i.jid));
                    state.searchResult = [...state.searchResult, ...temp];
                }
                if (from === 'local') {
                    state.searchResult = result;
                }
                state.searchResult.sort((left, right) => {
                    const leftKey = getContactSortKey(left);
                    const rightKey = getContactSortKey(right);
                    return leftKey.localeCompare(rightKey);
                });
            }
        },
        deletGroups(state, { payload }: PayloadAction<Array<string>>) {
            payload.forEach((groupId) => {
                const labelList = Object.keys(state.labelToGroupTable);
                labelList.forEach((labelId) => {
                    const labelGroups = state.labelToGroupTable[labelId];
                    labelGroups.forEach((label, index) => {
                        if (label.id === groupId) {
                            labelGroups.splice(index, 1);
                        }
                    });
                });

                if (groupId in state.groups) {
                    delete state.groups[groupId];
                }

                if (groupId in state.ui.groups) {
                    delete state.ui.groups[groupId];
                }

                if (groupId in state.groupsLoadingState) {
                    delete state.groupsLoadingState[groupId];
                }
            });
        },
        deleteGroupMembers(state, { payload }: PayloadAction<{ groupId: string; jids: Array<string> }>) {
            const { groupId, jids } = payload;

            if (jids.length <= 0) return;

            // delete from groupToContactTable
            if (groupId in state.groupToContactTable) {
                const groupContactIdList = state.groupToContactTable[groupId];
                state.groupToContactTable[groupId] = groupContactIdList.filter((id) => !jids.includes(id));
            }

            // delete from contacts
            const contactsIds = state.contacts;
            jids.forEach((id) => {
                if (id in contactsIds) {
                    delete contactsIds[id];
                }
            });
            state.contacts = contactsIds;

            // delete from pendingContacts
            if (isExternalGroup(groupId)) {
                const pendingJids = jids.filter((jid) => isPendingContactJid(jid));
                // delete pending contact by jid
                if (pendingJids.length > 0) {
                    state.pendingContacts = state.pendingContacts.filter((item) => !pendingJids.includes(item.jid));
                }
            }
        },
        setPendingContacts(state, { payload }) {
            state.pendingContacts = payload;
        },
        refreshCameraContolContactGroupMember(state, { payload }) {
            // remove camera control group member
            if (payload.length > 0) {
                state.groupToContactTable[CAMERA_CONTROL_GROUP_ID] = payload;
                state.groups[CAMERA_CONTROL_GROUP_ID].total = payload.length;
            }
        },
        deleteCameraContolContact(state) {
            delete state.groups[CAMERA_CONTROL_GROUP_ID];
            delete state.groupToContactTable[CAMERA_CONTROL_GROUP_ID];
            delete state.ui.groups[CAMERA_CONTROL_GROUP_ID];

            const myList = state.labelToGroupTable[LABLE_GROUP.MY];
            if (myList) {
                const index = myList.findIndex((p) => p.id === CAMERA_CONTROL_GROUP_ID);
                if (index > -1) {
                    myList.splice(index, 1);
                }
            }
        },
        setCameraControlGroupMemberCount(state, { payload }) {
            state.groups[CAMERA_CONTROL_GROUP_ID].total = payload;
        },
    },
});

export const {
    setCurrentDisplayType,
    setGroups,
    setThisGroupContacts,
    updateContacts,
    setPresence,
    setGroupFolded,
    setSelectedContact,
    setSearchStatus,
    setSearchKey,
    setScrollTop,
    setSearchResult,
    setMySnoozePresenceInfo,
    setGroupsLoadingState,
    deletGroups,
    deleteGroupMembers,
    setPendingContacts,
    refreshCameraContolContactGroupMember,
    deleteCameraContolContact,
    setCameraControlGroupMemberCount,
} = contactSlice.actions;

export default contactSlice.reducer;
