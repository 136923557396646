import store from '../store';

import * as serviceWorker from '../serviceWorker';
import ServiceWorkerManager from '../ServiceWorkerManager';
export const serviceWorkerManager = new ServiceWorkerManager({ store: store });

if (process.env.NODE_ENV === 'development') {
    serviceWorker.unregister();
} else {
    serviceWorker.register({
        onUpdate: () => {
            serviceWorkerManager.onUpdateAvailable();
        },
    });
}
