/**
 * IndexDB Name
 */
export const PWA_DB_NAME = 'pwa-portal';

/**
 * IndeDB Vesion
 * do remember to change the version new objectStore is added
 * */
export const PWA_DB_VERSION = 4;

/**
 * OBJECT STORE NAME
 *
 * all the store name define here
 * */
export const STORE_NAME_MAIN = 'main';
export const STORE_NAME_MEETING_INFO = 'meetingInfo';
export const STORE_NAME_PENDING_CONTACT_INFO = 'pendingContactInfo';
export const STORE_NAME_CAMERA_CONTROL = 'cameraControl';
