import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { getNameAbbr, getColorIndex } from '../../utils';
import '../../assets/styles/main.scss';
import './Avatar.scss';
import ImageContainer from '../ImageContainer/ImageContainer';
import { IconProfileFill } from '@zoom/icons-react';
import { ReactComponent as IconProfileGroupFill } from '../../assets/images/group_avatar.svg';
import useIsStranger from '../../hooks/useIsStranger';

interface IProps {
    isDeactive?: boolean;
    jid?: string;
    displayName: string;
    picUrl?: string;
    extraClass?: string;
    style?: CSSProperties;
    bannerComponent?: string | JSX.Element;
    isGroupChat?: boolean; // = false,
    onClickAvatar?: () => any;
    imgClass?: string;
    tabIndex?: number;
    isActive?: boolean;
}

const Avatar = ({
    isDeactive = false,
    jid = '',
    displayName = '',
    extraClass = '',
    picUrl,
    style = {},
    bannerComponent = null,
    isGroupChat = false,
    onClickAvatar = () => {
        // empty
    },
    imgClass = '',
    tabIndex,
}: IProps) => {
    const isStranger = useIsStranger(jid);
    const getDisplayNameClass = () => {
        return `bgColor${getColorIndex(jid || displayName, isDeactive)}`;
    };

    const placeHolderName: JSX.Element = (
        isGroupChat
            ? <div className='list-item__avatar list-item__avatar-group'><IconProfileGroupFill className="unknown-avatar-icon" aria-hidden /></div>
            : isStranger
                ? <div className='list-item__avatar'><IconProfileFill className="unknown-avatar-icon" aria-hidden /></div>
                : <span className={classnames('_avatar__name', getDisplayNameClass())}>{getNameAbbr(displayName)}</span>
    );

    return (
        <div className="_avatar" onClick={onClickAvatar} tabIndex={tabIndex}>
            <div className={classnames('_avatar__pic-wrapper', extraClass)} style={style}>
                <ImageContainer
                    url={picUrl}
                    alt="Avatar"
                    imgClass={classnames('_avatar__pic', imgClass)}
                    placeHolder={placeHolderName}
                />
            </div>
            {bannerComponent}
        </div>
    );
};

export default Avatar;
