import { useState, useLayoutEffect, useEffect, useRef } from 'react';

interface WindowSize {
    width: number;
    height: number;
}

interface Handler {
    (p: WindowSize): void;
}

function useWindowSize(handler: Handler, delay?: number): WindowSize {
    const [counter, setCounter] = useState(0);
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handlerRef = useRef(handler);
    useLayoutEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    useEffect(() => {
        const handler = () => {
            const size = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            if (handlerRef.current) {
                handlerRef.current(size);
            }
            setWindowSize(size);
        };

        let timer = -1;
        if (delay === undefined) {
            handler();
        } else {
            timer = window.setTimeout(handler, delay);
        }

        return () => {
            if (timer !== -1) {
                window.clearTimeout(timer);
            }
        };
    }, [counter]);

    useEffect(() => {
        const listener = () => {
            setCounter((c) => c + 1);
        };
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, []);

    useLayoutEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, []);

    return windowSize;
}

export default useWindowSize;
