import { default as getStore } from '../../store-retriever';
import { isTeslaMode } from '../../../utils';
import { getAsyncContactCenterModule } from '../../../asyncModulesStore';
import { isContactCenterEnabled } from '../../../utils/clientCapability/userSettingQuery';
import { getUserWebSettingsThunk } from '../../common/common-thunk';
import { setAppModule } from '../app-modules-store';
import { AppStore, RootState } from '../..';
import { isZCCEnabled } from '../../../utils/featureOptions';

const store = getStore();
const isTesla = isTeslaMode();
const noPermission = () => Promise.reject('zcc: no permission');
const notSupport = () => Promise.reject('zcc: not support');

const getModule = () => {
    return getAsyncContactCenterModule().then((module) => {
        store.dispatch(
            setAppModule({
                module: 'contact-center',
                data: {
                    loaded: true,
                },
            }),
        );
        return module;
    });
};

const checkZCCAgainstWebSetting = () => {
    const {
        common: { userWebSettings },
    } = store.getState();

    const check = () => {
        const {
            common: { userWebSettings },
        } = store.getState();
        return isContactCenterEnabled(userWebSettings?.featureOptions5);
    };

    if (userWebSettings) {
        return Promise.resolve(check());
    } else {
        return store.dispatch(getUserWebSettingsThunk()).then(check);
    }
};

export const checkWillLoadContactCenter = (): Promise<boolean> => {
    const { appModules } = store.getState();
    const { willLoad, willLoadChecked, loaded } = appModules['contact-center'];

    if (loaded) {
        return Promise.resolve(true);
    }

    if (isTesla) {
        return notSupport();
    }

    if (!isZCCEnabled()) {
        return noPermission();
    }

    const checkWillLoad = (will: boolean) => {
        if (will) {
            return Promise.resolve(true);
        } else {
            return noPermission();
        }
    };

    if (willLoadChecked) {
        return checkWillLoad(willLoad);
    } else {
        return checkZCCAgainstWebSetting()
            .then((websettings) => {
                const willLoad = Boolean(websettings);
                store.dispatch(
                    setAppModule({
                        module: 'contact-center',
                        data: {
                            willLoadChecked: true,
                            willLoad,
                        },
                    }),
                );
                return willLoad;
            })
            .then(checkWillLoad);
    }
};

export const getContactCenterModule = () => {
    return checkWillLoadContactCenter().then(getModule);
};

export const isZCCInVideoCall = (store: AppStore | RootState) => {
    const state = 'getState' in store ? store.getState() : store;
    const contactCenter = state.contactCenter;
    if (!contactCenter) {
        return false;
    }
    const { currentCall } = contactCenter;

    if (!currentCall) {
        return false;
    }

    return currentCall.type === 'video';
};

export const isZCCUsingAudio = (store: AppStore | RootState) => {
    const state = 'getState' in store ? store.getState() : store;
    const contactCenter = state.contactCenter;
    if (!contactCenter) {
        return false;
    }
    const { audioStatus } = contactCenter;
    return Boolean(audioStatus?.audioStatus);
};
