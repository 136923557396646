import { getAsyncPhoneModule } from '../../asyncModulesStore';
import { setAppModule } from '../../store/appModules/app-modules-store';
import { setModal } from '../../store/modal/modal-store';
import httpRequest from './http';
import serviceContainer from '../container';
import { RoutePath, history as appHistory } from '../../routes';
import { matchPath } from 'react-router-dom';
import { getAppLoadPathname } from '../../utils/captureAppLoadUrl';
import { Phone_Log_Tag, onLoadLogger } from '../../logger/pwa-loggers';
import { EPhoneLoadType, type IPhoneLoadEvent } from '../../events/event-modules/phone-events';

const fetchPbxPermissionUrl = '/api/v2/pbx/webRTC/pb/getUserSipConf';

const matchIsPhonePath = (path: string) => {
    return !!matchPath(path.replace('/wc', ''), { path: RoutePath.Phone, exact: true });
};

export const isAppLoadedFromZoomPhoneRoute = () => {
    return matchIsPhonePath(getAppLoadPathname());
};

/**
 * 1. you load /wc/phone
 * 2. we check if you have permission to access zoom phone in pwa
 * 3. before the check completes,
 *      - you don't switch to other tab (eg, you click meetings/team chat tab)
 *          * we redirect you to home tab and show CanNotShowZoomPhone dialog.
 *      - you switch to other tab (eg team-chat)
 *          * we stay here and show CanNotShowZoomPhone dialog.
 */
const maybeRedirectAfterCheckPermissionFailed = () => {
    if (matchIsPhonePath(appHistory.location.pathname)) {
        const state = appHistory.location.state;
        appHistory.replace(RoutePath.Home, state);
    } else {
        // we do not redirect
    }
};

export const fetchPbxPermission = () => {
    const reduxStore = serviceContainer.getReduxStore();
    const phoneEvents = serviceContainer.getEventBus()?.phone;

    const { dispatch, getState } = reduxStore;

    const {
        common: { isPhoneModuleLoaded, userInfo },
    } = getState();

    if (isPhoneModuleLoaded) {
        return Promise.resolve(false);
    }
    if (!userInfo) {
        return Promise.resolve(false);
    }

    const { pbxToken, pbxDomain } = userInfo;

    const setZoomPhoneHasPlan = (flag: boolean) => {
        const event: IPhoneLoadEvent = { type: EPhoneLoadType.NoLicence, reason: 'No phone licence' };

        if (!flag) {
            phoneEvents.load.dispatchEvent(event);
        }

        dispatch(setAppModule({ module: 'zoom-phone', data: { hasZoomPhonePlan: flag } }));
    };

    const setZoomPhoneFlagEnabled = (flag: boolean) => {
        const event: IPhoneLoadEvent = { type: EPhoneLoadType.NotEnabled, reason: 'Not enable for pwa' };

        if (!flag) {
            phoneEvents.load.dispatchEvent(event);
        }

        dispatch(setAppModule({ module: 'zoom-phone', data: { pwaFlagEnabled: flag } }));
    };

    const openCanNotShowZoomPhoneModal = () => {
        maybeRedirectAfterCheckPermissionFailed();
        if (!isAppLoadedFromZoomPhoneRoute()) {
            return;
        }
        dispatch(
            setModal({
                name: 'canNotShowZoomPhone',
                data: { show: true },
            }),
        );
    };

    if (!pbxToken?.access_token) {
        onLoadLogger.log('No phone licence', Phone_Log_Tag);
        setZoomPhoneHasPlan(false);
        openCanNotShowZoomPhoneModal();
        return Promise.resolve(false);
    } else {
        setZoomPhoneHasPlan(true);
    }

    const { access_token, x_zm_aid, x_zm_client_cluster_id, x_zm_haid } = pbxToken;
    const url = `${pbxDomain}${fetchPbxPermissionUrl}`;
    const config = {
        responseType: 'arraybuffer' as const,
        headers: {
            Authorization: `Bearer ${access_token}`,
            x_zm_aid,
            x_zm_client_cluster_id,
            x_zm_haid,
        },
        params: {
            primary: 'WSSProxy',
        },
    };

    return httpRequest.get(url, config).then((response) => {
        const { status } = response;
        if (status === 204) {
            onLoadLogger.log('Not allowed for pwa client', Phone_Log_Tag);
            setZoomPhoneFlagEnabled(false);
            openCanNotShowZoomPhoneModal();
            return false;
        }

        onLoadLogger.log('Get pbx info success', Phone_Log_Tag);

        if (status === 200) {
            setZoomPhoneFlagEnabled(true);
            return getAsyncPhoneModule()
                .then((module) => {
                    onLoadLogger.log('Load phone module success', Phone_Log_Tag);
                    setTimeout(() => {
                        // wait a moment for phone module initialize
                        const event: IPhoneLoadEvent = { type: EPhoneLoadType.Success, module };
                        phoneEvents.load.dispatchEvent(event);
                    }, 300);

                    dispatch(
                        setAppModule({
                            module: 'zoom-phone',
                            data: { loaded: true },
                        }),
                    );

                    return module;
                })
                .then((module) => {
                    const { onGetUserSipInfoFromApp } = module;
                    return onGetUserSipInfoFromApp(response);
                })
                .catch((e) => {
                    onLoadLogger.error(`Load phone module failed: ${e}`, Phone_Log_Tag);
                    return false;
                });
        }
        return false;
    });
};
