import serviceContainer from '../container/index';

export const maybeWaitUserInfoReady = (): Promise<any> => {
    const reduxStore = serviceContainer.getReduxStore();
    const {
        common: { userInfo },
    } = reduxStore.getState();

    if (userInfo && userInfo.userId) {
        return Promise.resolve();
    } else {
        return new Promise<any>((resolve, reject) => {
            const eventBus = serviceContainer.getEventBus();
            eventBus.app.userInfoReady.subscribe((result, { id }) => {
                eventBus.app.userInfoReady.unsubscribe(id);
                const state = reduxStore.getState();
                if (state?.common?.userInfo && state.common.userInfo.userId) {
                    return resolve(result);
                }
                return reject('Not get userId');
            });
        });
    }
};

export const maybeWaitXMPPReady = (): Promise<any> => {
    const xmppAgent = serviceContainer.getXmppAgent();

    if (xmppAgent.isConnected()) {
        return Promise.resolve();
    } else {
        return new Promise<any>((resolve, reject) => {
            const eventBus = serviceContainer.getEventBus();
            eventBus.xmpp.xmppReady.subscribe((result, { id }) => {
                eventBus.xmpp.xmppReady.unsubscribe(id);
                if (xmppAgent.isConnected()) {
                    return resolve(result);
                }
                return reject('XMPP not connected');
            });
        });
    }
};
