import React from 'react';
import style from './index.module.scss';
import { EXTERNAL_TEXT } from '../../resource';
import classNames from 'classnames';

interface IProps {
    isExternal: boolean;
    extraCls?: string;
}

const ExternalBadge = ({ isExternal, extraCls }: IProps) => {
    if (!isExternal) {
        return null;
    }

    const cls = classNames(style['external-badge'], {
        [extraCls]: Boolean(extraCls),
    });

    return <span className={cls}>{EXTERNAL_TEXT}</span>;
};

export default ExternalBadge;
