import { iText } from '../../../../../utils/i18n';

export const MediaType = {
    Image: 'image',
    Audio: 'audio',
    Invisible: 'invisible',
    Visible: 'visible',
};
export const CaptchaTypes = {
    Recaptcha: 'recaptcha',
    VisibleRecaptcha: 'VisibleRecaptcha',
    SmartCaptcha: 'smart',
    HCaptcha: 'hcaptcha',
    NoCaptcha: 'nocaptcha',
};

export const SmartCaptchaType = {
    Image: 0,
    Audio: 1,
};

export const PlayStatus = {
    Play: 0,
    Ended: 1,
};

export const CAPTCHA_NO_PERMISSION_ERROR_CODE = 200;
export const CAPTCHA_ERROR_CODE = 9505;

export const CAPTCHAT_TIP = {
    wrong: iText('Captcha wrong, please try again', 'apac.wc_meeting.captcha_wrong_try_again'),
    show: iText('Please input captcha and retry', 'apac.wc_meeting.input_captcha'),
};
