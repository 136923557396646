import React from 'react';

const CalendarNumberSVG = () => {
    return (
        <svg width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipRule="url(#clip0_1819_18769)">
                <g filter="url(#filter0_d_1819_18769)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14 5H6V13H14V5ZM10 11C11.1046 11 12 10.1046 12 9C12 7.8954 11.1046 7 10 7C8.8954 7 8 7.8954 8 9C8 10.1046 8.8954 11 10 11Z"
                        fill="url(#paint0_linear_1819_18769)"
                    />
                </g>
                <g filter="url(#filter1_d_1819_18769)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M30 5H22V13H30V5ZM26 11C27.1046 11 28 10.1046 28 9C28 7.8954 27.1046 7 26 7C24.8954 7 24 7.8954 24 9C24 10.1046 24.8954 11 26 11Z"
                        fill="url(#paint1_linear_1819_18769)"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.654 6.2761C2.98023e-07 7.5595 0 9.2397 0 12.6V29.4C0 32.7603 2.98023e-07 34.4405 0.654 35.7239C1.2292 36.8529 2.1471 37.7708 3.2761 38.346C4.5595 39 6.2397 39 9.6 39H26.4C29.7603 39 31.4405 39 32.7239 38.346C33.8529 37.7708 34.7708 36.8529 35.346 35.7239C36 34.4405 36 32.7603 36 29.4V12.6C36 9.2397 36 7.5595 35.346 6.2761C34.7708 5.1471 33.8529 4.2292 32.7239 3.65396C31.4405 3 29.7603 3 26.4 3H9.6C6.2397 3 4.5595 3 3.2761 3.65396C2.1471 4.2292 1.2292 5.1471 0.654 6.2761ZM10 12C11.6569 12 13 10.6569 13 9C13 7.3431 11.6569 6 10 6C8.3431 6 7 7.3431 7 9C7 10.6569 8.3431 12 10 12ZM29 9C29 10.6569 27.6569 12 26 12C24.3431 12 23 10.6569 23 9C23 7.3431 24.3431 6 26 6C27.6569 6 29 7.3431 29 9ZM12.3838 31H14.5107V18.1592H14.2383L9.2461 19.9961V21.7979L12.3838 20.7256V31ZM22.5088 26.7812C23.4639 26.7812 24.2988 26.4033 25.0137 25.6475C24.9316 26.8779 24.5537 27.7891 23.8799 28.3809C23.2061 28.9668 22.2393 29.2686 20.9795 29.2861H20.6719V31.0527H20.9268C22.9951 31.0352 24.5508 30.4199 25.5938 29.207C26.6367 27.9941 27.1582 26.2334 27.1582 23.9248V23.3271C27.1582 21.7158 26.7803 20.4297 26.0244 19.4688C25.2686 18.5078 24.2461 18.0273 22.957 18.0273C22.1309 18.0273 21.4043 18.2178 20.7773 18.5986C20.1504 18.9795 19.667 19.5127 19.3271 20.1982C18.9873 20.8838 18.8174 21.6484 18.8174 22.4922C18.8174 23.7754 19.1514 24.8125 19.8193 25.6035C20.4873 26.3887 21.3838 26.7812 22.5088 26.7812ZM24.1611 24.7422C23.7861 24.9883 23.3848 25.1113 22.957 25.1113C22.3418 25.1113 21.8496 24.8652 21.4805 24.373C21.1113 23.8809 20.9268 23.2334 20.9268 22.4307C20.9268 21.6572 21.1084 21.0186 21.4717 20.5146C21.8408 20.0049 22.333 19.75 22.9482 19.75C23.5635 19.75 24.0645 20.0371 24.4512 20.6113C24.8379 21.1855 25.0312 21.9443 25.0312 22.8877V23.7227C24.8262 24.1562 24.5361 24.4961 24.1611 24.7422Z"
                    fill="white"
                />
                <g filter="url(#filter2_d_1819_18769)">
                    <path
                        d="M11 1C11 0.447715 10.5523 0 10 0C9.44772 0 9 0.447715 9 1V9C9 9.55229 9.44772 10 10 10C10.5523 10 11 9.55229 11 9V1Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M9 7V9C9 9.5523 9.4477 10 10 10C10.5523 10 11 9.5523 11 9C10.1184 9 9.35 8.4 9.1362 7.5448L9 7Z"
                    fill="#F8F8F8"
                />
                <g filter="url(#filter3_d_1819_18769)">
                    <path
                        d="M27 1C27 0.447715 26.5523 0 26 0C25.4477 0 25 0.447715 25 1V9C25 9.55229 25.4477 10 26 10C26.5523 10 27 9.55229 27 9V1Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M25 7V9C25 9.5523 25.4477 10 26 10C26.5523 10 27 9.5523 27 9C26.1184 9 25.35 8.4 25.1362 7.5448L25 7Z"
                    fill="#F8F8F8"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1819_18769"
                    x="5"
                    y="5"
                    width="10"
                    height="10"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1819_18769" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1819_18769" result="shape" />
                </filter>
                <filter
                    id="filter1_d_1819_18769"
                    x="21"
                    y="5"
                    width="10"
                    height="10"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1819_18769" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1819_18769" result="shape" />
                </filter>
                <filter
                    id="filter2_d_1819_18769"
                    x="7"
                    y="0"
                    width="6"
                    height="14"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1819_18769" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1819_18769" result="shape" />
                </filter>
                <filter
                    id="filter3_d_1819_18769"
                    x="23"
                    y="0"
                    width="6"
                    height="14"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1819_18769" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1819_18769" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_1819_18769"
                    x1="10"
                    y1="5"
                    x2="10"
                    y2="13"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1819_18769"
                    x1="26"
                    y1="5"
                    x2="26"
                    y2="13"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <clipPath id="clip0_1819_18769">
                    <rect width="36" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CalendarNumberSVG;
