export type TagType = string | Array<string>;

export const stringify = (input: string | Object) => {
    let output = '';
    if (typeof input === 'string') {
        output = input;
    } else {
        output = JSON.stringify(input);
    }
    return output;
};

export const mergeTags = (tag1: TagType, tag2: TagType) => {
    let tag1Cache = tag1;
    let tag2Cache = tag2;
    if (!Array.isArray(tag1Cache)) {
        tag1Cache = [tag1 as string];
    }
    if (!Array.isArray(tag2Cache)) {
        tag2Cache = [tag2 as string];
    }

    return [...tag1Cache, ...tag2Cache].filter((x) => Boolean(x));
};

// this is expected to identify one browser tab, because session storage persists if tab doesn't get closed.
export const getSsid = () => {
    let logSessionIdInSessionStorage = window.sessionStorage.getItem('log-session-id');
    if (!logSessionIdInSessionStorage) {
        logSessionIdInSessionStorage = String(Date.now());
        window.sessionStorage.setItem('log-session-id', logSessionIdInSessionStorage);
    }
    return logSessionIdInSessionStorage;
};

const addZeroPlaceholder = (num: number, digit: number = 2) => {
    let str = `${num}`;
    while (str.length < digit) {
        str = `0${str}`;
    }

    return str;
};

export const timestampToTime = (timestamp: number) => {
    const dateBase = new Date(timestamp);
    const year = dateBase.getUTCFullYear();
    const month = addZeroPlaceholder(dateBase.getUTCMonth() + 1);
    const date = addZeroPlaceholder(dateBase.getUTCDate());
    const hour = addZeroPlaceholder(dateBase.getUTCHours());
    const minute = addZeroPlaceholder(dateBase.getUTCMinutes());
    const second = addZeroPlaceholder(dateBase.getUTCSeconds());
    const millisecond = addZeroPlaceholder(dateBase.getUTCMilliseconds(), 3);

    return `${year}-${month}-${date} ${hour}:${minute}:${second}.${millisecond}`;
};
