import { getDomain } from '../../utils/meeting-url';

export const CALENDAR_APP_NAME = 'calendar';

const CALENDAR_APP_PATH_QA = 'zcal.acqa';
const CALENDAR_APP_PATH = 'zcal';

// TODO: will update for gov, dev-int and other environment.
export const getCalendarUrl = () => {
    const domain = getDomain();
    if (/zoom\.us/.test(domain)) {
        return `https://${CALENDAR_APP_PATH}.${domain}`;
    }
    return `https://${CALENDAR_APP_PATH_QA}.${domain}`;
};
