import React from 'react';
import { IconCloseFill } from '@zoom/icons-react';
import Button from '../../../components/Button/Button';
import { chatAgent } from '../../Chat';
import { InviteContactData } from './types';
import I18nStrings from '../../../components/I18nStrings';
import { START_CHAT, ALREADY_CONTACT, Close_Text } from '../../../resource';
import Avatar from '../../../components/Avatar/Avatar';
import { isChatEnabled } from '../../../utils/featureOptions';

interface IProps {
    data: InviteContactData;
    closeModal: () => void;
}

const AlreadyContent = ({ closeModal, data }: IProps) => {
    const { avatar, name, jid } = data;
    const chatEnabled = isChatEnabled();
    const startChat = () => {
        closeModal();
        if (jid && chatEnabled) {
            chatAgent.chatWithUserOrChannel({ id: jid });
        }
    };

    return (
        <>
            <div className="close-wrapper">
                <button
                    className="close-button"
                    title={Close_Text}
                    tabIndex={0}
                    aria-label={Close_Text}
                    onClick={closeModal}
                >
                    <IconCloseFill className="close" aria-hidden="true" />
                </button>
            </div>

            <div className="already-contact-content">
                <Avatar
                    aria-hidden="true"
                    picUrl={avatar}
                    jid={jid}
                    displayName={name}
                    extraClass="avatar"
                    imgClass="avatar-img"
                />
                <div id="already-contact-content-aria-desc" className="tip" aria-hidden={chatEnabled}>
                    <I18nStrings
                        i18nString={ALREADY_CONTACT}
                        stubs={[
                            <span key={name} className="name-text">
                                {name}
                            </span>,
                        ]}
                    />
                </div>
            </div>
            {chatEnabled && (
                <div className="modal-buttons center">
                    <Button
                        aria-describedby="already-contact-content-aria-desc"
                        extraCls="ok"
                        size={Button.Size24}
                        type={Button.Normal}
                        onClick={startChat}
                    >
                        {START_CHAT}
                    </Button>
                </div>
            )}
        </>
    );
};

export default AlreadyContent;
