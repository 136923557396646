import React from 'react';
import { useDispatch } from 'react-redux';
import { meetingAgent } from '../../../app-init';
import Button from '../../../components/Button/Button';
import { Modal } from 'Modal';
import { CANCEL, JOIN_NEW_MEETING, LEAVE_AND_JOIN, LEAVE_THIS_TO_JOIN_NEW_MEETING } from '../../../resource';
import { setModal } from '../../../store/modal/modal-store';
import './index.scss';

type IJoinMeetingModal = {
    data: any;
};

const JoinMeetingModal = ({ data }: IJoinMeetingModal) => {
    const dispatch = useDispatch();
    const { meetingNo, extraParams } = data;
    const closeModal = () => {
        dispatch(
            setModal({
                name: 'ifJoinMeeting',
                data: null,
            }),
        );
    };

    return (
        <Modal isOpen={true} needShowAboveBase={true}>
            <div className="if-join-meeting__main">
                <div className="if-join-meeting__title" tabIndex={0} aria-label={JOIN_NEW_MEETING}>
                    {JOIN_NEW_MEETING}
                </div>
                <div className="if-join-meeting__content" tabIndex={0} aria-label={LEAVE_THIS_TO_JOIN_NEW_MEETING}>
                    {LEAVE_THIS_TO_JOIN_NEW_MEETING}
                </div>
                <div className="if-join-meeting__actions">
                    <Button aria-label={CANCEL} size={Button.Size32} type={Button.Secondary} onClick={closeModal}>
                        {CANCEL}
                    </Button>
                    <Button
                        size={Button.Size32}
                        aria-label={LEAVE_AND_JOIN}
                        type={Button.Destructive}
                        onClick={() => {
                            meetingAgent.endCurrentAndJoinThisMeeting({ meetingNo, ...extraParams });
                            closeModal();
                        }}
                    >
                        {LEAVE_AND_JOIN}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default JoinMeetingModal;
