// https://zoomvideo.atlassian.net/wiki/spaces/EJB/pages/87442533/PNS+Protocol
// denote bit position from the least significant bit

// const REPORT_PRES_TO_WEB = 0; // report online/offline status to web backend via asyncmq
// const REQUIRE_MULTI_RESOURCE_SUPPORT          = 1; // DEPRECATED. this was only for ZRP (APPTYPE=2)
// const BOUNCE_PRESENCE = 2;
// const SHOULD_CHECK_EXPIRE = 3;
// const EXPLICIT_UNREGISTER_MOBILE = 4;
// const ENABLE_NOTIFICATION_ENC = 5; // allow encrypted notifications.
// const ENABLE_PERSISTENT_EVENT = 6; // whether to store events for this user
// const CCI_LOGOUT_CALLBACK = 7; // when cci user logout notify cci server
// const ENABLE_CHECK_ACK = 8; // when push event to client, zpns didn't receive push-ack, will disconnect connection
const ENABLE_ACCT_CHANGE_CFM = 9; // ZOOM-381348 return resources which support receiving account change notification
const TEMP_PRESENCE = 10;

export const getPwaZpnsCapability = () => {
    let result = 0;

    // result |= 1 << REPORT_PRES_TO_WEB;
    // result |= 1 << REQUIRE_MULTI_RESOURCE_SUPPORT;
    // result |= 1 << BOUNCE_PRESENCE;
    // result |= 1 << SHOULD_CHECK_EXPIRE;
    // result |= 1 << EXPLICIT_UNREGISTER_MOBILE;
    // result |= 1 << ENABLE_NOTIFICATION_ENC;
    // result |= 1 << ENABLE_PERSISTENT_EVENT;
    // result |= 1 << CCI_LOGOUT_CALLBACK;
    // result |= 1 << ENABLE_CHECK_ACK;
    result |= 1 << ENABLE_ACCT_CHANGE_CFM;
    result |= 1 << TEMP_PRESENCE;

    return result;
};
