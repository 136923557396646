import { useEffect } from 'react';
import { usePrevious } from './usePrevious';
import { AuthRoutes, PortalRoute, RoutePath, history, historyVariable } from '../routes';
import { useAppSelector } from '../store';
import { matchPath, useLocation } from 'react-router-dom';

const useIsUserSignedInEffect = (isUserSignin: boolean) => {
    const {
        meeting: { isMeetingMinimized, showWebclient },
    } = useAppSelector((_) => _);
    const prevIsUserSignin = usePrevious(isUserSignin);
    const location = useLocation();

    useEffect(() => {
        // been signed out when in maximum meeting, then we should't change the route
        if (!isMeetingMinimized && showWebclient) {
            return;
        }

        // sign out
        if (!isUserSignin && prevIsUserSignin) {
            history.push(RoutePath.Index);
        }
    }, [isUserSignin, prevIsUserSignin, isMeetingMinimized, showWebclient]);

    useEffect(() => {
        // if portal route matched
        if (PortalRoute.some((path) => !!matchPath(location.pathname, { path, exact: true }))) {
            // if current location is no /wc/join
            if (!matchPath(location.pathname, { path: RoutePath.Join, exact: true })) {
                historyVariable.currentPortalLocationNotJoin = location;
            }
        }

        // listening for route change, if it's authroute when user have already signed out, then we go back to index page
        if (!isUserSignin) {
            if (AuthRoutes.some((path) => !!matchPath(location.pathname, { path: path, exact: true }))) {
                history.push(RoutePath.Index);
            }
        }
    }, [isUserSignin, location]);
};

export default useIsUserSignedInEffect;
