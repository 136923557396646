import React from 'react';
import Button from '../../../components/Button/Button';
import I18nStrings from '../../../components/I18nStrings';
import { INVITE_SEND_SUCCESS, OK_Text } from '../../../resource';

interface IProps {
    closeModal: () => void;
    email: string;
}

const SendSuccessContent = ({ closeModal, email }: IProps) => {
    return (
        <>
            <div className="send-success-content">
                <span className="invite-send-success" />
                <div id="send-success-content-aria-desc" aria-hidden="true" className="success-text">
                    <I18nStrings
                        i18nString={INVITE_SEND_SUCCESS}
                        stubs={[
                            <span key={email} className="email-text">
                                {email}
                            </span>,
                        ]}
                    />
                </div>
            </div>
            <div className="modal-buttons center">
                <Button
                    aria-describedby="send-success-content-aria-desc"
                    extraCls="ok"
                    size={Button.Size24}
                    type={Button.Normal}
                    onClick={closeModal}
                >
                    {OK_Text}
                </Button>
            </div>
        </>
    );
};

export default SendSuccessContent;
