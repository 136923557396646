/**
 * this toast is customized for PWA Client
 * @example
 *  pwaToast(
 *      {
 *          type: ICON_TYPE.INFO,
 *          title: 'test123',
 *          desc: 'go gogo',
 *          mode: PWA_POPUP_MODE.TEXT_BOTTOM,
 *      },
 *      {
 *          autoClose: false,
 *          toastId: 123,
 *      },
 *  );
 */

import React from 'react';
import { ToastOptions } from 'react-toastify';
import { meetingAgent } from '../../../app-init';
import Button from '../../../components/Button/Button';
import { IToast, toast, inherit } from 'Toast';
import { BACK_TO_MEETING_TEXT } from '../../../resource';
import { setIsMeetingMinimized } from '../../Meeting/redux';
import store from '../../../store';
import './toast.scss';
import { PWAMeetingEvent } from '../../Meeting/meeting-agent-msg-type';

export enum PWA_POPUP_MODE {
    BUTTONS_BOTTOM = 0,
    BUTTONS_RIGHT = 1,
    TEXT_BOTTOM = 2,
}

export enum TOAST_PATH {
    GLOABL = '/home',
    PHONE = '/phone',
}

const pwaToastFn = (
    params: IToast & { mode?: PWA_POPUP_MODE; actionPlacement: 'bottom' | 'right' },
    options?: ToastOptions & { belongsTo?: TOAST_PATH },
) => {
    const buttons = [
        <div className="popup-actions__buttons">
            <Button size={Button.Size24} type={Button.Destructive}>
                Cancel
            </Button>
            <Button size={Button.Size24} type={Button.Normal}>
                Ok
            </Button>
        </div>,
    ];
    if (!options) {
        options = {};
    }

    switch (params.mode) {
        case PWA_POPUP_MODE.BUTTONS_BOTTOM:
            params.actionPlacement = 'bottom';
            params.toastActions = buttons;
            if (!options.className) {
            } else {
                options.className += ' popup-action__bottom';
            }
            break;

        case PWA_POPUP_MODE.BUTTONS_RIGHT:
            params.actionPlacement = 'right';
            params.toastActions = buttons;
            break;

        default:
            params.toastActions = [
                <div
                    className="back-meetings"
                    aria-label={BACK_TO_MEETING_TEXT}
                    role="button"
                    onClick={() => {
                        toast.dismiss(options.toastId);
                        store.dispatch(setIsMeetingMinimized(false));
                        meetingAgent.sendMsgToWebClient(PWAMeetingEvent.BACK_TO_MEETING);
                    }}
                >
                    {BACK_TO_MEETING_TEXT}
                </div>,
            ];
            break;
    }
    return toast(params, options);
};

const pwaToast = inherit(pwaToastFn, toast);

export { pwaToast };
