import React, { useState, useRef, useMemo } from 'react';
import { produce } from 'immer';
import SeeFeedbackTip from '../components/see-feedback-tip';
import { hadissuesListLeft, hadissuesListRight, hadIssuesTitle, submit } from '../resource';
import { Button, isMobileDevice } from '@zoom/zoom-react-ui';
import { sendFeedbackToWebAction } from '../redux/feedback-thunk-actions';
import { useAppDispatch, useAppSelector } from '../../../Phone/types';
import Captcha from '../components/captcha';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import './feedback-hadissues.scss';
import { setModal } from '../../../../store/modal/modal-store';
import { useFeedback } from '../../../ThankYou/hooks/useFeedback';
import { isFeedbackFromWebinar } from '../../../ThankYou/util';

const defluatCheckedList = hadissuesListLeft.concat(hadissuesListRight).map((item) => ({
    id: item.id,
    checked: false,
}));
export default function FeedbackHadissues() {
    const itemsRef = useRef();
    const [text, setText] = useState('');
    const [checkedList, setCheckedList] = useState(defluatCheckedList);
    const {
        common: { userInfo },
        meeting: { enableFeedbackTextField, feedbackCustMessage },
    } = useAppSelector((state) => state);
    const captchaRef = useRef<any>();
    const dispatch = useAppDispatch();
    const feedback = useFeedback();

    const submitDisabled = useMemo(() => {
        const checked = checkedList.find((item) => item.checked);
        if (!checked && !text) {
            return true;
        } else {
            return false;
        }
    }, [text, checkedList]);

    function hadIssuesFilterForWebinar(item: { text: string, id: number }) {
        const isFromWebinar = isFeedbackFromWebinar(feedback.feedbackModal);

        if (isFromWebinar && isMobileDevice()) return item.id !== 8

        return true;
    }

    function handleClick() {
        const checkedIdList = checkedList.filter((item) => item.checked).map((item) => item.id);
        const checkedIdStr = checkedIdList.map((item) => '' + item).join(',');
        const quality = checkedIdStr.length ? `NOT GOOD|${checkedIdStr}` : 'NOT GOOD|0';
        const recaptcha = captchaRef.current?.getCaptchaValidateResult();
        dispatch(sendFeedbackToWebAction({ quality, addComments: text, recaptcha, email: userInfo?.email }));
        dispatch(setModal({ name: 'feedback', data: { show: false } }));
    }

    function handleChange(flag: boolean, checkId: number) {
        const newCheckedList = produce(checkedList, (draft) => {
            const index = checkedList.findIndex((item) => item.id === checkId);
            draft[index].checked = flag;
        });
        setCheckedList(newCheckedList);
    }

    return (
        <div className="feedback-hadissues">
            <div style={{ display: 'none' }}>
                <Captcha ref={captchaRef} />
            </div>
            <div className="feedback-hadissues__title"> {hadIssuesTitle} </div>
            <div className="feedback-hadissues__content">
                <div className="feedback-hadissues__content__items" ref={itemsRef}>
                    <div className="feedback-hadissues__content__items--column">
                        {hadissuesListLeft
                        .filter(hadIssuesFilterForWebinar)
                        .map((item) => (
                            <Checkbox
                                containerClass="feedback-hadissues__content__items_item"
                                onChange={(flag: boolean) => {
                                    handleChange(flag, item.id);
                                }}
                                autoControlled={true}
                                defaultChecked={false}
                                key={item.id}
                            >
                                {item.text}
                            </Checkbox>
                        ))}
                    </div>
                    <div className="feedback-hadissues__content__items--column">
                        {hadissuesListRight.map((item) => (
                            <Checkbox
                                containerClass="feedback-hadissues__content__items_item"
                                onChange={(flag: boolean) => {
                                    handleChange(flag, item.id);
                                }}
                                autoControlled={true}
                                defaultChecked={false}
                                key={item.id}
                            >
                                {item.text}
                            </Checkbox>
                        ))}
                    </div>
                </div>
                {enableFeedbackTextField && (
                    <textarea
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                        value={text}
                        placeholder="Additional comments"
                        className="feedback-hadissues__content__textinput"
                    />
                )}
                <Button
                    type="primary"
                    className="feedback-hadissues__content__button"
                    onClick={handleClick}
                    disabled={submitDisabled}
                >
                    {submit}
                </Button>
                {feedbackCustMessage && (
                    <div className="feedback-hadissues__content__customtext" tabIndex={0}>
                        {feedbackCustMessage}
                    </div>
                )}
            </div>
            <SeeFeedbackTip />
        </div>
    );
}
