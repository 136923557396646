import React, { useEffect } from 'react';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { Popover } from 'react-bootstrap';
import { IconCloseFill } from '@zoom/icons-react';
import { useAppDispatch } from '../../../../store';
import { updateChatPopover } from '../../redux/chat-thunk';
import Button from '../../../../components/Button/Button';
import { chatAgent } from '../../ChatAgent';
import './index.scss';
import { CHAT_POST_MEETING_NOTICE, CHAT_POST_MEETING_NOTICE_TITLE, GO_TO_CHAT } from '../../../../resource';
import I18nStrings from '../../../../components/I18nStrings';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../../../routes';

interface IProps {
    positionUpdateDep: number;
    topic: string;
    channelId: string;
}

type IPopoversProps = OverlayInjectedProps & IProps;

export default React.forwardRef<HTMLDivElement, IPopoversProps>(
    ({ popper, positionUpdateDep, topic, channelId, ...props }, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [positionUpdateDep]);

        const dispatch = useAppDispatch();
        const match = useRouteMatch(RoutePath.Chat);
        const isMatch = Boolean(match);

        const closeMe = () => {
            chatAgent.toNotifyPostMeetingTips(null);
            dispatch(updateChatPopover({ popover: 'post-meeting', info: null }));
        };

        const goToChat = () => {
            closeMe();
            chatAgent.chatWithUserOrChannel({ id: channelId });
        };

        useEffect(() => {
            // when we are in chat tab, we hide the popover and notify chat to show it
            // otherwise we show show it
            if (isMatch) {
                chatAgent.toNotifyPostMeetingTips({ topic, channelId });
            } else {
                chatAgent.toNotifyPostMeetingTips(null);
            }
        }, [isMatch]);

        if (isMatch) {
            return <i></i>;
        }

        return (
            <Popover id="chat-popover" ref={ref} {...props}>
                <div className="chat-post-popover">
                    <div className="chat-post__content" tabIndex={0}>
                        <header>
                            <span className="chat-post__content-title">{CHAT_POST_MEETING_NOTICE_TITLE}</span>
                            <button className="chat-popover__action-close" onClick={closeMe}>
                                <IconCloseFill />
                            </button>
                        </header>
                        <div className="chat-post__content-body">
                            <I18nStrings
                                i18nString={CHAT_POST_MEETING_NOTICE}
                                stubs={[
                                    <span className="chat-post__content-topic" key={topic}>
                                        {topic}
                                    </span>,
                                ]}
                            />
                        </div>
                        <footer>
                            <Button type={Button.Normal} size={Button.Size24} onClick={goToChat}>
                                {GO_TO_CHAT}
                            </Button>
                        </footer>
                    </div>
                </div>
            </Popover>
        );
    },
);
