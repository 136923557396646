import microApp from '@micro-zoe/micro-app';
import { docsMessageHandler } from './messageHandlerDecorator';
import serviceContainer from '../../../services/container';
import { setStatus } from '../redux/docs-slice';
import { signoutWhenSeesionExpiredThunk } from '../../../store/common/sign-out-thunk';
import { userSessionLogger } from '../../../logger/pwa-loggers';
const DocsAppName = 'docs';

const { onReceiveMessage, handleMessage } = docsMessageHandler;

export default class DocsAgent {
    constructor() {
        this.onMessage = this.onMessage.bind(this);
    }

    init() {
        microApp.addDataListener(DocsAppName, this.onMessage);
    }

    uninit() {
        microApp.removeDataListener(DocsAppName, this.onMessage);
        microApp.unmountApp(DocsAppName);
    }

    @onReceiveMessage
    onMessage(message: { type: string }) {
        return message.type;
    }

    postMessage(message: any) {
        microApp.setData(DocsAppName, message);
    }

    @handleMessage('docReady')
    onDocReady(message: any) {
        setTimeout(() => {
            const store = serviceContainer.getReduxStore();
            store.dispatch(setStatus('success'));
        }, 50);

        this.postMessage({
            type: `${message.type}`,
            data: 'copy',
        });
    }

    @handleMessage('docSignOut')
    onDocSignout(_message: any) {
        userSessionLogger.log('', ['Session_Expire', 'From_Docs']);
        const store = serviceContainer.getReduxStore();
        store.dispatch(signoutWhenSeesionExpiredThunk());
    }
}
