import { useState, useEffect } from 'react';
import { getAsyncLibPhoneNumberModule } from '../../../asyncModulesStore';

export function useFormatPhoneNumber(num = '', region = ''): string {
    const [number, setNumber] = useState(num);

    useEffect(() => {
        getAsyncLibPhoneNumberModule().then((libPhoneNumber: any) => {
            const { PhoneNumberUtil, PhoneNumberFormat } = libPhoneNumber;
            const phoneUtil = PhoneNumberUtil.getInstance();

            if (!number) {
                setNumber('');
            } else if (number.startsWith('+')) {
                // if star whith '+', region default is 'ZZ'. this logic from native client
                const phoneNumber = phoneUtil.parse(number, 'ZZ');
                setNumber(phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL).replaceAll('-', ' '));
            } else if (region !== '') {
                try {
                    const phoneNumber = phoneUtil.parse(number, region);
                    setNumber(phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL));
                } catch (error) {
                    setNumber(number);
                }
            } else {
                setNumber(number);
            }
        });
    }, [num, region]);

    return number;
}
