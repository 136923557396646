import useMeetingItem from './useMeetingItem';
import { useAppSelector } from '../../../store/store-hooks';
import { isZCCInVideoCall } from '../../../store/appModules/contact-center';

export default (props: ReturnType<typeof useMeetingItem>) => {
    const {
        meeting: { showWebclient, meetingInfo, isStartDisabled },
    } = useAppSelector((_) => _);

    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);

    const { meetingNo } = props;

    return {
        disableStart: showWebclient || isStartDisabled || isZCCInMeeting,
        disableJoin: showWebclient || isStartDisabled || isZCCInMeeting,
        disableEdit: meetingInfo?.meetingNo ? String(meetingInfo?.meetingNo) === meetingNo : showWebclient,
        disableDelete: meetingInfo?.meetingNo ? String(meetingInfo?.meetingNo) === meetingNo : showWebclient,
    };
};
