import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
    isSupportClientReurringMeetingEnabled,
    isShowEveryoneMeetingListEnabled,
    isPMCEnabled,
    isMeetingTransferEnabled,
    isFarEndCameraControlEnabled,
    isAutoFarEndCameraControlEnabled,
} from '../../utils/clientCapability/userSettingQuery';

export const userWebSettingsSelector = (state: RootState) => state.common.userWebSettings;

export const showEveryoneMeetingListSeletor = createSelector([userWebSettingsSelector], (userWebSettings) => {
    return isShowEveryoneMeetingListEnabled(userWebSettings?.featureOptions3);
});

export const enableExpandRecurringMeetingsSelector = createSelector([userWebSettingsSelector], (userWebSettings) => {
    return isSupportClientReurringMeetingEnabled(userWebSettings?.featureOptions6);
});

export const isPMCEnabledSelector = createSelector([userWebSettingsSelector], (userWebSettings) => {
    return isPMCEnabled(userWebSettings?.featureOptions6);
});

export const enableMeetingTransferSelector = createSelector([userWebSettingsSelector], (userWebSettings) => {
    return isMeetingTransferEnabled(userWebSettings?.featureOptions5);
});

// far end camera control
export const enableFarEndCameraControlSelector = createSelector([userWebSettingsSelector], (userWebSettings) => {
    return isFarEndCameraControlEnabled(userWebSettings?.featureOptions);
});

// auto far end camer control
export const enableAutoFarEndCameraControlSelector = createSelector([userWebSettingsSelector], (userWebSettings) => {
    return isAutoFarEndCameraControlEnabled(userWebSettings?.featureOptions6);
});
