import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Phone/types';
import { setModal } from '../../../../store/modal/modal-store';
import { IconListen, IconQuestionCircle } from '@zoom/icons-react';
import { GIVE_FEEDBACK_BUTTON_TEXT } from '../../../../resource';
import { OverlayTrigger } from 'react-bootstrap';
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import classNames from 'classnames';
import '../Header.scss';
import { withFocusWalker } from '../../../../hoc/withFocusWalker';
import { keyBoardEventHandler } from '../../../../utils/keyboard-event';
import FocusLock from 'react-focus-lock';

const HelpPanel = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((_) => _);
    const {
        settings: { userSettingTheme },
    } = state;

    const [showHelpModal, setShowHelpModal] = useState(false);

    const openFeedbackModal = () => {
        dispatch(setModal({ name: 'giveFeedback', data: {} }));
    };

    const onFeedback = () => {
        openFeedbackModal();
        setShowHelpModal(false);
    };

    const onToggle = (show: boolean) => {
        if (!show) {
            setShowHelpModal(false);
        } else {
            setShowHelpModal(true);
        }
    };

    const renderHelpPopUp = ({ arrowProps, hasDoneInitialMeasure, show, ...props }: any) => {
        return (
            <FocusLock disabled>
                <div className="home-header-right__help" {...props} id="homeHeaderHelp">
                    <button className="help-tab" onClick={onFeedback} tabIndex={0}>
                        <div className="help-tab-contents">
                            <IconListen />
                            <span>{GIVE_FEEDBACK_BUTTON_TEXT}</span>
                        </div>
                    </button>
                </div>
            </FocusLock>
        );
    };

    const onKeyDownHelp = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            onToggle(!showHelpModal);
        } else if (e.key === 'Escape') {
            onToggle(false);
        }
    };

    return (
        <OverlayTrigger
            overlay={(props: OverlayTriggerRenderProps) => renderHelpPopUp(props)}
            placement="bottom-end"
            trigger={['click']}
            onToggle={onToggle}
            rootClose={true}
            show={showHelpModal}
        >
            <div
                className={classNames('home-header__help', { themed: userSettingTheme !== 'classic' })}
                role="button"
                aria-haspopup={true}
                aria-expanded={showHelpModal}
                onKeyDown={onKeyDownHelp}
                tabIndex={0}
            >
                <IconQuestionCircle />
            </div>
        </OverlayTrigger>
    );
};

export default withFocusWalker(HelpPanel, 'homeHeaderHelp', () => true, ['.help-tab'], keyBoardEventHandler);
