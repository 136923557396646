import { useEffect } from 'react';
import { unmountAllApps } from '@micro-zoe/micro-app';
import { usePhoneModule } from '../../asyncModulesStore';
import { EChatOutgoingMessages, MeetingStatusToChat } from './chat-types';
import { isDuringMeetingSelector } from '../Meeting/redux';
import { useAppSelector } from '../../store';
import { chatAgent } from './ChatAgent/';
import { isChatEnabled } from '../../utils/featureOptions';

export const useSyncMeetingStatusToChat = () => {
    const isDuringMeeting = useAppSelector(isDuringMeetingSelector);
    useEffect(() => {
        chatAgent.syncMeetingStatusToChat(
            isDuringMeeting ? MeetingStatusToChat.In_Meeeting : MeetingStatusToChat.Not_In_Meeting,
        );
    }, [isDuringMeeting]);
};

// TODO to move this out and subscribe global event bus
export const useChatInit = () => {
    useEffect(() => {
        return () => {
            if (!isChatEnabled()) return;
            unmountAllApps({ destroy: true, clearAliveState: true }).then(() => console.log('unmount all app done'));
        };
    }, []);

    usePhoneModule({
        onLoad: () => {
            chatAgent.postMessage({
                type: EChatOutgoingMessages.CAN_START_PHONE_CALL,
            });
        },
    });

    useSyncMeetingStatusToChat();
};
