export const PWAMeetingEvent_MINIMIZE = {
    WAITING_ROOM_NEW: 'WAITING_ROOM_NEW',
    LIVE_TRANSCRIPTION_ENABLED: 'LIVE_TRANSCRIPTION_ENABLED',
    MUTED_BY_HOST: 'MUTED_BY_HOST',
    UNMUTE_REQUEST: 'UNMUTE_REQUEST',
    VIDEO_REQUEST: 'VIDEO_REQUEST',
    INVITED_TO_BO: 'INVITED_TO_BO',
    RECORDING: 'RECORDING',
    BO_TIME_UP: 'BO_TIME_UP',
    LIVE_TRANSCRIPTION_REQUEST: 'LIVE_TRANSCRIPTION_REQUEST',
    POLL_INBOX: 'POLL_INBOX',
    MEETING_MINIMIZE: 'MEETING_MINIMIZE',
    MEETING_MAXIMIZE: 'MEETING_MAXIMIZE',
    IS_AUDIO_CONNECTED_RES: 'IS_AUDIO_CONNECTED_RES',
    RETURN_MAIN_SESSION: 'RETURN_MAIN_SESSION',
    PROMOTE_TO_PANELIST: 'PROMOTE_TO_PANELIST',
    REMOVED_BY_HOST: 'REMOVED_BY_HOST',
    RENAMED_BY_HOST: 'RENAMED_BY_HOST',
    BAN_VIDEO: 'BAN_VIDEO',
    END_BY_HOST: 'END_BY_HOST',
    BAN_UNMUTE_AUDIO: 'BAN_UNMUTE_AUDIO',
    POLL_RESULT_SHARED: 'POLL_RESULT_SHARED',
    BO_WILL_CLOSE: 'BO_WILL_CLOSE',
    HELP_REQUEST: 'HELP_REQUEST',
};

export const PWAMeetingEvent = {
    // meeting status event send by web-client
    NONE: 'NONE',
    JOINING: 'JOINING', // webclient send to make sure if it is in pwa, if we exist, we must respond "yes"
    SUCCESS: 'SUCCESS', // meeting start/join successfully
    FAIL: 'FAIL',
    LEAVE: 'LEAVE', // the child window still exist
    CLOSED: 'CLOSED',
    END: 'END',

    INVITE_START: 'INVITE_START', // start to get contacts and user details // user open invite dialog in webclient
    INVITE_STOP: 'INVITE_STOP', // like unsubscribe // user closes invite dialog in webclient
    INVITE_FETCH_USER_DETAILS: 'INVITE_FETCH_USER_DETAILS', // fetch specific range users' details when user scrolls invite contacts list
    INVITE_INVITE_TO_MEETING: 'INVITE_INVITE_TO_MEETING', // invite these people to the current meeting
    BACK_TO_MEETING: 'BACK_TO_MEETING',
    LEAVE_AUDIO: 'LEAVE_AUDIO',
    IS_AUDIO_CONNECTED_REQ: 'IS_AUDIO_CONNECTED_REQ',
    ASK_TO_INVITE_TO_MEETING: 'ASK_TO_INVITE_TO_MEETING',
    SEND_CHANNEL_ID: 'SEND_CHANNEL_ID',
    START_PRESENTING: 'START_PRESENTING',
    STOP_PRESENTING: 'STOP_PRESENTING',
    MEETING_TOPIC_UPDATE: 'MEETING_TOPIC_UPDATE',

    // event send by pwa
    PWA_EXIST: 'PWA_EXIST', // yes, webclient is in pwa
    PWA_STATUS: 'PWA_STATUS', // to tell webclient about xmpp server's connection status
    INVITE_CONTACTS: 'INVITE_CONTACTS', // send after INVITE_START, all the contacts information
    INVITE_USER_DETAILS_UPDATE: 'INVITE_USER_DETAILS_UPDATE', // send when any user information  change [presence, vcard]
    INVITE_USER_DECLINED: 'INVITE_USER_DECLINED', // webclient invite others and others declient its invitation

    PWA_LEAVE_MEETING: 'PWA_LEAVE_MEETING',
    ...PWAMeetingEvent_MINIMIZE,

    DRAG_START: 'DRAG_START',
    DRAG_MOVE: 'DRAG_MOVE',
    DRAG_END: 'DRAG_END',
    END_MEETING_REQUEST: 'END_MEETING_REQUEST',
    AUDIO_CONNECTED_CHANGE: 'AUDIO_CONNECTED_CHANGE',
    MEETING_MINIMIZE_REQUEST: 'MEETING_MINIMIZE_REQUEST',

    // messages from WEB's empty page
    CROSS_SITE: 'CROSS_SITE',
    EXTERNAL_AUTH: 'EXTERNAL_AUTH',
    PAC: 'PAC',
    NEED_REGISTER: 'NEED_REGISTER', // this meetig requires your registration first, we open register page in a new tab

    CHECK_IS_IN_CHANNEL: 'CHECK_IS_IN_CHANNEL', // check current login acount is in channel
    JUMP_CHAT_CHANNEL: 'JUMP_CHAT_CHANNEL', // jump to pwa chat channel
    LCP_RESPONSE: 'LCP_RESPONSE',
    LCP_REQUEST: 'LCP_REQUEST',

    // FECC ==> Far end comera control
    FECC_GROUP_REQUEST: 'FECC_GROUP_REQUEST', // webclient --> pwa: webclient get camera control group
    FECC_GROUP_RESPONSE: 'FECC_GROUP_RESPONSE', // pwa  --> web client
    FECC_GROUP_ADD: 'FECC_GROUP_ADD', //  webclient --> pwa : webclient  add fecc
    FECC_GROUP_ADD_RESPONSE: 'FECC_GROUP_ADD_RESPONSE', // pwa  --> web client
    FECC_GROUP_REMOVE: 'FECC_GROUP_REMOVE', //  webclient --> pwa : webclient remove fecc
    FECC_GROUP_REMOVE_RESPONSE: 'FECC_GROUP_REMOVE_RESPONSE', // pwa --> web client
    FECC_GROUP_PWA_ADD: 'FECC_GROUP_PWA_ADD', // pwa -> webclient, pwa add fecc in pwa contact
    FECC_GROUP_PWA_REMOVE: 'FECC_GROUP_PWA_REMOVE', // pwa -> webclient, pwa remove fecc in pwa contact

    ATTENDEE_WILL_TRANSFER_TO_WR: 'ATTENDEE_WILL_TRANSFER_TO_WR', // webclient --> pwa : show modal

    // incoming event
    ZOOM_PHONE_CALL: 'ZOOM_PHONE_CALL',
    ZOOM_PHONE_CALL_HANGUP: 'ZOOM_PHONE_CALL_HANGUP',

    // outgoing event
    ZOOM_PHONE_SUCCESS: 'ZOOM_PHONE_SUCCESS',

    // incoming and outgoing
    ZOOM_PHONE_CHANGE: 'ZOOM_PHONE_CHANGE', // change callerid

    // webclient requests to call out, but we got error
    ZOOM_PHONE_ERROR: 'ZOOM_PHONE_ERROR',
};
