import React, { useCallback } from 'react';
import './Contacts.scss';
import ContactList from './ContactPanel/ContactList/ContactList';
import ContactDeatil from './ContactDetail/ContactDetail';
import { useAppDispatch, useAppSelector } from '../../store';
import { DisplayType, GROUP_ID, JID } from './types';
import { setSelectedContact } from './redux';
import SearchList from './ContactPanel/Search/SearchList';
import Search from './ContactPanel/Search/Search';
import ContactOperation from './ContactPanel/ContactOperation/ContactOperation';
import { featureOptionManager } from '../../app-init';

const Contacts = () => {
    const dispatch = useAppDispatch();
    const currentDisplayType = useAppSelector((state) => state.contacts.currentDisplayType);

    const setContact = useCallback((jid: JID, groupId?: GROUP_ID) => {
        if (groupId) {
            dispatch(setSelectedContact({ jid, groupId }));
        } else {
            dispatch(setSelectedContact({ jid }));
        }
    }, []);

    return (
        <div
            className="contacts-wrapper"
            id="home-tabpanel-contacts"
            role="tabpanel"
            aria-labelledby="home-tab-contacts"
        >
            <div className="contacts-panel">
                <div className="contacts-panel__header">
                    <Search />
                    {featureOptionManager.isInviteContactOn() && <ContactOperation />}
                </div>
                {currentDisplayType === DisplayType.contact ? <ContactList setContact={setContact} /> : null}
                {currentDisplayType === DisplayType.search ? <SearchList setContact={setContact} /> : null}
            </div>
            <ContactDeatil />
        </div>
    );
};

export default React.memo(Contacts);
