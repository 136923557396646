import React from 'react';
import { ReactComponent as LineDocs } from './svgs/docs-normal.svg';
import { ReactComponent as SolidDocs } from './svgs/docs-selected.svg';

export const DocsNormal = () => {
    return (
        <i className="zmicon zmicon-acc-no-outline">
            <LineDocs />
        </i>
    );
};

export const DocsSelected = () => {
    return (
        <i className="zmicon zmicon-acc-no-outline">
            <SolidDocs />
        </i>
    );
};
