import { CHAT_APP_NAME, DocsAppName, getDocsEntryHtmlUrl, getTeamChatEntryUrl } from '../../configs';
import { CALENDAR_APP_NAME, getCalendarUrl } from '../../features/Calendar/constant';
import {
    CHAT_HISTORY_NAME,
    CONTACT_CENTER_NAME,
    getPreviewHtmlUrl,
    getZCCUrl,
} from '../../store/appModules/contact-center/config';

const MICRO_APP_WHITE_LIST = {
    [CHAT_APP_NAME]: () => window.chatInitConfig.teamChatEntryUrl,
    [CONTACT_CENTER_NAME]: getZCCUrl,
    [DocsAppName]: getDocsEntryHtmlUrl,
    [CALENDAR_APP_NAME]: getCalendarUrl,
    [CHAT_HISTORY_NAME]: getPreviewHtmlUrl,
};

const includeCredentialsForEntryHtmlFetch = (url: string, options: any) => {
    const willInclude =
        url.startsWith(getZCCUrl()) || url.startsWith(getDocsEntryHtmlUrl()) || url.startsWith(getTeamChatEntryUrl());

    if (willInclude) {
        return Object.assign({}, options, {
            credentials: 'include',
        });
    } else {
        return options;
    }
};

const addStyleSheets2Document = (cssRule: string, baseUrl: string) => {
    if (!cssRule) {
        return;
    }

    const fontFaceSheet = new CSSStyleSheet();
    const matches = cssRule.match(/url\((.+?static.+?)\)/);
    if (!matches) {
        return;
    }
    const [_wholeString, fontRelativePath] = matches;
    const fontAbsolutePath = new URL(fontRelativePath, baseUrl).href;
    const newCssRule = cssRule.replace(/url\(.+?static.+?\)/, `url(${fontAbsolutePath})`);
    fontFaceSheet.replaceSync(newCssRule);
    document.adoptedStyleSheets = [...document.adoptedStyleSheets, fontFaceSheet];
};

const isHtmlEntry = (cache: string) => cache && cache === 'no-cache';

const isSameOrigin = (url: string, appName: string) => {
    try {
        const { origin: currentOrigin } = new URL(url);
        const { origin = '' } = new URL(MICRO_APP_WHITE_LIST[appName]());
        return origin && currentOrigin === origin;
    } catch (error) {
        console.warn('isSameOrigin error ==>', error);
    }

    return false;
};

const isTextHtml = (contentType: string) => contentType.startsWith('text/html');

const entrySameOriginRule = (url: string, options: Record<string, unknown>, appName: string) => {
    const { cache } = options || {};

    if (isHtmlEntry(cache as string) && !isSameOrigin(url, appName)) {
        return false;
    }

    return true;
};

const entryResponseHtmlRule = (options: Record<string, unknown>, headers: Headers) => {
    const { cache } = options || {};
    const contentType = headers.get('content-type') || '';
    if (isHtmlEntry(cache as string) && !isTextHtml(contentType)) {
        return false;
    }

    return true;
};

export const interceptMicroAppFetch = (url: string, options: Record<string, unknown>, appName: string) => {
    // micro-app will auto add { cache: 'no-cache' } options when construct micro app.
    // so we can intercept micro-app's fetch and check the options's cache value, other intercept fetch have not cache field.
    // https://github.com/micro-zoe/micro-app/blob/dev/src/source/loader/html.ts
    if (!entrySameOriginRule(url, options, appName)) {
        console.warn('Micro App entry XSS !!!', url, options, appName);
        return Promise.resolve('');
    }

    return window
        .fetch(url, includeCredentialsForEntryHtmlFetch(url, options))
        .then((res) => {
            // attacker may get allowed filesa and tamper it, such as add some src in zoom svg file, so may cause a XSS.
            // so check the entry link fetch response content-type is better. the chat entry response file is html.
            if (!entryResponseHtmlRule(options, res.headers)) {
                console.warn('Micro App entry response XSS !!!', url, options, options);
                return Promise.resolve('');
            }

            /**
             * for calendar, we use shadowDOM to prevent css polution from zwa-portal, however, if calendar app use css variable by using `:root { --font-size-var--: red }`,
             * the variable won't work, the bug explained here:
             *      https://stackoverflow.com/questions/68926057/css-custom-variables-in-custom-element-and-shadow-dom-not-resolved-by-ide-or-bro
             * to fix, we need to use `:host` which represent the shadowRoot instead of `:root` which represent the root html of zwa-portal.
             */
            if ((url.startsWith(getCalendarUrl()) || /client-calendar/.test(url)) && url.endsWith('.css')) {
                return res.text().then((cssString) => {
                    // calendar use shadow-dom, so the font-face have to be redefined outside the shadow-dom
                    if (url.match(/chunk\-.+?\.css/)) {
                        const matches = cssString.match(/@font-face{([\s\S]*?)}/g);
                        matches?.forEach((match) => addStyleSheets2Document(match, url));
                    }

                    return cssString.replaceAll(':root', ':host');
                });
            }

            return res.text();
        })
        .catch((error) => {
            console.warn('interceptMicroAppFetch error ==>', error);
            return Promise.resolve('');
        });
};
