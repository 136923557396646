import { DOM_KEY_CODE } from '../../../utils/constants';

export enum A11Y_POLICY {
    CONTACT_TREE_POLICY = 'LIST_TREE_POLICY',
    CONTACT_TREE_ITEM_POLICY = 'LIST_TREE_ITEM_POLICY',
}

export type All_DOM_Nodes_Type = {
    rootJDom: HTMLElement | null;
    currentKey: string | null;
    currentDom: HTMLElement | null;
    firstChildJDom: HTMLElement | null;
    prevJDom: HTMLElement | null;
    isVirtuallyPrevJDom: boolean;
    nextJDom: HTMLElement | null;
    isCurrentDomAriaExpanded: boolean;
    isCurrentDomIsGroup: boolean;
    currentDomOwnGroupJDom: HTMLElement | null;
    leftKeyDisabled: boolean;
};

export type Perform_Focus_Type = (
    keyCode: DOM_KEY_CODE,
    rootJDom: HTMLElement | null,
    targetJDom: HTMLElement | null,
) => void;

export type Perform_Click_Type = (
    keyCode: DOM_KEY_CODE,
    rootJDom: HTMLElement | null,
    targetJDom: HTMLElement | null,
) => void;

export type Keyboard_Event_Handler_Type = (
    walkerPolicy: A11Y_POLICY,
    performFocus: Perform_Focus_Type,
    performClick: Perform_Click_Type,
    e: KeyboardEvent,
    allDomNodes: All_DOM_Nodes_Type,
) => boolean | undefined;
