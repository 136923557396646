import { COMPANY_CONTACT_TEXT, EXTERNAL_TEXT, BOTS_TEXT } from '../../../resource';

export function correctName(name: string) {
    switch (name) {
        case 'Coworkers':
            return COMPANY_CONTACT_TEXT;
        case 'Friends':
        case 'default':
            return EXTERNAL_TEXT;
        case 'Apps':
            return BOTS_TEXT;
        default:
            return name;
    }
}

export const PENDING_CONTACT_SUFFIX = '##PENDING##';

export const createPendingContactJid = (email: string) => {
    return `${email}${PENDING_CONTACT_SUFFIX}`;
};

export const isPendingContactJid = (jid: string) => {
    if (!jid) return false;
    return jid?.endsWith(PENDING_CONTACT_SUFFIX);
};

export const EXTERNAL_GROUP_ID = 'default';

export const isExternalGroup = (groupId = '') => {
    return groupId === EXTERNAL_GROUP_ID;
};

export const CAMERA_CONTROL_GROUP_ID = '##CameraControlGroup##';

export const isCameraControlGroup = (id: string) => {
    return id === CAMERA_CONTROL_GROUP_ID;
};
