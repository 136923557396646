import { Base64 } from 'js-base64';

/* url safe mode of base64 is required for any system-wide data encoding/decoding */
export function encodeBase64(value: any) {
    return Base64.encode(value, true);
}
/* url safe mode of base64 is required for any system-wide data encoding/decoding */
export function decodeBase64(value: any) {
    return Base64.decode(value);
}

export const NOTHING = encodeBase64('{}');
