import { ITaskOptions } from './types';

export const DEFAULT_OPTION: Required<ITaskOptions> = {
    priority: 0,
};

let idIndex = 0;

export const getId = () => {
    return `${Date.now()}_${idIndex++}`;
};

export const isTestEnv = () => {
    return process.env.NODE_ENV === 'test';
};

export const isDevEnv = () => {
    return process.env.NODE_ENV === 'development';
};

export const isTestOrDevEnv = () => {
    return isTestEnv() || isDevEnv();
};
