import serviceContainer from '../container/index';

/**
 * wait xmpp connection ready decorator
 * */
export const waitXMPPConnectionReadyDecorator = () => {
    return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
        if (!descriptor) Promise.reject('waitXMPPConnectionReadyDecorator only use in class method');
        const method = descriptor.value;
        descriptor.value = function (...args: any) {
            const xmppAgent = serviceContainer.getXmppAgent();
            const eventBus = serviceContainer.getEventBus();
            const isConnected = xmppAgent.isConnected();
            if (isConnected) {
                const result = method.apply(this, args);
                return Promise.resolve(result);
            } else {
                return new Promise<any>((resolve, reject) => {
                    eventBus.xmpp.xmppReady.subscribe((_, { id }) => {
                        eventBus.app.userInfoReady.unsubscribe(id);
                        if (xmppAgent.isConnected()) {
                            const result = method.apply(this, args);
                            return resolve(result);
                        }
                        return reject('xmppConnectionReadyDecorator xmpp connection not ready');
                    });
                });
            }
        };
    };
};
