import React, { useRef } from 'react';
import { useAppDispatch } from '../../../store/store-hooks';
import { setLogoutModal, setModal } from '../../../store/modal/modal-store';
import './LogoutModal.scss';
import { DOM_KEY_CODE } from '../../../utils/constants';
import '../../../assets/styles/main.scss';
import { WebimStatusErrorType } from '../../../services/xmpp/constants';
import { OK_Text, SIGNOUT_CONFLICT, SIGNOUT_REVOKE_TOKEN, YOU_ARE_SIGN_OUT_TEXT } from '../../../resource';
import { Modal } from 'Modal';
import { SignOutType } from '../../../store/common/sign-out-thunk';

const logoutModalText = {
    [SignOutType.SESSION_EXPIRED]: SIGNOUT_REVOKE_TOKEN, // text same as reovke token
    [SignOutType.XMPP_CONFLICT]: SIGNOUT_CONFLICT,
    [SignOutType.XMPP_REVOKE_TOKEN]: SIGNOUT_REVOKE_TOKEN,
};

const LogoutModal = ({ data }) => {
    const dispatch = useAppDispatch();

    const hideMe = () => dispatch(setModal({ name: 'logout', data: null }));

    let okRef = useRef(null);
    let onKeyDown = (e) => {
        if (e.keyCode === DOM_KEY_CODE.ENTER || e.keyCode === DOM_KEY_CODE.SPACE || e.keyCode === DOM_KEY_CODE.ESC) {
            hideMe();
        }
    };

    const { signOutType } = data;

    return (
        <Modal
            isOpen={true}
            isDraggable={false}
            needBackdrop={true}
            onRequestClose={hideMe}
            contentLabel="logout-title"
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className="logout-modal">
                <p className="logout-modal__title" id="logout-title">
                    {YOU_ARE_SIGN_OUT_TEXT}
                </p>
                <p className="logout-modal__text">{logoutModalText[signOutType]}</p>
                <div className="logout-modal__btns">
                    <button
                        ref={okRef}
                        onClick={hideMe}
                        onKeyDown={onKeyDown}
                        className="logout-modal__btn-ok button-normal button-size-32"
                    >
                        {OK_Text}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default LogoutModal;
