import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useWindowResize from '../../hooks/useWindowResize';
import { useAppSelector } from '../../store/store-hooks';
import { MESSAGE_TYPE_WITH_WEBCLIENT } from './MeetingAgent';
import { PWAMeetingEvent } from './meeting-agent-msg-type';
interface IRect {
    top: number;
    left: number;
    width: number | string;
    height: number | string;
}
interface IPosition {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
}

interface ISize {
    width: number;
    height: number;
}
interface IBounds {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

interface IParams {
    clientX: number;
    clientY: number;
    screenX: number;
    screenY: number;
}

export interface IProps {
    initialPosition: IPosition;
    minimizedSize: ISize;
    bounds: IBounds;
}

export default ({ initialPosition, bounds, minimizedSize }: IProps) => {
    const [isDragging, setIsDragging] = useState(false);
    const hasDragged = useRef(false);
    const {
        meeting: { isMeetingMinimized },
    } = useAppSelector((_) => _);

    const [position, setPosition] = useState<IRect>(() => {
        const { bottom, right, left, top } = initialPosition;
        const { width, height } = minimizedSize;
        const winW = window.innerWidth;
        const winH = window.innerHeight;

        let result = { top, left, ...minimizedSize };
        if (bottom !== undefined && right !== undefined) {
            const left = winW - right - width;
            const top = winH - bottom - height;
            result = { width, height, top, left };
        }
        return result;
    });

    const startPos = useRef<any>(null);

    const adjustPosWhenWindowSizeUpdate = () => {
        if (!isMeetingMinimized) return;
        updatePosition(position);
    };

    useWindowResize(() => {
        if (!isMeetingMinimized) return;
        adjustPosWhenWindowSizeUpdate();
    }, 200);

    // when you minimize ->  maximize -> decrease window size so that minimized iframe could hide -> minimize
    // we should adjust iframe's position so that we could see it
    useLayoutEffect(() => {
        adjustPosWhenWindowSizeUpdate();
    }, [isMeetingMinimized]);

    const updatePosition = (position: { top: number; left: number }) => {
        const { top, left } = position;
        const { top: topBound = 0, left: leftBound = 0, bottom: bottomBound = 0, right: rightBound = 0 } = bounds;
        const { width, height } = minimizedSize;
        const winWidth = window.innerWidth;
        const winHeight = window.innerHeight;
        let newLeft = left;
        let newTop = top;

        if (top <= topBound) {
            newTop = topBound;
        } else if (top + height > winHeight - bottomBound) {
            newTop = winHeight - bottomBound - height;
        }

        if (left <= leftBound) {
            newLeft = leftBound;
        } else if (left + width > winWidth - rightBound) {
            newLeft = winWidth - rightBound - width;
        }

        const newPosition = { width, height, top: newTop, left: newLeft };

        setPosition(newPosition);
    };

    const handleDragPosition = (params: IParams) => {
        const { screenX, screenY } = params;
        const deltaX = screenX - startPos.current.screenX;
        const deltaY = screenY - startPos.current.screenY;
        const newX = startPos.current.curPosX + deltaX;
        const newY = startPos.current.curPosY + deltaY;

        updatePosition({
            top: newY,
            left: newX,
        });
    };

    const onStart = (params: IParams) => {
        startPos.current = {
            ...params,
            curPosX: position.left,
            curPosY: position.top,
        };
    };

    const onDrag = (params: IParams) => {
        if (!startPos.current) return;
        hasDragged.current = true;
        setIsDragging(true);
        handleDragPosition(params);
    };

    const onEnd = (params: IParams) => {
        if (!startPos.current) return;
        handleDragPosition(params);
        startPos.current = null;
        hasDragged.current = false;
        setIsDragging(false);
    };

    const handleClientMessage = (e: any) => {
        let payload = e;
        if (e instanceof MessageEvent) {
            payload = e.data;
        }
        const { message, type } = payload;

        if (type !== MESSAGE_TYPE_WITH_WEBCLIENT) return;

        const { event, data } = message as any;
        switch (event) {
            case PWAMeetingEvent.DRAG_START:
                onStart(data);
                break;
            case PWAMeetingEvent.DRAG_MOVE:
                onDrag(data);
                break;
            case PWAMeetingEvent.DRAG_END:
                onEnd(data);
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        if (isMeetingMinimized) {
            window.handleClientMessage = handleClientMessage;
            window.addEventListener('message', handleClientMessage);
        } else {
            window.handleClientMessage = null;
            window.removeEventListener('message', handleClientMessage);
        }
        return () => {
            window.handleClientMessage = null;
            window.removeEventListener('message', handleClientMessage);
        };
    }, [isMeetingMinimized, handleClientMessage]);

    return { position, isDragging };
};
