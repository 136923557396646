import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '../../../../utils/constants';
import { MeetingItem, CalendarEvent } from '../types';
import { EveryoneId } from './meetings-selector';

export enum TabKey {
    upcoming = 'upcoming',
}
interface IState {
    selectedTabKey: TabKey;
    loadingStatus: FetchStatus;
    selectedMeetingItemId: string;
    selectedMeetingHostId: string;
    meetings: Array<MeetingItem>;
    calendars: Array<CalendarEvent>;
    isConnectedToCalendar: boolean; // if user has integrted an calendar service eg. google calendar, office365, outlook calendar
    isNeedReAuthCalendar: boolean;
    calculateUpcomingMeetingsAt: number;
}

const initialState: IState = {
    selectedTabKey: TabKey.upcoming,
    loadingStatus: FetchStatus.idle,
    selectedMeetingItemId: '',
    selectedMeetingHostId: EveryoneId,
    meetings: [],
    calendars: [],
    isConnectedToCalendar: true,
    isNeedReAuthCalendar: false,
    calculateUpcomingMeetingsAt: -1,
};

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState,
    reducers: {
        setSelectedTabKey(state, { payload }) {
            state.selectedTabKey = payload;
        },
        setLoadingStstus(state, { payload }) {
            state.loadingStatus = payload;
        },

        setMeetings(state, { payload = [] }) {
            state.meetings = payload;
        },

        updateMeetings(state, { payload: meetingList }: { payload: Array<Partial<MeetingItem>> }) {
            const { meetings } = state;
            meetingList.forEach((item) => {
                if (!item.id) return;
                const _index = meetings.findIndex(({ id }) => id === item.id);
                if (_index > -1) {
                    meetings[_index] = { ...meetings[_index], ...item };
                } else {
                    meetings.push(item as MeetingItem);
                }
            });
        },

        deleteMeetings(state, { payload: itemIds }: { payload: Array<string> }) {
            state.meetings = state.meetings.filter((item) => !itemIds.find((_) => _ === item.id));
        },

        setCalendars(state, { payload = [] }) {
            // every time we get the full list; just replace, not update;
            state.calendars = payload;
        },

        setSelectedMeetingItemId(state, { payload }) {
            state.selectedMeetingItemId = payload;
        },

        setSelectedMeetingHostId(state, { payload }) {
            state.selectedMeetingHostId = payload;
        },

        setIsConnectedToCalendar(state, { payload }) {
            state.isConnectedToCalendar = payload;
        },

        setIsNeedReAuthCalendar(state, { payload }) {
            state.isNeedReAuthCalendar = payload;
        },
        setCalculateUpcomingMeetingsAt(state, { payload }) {
            state.calculateUpcomingMeetingsAt = payload;
        },
    },
});

export const {
    setSelectedTabKey,
    setLoadingStstus,
    setMeetings,
    updateMeetings,
    deleteMeetings,
    setCalendars,
    setSelectedMeetingItemId,
    setSelectedMeetingHostId,
    setIsConnectedToCalendar,
    setIsNeedReAuthCalendar,
    setCalculateUpcomingMeetingsAt,
} = meetingsSlice.actions;

export * from './meetings-selector';
export * from './meetings-thunk';
export default meetingsSlice.reducer;
