import { useEffect, useRef } from 'react';
import { useAppSelector } from '../store';
import { HIDControl } from '../features/Phone/HID';
import _ from 'lodash-es';

const bindCallingCallbackMap = new Map();

type TypeCallHandler = {
    accept: () => void;
    decline: () => void;
};

export default (bindID: string, actions: TypeCallHandler) => {
    const onAcceptRef = useRef(() => {});
    const onDeclineRef = useRef(() => {});
    const { isEnableHID, hidAvalible } = useAppSelector(({ settings: { isEnableHID, hidAvalible } }) => ({
        isEnableHID,
        hidAvalible,
    }));

    /**
     * setup bindingCallback when callback changed
     */
    useEffect(() => {
        if (!isEnableHID || !hidAvalible) {
            return;
        }

        bindCallingCallbackMap.set(bindID, actions);
        const currentBindID = _.last(Array.from(bindCallingCallbackMap.keys()));
        if (currentBindID === bindID && actions) {
            onAcceptRef.current = actions.accept;
            onDeclineRef.current = actions.decline;
            setTimeout(() => {
                HIDControl.onHookSwitched = (isOn) => {
                    // if the call is being handled already
                    if (!HIDControl.webHid.device.ring) {
                        return;
                    }
                    if (isOn) {
                        if (typeof onAcceptRef.current === 'function') {
                            onAcceptRef.current();
                        }
                    } else {
                        if (typeof onDeclineRef.current === 'function') {
                            onDeclineRef.current();
                        }
                    }

                    HIDControl.onHookSwitched = () => {};
                };
                /**
                 * we need the 500 delay to skip the HID reply message.
                 * otherwise the code can't tell if the message is a reply message or a button press
                 */
            }, 500);
        }
    }, [actions, isEnableHID, hidAvalible]);

    /**
     * setup current calling binding
     */
    useEffect(() => {
        HIDControl.sendReport('ring', true);

        return () => {
            bindCallingCallbackMap.delete(bindID);
            const previousActions = _.last(Array.from(bindCallingCallbackMap.values()));
            if (!previousActions) {
                HIDControl.sendReport('ring', false);
            } else {
                setTimeout(() => {
                    onAcceptRef.current = actions.accept;
                    onDeclineRef.current = actions.decline;
                }, 500);
            }
        };
    }, []);
};
