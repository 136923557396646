import React from 'react';
import { ReactComponent as LogoDark } from '../../../assets/images/zoom-workplace-dark.svg';
import { ReactComponent as LogoTesla } from '../../../assets/images/zoom-workplace-tesla.svg';
import { ReactComponent as LogoLight } from '../../../assets/images/zoom-workplace-light.svg';
import { isTeslaMode } from '../../../utils';
import classNames from 'classnames';
import { checkIsJoinRoute } from '../../../routes';
import { useAppSelector } from '../../Phone/types';

export default () => {
    const theme = useAppSelector((state) => state.settings.userSettingTheme);

    const containerClass = classNames('home-header__logo', {
        'home-header__logo-tesla-join': isTeslaMode() && checkIsJoinRoute(),
    });

    const logo = isTeslaMode() ? (
        <LogoTesla className={'zoom-workplace-light-tesla'} />
    ) : theme !== 'classic' ? (
        <LogoLight className={'zoom-workplace-light'} />
    ) : (
        <LogoDark className={'zoom-workplace-dark'} />
    );

    return <div className={containerClass}>{logo}</div>;
};
