import React, { useCallback } from 'react';
import { setSearchKey, setCurrentDisplayType, searchContactsThunk } from '../../redux';
import { DisplayType } from '../../types';
import { debounce } from 'lodash-es';
import { IconMagnifier } from '@zoom/icons-react';
import './Search.scss';
import { CONTACTS_SEARCH } from '../../../../resource';
import { useAppSelector, useAppDispatch } from '../../../../store';

const Search = () => {
    const dispatch = useAppDispatch();
    const searchKey = useAppSelector((state) => state.contacts.searchKey);

    const onChangeDelayed = useCallback(
        debounce((key) => {
            if (key) {
                dispatch(searchContactsThunk({ key }));
                dispatch(setCurrentDisplayType(DisplayType.search));
            } else {
                dispatch(setCurrentDisplayType(DisplayType.contact));
            }
        }, 500),
        [dispatch],
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchStr = e.target.value.slice(0, 200);
        dispatch(setSearchKey(searchStr));
        onChangeDelayed(searchStr);
    };

    return (
        <div className="contacts-search">
            <IconMagnifier className="contacts-search-icon" />
            <input spellCheck="false" type="text" placeholder={CONTACTS_SEARCH} value={searchKey} onChange={onChange} />
        </div>
    );
};

export default Search;
