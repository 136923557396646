/**
 * data-a-l: describe the dom level relationship. format: '0-*' or '0-*-*',
 * data-a-walk-policy: describe current a11y behavior
 */

import { Component } from 'react';
import { DOM_KEY_CODE } from '../../../utils/constants';
import { All_DOM_Nodes_Type, Perform_Focus_Type, Perform_Click_Type, Keyboard_Event_Handler_Type } from './type';

const performClick: Perform_Click_Type = (_keyCode, _rootJDom, targetJDom) => {
    if (targetJDom) {
        targetJDom.click();
    }
};

const performFocus: Perform_Focus_Type = (_keyCode, _rootJDom, targetJDom) => {
    if (targetJDom) {
        targetJDom.focus();
    }
};

export default class FocusWalkerComponent<T> extends Component<T> {
    private eventBind: boolean;

    // must be implemented by child components
    public rootId: string;
    public openPropSubscriber: (flag: any) => any = () => {}; // init event listener when subscriber field change
    public keyboardEventHandler: Keyboard_Event_Handler_Type;
    public getWalkJDoms: (currentDom: any) => All_DOM_Nodes_Type;

    constructor(props: any) {
        super(props);
        this.eventBind = false;
    }

    a11yKeyEventHandler = (event: KeyboardEvent) => {
        const { target: currentDom, keyCode } = event as { target: any; keyCode: DOM_KEY_CODE };
        const { TAB, UP, DOWN, LEFT, RIGHT, ENTER, SPACE } = DOM_KEY_CODE;

        if ([TAB, UP, DOWN, LEFT, RIGHT, ENTER, SPACE].every((whiteListKey) => whiteListKey !== keyCode)) {
            return true;
        }

        if (!currentDom?.getAttribute('data-a-l')) return true;

        const allDomNodes = this.getWalkJDoms(currentDom);
        const walkerPolicy = currentDom.getAttribute('data-a-walk-policy');
        const hasPerformed = this.keyboardEventHandler(walkerPolicy, performFocus, performClick, event, allDomNodes);
        // hasPerformed represent the focusWalker doing the perform successfully,
        // so it's necessary to prevent the Native focus behavior
        if (hasPerformed) {
            event.preventDefault();
            event.stopPropagation();
        }
        return false;
    };

    componentDidMount() {
        this.initEventListener();
    }

    initEventListener = () => {
        setTimeout(() => {
            const rootJDom = document.getElementById(this.rootId);
            if (!this.eventBind && rootJDom) {
                this.eventBind = true;
                rootJDom.addEventListener('keydown', this.a11yKeyEventHandler, false);
            }
        }, 0);
    };

    disposeEventListener = () => {
        const rootJDom = document.getElementById(this.rootId);
        if (rootJDom) {
            rootJDom.removeEventListener('keydown', this.a11yKeyEventHandler);
        }
        this.eventBind = false;
    };

    componentDidUpdate(prevProps: any) {
        const previousOpenFlag = this.openPropSubscriber(prevProps);
        const currentOpenFlag = this.openPropSubscriber(this.props);
        if (previousOpenFlag !== currentOpenFlag && currentOpenFlag) {
            this.initEventListener();
        } else if (previousOpenFlag !== currentOpenFlag && !currentOpenFlag) {
            this.disposeEventListener();
        }
    }

    componentWillUnmount() {
        this.disposeEventListener();
    }
}
