import serviceContainer from '../container';
import Zpns from './zpns';
import './PresneceManager';

const reduxStore = serviceContainer.getReduxStore();

const zpns = new Zpns({ store: reduxStore });

serviceContainer.setZpns(zpns);

export default zpns;
