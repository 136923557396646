import React from 'react';
import Button from '../../../components/Button/Button';
import { xmppAgent } from '../../../app-init';
import { InviteContactData } from './types';
import { PHONE_DELETE, CANCEL, DELETE_CONTACT_F } from '../../../resource';

interface IProps {
    data: InviteContactData;
    closeModal: () => void;
}

const DeleteContent = ({ data, closeModal }: IProps) => {
    const { jid, name } = data;

    const deleteContact = () => {
        xmppAgent.deleteContactByJid(jid);
        closeModal();
    };

    return (
        <>
            <div className="delete-content">
                <span tabIndex={0} className="title overflow-ellipsis-1">{`${PHONE_DELETE} ${name}`}</span>
                <span tabIndex={0} className={'description overflow-ellipsis-3 '}>
                    {DELETE_CONTACT_F(name)}
                </span>
            </div>
            <div className="modal-buttons">
                <Button extraCls="delete" size={Button.Size24} type={Button.Normal} onClick={deleteContact}>
                    {PHONE_DELETE}
                </Button>
                <Button extraCls="cancel" size={Button.Size24} type={Button.Dark} onClick={closeModal}>
                    {CANCEL}
                </Button>
            </div>
        </>
    );
};

export default DeleteContent;
