import React, { useCallback, useEffect } from 'react';
import './invitation.scss';
import Invitation from './Invitation.js';
import { acceptThunk, declineThunk, isDuringMeetingSelector } from '../redux';
import useAudioPlay from '../../../hooks/useAudioPlay';
import { ringType } from '../../../utils/audio';
import { useAppSelector, useAppDispatch } from '../../../store/store-hooks';

export default () => {
    const dispatch = useAppDispatch();
    const { audio, speakerDeviceId } = useAudioPlay(ringType.invite);
    const {
        meeting: { invitationList },
    } = useAppSelector((_) => _);
    const isDuringMeeting = useAppSelector(isDuringMeetingSelector);

    useEffect(() => {
        audio.changeSpeaker(speakerDeviceId);
    }, [speakerDeviceId]);

    // play auido
    const shouldPlayAudio = invitationList.length !== 0;
    useEffect(() => {
        if (shouldPlayAudio) {
            audio.play(true);
        } else {
            audio.stop();
        }
    }, [shouldPlayAudio]);

    const accept = useCallback(
        (invitation: any) => {
            dispatch(acceptThunk(invitation));
        },
        [dispatch],
    );

    const decline = useCallback(
        (invitation: any) => {
            dispatch(declineThunk(invitation));
        },
        [dispatch],
    );

    const timeoutDecline = useCallback(
        (invitation: any) => {
            dispatch(declineThunk({ ...invitation, isTimeoutDecline: true }));
        },
        [dispatch],
    );

    const closeMe = useCallback(
        (invitation: any) => {
            decline(invitation);
        },
        [decline],
    );

    const invitationElements = invitationList.map((invitation, index) => {
        const winW = window.innerWidth;
        const winH = window.innerHeight;
        const w = 324;
        const h = 160;
        const originX = (winW - w) / 2;
        const originY = (winH - h) / 2 - h / 2;
        const offsetX = w * 0.25;
        const offsetY = h * 0.25;

        const style = {
            left: originX + index * offsetX,
            top: originY + index * offsetY,
        };

        return (
            <Invitation
                style={style}
                key={invitation.fromJid}
                closeMe={closeMe}
                accept={accept}
                decline={decline}
                timeoutDecline={timeoutDecline}
                isDuringMeeting={isDuringMeeting}
                invitation={invitation}
            />
        );
    });

    return <div className="invitation-container">{invitationElements}</div>;
};
