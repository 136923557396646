import { RootState } from '..';
import serviceContainer from '../../services/container';
import type { ModalData } from './modal-store';
import { initialState, setModal } from './modal-store';

export type ModalName = keyof typeof initialState.modals;

export const showModal = (name: ModalName, data: ModalData = {}) => {
    const reduxStore = serviceContainer.getReduxStore();

    const payload = {
        name,
        data: { show: true, ...data },
    };

    return reduxStore.dispatch(setModal(payload));
};

export const hideModal = (name: ModalName) => {
    const reduxStore = serviceContainer.getReduxStore();

    const payload = {
        name,
        data: { show: false },
    };

    return reduxStore.dispatch(setModal(payload));
};

export const selectModalData = (name: ModalName) => (state: RootState) => {
    const {
        modal: { modals },
    } = state;

    if (name in modals) {
        return modals[name];
    } else {
        return null;
    }
};

export const getModalData = (name: ModalName) => {
    const reduxStore = serviceContainer.getReduxStore();
    const state = reduxStore.getState();
    return state.modal.modals[name];
};

export const hideAllModals = () => {};
