import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { PWAMeetingEvent } from '../meeting-agent-msg-type';

export const isDuringMeetingSelector = createSelector(
    ({ meeting }: RootState) => meeting.meetingInfo,
    (meetingInfo) => {
        return (
            meetingInfo &&
            meetingInfo.meetingNo &&
            (PWAMeetingEvent.JOINING === meetingInfo.meetingStatus ||
                PWAMeetingEvent.SUCCESS === meetingInfo.meetingStatus)
        );
    },
);
