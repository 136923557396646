import { MemoryStorage, LocalStorage, SessionStorage } from './Storages';

const STORAGE_KEY = 'pwa-store';

const memoryStore = new MemoryStorage({});
const localStore = new LocalStorage({ storageKey: STORAGE_KEY });
const sessionStore = new SessionStorage({ storageKey: STORAGE_KEY });

interface IEasyStore {
    memoryStore: MemoryStorage;
    localStore: LocalStorage;
    sessionStore: SessionStorage;
}

const easyStore: IEasyStore = {
    memoryStore,
    localStore,
    sessionStore,
};

export default easyStore;
