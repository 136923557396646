import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../features/Phone/types';
import { isFalse, isTrue } from '../../features/ModalContainer/CanNotShowZoomPhone/utils';
import { isUndefined } from 'lodash-es';

const contactCenterModuleSelector = (state: RootState) => {
    return state.appModules['contact-center'];
};

export const isContactCenterEnabledSelector = createSelector(contactCenterModuleSelector, (module) => {
    return module.willLoad;
});

export const canWeLoadZoomPhone = (state: RootState) => {
    const {
        'zoom-phone': { hasZoomPhonePlan, pwaFlagEnabled },
    } = state.appModules;
    return isTrue(hasZoomPhonePlan) && isTrue(pwaFlagEnabled);
};

export const canWeShowPhoneLoading = (state: RootState) => {
    const {
        'zoom-phone': { hasZoomPhonePlan, pwaFlagEnabled },
    } = state.appModules;

    if (isUndefined(hasZoomPhonePlan) && isUndefined(pwaFlagEnabled)) {
        return true;
    }

    if (isTrue(hasZoomPhonePlan) && isUndefined(pwaFlagEnabled)) {
        return true;
    }

    return false;
};

export const shouldWeHidePhoneRoute = (state: RootState) => {
    const {
        'zoom-phone': { hasZoomPhonePlan, pwaFlagEnabled },
    } = state.appModules;

    return isFalse(hasZoomPhonePlan) || isFalse(pwaFlagEnabled);
};
