import React from 'react';
import { WebimStatus } from '@zoom/pwa-webim';
import { RootState, useAppSelector } from '../../../store';
import { Connecting_Text, Not_Connected_Text } from '../../../resource';

// "const enum" is not support reverse mapping, but it can reduce code size and improve performance
// https://www.typescriptlang.org/docs/handbook/enums.html#const-enums
const enum Status {
    NULL,
    NOT_CONNECTED,
    CONNECTING,
}

const Connecting = () => <div className="connect-status_text-content connect-status_connnecting overflow-ellipsis-1" title={Connecting_Text}>{Connecting_Text}...</div>;
const NotConnected = () => (
    <div className="connect-status_text-content connect-status_notconnected overflow-ellipsis-1" title={Not_Connected_Text}>{Not_Connected_Text}</div>
);

const StatusMap = {
    [Status.CONNECTING]: <Connecting />,
    [Status.NOT_CONNECTED]: <NotConnected />,
};

const getStatusSelector = (state: RootState) => {
    const {
        xmpp: { status },
    } = state;

    let result = Status.NULL;
    if (status === WebimStatus.RECONNECTING || status === WebimStatus.CONNECTING) {
        result = Status.CONNECTING;
    } else if (status === WebimStatus.DISCONNECTED) {
        result = Status.NOT_CONNECTED;
    }
    return result;
};

const ConnectionStatus = () => {
    const status = useAppSelector(getStatusSelector);
    if (status === Status.NULL) return null;
    return <div className="connect-status_wapper">{StatusMap[status]}</div>
};

export default ConnectionStatus;
