import Long from 'long';

/**
 *
 * @param bitPatterns - "162341929778348032", 64-bits decimal
 * @param mask - usually got from native clients, they could handle 64bits integers
 *      {number} - 0x400000 could be express within 6 bytes
 *      {string} - "0x8000000000000000"
 * @returns boolean
 */
export const isThisBitSetByMask =
    (bitPatterns: string, pattern_radix = 10) =>
    (mask: number | string, mask_radix = 10) => {
        const _mask = typeof mask === 'number' ? Long.fromNumber(mask, true) : Long.fromString(mask, true, mask_radix);
        return !Long.fromString(bitPatterns, true, pattern_radix).and(_mask).equals(Long.ZERO);
    };

/**
 *
 * @param bitPatterns -"162341929778348032", 64-bits decimal
 * @param offset - < 64; offset begins at 0, not 1
 * @returns boolean
 */
export const isThisBitSetByOffset =
    (bitPatterns: string, radix = 10) =>
    (offset: number) => {
        return !Long.ONE.shiftLeft(offset).and(Long.fromString(bitPatterns, true, radix)).equals(Long.ZERO);
    };

/**
 *
 * @param mask
 * @param value - 0 or 1
 * @param bitPatterns: decimal
 * @returns  string: decimal in string format
 */
export const updateBitPattern = (mask: number, value: number, bitPatterns: string): string => {
    let feature = Long.fromString(bitPatterns, true, 10);
    if (mask) {
        feature = Long.fromNumber(mask, true).not().and(feature); // remove that bit
    }
    if (value) {
        feature = Long.fromNumber(value, true).or(feature);
    }
    return feature.toString(10);
};
