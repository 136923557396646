export type PWA_ID = 'pwa-client';

export const WHITEBOARD_URL = '/wb/pwa?from=pwa';
export const WHITEBOARD_ID = 'whiteboard';

export enum WHITEBOARD_EVENT_TYPES_RECEIVED {
    'DASHBOARD_LOADED' = 'dashboardLoaded',
    'TOKEN_EXPIRED' = 'tokenExpired',
}

export enum WHITEBOARD_EVENT_TYPES_SENT {
    HELLO = 'hello',
}

export interface WBPostMessage_EVENT {
    from: PWA_ID;
    type: WHITEBOARD_EVENT_TYPES_SENT;
    value: any;
}

export interface WBPostMessage {
    (event: WBPostMessage_EVENT): void;
}

export interface IWhiteboardState {
    isWbLoaded: false;
}
