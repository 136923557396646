import React from 'react';
import './Header.scss';
import '../../../assets/styles/main.scss';
import Settings from '../../Settings';

import HeaderLeft from './HeaderLeft';
import HeaderTabs from './HeaderTabs';
import HeaderRight from './HeaderRight';
import { IUserInfo } from '../../../store/common/common-store';
import { isTeslaMode } from '../../../utils';
import ConnectionStatus from './ConnectionStatus';
import classNames from 'classnames';
import { useAppSelector } from '../../Phone/types';
import HeaderLogo from './HeaderLogo';

interface IProps {
    userInfo: IUserInfo;
}

function Header({ userInfo }: IProps) {
    const isTesla = isTeslaMode();
    const theme = useAppSelector((state) => state.settings.userSettingTheme);

    if (isTesla) {
        return (
            <div className="home-header header-tesla">
                <HeaderLogo />
                <HeaderRight />
                {!!userInfo ? <Settings /> : null}
            </div>
        );
    }

    const clsNames = classNames(
        'home-header',
        { bloom: theme === 'bloom' },
        { agave: theme === 'agave' },
        { rose: theme === 'rose' },
    );

    return (
        <div className={clsNames}>
            <HeaderLeft />
            <HeaderTabs />
            <ConnectionStatus />
            <HeaderRight />
            {!!userInfo ? <Settings /> : null}
        </div>
    );
}

export default Header;
