import { createSlice } from '@reduxjs/toolkit';
import { IWhiteboardState } from '../types';

const initialState: IWhiteboardState = {
    isWbLoaded: false,
};

const whiteboardSlice = createSlice({
    name: 'whiteboard',
    initialState,
    reducers: {
        setIsWbLoaded(state, { payload }) {
            state.isWbLoaded = payload;
        },
    },
});

export const { setIsWbLoaded } = whiteboardSlice.actions;

export default whiteboardSlice.reducer;
