import React from 'react';
import './feedback-thumb.scss';

export default function FeedbackThumb({ status }: any) {
    const { Default, Hover, Active } = status;

    return (
        <div className="feedback-thumb">
            <img src={Default} alt="" className="feedback-thumb__default" />
            <img src={Hover} alt="" className="feedback-thumb__hover" />
            <img src={Active} alt="" className="feedback-thumb__active" />
        </div>
    );
}
