import type { RootState } from '../../../store';

export const isTrue = (value: any) => {
    return true === value;
};

export const isFalse = (value: any) => {
    return false === value;
};

const getUrlByPath = (path: string) => {
    if (!path) {
        return path;
    }
    if (/^https/.test(path)) {
        return path;
    } else {
        if (!/^\//.test(path)) {
            path = '/' + path;
        }
        return `https://${window.location.hostname}${path}?from=pwa`;
    }
};

export const getZoomPhoneSetUpUrl = () => {
    return getUrlByPath(window.PwaConfig.setUpPath);
};

export const getBuyZoomPhonePlanUrl = () => {
    return getUrlByPath(window.PwaConfig.planPath);
};

export const getPwaZoomPhoneSupportUrl = () => {
    return getUrlByPath('https://support.zoom.us/hc/articles/4403638230925-Using-Zoom-for-Chrome-PWA');
};

export const getLearnZoomPhoneUrl = () => {
    return getUrlByPath('https://explore.zoom.us/products/zoom-phone');
};

export const getZoomPhoneModuleState = (state: RootState) => {
    const {
        'zoom-phone': { hasZoomPhonePlan, pwaFlagEnabled },
    } = state.appModules;

    return { hasZoomPhonePlan, pwaFlagEnabled };
};
