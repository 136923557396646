import React from 'react';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { MeetingItem } from '../../Meetings/Meetings/types';
import './index.scss';
import { IUserInfo } from '../../../store/common/common-store';
import { deleteMeetingFromServer, deleteMeetings } from '../../Meetings/Meetings/redux/meetings-store';
import { isPMIMeeting } from '../../Meetings/Meetings/utils';
import {
    Delete_Modal_Content_Text2,
    Delete_Modal_Content_Text1,
    Delete_Modal_Prefix_Text,
    Delete_Modal_Content_Recent_Text,
    Delete_Failed_Toast_Text,
    Delete_Text,
    Cancel_Text,
    DELETE_MEETINGS_TEXT,
} from '../../Meetings/LanguageResource';
import { ICON_TYPE, toast } from 'Toast';
import { TOAST_PATH } from '../../../utils/constants';
import { Modal } from 'Modal';
import { useCloseWhenLogout } from '../../../store/modal/modal-hooks';
import { IconCloseFill } from '@zoom/icons-react';
interface IProps {
    data: MeetingItem;
    userInfo: IUserInfo;
}

export default function ({ data, userInfo }: IProps) {
    const dispatch = useAppDispatch();

    if (!userInfo) return null;

    const { userId } = userInfo;
    const { hostName, hostId, meetingNumber, originalMtgNumber } = data;
    const recentlyDeletePageUrl = '/meeting/trashcan/list';
    const showPrefix = hostId !== userId;
    const descPrefix = showPrefix ? `${Delete_Modal_Prefix_Text(hostName)}. ` : '';
    const desc = (
        <>
            {descPrefix}
            {Delete_Modal_Content_Text1}{' '}
            <a href={recentlyDeletePageUrl} target="_blank" rel="noreferrer">
                {Delete_Modal_Content_Recent_Text}
            </a>{' '}
            {Delete_Modal_Content_Text2}
        </>
    );
    const mn = isPMIMeeting(data) ? originalMtgNumber : meetingNumber;
    const onClose = () => {
        dispatch(setModal({ name: 'meetingsDelete', data: null }));
    };

    // Todo if it's recurring meeting, we will delete them all
    // need to give user options to delete one or all
    const onDelete = () => {
        deleteMeetingFromServer({ mn })
            .then(() => {
                dispatch(deleteMeetings([data.id]));
            })
            .catch((_e) => {
                toast(
                    {
                        type: ICON_TYPE.FAILURE,
                        desc: Delete_Failed_Toast_Text,
                    },
                    {
                        belongsTo: TOAST_PATH.GLOBAL,
                        toastId: 'Delete_Meeting_Tag',
                    },
                );
            })
            .finally(() => {
                onClose();
            });
    };

    useCloseWhenLogout(onClose);

    return (
        <Modal
            isOpen={true}
            isDraggable={false}
            needBackdrop
            onRequestClose={onClose}
            overlayClassName="z-modal-confirm__overlay"
        >
            <div className="z-modal-confirm-outer-content">
                <div className="z-modal-confirm">
                    <div className="z-modal-confirm__header">
                        <span className="z-modal-confirm__header-title">
                            <span className="z-modal-confirm__header-logo"></span>
                            <span className="z-modal-confirm__header-txt">Zoom</span>
                        </span>
                        <IconCloseFill className="z-modal-confirm__header-close" onClick={onClose} />
                    </div>
                    <div className="z-modal-confirm__body">
                        <h5>{DELETE_MEETINGS_TEXT}</h5>
                        <p className="z-modal-confirm__body-desc">{desc}</p>
                    </div>
                    <footer className="z-modal-confirm__footer">
                        <Button size={Button.Size32} type={Button.Destructive} onClick={onDelete}>
                            {Delete_Text}
                        </Button>
                        <Button size={Button.Size32} type={Button.Secondary} onClick={onClose}>
                            {Cancel_Text}
                        </Button>
                    </footer>
                </div>
            </div>
        </Modal>
    );
}
