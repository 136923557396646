import { EPhoneLoadType } from '../events/event-modules/phone-events';
import serviceContainer from '../services/container';

export enum AsyncModule {
    Phone = 'phone',
    ContactCenter = 'contact-center',
}

let resultPromise: Promise<ZoomPhoneModule> | null = null;

export const tryToGetAsyncPhoneModule = () => {
    const reduxStore = serviceContainer.getReduxStore();
    const state = reduxStore.getState();

    const {
        appModules: {
            ['zoom-phone']: { hasZoomPhonePlan, pwaFlagEnabled, loaded },
        },
    } = state;

    if (hasZoomPhonePlan === false) {
        return Promise.reject('No phone licence');
    }

    if (pwaFlagEnabled === false) {
        return Promise.reject('Not enable for pwa');
    }

    if (loaded === true) {
        return getAsyncPhoneModule();
    }

    if (resultPromise) {
        return resultPromise;
    }

    resultPromise = new Promise((resolve, reject) => {
        const eventBus = serviceContainer.getEventBus();
        const phone = eventBus.phone;
        if (!phone) {
            reject('Phone event error');
        }

        phone.load.subscribe((event, { id }) => {
            phone.load.unsubscribe(id);
            if (event?.type === EPhoneLoadType.Success) {
                resolve(event.module);
            } else {
                reject(event.reason);
            }
        });
    });

    return resultPromise;
};

export const getAsyncPhoneModule = () => {
    return import(/* webpackChunkName: "phone" */ '../features/Phone');
};

type ExtractPromiseValueType<T> = T extends Promise<infer V> ? V : T;
export type ZoomPhoneModule = ExtractPromiseValueType<ReturnType<typeof getAsyncPhoneModule>>;

export const getAsyncContactCenterModule = () => {
    return import(/* webpackChunkName: "contact-center" */ '../features/ContactCenter');
};

export const getAsyncLibPhoneNumberModule = () => {
    return import('google-libphonenumber');
};

export const asyncModuleCollection = (function () {
    const modules = {};

    const getModule = (id: AsyncModule) => {
        return modules[id] || null;
    };
    const addModule = (id: AsyncModule, moduleExports: any) => {
        modules[id] = moduleExports;
    };

    return {
        getModule,
        addModule,
    };
})();
