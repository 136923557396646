import React, { useState } from 'react';
import { Tooltip } from '@zoom/zoom-react-ui';
import { feedbackTipText, whoCanSeeTip } from '../resource';
import { Tip } from '../icons';
import './see-feedback-tip.scss';

export default function SeeFeedbackTip() {
    const [open, setOpen] = useState(false);
    const tip = (
        <p tabIndex={0} className="feedback-attention__hover">
            {feedbackTipText}
        </p>
    );

    return (
        <Tooltip
            position="top-middle"
            // @ts-ignore
            trigger="controlled"
            paperClassName="feedback-attention"
            contentNode={tip}
            isOpen={open}
        >
            <button type="button" tabIndex={0} className="see-feedback-tip" onClick={() => setOpen(!open)}>
                <img src={Tip} alt="" className="see-feedback-tip__svg" />
                <div className="see-feedback-tip__text">{whoCanSeeTip}</div>
            </button>
        </Tooltip>
    );
}
