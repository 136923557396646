import { createSlice } from '@reduxjs/toolkit';
import easyStore from '../../../utils/storage';
import { IChatState } from '../chat-types';

const initialState: IChatState = {
    blockedList: [],
    recentChat: [],
    unreadCount: {
        total: 0,
        shown: 0,
    },
    uiState: {
        chatAvailablePopover: easyStore.localStore.easyGet('showChatAvailablePopover', true),
        postMeetingPopover: null,
    },
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setBlockedList(state, { payload = [] }) {
            state.blockedList = payload;
        },
        setRecentChat(state, { payload = [] }) {
            state.recentChat = payload;
        },
        setUnreadCount(state, { payload }) {
            state.unreadCount = payload;
        },
        setChatAvailablePopover(state, { payload }) {
            state.uiState.chatAvailablePopover = payload;
        },
        setPostMeetingPopover(state, { payload }) {
            state.uiState.postMeetingPopover = payload;
        },
    },
});

export const { setRecentChat, setUnreadCount, setBlockedList, setChatAvailablePopover, setPostMeetingPopover } =
    chatSlice.actions;

export default chatSlice.reducer;
