import { WBPostMessage, WBPostMessage_EVENT, WHITEBOARD_EVENT_TYPES_RECEIVED, WHITEBOARD_ID } from './types';
import store from '../../store';
import { setIsWbLoaded } from './redux';
import { signoutWhenSeesionExpiredThunk } from '../../store/common/sign-out-thunk';

export default class WhiteboardAgent {
    postMessage: WBPostMessage;

    setPostMessage = (postMessage: WBPostMessage) => {
        this.postMessage = postMessage;
    };

    sendMessageToWhiteboard = (_data: Omit<WBPostMessage_EVENT, 'from'>) => {
        if (typeof this.postMessage === 'function') {
            const data = {
                from: 'pwa-client' as const,
                ..._data,
            };
            this.postMessage(data);
            console.log('xxxxxxxx send message to whiteboard', data);
        }
    };

    onWhiteboardMessage = (event: any) => {
        const { data } = event;
        if (data?.from !== WHITEBOARD_ID) {
            return;
        }

        console.log('xxxxxxxx receive message from whiteboard', data);
        switch (data.type) {
            case WHITEBOARD_EVENT_TYPES_RECEIVED.DASHBOARD_LOADED: {
                store.dispatch(setIsWbLoaded(true));
                break;
            }
            case WHITEBOARD_EVENT_TYPES_RECEIVED.TOKEN_EXPIRED: {
                store.dispatch(signoutWhenSeesionExpiredThunk());
                break;
            }
            default:
                return;
        }
    };
}
