import Long from 'long';

// this is the string what native client passes to server.
// i tried my best to decompose them into single bit and give each one it's meaning, but ended up giving up
// so in order to make we consistent with native client, we calculate the new capbilities basen on these old capbilities
const oldCaps = ['8300567970761955255', '6445493618999263204', '7795585261062049023', '20264249343', '0'];

const newCaps = {
    CLIENT_SUPPORT_RECURRING_MEETING: 158, //ZOOM-264069
    CLIENT_SUPPORT_LIST_ATTENDEE_MEETINGS: 223, //ZOOM-42900CLIENT_SUPPORT_CMR_DISCLAIMER_SECTION = 226, //438915
    CLIENT_SUPPORT_PERSISTENT_MEETING: 229, //ZOOM-445560

    CLIENT_SUPPORT_LIST_ATTENDEE_MEETINGS_V2: 242,
    CLIENT_SUPPORT_RECURRING_MEETING_FOR_CLIENT_V2: 243,
    CLIENT_SUPPORT_PMC: 244,
    CLIENT_SUPPORT_LIST_RECURRING_MEETING_FOR_CLIENT_V3: 252,
};

export const getClientCaps = () => {
    Object.keys(newCaps).forEach((key) => {
        const value = newCaps[key];
        const index = Math.floor(value / 63);
        const shift = value % 63;
        oldCaps[index] = Long.fromString(oldCaps[index], 10).or(Long.ONE.shiftLeft(shift)).toString(10);
    });

    return oldCaps.join(',');
};
