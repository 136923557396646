import serviceContainer from '../services/container';
import { xmppEvents } from './event-modules/xmpp-events';
import { meetingEvents } from './event-modules/meeting-events';
import { appEvents } from './event-modules/app-events';
import { zpnsEvents } from './event-modules/zpns-events';
import { phoneEvents } from './event-modules/phone-events';

const eventBus = {
    app: appEvents,
    xmpp: xmppEvents,
    meeting: meetingEvents,
    zpns: zpnsEvents,
    phone: phoneEvents,
};

export type IEventBus = typeof eventBus;

serviceContainer.setEventBus(eventBus);

export default eventBus;
