/* eslint-disable react/prop-types,react/display-name,jsx-a11y/alt-text */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
// import { Button, IconButton, TextField } from '@mui/material';
import { TextInput } from '@zoom/zoom-react-ui';
import IconEyeShow from './icon/IconEyeShow.svg';
import IconHeadphone from './icon/IconHeadphone.svg';
import IconPause from './icon/IconPause.svg';
import IconPlay from './icon/IconPlay.svg';
import IconRefresh from './icon/IconRefresh.svg';
import { MediaType, PlayStatus, SmartCaptchaType } from './type';
import Button from '../../../../../components/Button/Button';
import './SmartCaptcha.scss';

const SmartCaptcha = React.forwardRef(({ onInputChange }: any, ref) => {
    const [type, setType] = useState(SmartCaptchaType.Image);
    const [playStatus, setPlayStatus] = useState(PlayStatus.Ended);
    const imageRef = useRef(null);
    const audioRef = useRef(null);

    const toggleType = (type: number) => {
        setType(type);
    };

    useEffect(() => {
        initImageCaptcha();
        initAudioCaptcha();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('play', onPlay);
            audioRef.current.addEventListener('ended', onEnded);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('play', onPlay);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                audioRef.current.removeEventListener('ended', onEnded);
            }
        };
    }, []);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            initImageCaptcha();
            initAudioCaptcha();
        },
    }));

    const initImageCaptcha = () => {
        const imgUrl = getImageUrl();
        if (imgUrl && imageRef.current) {
            imageRef.current.src = imgUrl;
        }
    };

    const initAudioCaptcha = () => {
        const audioUrl = getAudioUrl();
        if (audioUrl && audioRef.current) {
            audioRef.current.src = audioUrl;
            audioRef.current.pause();
        }
    };

    const onPlay = () => {
        setPlayStatus(PlayStatus.Play);
    };

    const onEnded = () => {
        setPlayStatus(PlayStatus.Ended);
    };

    const getImageUrl = () => {
        return '/captcha-image';
    };

    const getAudioUrl = () => {
        return '/captcha-audio';
    };

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return /safari/.test(ua);
    };

    const playAudio = () => {
        if (isSafari()) {
            setTimeout(() => {
                audioRef?.current?.play();
            }, 0);
            return;
        }
        audioRef?.current?.play();
    };

    const refreshCaptcha = () => {
        if (type === SmartCaptchaType.Image) {
            initImageCaptcha();
        } else {
            initAudioCaptcha();
        }
    };

    const handleInputChange = (value: any) => {
        if (type === SmartCaptchaType.Image) {
            onInputChange(MediaType.Image, value);
        } else {
            onInputChange(MediaType.Audio, value);
        }
    };
    return (
        <div className="component_smart_captcha">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="smart_tips">Type the characters you see</label>
            <div className="smart_container">
                <div className="captcha_code_input">
                    <TextInput
                        onChange={handleInputChange}
                        placeholder="Please input captcha"
                        maxLength={10}
                        aria-label="Type the characters you see"
                        aria-required="true"
                        value=""
                    />
                </div>

                <div className="captcha_code_layout">
                    <div className="captcha_display">
                        <div
                            className="captcha_code_img"
                            style={{ display: type === SmartCaptchaType.Image ? '' : 'none' }}
                        >
                            <img ref={imageRef} alt="" />
                        </div>
                        <div
                            className="captcha_code_audio"
                            style={{ display: type === SmartCaptchaType.Audio ? '' : 'none' }}
                        >
                            <Button
                                variant="text"
                                className="btn_play"
                                onClick={playAudio}
                                aria-label="Play Audio Captcha"
                            >
                                {playStatus === PlayStatus.Play ? (
                                    <IconPause style={{ width: '16px', height: '16px' }} />
                                ) : (
                                    <IconPlay style={{ width: '16px', height: '16px' }} />
                                )}
                            </Button>
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <audio ref={audioRef} preload="none" />
                        </div>
                    </div>

                    <div className="captcha_action">
                        <Button className="action_button_item" onClick={refreshCaptcha} aria-label="refresh captcha">
                            <IconRefresh style={{ width: '14px', height: '14px' }} />
                        </Button>

                        {type === SmartCaptchaType.Image ? (
                            <Button
                                className="action_button_item"
                                onClick={() => toggleType(SmartCaptchaType.Audio)}
                                aria-label="Switch to Audio Captcha"
                            >
                                <IconHeadphone style={{ width: '14px', height: '14px' }} />
                            </Button>
                        ) : (
                            <Button
                                className="action_button_item"
                                onClick={() => toggleType(SmartCaptchaType.Image)}
                                aria-label="Switch to Visual Captcha"
                            >
                                <IconEyeShow style={{ width: '14px', height: '14px' }} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SmartCaptcha;
