import BaseStorage from './BaseStorage';

export const AddUserIdToKey = (_target: BaseStorage, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalFunc = descriptor.value;
    const uid = window.PwaConfig.uid;
    descriptor.value = function (key: string, ...rest: Array<any>) {
        key = `${uid}.${key}`;
        // must apply to this, NOT target
        return originalFunc.apply(this, [key, ...rest]);
    };
};
