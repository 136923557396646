import { AppThunk } from '../../../store';
import { getUcsGroupMembers, getUcsGroups } from '../services/ucs';
import {
    createContactFromUcsContact,
    createGroupFromUcsGroup,
    isGroupAddon,
    isGroupSharedOffice,
    isGroupZoomRoom,
} from './contact-utils';
import { setGroups, setGroupsLoadingState, setThisGroupContacts, deletGroups } from './contact-store';
import { IGroup, GroupFrom } from '../types';
import { onGetUcsServerGroups, listenXmppEvents } from './ucs-and-xmpp';
import { IUCSGroupCompact } from '../services/ucs-types';
import { requestLimiter } from '../utils/requestUtils';
import { FetchStatus } from '../../../utils/constants';
import { IGroupRender } from './contact-selector';
import { getPendingContactsThunk } from './contact-thunk';

const UCS_MEMBER_COUNT_LIMIT_PER_REQ = 100;
const GROUP_INITIAL_REQUESTS = 6;

export const getUcsGroupsThunk = (): AppThunk => (dispatch, getState) => {
    const {
        common: { userInfo },
    } = getState();

    // get pending contact from indexdb
    dispatch(getPendingContactsThunk());

    const { userId } = userInfo;
    return getUcsGroups(userId).then((res) => {
        // console.log('ucs yyyyyyy', 'getUcsGroups response', res);
        const {
            normal_groups = [],
            sp_groups = [],
            zr_groups = [],
            directory_groups = [],
            assignable_groups = [],
        } = res;
        const allGroups = [].concat(assignable_groups, sp_groups, normal_groups, zr_groups, directory_groups);

        const xmppGroups: Array<IUCSGroupCompact> = [];

        // filter ucsGroups and xmppGroups
        const ucsGroups: Array<IFetchGroup> = allGroups
            .sort((left, right) => {
                const { name: leftName } = left;
                const { name: righName } = right;
                return leftName.localeCompare(righName);
            })
            .map((group) => {
                group.id = group.groupId;
                return group;
            })
            .filter((group) => {
                if (isGroupZoomRoom(group) || isGroupAddon(group) || isGroupSharedOffice(group)) {
                    xmppGroups.push(group);
                    return false;
                }
                return true;
            });

        // console.log('ucsGroups count', ucsGroups.length);
        // console.log('ucsGroups', ucsGroups);

        const groups = ucsGroups.map((group) => {
            return {
                name: group.name,
                id: group.id,
                type: group.type,
                groupFrom: GroupFrom.ucs,
                total: -1,
            };
        });
        // render cotnact list, just has simple group
        dispatch(setGroups({ groups } as any));

        // get detail group infro
        // when scroll list, trigger lazy load, getUcsGroupInfoLazyThunk will be called
        const fetchUcsGroup = (group: IFetchGroup) => dispatch(getUcsGroupMembersThunk(group));
        requestLimiter(ucsGroups.slice(0, GROUP_INITIAL_REQUESTS), fetchUcsGroup);

        // get xmpp group info from xmpp server
        onGetUcsServerGroups(xmppGroups);
    });
};

interface IFetchGroup {
    id: string;
    type: number | string;
    name: string;
    groupId?: string;
}
export const getUcsGroupMembersThunk =
    (groupData: IFetchGroup): AppThunk<any> =>
    (dispatch, getState) => {
        const {
            common: { userInfo },
            contacts: { groups, groupsLoadingState },
        } = getState();
        const { id: groupId } = groupData;
        const localGroup = groups[groupId];
        const gLoadingState = groupsLoadingState[groupId];

        // if we already have all the content;
        if (localGroup?.nextCursor === -1) {
            return Promise.resolve(null);
        }

        // we always request members for ONLY one group
        const reqProps = {
            limit: UCS_MEMBER_COUNT_LIMIT_PER_REQ,
            cursor: 0,
            groups: [
                {
                    groupId: groupId,
                    localVersion: -1,
                },
            ],
        };

        if (localGroup) {
            reqProps.cursor = localGroup.nextCursor;
            reqProps.groups[0].localVersion = -1; // we don't store data permanent in database, so we always fetch from empty
        }

        // if a request from cursor is already made (idle or loading or successed), do not reqeust it again.
        if (
            gLoadingState &&
            gLoadingState?.cursor === reqProps.cursor &&
            gLoadingState?.status !== FetchStatus.failed
        ) {
            return Promise.resolve(null);
        }

        dispatch(
            setGroupsLoadingState({
                id: groupId,
                cursor: reqProps.cursor,
                limit: reqProps.limit,
                status: FetchStatus.loading,
            }),
        );

        return getUcsGroupMembers(reqProps, userInfo)
            .then((res) => {
                // console.log('ucs yyyyyyy', 'get group members response', res);
                const { groups, nextCursor } = res;
                if (groups.length === 0) {
                    return null;
                }
                const resGroup = createGroupFromUcsGroup(groups[0]);
                if (!resGroup) {
                    // validate group will be deleted in redux state
                    dispatch(deletGroups([groupId]));
                    return null; // maybe group is invalid
                }

                resGroup.nextCursor = nextCursor;

                const ucsContacts = groups[0].items || [];

                // remove myself
                const myselfIndex = ucsContacts.findIndex(({ jid }) => jid === userInfo.jid);
                if (myselfIndex > -1) {
                    ucsContacts.splice(myselfIndex, 1);
                    resGroup.total--;
                }

                dispatch(
                    setThisGroupContacts({
                        groupId: groupId,
                        contacts: ucsContacts.map(createContactFromUcsContact),
                    }),
                );

                // should update contacts first, then update groups
                // if you update groups first, then react renders, the the newly added contacts' data are not added to store
                // the we have null contact data
                dispatch(setGroups({ groups: [resGroup as IGroup] }));
                dispatch(
                    setGroupsLoadingState({
                        id: groupId,
                        status: FetchStatus.succeeded,
                    }),
                );
                return null;
            })
            .catch((_e) => {
                dispatch(
                    setGroupsLoadingState({
                        id: groupId,
                        status: FetchStatus.failed,
                    }),
                );
            });
    };

// contact list: group label lazy load
export const getUcsGroupInfoLazyThunk =
    (groupLabelList: Array<IGroupRender>): AppThunk<any> =>
    (dispatch, getState) => {
        if (groupLabelList.length <= 0) return;
        const {
            contacts: { groups },
        } = getState();

        const resultGroup = groupLabelList
            .filter(({ groupId }) => {
                const { total } = groups[groupId];
                // totla === -1 : need requst group info
                // totla === >=0 : has requested
                return total < 0;
            })
            .map(({ groupId }) => {
                const { id, type, name } = groups[groupId];
                return {
                    id,
                    type,
                    name,
                    groupId,
                };
            });

        if (resultGroup.length > 0) {
            // get group members info
            requestLimiter(resultGroup, (group: IFetchGroup) => dispatch(getUcsGroupMembersThunk(group)));
        }
    };

export const testUcs = (): AppThunk => (dispatch) => {
    listenXmppEvents();
    return dispatch(getUcsGroupsThunk());
};
