import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../store/store-hooks';
import './Upcoming.scss';

const useFormatedCurrentTime = (timeF, dayF) => {
    let getResult = () => {
        let now = dayjs();
        return {
            time: now.format(timeF),
            day: now.format(dayF),
        };
    };

    const [result, updateResult] = useState(getResult);

    useEffect(() => {
        let timer = setInterval(() => {
            updateResult(getResult());
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [timeF, dayF]);
    return result;
};

const UpcomingHeader = () => {
    let dayF = 'dddd, MMMM D, YYYY';
    const timeFormat = useAppSelector((state) => state.common.userInfo?.timeFormat);
    let timeF = 'HH:mm'; // 24 - 14:33
    if (String(timeFormat) === '12') {
        timeF = 'hh:mm A'; // 02:33 PM
    }
    let { time, day } = useFormatedCurrentTime(timeF, dayF);
    return (
        <div className="upcoming__header">
            <span className="upcoming__header-time">{time}</span>
            <span className="upcoming__header-day">{day}</span>
        </div>
    );
};

export default UpcomingHeader;
