import { Location, LocationDescriptor } from 'history';
import { RoutePath } from './routePath';
import store from '../store';
import { checkIsMeetingRoute, getRouteFromURL } from '../features/Meetings/Meetings/utils';
import { matchPath } from 'react-router-dom';
import { history } from './history';
import { setIsMeetingMinimized } from '../features/Meeting/redux/meeting-store';
import { meetingAgent } from '../app-init';
import { PWAMeetingEvent } from '../features/Meeting/meeting-agent-msg-type';

export const historyVariable = {
    /**
     * there are 2 kinds of locations in pwa portal:
     * 1. non-meeting location (/wc/phone, /wc/contact, /wc/home, /wc/join, /wc/chat...)
     * 2. meeting location, like /wc/1321312312/join, /wc/1231231231/start, etc
     *
     * so this var is to store the non-meeting location, but except for the /wc/join
     *
     * why we need this ?
     * because when user join meeting, then end meeting, user should go back to the previous location, but not the /wc/join
     */
    currentPortalLocationNotJoin: { pathname: RoutePath.Home },
};

export const routeBack = (replace = false) => {
    const location = history.location as Location<Record<string, unknown>>;
    if (!window.PwaConfig.enableMeetingRoute) {
        return;
    }

    const {
        common: { userInfo },
    } = store.getState();

    const isUserSignin = Boolean(userInfo && (userInfo.email || userInfo.displayName));

    const targetRoute = isUserSignin ? RoutePath.Home : RoutePath.Index;

    if (!location.state?.isFirstRoute) {
        // `replace=true` means end meeting
        history.push(historyVariable.currentPortalLocationNotJoin);
        return;
    }
    if (replace) {
        replaceRoute(targetRoute);
        return;
    }

    history.push({ pathname: targetRoute });
};

export const routeMeeting = (meetingUrl: string) => {
    if (!window.PwaConfig.enableMeetingRoute) {
        return;
    }
    history.push(getRouteFromURL(meetingUrl));
};

export const pushRouteAndMinimizeMeeting = (url: LocationDescriptor<unknown>) => {
    if (checkIsMeetingRoute(location.pathname)) {
        meetingAgent.sendMsgToWebClient(PWAMeetingEvent.MEETING_MINIMIZE_REQUEST);
        store.dispatch(setIsMeetingMinimized(true));
    }
    // isMeetingMinimized will still be false when <MeetingStarter/> destroyed, to fix it, we need setTimeout.
    setTimeout(() => {
        history.push(url);
    }, 50);
};

// keep passing down the isFirstRoute flag when replace route
export const replaceRoute = (_location: any) => {
    const location = history.location as Location<Record<string, unknown>>;
    let state = {};
    if (location.state && 'isFirstRoute' in location.state) {
        state = {
            isFirstRoute: true,
        };
    }
    history.replace(_location, state);
};

export const checkIsUrl2Meeting = () => {
    const location = history.location as Location<Record<string, unknown>>;
    return checkIsMeetingRoute(location.pathname, window.location.href);
};

export const checkIsJoinRoute = () => {
    const location = history.location as Location<Record<string, unknown>>;
    return matchPath(location.pathname, { path: RoutePath.Join, exact: true });
};

export const canStopRouting = () => {
    return checkIsUrl2Meeting() || checkIsJoinRoute();
};
