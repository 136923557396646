import { BusyPolicy, DnDPolicy, DndSnoozePolicy, PolicyName, PresencePolicy, OOOPolicy } from './presence-policy-types';

type Policies = {
    [PolicyName.dnd]?: DnDPolicy;
    [PolicyName.snooze]?: DndSnoozePolicy;
    [PolicyName.ooo]?: OOOPolicy;
    [PolicyName.busy]?: BusyPolicy;
};

export default class PresencePolicyStore {
    private policies: Policies = {};

    constructor() {}

    // receive from server
    update(policy: PresencePolicy) {
        // just set

        let isUnset = false;

        if (PolicyName.dnd === policy.name) {
            const detail = policy.value;
            if (!detail.from || !detail.to) {
                delete this.policies[policy.name];
                isUnset = true;
            } else {
                this.policies[policy.name] = detail;
            }
        }

        if (PolicyName.snooze === policy.name) {
            const detail = policy.value;
            if (detail.duration <= 0) {
                delete this.policies[PolicyName.snooze];
                isUnset = true;
            } else {
                this.policies[PolicyName.snooze] = detail;
            }
        }

        if (PolicyName.busy === policy.name) {
            const detail = policy.value;
            if (detail.duration <= 0) {
                delete this.policies[policy.name];
                isUnset = true;
            } else {
                this.policies[policy.name] = detail;
            }
        }

        if (PolicyName.ooo === policy.name) {
            const detail = policy.value;
            if (detail.duration <= 0) {
                delete this.policies[policy.name];
                isUnset = true;
            } else {
                this.policies[policy.name] = detail;
            }
        }
        return isUnset;
    }

    get<T extends keyof Policies>(name: T): Policies[T] {
        return this.policies[name];
    }

    del<T extends keyof Policies>(name: T): Policies[T] {
        const policy = this.policies[name];
        delete this.policies[name];
        return policy;
    }

    getAll() {
        return this.policies;
    }
}
