import React from 'react';
import Logo from "../Index/Logo";
import './Index.scss';
import FooterLink from '../../vendors/pwa/FootLink'
import ModalContainer from '../ModalContainer/ModalContainer';
import { MEETING_TEXT, Thank_You_Text, WEBINAR_TEXT } from '../../resource';
import { isFeedbackFromWebinar } from './util';
import { iFeedbackData } from './models/feedback.data.model';

interface IProps {
    feedback: iFeedbackData;
}

const ThankYou = ({ feedback }: IProps) => {

    const isFromWebinar = isFeedbackFromWebinar(feedback);

    const webinarOrMeeting = isFromWebinar ? WEBINAR_TEXT : MEETING_TEXT;

    return (
        <>
            <div className="index after-feedback">
                <div className="index-logo">{<Logo />}</div>
                <div className="after-feedback__message">
                    { Thank_You_Text(webinarOrMeeting) }
                </div>
                <FooterLink />
            </div>
            <ModalContainer />
        </>
    )

}

export default ThankYou