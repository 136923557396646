import React, { useEffect } from 'react';
import './SessionExpireTimerBanner.scss';
import Button from '../../../components/Button/Button';
import { SESSION_WILL_EXPIRE, SIGN_IN_BUTTON } from '../../../resource';
import useSessionExpireTimeControl, { formatExpiredTime } from './useSessionExpireTimeControl';

import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../../routes/routePath';
import { useAppDispatch } from '../../../store/store-hooks';
import { useAppSelector } from '../../..//store/store-hooks';
import { setUserInfoThunk } from '../../../store/common/common-store';
import { setModal } from '../../../store/modal/modal-store';
import I18nStrings from '../../../components/I18nStrings';

const SessionExpireTimerBar = () => {
    const dispatch = useAppDispatch();
    const [duration, isDisplay, relogin] = useSessionExpireTimeControl();
    const isMatch = useRouteMatch(RoutePath.Home);

    const {
        common: { userInfo },
        modal: {
            modals: {
                sessionExpireTimeModal: { hasClickExpiredModalLater },
            },
        },
    } = useAppSelector((state) => state);

    useEffect(() => {
        // first time show modal, click modal's later button, then show banner.
        if (isDisplay && !hasClickExpiredModalLater) {
            dispatch(setUserInfoThunk({ ...userInfo, sessionExpireTime: duration / 1000 }));
            dispatch(setModal({ name: 'sessionExpireTimeModal', data: { show: true } }));
        }
    }, [isDisplay, hasClickExpiredModalLater]);

    // just in home page show session expire time banner.
    return isDisplay && isMatch ? (
        <div className={'session-expired-timer__bar'}>
            <div className="bar__content">
                <I18nStrings
                    i18nString={SESSION_WILL_EXPIRE}
                    stubs={[<span className="bar__content-time">{formatExpiredTime(duration / 1000)}</span>]}
                />
                <Button extraCls="bar__sign-button" size={Button.Size24} type={Button.Text} onClick={() => relogin()}>
                    {SIGN_IN_BUTTON}
                </Button>
            </div>
        </div>
    ) : null;
};

export default SessionExpireTimerBar;
