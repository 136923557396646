import React from 'react';
import styles from './re-auth.module.scss';
import { ADD_Calendar_URL } from './calendar-configure';
import { Configure_Calendar, ReAuth_Calendar } from '../../../../resource';
import { useAppSelector } from '../../../../store';
import { shallowEqual } from 'react-redux';
import { isTeslaMode } from '../../../../utils';

export const ReAuthCalendarInUpcoming = () => {
    const { isConnectedToCalendar, isNeedReAuthCalendar } = useAppSelector((state) => state.meetings, shallowEqual);

    const isTesla = isTeslaMode();

    if(isTesla) {
        return null;
    }

    if (!(isConnectedToCalendar && isNeedReAuthCalendar)) {
        return null;
    }

    return (
        <div className={styles.reAuth}>
            <p>{ReAuth_Calendar}</p>
            <a href={ADD_Calendar_URL} target="__blank" className={styles.anchor}>
                {Configure_Calendar}
            </a>
        </div>
    );
};
