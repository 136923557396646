import React, { useCallback, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import './InfoPanel.scss';
import { A11Y_FOCUS_WALKER_POLICY, DOM_KEY_CODE } from '../../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../../store/store-hooks';
import {
    MY_PROFILE,
    ABOUT_BUTTON,
    SIGN_OUT_BUTTON,
    PROFILE_DROP_SETTINGS,
    LANGUAGE,
    CONTACTS_AWAY,
    CONTACTS_AVAILABLE,
    CONTACTS_BUSY,
    CONTACTS_OOO,
} from '../../../../resource';
import { connect } from 'react-redux';
import { featureOptionManager } from '../../../../app-init';
import { IconSettingFill } from '@zoom/icons-react';
import { withFocusWalker } from '../../../../hoc/withFocusWalker';
import { keyBoardEventHandler } from '../../../../utils/keyboard-event';
import { makeA11yListInfoInjector } from '../../../../utils';
import DndPanel from '../DndPanel';
import { PresenceIcon } from '../../../../components/Presence';
import eventBus from '../../../../events/eventBus';
import { signoutThunk } from '../../../../store/common/common-thunk';
import { isDuringMeetingSelector } from '../../../Meeting/redux';
import { RootState } from '../../../Phone/types';
import { IUserCallQueueEx } from '../../../Phone/redux/phone-types';
import { IUserInfo } from '../../../../store/common/common-store';
import { isDisableInternalPresence } from '../../../../store/common/common-utils';
import { SharedCallsSwitchList } from '../SharedCallsSwitchList';
import { userSessionLogger } from '../../../../logger/pwa-loggers';
import { PolicyName } from '../../../../services/zpns/PresneceManager/presence-policy-types';
import { PresenceType } from '@zoom/zpns-presence-handler';
import serviceContainer from '../../../../services/container';

type TypeInfoPanel = {
    userCallQueueEx: IUserCallQueueEx;
    userInfo: IUserInfo;
    hideMe: () => void;
    onProfile: React.MouseEventHandler<HTMLButtonElement>;
    currentPresenceType: PresenceType;
    onLang: React.MouseEventHandler<HTMLButtonElement>;
    onAbout: React.MouseEventHandler<HTMLButtonElement>;
    isTeslaMode: boolean;
    onSettings: React.MouseEventHandler<HTMLButtonElement>;
    receiveQueueCalls: number;
    infoPanelStatus: boolean;
};

const InfoPanel = ({
    userCallQueueEx,
    userInfo,
    hideMe,
    onProfile,
    onLang,
    onAbout,
    isTeslaMode,
    onSettings,
}: TypeInfoPanel) => {
    const { isPhoneModuleLoaded, language } = useAppSelector((state) => state.common);
    const isInMeeting = useAppSelector(isDuringMeetingSelector);
    const containerRef = useRef(null);
    const { dataALGenerator } = makeA11yListInfoInjector('0', containerRef);
    const dispatch = useAppDispatch();
    let handleInfoPanelClickOutside = useCallback(
        (flag: boolean) => {
            if (flag) {
                hideMe();
            }
        },
        [hideMe],
    );

    let infoPanelRef = useClickOutside({
        callback: handleInfoPanelClickOutside,
    });

    let onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.keyCode === DOM_KEY_CODE.ESC) {
            hideMe();
        }
    };

    let _onAbout = () => {
        setTimeout(onAbout, 20);
        hideMe();
    };

    let _onLang = () => {
        if (isInMeeting) {
            return;
        }
        setTimeout(onLang, 20);
        hideMe();
    };

    const setStatusToOnline = () => {
        hideMe();
        // update presence
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            presenceManager.updatePresenceFromUserProfile(PresenceType.available);
        }
    };

    const setStatusToAway = () => {
        hideMe();

        // update presence
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            presenceManager.updatePresenceFromUserProfile(PresenceType.away);
        }
    };

    const setStatusToOOO = (unset = false) => {
        hideMe();

        let policy = {
            duration: 60 * 60 * 24 * 30, // 30 days in second
        };

        if (unset) {
            policy = {
                duration: 0,
            };
        }

        // update presence
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            presenceManager.updatePresenceFromUserProfile(PresenceType.ooo, {
                name: PolicyName.ooo,
                value: policy,
            });
        }
    };

    const setStatusToBusy = (unset = false) => {
        hideMe();

        let policy = {
            duration: 60 * 60 * 24, // 24 hours in second
        };

        if (unset) {
            policy = {
                duration: 0,
            };
        }

        // update presence
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            presenceManager.updatePresenceFromUserProfile(PresenceType.busy, {
                name: PolicyName.busy,
                value: policy,
            });
        }
    };

    const setStatusToSnooze = (durationInMinute: number) => {
        hideMe();
        const from = Math.ceil(Date.now() / 1000);
        const to = from + durationInMinute * 60;
        const policy = {
            duration: durationInMinute,
            from,
            to,
        };

        // update presence
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            presenceManager.updatePresenceFromUserProfile(PresenceType.dnd, {
                name: PolicyName.snooze,
                value: policy,
            });
        }
    };

    const onClickSignOut = async () => {
        hideMe();
        userSessionLogger.log('', ['Manual_Signout']);
        eventBus.app.beforeSignOut
            .dispatchEvent(null)
            .then(() => {
                eventBus.app.willSingout.dispatchEvent(null).then(() => {
                    dispatch(signoutThunk());
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <FocusLock disabled returnFocus={true}>
            <div ref={infoPanelRef} className="home-header__info-panel" onKeyDown={onKeyDown} id="homeHeaderInfoPanel">
                <div ref={containerRef} data-a-l="0">
                    <div className="info-panel-detail">
                        <span title={userInfo.displayName}>{userInfo.displayName}</span>
                        <span title={userInfo.email}>{userInfo.email}</span>
                    </div>

                    <div className="info-panel-divider"></div>

                    {!isTeslaMode ? (
                        <>
                            <div className="info-panel-block info-panel-settings">
                                <button
                                    className="info-panel-settings-button"
                                    onClick={onSettings}
                                    data-a-l={dataALGenerator()}
                                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                >
                                    <IconSettingFill className="settings-text-icon" />
                                    {PROFILE_DROP_SETTINGS}
                                </button>
                            </div>
                            <div className="info-panel-divider"></div>
                        </>
                    ) : null}

                    {!isTeslaMode && (
                        <>
                            <div className="info-panel-block info-panel-status">
                                <button
                                    onClick={setStatusToOnline}
                                    data-a-l={dataALGenerator()}
                                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                    aria-label={CONTACTS_AVAILABLE}
                                    className="presence-available-button"
                                >
                                    <PresenceIcon presenceType={'available'} className={'presence-icon'} />
                                    {CONTACTS_AVAILABLE}
                                </button>

                                {featureOptionManager?.isBusyPresenceOn() && !isDisableInternalPresence() && (
                                    <button
                                        onClick={() => setStatusToBusy(false)}
                                        data-a-l={dataALGenerator()}
                                        data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                        className="presence-busy-button"
                                        aria-label={CONTACTS_BUSY}
                                    >
                                        <PresenceIcon presenceType={'busy'} className={'presence-icon'} />
                                        {CONTACTS_BUSY}
                                    </button>
                                )}

                                <DndPanel containerDataAL={dataALGenerator()} setStatusToSnooze={setStatusToSnooze} />

                                <button
                                    onClick={setStatusToAway}
                                    data-a-l={dataALGenerator()}
                                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                    className="dnd-away__button"
                                    aria-label={CONTACTS_AWAY}
                                >
                                    <PresenceIcon presenceType={'away'} className={'presence-icon'} />
                                    {CONTACTS_AWAY}
                                </button>

                                {featureOptionManager?.isOOOPresenceOn() && !isDisableInternalPresence() && (
                                    <button
                                        onClick={() => setStatusToOOO(false)}
                                        data-a-l={dataALGenerator()}
                                        data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                        className="presence-outofoffice-button"
                                        aria-label={CONTACTS_OOO}
                                    >
                                        <PresenceIcon presenceType={'outofoffice'} className={'presence-icon'} />
                                        {CONTACTS_OOO}
                                    </button>
                                )}
                            </div>
                            <div className="info-panel-divider"></div>
                        </>
                    )}

                    {!isTeslaMode && isPhoneModuleLoaded && userCallQueueEx?.userCallQueueMembers?.length > 0 ? (
                        <>
                            <SharedCallsSwitchList containerDataAL={dataALGenerator()} />
                            <div className="info-panel-divider"></div>
                        </>
                    ) : null}

                    <div className="info-panel-block info-panel-about">
                        {!isTeslaMode && (
                            <button
                                onClick={onProfile}
                                data-a-l={dataALGenerator()}
                                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                            >
                                {MY_PROFILE}
                            </button>
                        )}
                        <button
                            onClick={_onAbout}
                            data-a-l={dataALGenerator()}
                            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                        >
                            {ABOUT_BUTTON}
                        </button>
                        {!isTeslaMode && language ? <div className="info-panel-divider"></div> : null}
                        {!isTeslaMode && language ? (
                            <button
                                disabled={isInMeeting}
                                className="lang__button"
                                onClick={_onLang}
                                data-a-l={dataALGenerator()}
                                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                            >
                                {LANGUAGE}
                                <span className="inline_lang">{language.value}</span>
                            </button>
                        ) : null}
                    </div>
                    <div className="info-panel-divider"></div>
                    <div className="info-panel-block info-panel-sign-out">
                        <button
                            data-a-l={dataALGenerator()}
                            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                            onClick={onClickSignOut}
                        >
                            {SIGN_OUT_BUTTON}
                        </button>
                    </div>
                </div>
            </div>
        </FocusLock>
    );
};

const mapStateToProps = (state: RootState) => {
    const {
        phone,
        contacts: { presences },
    } = state;
    return {
        receiveQueueCalls: phone?.receiveQueueCalls,
        userCallQueueEx: phone?.userSipInfo?.userCallQueueEx,
        presencesDuration: presences?.duration,
    };
};

export default connect(mapStateToProps)(
    withFocusWalker(
        InfoPanel,
        'homeHeaderInfoPanel',
        (props: TypeInfoPanel) => props.infoPanelStatus,
        ['.info-panel-detail'],
        keyBoardEventHandler,
    ),
);
