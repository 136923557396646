export const ZPNS_PING_PAYLOAD = 'PING_PING_PING';
export enum ZpnsMsgType {
    push = 'push',
    login = 'login',
    loginAck = 'login-ack',
}

export enum ZpnsMsgCategory {
    Pbx = 1,
    Zpns = 2,
    Mail = 4,
    Calendar = 8,
    Ucs = 16,
    Web = 32,
    Async = 64,
    Meeting = 128,
    UserSetting = 256,
    Zrp = 512,
    Cci = 1024,
    Zr = 2048,
    Integration = 4096,
    Marketplace = 8192,
}

export enum ZpnsStatus {
    Init = 'Init',
    Connecting = 'connecting',
    Connected = 'connected',
    Disconnecting = 'disconnecting',
    Disconnected = 'disconnected',
}

export const Zpns_Max_Reconect_Times = 100000; // Number.POSITIVE_INFINITY
export const Zpns_Reconnect_Interval = 5 * 1000;
export const Zpns_Reconnect_Random_Max = 3000;
