import { iText } from './i18n';
import { Can_Not_Access_Micro } from '../resource';
import { ICON_TYPE, toast } from 'Toast';
import easyStore from './storage';
import { storageKey } from '../store/common/storage-keys';
import { Same_As_System_Text } from '../features/Phone/LanguageResource';

// input must be all numbers
export function addSpaceToMeetingId(input) {
    // pattern of meeting id: /^\d{3}\d{3,4}\d{3,4}$/
    // user can input them by hand
    // or paste from clipboard
    // split string into a char array. "hey" => ['h', 'e', 'y'];
    input = String(input).split('');
    const length = input.length;
    if (length <= 3) {
        return input.join('');
    } else if (length > 3 && length <= 6) {
        input.splice(3, 0, ' ');
    } else if (length <= 10) {
        input.splice(3, 0, ' ');
        input.splice(7, 0, ' ');
    } else if (length <= 11) {
        input.splice(3, 0, ' ');
        input.splice(8, 0, ' ');
    }
    return input.join('');
}

export function isEmail(value) {
    return /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/.test(value);
}

export function isMaybeMultiEmail(value) {
    // return value.split(/,|;|\s/).length > 1;
    const regx = /,|;|\s/;
    const result = value.split(regx);

    return isEmail(result[0]) && result.length > 1;
}
export function areSetsEqual(set1, set2) {
    const equalSize = set1.size === set2.size;
    if (!equalSize) {
        return false;
    }
    return new Set([...set1, ...set2]).size === set1.size;
}

export function getDomain(url) {
    let result;
    const urlExec = /https?:\/\/([^/]+)/gi.exec(url);
    if (urlExec && urlExec.length) {
        result = urlExec[0];
    }
    return result;
}

export function isInStandaloneMode() {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
}

export function getNameAbbr(name = '') {
    const arr = String(name).split(' ');
    const shortNameArr = [];
    let shortName = '';
    for (let j = 0; j < arr.length; j++) {
        if (arr[j] !== '') {
            shortNameArr.push(arr[j].substr(0, 1).toUpperCase());
        }
    }

    if (!shortNameArr.length) {
        // username contains only spaces
        shortName = '\xa0';
    } else if (shortNameArr.length === 1) {
        shortName = shortNameArr[0];
    } else {
        // eslint-disable-next-line
        const byteNum = shortNameArr[0].replace(/[^\x00-\xff]/g, '**').length;
        if (byteNum === 2) {
            shortName = shortNameArr[0];
        } else {
            shortName = shortNameArr[0] + shortNameArr[1];
        }
    }
    return shortName;
}

// same as natiev mac client
export function getColorIndex(bgStr = '', isDeactivated = false) {
    let j = 0;

    if (isDeactivated || !bgStr) return 'Deactive';

    for (let i = 0; i < bgStr.length; i++) {
        j += bgStr.charCodeAt(i);
        j %= 8;
    }

    return j;
}

export function getBareJidFromJid(jid) {
    if (!jid) return '';
    return jid.split('/')[0];
}

export function getDomainFromJid(jid) {
    const bareJid = getBareJidFromJid(jid);
    if (!bareJid) return '';
    return bareJid.split('@')[1];
}

export function getJidFromFullJid(fullJid) {
    const bareJid = getBareJidFromJid(fullJid);
    if (!bareJid) return '';
    return bareJid.split('@')[0];
}

// userId  get from jid is lowercase, The real userId is case sensitive, but both are same char
export function getUserIdFromJid(jid) {
    if (!jid) return '';
    return jid.split('@')[0];
}

export function getUniqueId(suffix) {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    if (typeof suffix === 'string' || typeof suffix === 'number') {
        return uuid + ':' + suffix;
    } else {
        return uuid + '';
    }
}

export const BrowserDetect = {
    isEdge() {
        return /edge\/(\d+)/i.test(navigator.userAgent);
    },
    isFirefox() {
        return /firefox/i.test(navigator.userAgent);
    },
    isSafai() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    isOpera() {
        return /opera|opr\/[\d]+/i.test(navigator.userAgent);
    },
    isChromeOS() {
        return /\bCrOS\b/.test(navigator.userAgent);
    },
    isChrome() {
        // chrome|chromium based
        return (
            !BrowserDetect.isOpera() &&
            !BrowserDetect.isEdge() &&
            /chrome/i.test(navigator.userAgent) &&
            /webkit/i.test(navigator.userAgent)
        );
    },
};

export const isSupportServiceWorker = () => {
    return 'serviceWorker' in window.navigator;
};

export const getDefaultMediaDeviceId = () => {
    if (BrowserDetect.isChrome() || BrowserDetect.isOpera()) {
        return 'default';
    } else {
        return undefined;
    }
};

export const getMediaDeviceId = (isSpeaker = false) => {
    if (BrowserDetect.isChrome() || BrowserDetect.isOpera()) {
        return (
            easyStore.localStore.easyGet(isSpeaker ? storageKey.speakerDeviceId : storageKey.microphoneDeviceId) ||
            'default'
        );
    } else {
        return undefined;
    }
};

export const getMediaDeviceLabel = (isSpeaker = false) => {
    if (BrowserDetect.isChrome() || BrowserDetect.isOpera()) {
        return (
            easyStore.localStore.easyGet(
                isSpeaker ? storageKey.speakerDeviceLabel : storageKey.microphoneDeviceLabel,
            ) || Same_As_System_Text
        );
    } else {
        return undefined;
    }
};

export const isSupportSetSinkIdMethod = () => {
    const audio = new Audio();
    return audio.setSinkId !== undefined && typeof audio.setSinkId === 'function';
};

export function transformParams(paramsObj) {
    const keys = Object.keys(paramsObj);
    return keys.map((key) => `${key}=${paramsObj[key]}`).join('&');
}

export const isTeslaMode = () => {
    return /TESLA/i.test(navigator.userAgent);
};

export const testMicrophonePermission = (showErrorToast = true) => {
    return navigator.mediaDevices
        .getUserMedia({
            audio: true,
            video: false,
        })
        .then((stream) => {
            stream.getAudioTracks().forEach((track) => track.stop());
        })
        .catch((e) => {
            if (showErrorToast) {
                toast(
                    {
                        type: ICON_TYPE.FAILURE,
                        desc: Can_Not_Access_Micro,
                    },
                    {
                        autoClose: 6000,
                        toastId: 'microphone-denied',
                    },
                );
            }
            throw e;
        });
};

export const paramsToObject = (entries) => {
    const result = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
};

export const makeDataALGenerator = (prefix, spliter = '-', startAt = 0) => {
    let currentL = startAt;
    const fn = () => {
        const dataAL = `${prefix}${spliter}${currentL}`;
        currentL += 1;
        return dataAL;
    };
    fn.currentL = currentL;
    return fn;
};

export const makeA11yListInfoInjector = (dataALPrefix, listInfoContainerRef, spliter = '-', startAt = 0) => {
    const tempDataALGenerator = makeDataALGenerator(dataALPrefix, spliter, startAt);
    const { dataALGenerator, getDataALGenerateCount } = (() => {
        let dataALGenerateCount = 0;
        const dataALGenerator = () => {
            dataALGenerateCount += 1;
            return tempDataALGenerator();
        };
        return {
            getDataALGenerateCount: () => dataALGenerateCount,
            dataALGenerator,
        };
    })();
    setTimeout(() => {
        if (listInfoContainerRef?.current?.dataset) {
            // eslint-disable-next-line no-param-reassign
            listInfoContainerRef.current.dataset.aLListInfo = `${dataALPrefix}${spliter}${startAt},${dataALPrefix}${spliter}${
                (getDataALGenerateCount() || 1) - 1
            }`;
        }
    }, 0);
    return { dataALGenerator, getDataALGenerateCount };
};

export const Noop = () => {};

export const isValidUrl = (url) => {
    let validity = /^https?:\/\//.test(url);
    if (!validity) {
        console.error('invalid url: ', url);
    }
    return validity;
};

export const reload = (url) => {
    if (!url) {
        window.location.reload();
    } else {
        isValidUrl(url) && (window.location.href = url);
    }
};

export const openTab = (url) => {
    return isValidUrl(url) && window.open(url, '_blank');
};

export const openWindow = (url, name = '', feathers = '') => {
    return isValidUrl(url) && window.open(url, name, feathers);
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const canTransferMeeting = (isEnableMeetingTransfer, isWebinar, isHost, needRegister, isSimuliveWebinar) => {
    if (isSimuliveWebinar) return false;
    if (!isEnableMeetingTransfer) return false;
    if (!isHost && (isWebinar || needRegister)) return false;
    return true;
};

export const interceptPropagation = (e, fn) => {
    e.stopPropagation();
    fn && fn(e);
};

export const isWindows = () => {
    const { userAgent } = navigator;
    return /windows/i.test(userAgent);
};

export const protocolHandlerOption = () => {
    if (navigator?.registerProtocolHandler) {
        return true;
    }
    return false;
};

export const isZoomRoomEmail = (email) => {
    // zoom room user email will have 'rooms' prifix
    const pattern = /^rooms_[\S]+@[\S]+\.[\S]+$/;
    return pattern.test(email);
};

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export function getStartWithPmi() {
    if (window.PwaConfig?.lockAlwaysUsePMIInstant) {
        return window.PwaConfig?.enableAlwaysUsePMIInstant;
    }
    return easyStore.localStore.easyGet('video.startWithPmi') ?? window.PwaConfig?.enableAlwaysUsePMIInstant;
}

export function getStartWithVideoOn() {
    if (window.PwaConfig?.enableStartPMIWithVideoOnOff && getStartWithPmi()) {
        return window.PwaConfig?.isPMIHostVideoOn;
    }
    return easyStore.localStore.easyGet('video.startWithVideoOn', false);
}
