import React from 'react';
import { useAppSelector } from '../../../../store/store-hooks';
import './calendar-integration.scss';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { Add_Calendar_Text, Add_Calendar_Tooltip_Text } from '../../LanguageResource';
import { IconCalendarAddCalendar } from '@zoom/icons-react';
import { ADD_Calendar_URL } from './calendar-configure';


export const AddCalendarInMeetingsPanel = () => {
    const { isConnectedToCalendar } = useAppSelector((_) => _.meetings);

    if (isConnectedToCalendar) {
        return null;
    }

    return (
        <div className="add-calendar add-calendar-meetings-panel">
            <div className="add-calendar__container">
                <Tooltip tooltip={Add_Calendar_Tooltip_Text}>
                    <a
                        href={ADD_Calendar_URL}
                        target="__blank"
                        aria-label={`${Add_Calendar_Text},${Add_Calendar_Tooltip_Text}`}
                    >
                        <IconCalendarAddCalendar className="add-calendar__icon" aria-hidden={true} />
                        <span className="add-calendar__txt">{Add_Calendar_Text}</span>
                    </a>
                </Tooltip>
            </div>
        </div>
    );
};

export const AddCalendarInUpComingEmpty = () => {
    const { isConnectedToCalendar } = useAppSelector((_) => _.meetings);

    if (isConnectedToCalendar) {
        return null;
    }

    return (
        <div className="add-calendar add-calendar-upcoming-empty">
            <Tooltip tooltip={Add_Calendar_Tooltip_Text}>
                <a
                    className="add-calendar__container"
                    href={ADD_Calendar_URL}
                    target="__blank"
                    aria-label={`${Add_Calendar_Text},${Add_Calendar_Tooltip_Text}`}
                >
                    <IconCalendarAddCalendar className="add-calendar__icon" aria-hidden={true} />
                    <span className="add-calendar__txt">{Add_Calendar_Text}</span>
                </a>
            </Tooltip>
        </div>
    );
};

// show as upcoming footer
export const AddCalendarAsUpComingFooter = () => {
    const { isConnectedToCalendar } = useAppSelector((_) => _.meetings);

    if (isConnectedToCalendar) {
        return null;
    }

    return (
        <div className="add-calendar add-calendar-as-upcoming-footer">
            <Tooltip tooltip={Add_Calendar_Tooltip_Text} distance={4}>
                <a
                    className="add-calendar__container"
                    href={ADD_Calendar_URL}
                    target="__blank"
                    aria-label={`${Add_Calendar_Text},${Add_Calendar_Tooltip_Text}`}
                >
                    <IconCalendarAddCalendar className="add-calendar__icon" aria-hidden={true} />
                    <span className="add-calendar__txt">{Add_Calendar_Text}</span>
                </a>
            </Tooltip>
        </div>
    );
};

// show in upcoming footer, right just an icon
export const AddCalendarInUpComingFooter = () => {
    const { isConnectedToCalendar } = useAppSelector((_) => _.meetings);

    if (isConnectedToCalendar) {
        return null;
    }

    return (
        <div className="add-calendar add-calendar-in-upcoming-footer">
            <Tooltip tooltip={Add_Calendar_Tooltip_Text} distance={4}>
                <div className="add-calendar__container">
                    <IconCalendarAddCalendar className="add-calendar__icon" aria-label={Add_Calendar_Tooltip_Text} />
                </div>
            </Tooltip>
        </div>
    );
};
