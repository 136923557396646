export enum SyncType {
    FSync = 0,
    ISync = 1,
}

export type SyncTypeBodyParam = 0 | 1;
export type SyncTypeQueryParam = 'FSync' | 'ISync';

// TODO Gotta be a way to get the static types generated from protobuf?
export interface IDeleteMessagesReq {
    msgIds: Array<string>;
    sessionId: string;
}

export interface IFetchSessionsReq {
    syncToken?: string;
    syncType: SyncType;
    recordCount?: number;
}

export interface IUpdateChatMuteStatusReq {
    sessionId: string;
    status: number;
}
