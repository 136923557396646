import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ZccAppShow_Params } from '../../ContactCenter/types/incoming-types';

export type RunningAppId = string;
export type RunningContext = 'inContactCenter';

export type RunningApp_Success = { status: 'success' } & Omit<ZccAppShow_Params, 'engagement'> & {
        runningAppId: string;
        runningContext: RunningContext;
    };

export type RunningApp_Starting = { status: 'starting' } & ZccAppShow_Params & {
        runningAppId: string;
        runningContext: RunningContext;
    };

export type RunningApp_Installing = { status: 'installing' } & ZccAppShow_Params & {
        runningAppId: string;
        runningContext: RunningContext;
    };

export type RunningApp_Error = {
    status: 'error';
    errorCode: number;
    errorMsg: string;
} & ZccAppShow_Params & {
        runningAppId: string;
        runningContext: RunningContext;
    };

export type RunningApp = RunningApp_Success | RunningApp_Starting | RunningApp_Installing | RunningApp_Error;

export interface IZoomAppsState {
    runningApps: {
        ids: RunningAppId[];
        entities: {
            [id: RunningAppId]: RunningApp;
        };
    };
}

const initialState: IZoomAppsState = {
    runningApps: {
        ids: [],
        entities: {},
    },
};

const ZoomAppsSlice = createSlice({
    name: 'zoom-apps',
    initialState,
    reducers: {
        addRunningApp(state, { payload }: PayloadAction<RunningApp_Starting>) {
            const { runningAppId } = payload;
            const {
                runningApps: { ids, entities },
            } = state;
            const index = ids.findIndex((id) => id === runningAppId);
            const entity = entities[runningAppId];
            if (index > -1) {
                Object.assign(entity, payload);
            } else {
                ids.push(runningAppId);
                entities[runningAppId] = payload;
            }
        },

        updateRunningApp(
            state,
            {
                payload,
            }: PayloadAction<{
                runningAppId: RunningAppId;
                updates: Partial<RunningApp_Success | RunningApp_Installing | RunningApp_Error>;
            }>,
        ) {
            const { runningAppId, updates } = payload;
            const {
                runningApps: { entities },
            } = state;
            if (!entities[runningAppId]) {
                return;
            }

            Object.assign(entities[runningAppId], updates);
        },

        deleteRunningApp(state, { payload }: PayloadAction<RunningAppId>) {
            const {
                runningApps: { ids, entities },
            } = state;
            const index = ids.findIndex((id) => id === payload);
            if (index > -1) {
                ids.splice(index, 1);
            }
            delete entities[payload];
        },
    },
});

const { addRunningApp, updateRunningApp, deleteRunningApp } = ZoomAppsSlice.actions;

export const actions = ZoomAppsSlice.actions;

export { addRunningApp, updateRunningApp, deleteRunningApp };

export default ZoomAppsSlice.reducer;
