import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { getAsyncPhoneModule } from '../../asyncModulesStore';

export const usePhoneCleanUp = () => {
    const dispatch = useAppDispatch();
    const {
        common: { isPhoneModuleLoaded },
    } = useAppSelector((_) => _);

    useEffect(() => {
        return () => {
            if (isPhoneModuleLoaded) {
                getAsyncPhoneModule().then(({ cleanUpPhone }) => {
                    dispatch(cleanUpPhone());
                });
            }
        };
    }, [isPhoneModuleLoaded]);
};

export const usePhoneLocationDetect = () => {
    const dispatch = useAppDispatch();
    const {
        common: { isPhoneModuleLoaded },
    } = useAppSelector((_) => _);
    const [E911Container, setE911Container] = useState<React.ComponentType>(null);

    useEffect(() => {
        if (isPhoneModuleLoaded) {
            getAsyncPhoneModule().then(
                ({ updateDefaultE911Address, setAllowAccessLocation, setAllowZoomaAccessLocation, E911Container }) => {
                    dispatch(updateDefaultE911Address());
                    setE911Container(E911Container);
                    navigator?.permissions?.query({ name: 'geolocation' }).then((res) => {
                        if (res.state === 'granted') {
                            dispatch(setAllowAccessLocation('resolved'));
                            dispatch(setAllowZoomaAccessLocation('resolved'));
                        }
                        if (res.state === 'denied') {
                            dispatch(setAllowAccessLocation('rejected'));
                        }
                        if (res.state === 'prompt') {
                            dispatch(setAllowAccessLocation('pending'));
                        }
                    });
                },
            );
        }
    }, [isPhoneModuleLoaded]);

    return { E911Container };
};
