import React, { useState, useRef } from 'react';
import { Modal } from 'Modal';
import './index.scss';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { IUserInfo } from '../../../store/common/common-store';

import { InviteContactModalType, InviteContactData } from './types';
import InviteCotent from './InviteCotent';
import LoadingContent from './LoadingContent';
import SendSuccessContent from './SendSuccessContent';
import DeleteContent from './DeleteContent';
import AlreadyContent from './AlreadyContent';

interface IProps {
    userInfo: IUserInfo;
    data: InviteContactData;
}

const InviteContactModal = ({ userInfo, data }: IProps) => {
    const dispatch = useAppDispatch();
    const [modalType, setModalType] = useState((data && data.modalType) || InviteContactModalType.DEFAULT);
    const emailRef = useRef('');
    const dataRef = useRef(data);

    const closeMe = () => {
        dispatch(setModal({ name: 'inviteContactModal', data: { show: false } }));
    };

    const modal = {
        DEFAULT: (
            <InviteCotent
                userInfo={userInfo}
                emailRef={emailRef}
                dataRef={dataRef}
                setModalType={setModalType}
                closeModal={closeMe}
            />
        ),
        LOADDING: <LoadingContent closeModal={closeMe} />,
        INVITE_SEND_SUCCESS: <SendSuccessContent email={emailRef.current} closeModal={closeMe} />,
        DELETE_CONTACT: <DeleteContent data={dataRef.current} closeModal={closeMe} />,
        ALREADY_CONTACT: <AlreadyContent data={dataRef.current} closeModal={closeMe} />,
    };

    return (
        <Modal isOpen={true} needBackdrop={true} shouldCloseOnOverlayClick={false} isDraggable={false} centered={true}>
            <div className="modal-invite-contact">{modal[modalType]}</div>
        </Modal>
    );
};

export default InviteContactModal;
