import { cookies } from '../utils/Cookies/cookie';
import { getSubDomain } from '../utils/meeting-url';
import { areWeInStandaloneWindow } from '../utils/pwa-mode';

const setSessionStorage = (key: string, value: string | number) => {
    window.sessionStorage.setItem(key, String(value));
};

const getSessionStorage = (key: string) => {
    return window.sessionStorage.getItem(key);
};

const urlObj = new URL(window.location.href);

// store in session storage in case you refresh pwa after we redirect from 'pwa.zoom.us' to 'app.zoom.us'
const RedirectFromDomainKey = 'rfd';

/* 
we changed pwa's manifest.json
{
    start_url: '/wc?source=pwa
}
when you open pwa by clicking the installed icon, browser will try to load you pwa with "https://pwa.zoom.us/wc?source=pwa".
but, we add this entry to manifest.json after we released pwa.
so if you've installed pwa before this update. we don't have source=pwa when you launch pwa from your docker.
and if you installed after this update, we will have source=pwa
*/
const isOpenedFromInstalled = () => {
    return urlObj.searchParams.get('source') === 'pwa';
};

const isOpenedFromInstalledByGuess = () => {
    const searchParams = new URLSearchParams(urlObj.searchParams);
    const isIndexPath = urlObj.pathname === '/wc';

    searchParams.delete('_x_zm_rtaid');
    searchParams.delete('_x_zm_rhtaid');

    const hasAnyOtherParams = searchParams.toString() !== '';

    if (isIndexPath && !hasAnyOtherParams) {
        return true;
    }
    return false;
};

const rfdReg = new RegExp(`${RedirectFromDomainKey}=1`);
const isRedirectFromTheOtherDomain = rfdReg.test(window.location.search) || getSessionStorage(RedirectFromDomainKey);

if (isRedirectFromTheOtherDomain) {
    setSessionStorage(RedirectFromDomainKey, 1);
}

/**
 * if we open pwa by clicking icon
 * browser opens a standalone window to load pwa.zoom.us/wc?source=pwa, then we redirect to app.zoom.us/wc?source=pwa.
 * if we pass along source=pwa, app.zoom.us will think it's opened from desktop icon.
 */
const removeUrlParams_Source = () => {
    const url = new URL(window.location.href);
    if (url.searchParams.has('source')) {
        url.searchParams.delete('source');
        window.history.replaceState(null, '', url);
    }
};

const isLaunchedFromDocker = isOpenedFromInstalled() || isOpenedFromInstalledByGuess();

if (areWeInStandaloneWindow() && isLaunchedFromDocker) {
    const isFromOldDomain = /^pwa/.test(window.location.hostname);
    const isFromNewDomain = /^app/.test(window.location.hostname);

    removeUrlParams_Source();

    if (isFromOldDomain) {
        cookies.set('_pwa_installed', '1', {
            domain: getSubDomain(window.location.hostname),
            path: '/',
            sameSite: 'strict',
            secure: true,
            maxAge: 60 * 60 * 24 * 365,
        });
    }

    if (isFromNewDomain) {
        cookies.set('_app_installed', '1', {
            domain: getSubDomain(window.location.hostname),
            path: '/',
            sameSite: 'strict',
            secure: true,
            maxAge: 60 * 60 * 24 * 365,
        });
    }
}
