import { iText } from '../../../utils/i18n';

export const Delete_Modal_Prefix_Text = (who: string) =>
    iText('This meeting is scheduled for {0}', 'pwa.meetings.delete_modal_prefix', [who]);
export const Delete_Modal_Content_Text1 = iText(
    'You can recover this meeting within 7 days from the',
    'pwa.meetings.delete_modal_content1',
);
export const Delete_Modal_Content_Recent_Text = iText('Recently Deleted', 'pwa.meetings.delete_modal_content_recent');
export const Delete_Modal_Content_Text2 = iText('page on the Zoom web portal', 'pwa.meetings.delete_modal_content2');

export const Delete_Failed_Toast_Text = iText('Delete meeting failed', 'pwa.meetings.delete_failed_toast');
export const My_PMI_Text = iText('My Personal Meeting ID (PMI)', 'pwa.meetings.my_pmi');

export const Call_Text = iText('Call', 'pwa.call');
export const Delete_Text = iText('Delete', 'pwa.delete');
export const Cancel_Text = iText('Cancel', 'pwa.cancel');
export const Refresh_Text = iText('Refresh', 'pwa.refresh');
export const Upcoming_Text = iText('Upcoming', 'pwa.upcoming');
export const Start_Text = iText('Start', 'pwa.start');
export const Join_Text = iText('Join', 'pwa.join');
export const View_Event_Text = iText('View Event', 'pwa.view_event');
export const Copy_Invitation_Text = iText('Copy Invitation', 'pwa.copy_invitation');
export const Edit_Text = iText('Edit', 'pwa.edit');
export const Host_Text = iText('Host', 'pwa.host');
export const Recurring_Text = iText('Recurring', 'pwa.recurring');
export const Everyone_Text = iText('Everyone', 'pwa.everyone');
export const Me_Text = iText('Me', 'pwa.me');
export const InProgress_Text = iText('In Progress', 'pwa.in_progress');
export const All_Day_Event_Text = iText('All-day event', 'pwa.all_day_event');
export const Today_Text = iText('Today', 'pwa.today');
export const Tomorrow_Text = iText('Tomorrow', 'pwa.tomorrow');
export const View_Text = iText('View', 'pwa.view');

export const Show_Invitation_Text = iText('Show Meeting Invitation', 'pwa.meetings.show_invitation');
export const Hide_Invitation_Text = iText('Hide Meeting Invitation', 'pwa.meetings.hide_invitation');
export const MeetingID_Text = iText('Meeting ID', 'pwa.meetings.meeting_id');
export const WebinarID_Text = iText('Webinar ID', 'pwa.meetings.webinar_id');
export const Not_Zoom_Meeting_Text = iText('This is not a Zoom meeting', 'pwa.meetings.not_zoom_meeting');
export const Not_Zoom_Meeting_Text_1 = iText('Not a Zoom meeting', 'pwa.meetings.not_zoom_meeting_1');
export const Starts_In_Minutes_Text = (time: number) =>
    iText('Starts in {0} minutes', 'pwa.meetings.start_in_mins', [time]);

export const Starts_In_1_Minute = iText('Starts in 1 minute', 'pwa.meetings.starts_in_1_min');
export const Now_Text = iText('NOW', 'pwa.meetings.now');
export const Hosted_By_Text = iText('meeting hosted by', 'pwa.meeitngs.hosted_by');
export const View_Today_Upcoming_Meetings_Text = iText(
    "View today's upcoming meetings",
    'pwa.meetings.view_today_upcoming',
);
export const No_Upcoming_Meetings_Today_Text = iText(
    'No upcoming meetings today',
    'pwa.meetings.no_upcoming_meetings_today',
);
export const No_Upcoming_Meetings_Text = iText('No upcoming meetings', 'pwa.meetings.no_upcoming_meetings_only');

export const OutLook_Private_Text = iText('Private Appointment', 'pwa.meetings.outlook_private');
export const Google_Calendar_Private_Text = iText('Busy', 'pwa.meetings.google_calendar_private');
export const Private_Text = iText('Private', 'pwa.private');

export const Add_Calendar_Tooltip_Text = iText(
    'Connect to your work or personal calendar to view all upcoming meetings here',
    'pwa.meetings.add_calendar_tooltip',
);
export const Add_Calendar_Text = iText('Add a calendar', 'pwa.meetings.add_calendar');
export const Meetings_Empty_Tips_Text = iText('Connect your calendar or schedule a meeting', 'pwa.meetings.empty_tips');
export const DELETE_MEETINGS_TEXT = iText('Delete Meeting', 'pwa.meetings_delete_meeting');
export const Meeting_Transfer_In_Progress_Text = iText('In progress', 'pwa.meetings.transfer.in_progress');
export const Meeting_Transfer_Switch_Text = iText('Switch', 'pwa.meetings.transfer.switch');
export const On_Device_Text = (deviceName: string) => iText('On {0}', 'pwa.meetings.transfer.device', [deviceName]);
export const Meeting_Transfer_Notify_Msg = (deviceName: string) =>
    iText('Tap here to finish moving your meeting to {0}', 'pwa.meetings.transfer.notify.msg', [deviceName]);
export const Meeting_Transfer_From_Notify_Msg = (deviceName: string) =>
    iText('Your meeting will be switched from {0} to your this device', 'pwa.meetings.transfer.from.notify.msg', [
        deviceName,
    ]);
export const Meeting_Transfer_Failed_Title = iText('Unable to join this meeting', 'pwa.meetings.transfer.failed.title');
export const Meeting_Transfer_Failed_Desc = iText(
    'Your meeting could not be transferred. Please try again.',
    'pwa.meetings.transfer.failed.desc',
);
export const Meeting_Transfer_Error_Confirm = iText('OK', 'pwa.meetings.transfer.failed.confirm');
export const Play_Audio_Text = iText('Play Audio', 'pwa.play_audio');
export const Stop_Audio_Text = iText('Stop Audio', 'pwa.stop_audio');
