/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * Custom Aria Flag
 *
 * data-a-l: describe the dom level relationship. format: '0-*' or '0-*-*',
 * data-a-walk-policy: describe current a11y behavior
 *
 * data-a-left-key-disabled: boolean, disabled left keyboard
 * data-a-group: boolean, group item
 * data-a-stick: boolean, stick group flag,
 * data-a-render-index: string number, current render item index, if the value < 0 means the contact item is virtarily, cannot focus on
 * data-a-own-group: string number, current contact item belong to witch group item,
 * */
import React from 'react';
import { A11Y_POLICY, All_DOM_Nodes_Type, Keyboard_Event_Handler_Type } from './type';
import FocusWalkerComponent from './FocusWalkerComponent';


const withA11y = <T extends any>(
    // fix: https://stackoverflow.com/questions/31815633/what-does-the-error-jsx-element-type-does-not-have-any-construct-or-call
    Wrappee: React.ComponentType<T>,
    rootId: string, // root dom id
    keyboardEventHandler: Keyboard_Event_Handler_Type, // a11y keyboard event handler
) => {
    if (!keyboardEventHandler) {
        throw new Error('Required parameter keyboardEventHandler');
    }

    type IWrapperComponentProps = T & { forwardedRef: React.ForwardedRef<any> };
    class WrapperComponent extends FocusWalkerComponent<IWrapperComponentProps> {
        constructor(props: any) {
            super(props);

            this.rootId = rootId;
            this.keyboardEventHandler = keyboardEventHandler;
        }

        private getRenderIndexdDom = (rootJDom: HTMLElement, index: string) => {
            const renderIndexAiraDoms = rootJDom!.querySelectorAll('[data-a-render-index]');
            const renderIndexdDom = Array.from(renderIndexAiraDoms).find(
                (dom) => dom.getAttribute('data-a-render-index') === index,
            );

            return renderIndexdDom || null;
        };

        private getFirstChildDom = (rootJDom: HTMLElement, _currentKey: string, _currentDom: HTMLElement) => {
            let firstChildJDom = null;

            const rootAiraDoms = rootJDom!.querySelectorAll('[data-a-l]');
            if (rootAiraDoms.length > 0) {
                firstChildJDom = rootAiraDoms[0];
            }

            // firstChildJDom is group dom means stick group
            const isGroupDom = firstChildJDom!.getAttribute('data-a-group') === 'true';

            if (!isGroupDom) {
                firstChildJDom = this.getRenderIndexdDom(rootJDom, '0');
            }

            return firstChildJDom;
        };

        private getPreJDom = (rootJDom: HTMLElement, currentKey: string) => {
            let prevJDom = null;
            const prevTempArr = currentKey!.split('-').map(Number);
            prevTempArr[prevTempArr.length - 1] = +prevTempArr[prevTempArr.length - 1] - 1;
            const prevKey = prevTempArr.join('-');
            prevJDom = rootJDom!.querySelector(`[data-a-l="${prevKey}"]`);

            let isVirtuallyPrevJDom = null;
            if (prevJDom) {
                const value = prevJDom!.getAttribute('data-a-render-index');
                if (value) {
                    isVirtuallyPrevJDom = parseInt(value) < 1;
                }
            }

            return { prevJDom, isVirtuallyPrevJDom };
        };

        private getNextJDom = (rootJDom: HTMLElement, currentKey: string, currentDom: HTMLElement) => {
            let nextJDom = null;

            const isStickDom = currentDom.getAttribute('data-a-stick') === 'true';

            // stick-group-item jump to contact-item
            if (isStickDom) {
                const isGroupDom = currentDom.getAttribute('data-a-group') === 'true';
                const walkerPolicy = currentDom.getAttribute('data-a-walk-policy');

                if (isStickDom && isGroupDom && walkerPolicy === A11Y_POLICY.CONTACT_TREE_ITEM_POLICY) {
                    // stick group item will cover on (0), so focus in (1) from view
                    nextJDom = this.getRenderIndexdDom(rootJDom, '1');
                }
            } else {
                const nextTempArr = currentKey!.split('-').map(Number);
                nextTempArr[nextTempArr.length - 1] = +nextTempArr[nextTempArr.length - 1] + 1;
                const nextKey = nextTempArr.join('-');
                nextJDom = rootJDom!.querySelector(`[data-a-l="${nextKey}"]`);
            }

            return nextJDom;
        };

        private getCurrentDomOwnGroupJDom = (rootJDom: HTMLElement, currentDom: HTMLElement) => {
            let currentDomOwnGroupJDom = null;

            const ownGroupKey = currentDom.getAttribute('data-a-own-group');
            currentDomOwnGroupJDom = rootJDom!.querySelector(`[data-a-l="${ownGroupKey}"]`);

            // Reverse search for the nearest group item
            if (!currentDomOwnGroupJDom) {
                try {
                    const rootAiraDoms = rootJDom!.querySelectorAll('[data-a-l]');
                    for (let i = 0; i < rootAiraDoms.length; i++) {
                        if (currentDom.isSameNode(rootAiraDoms[i])) {
                            currentDomOwnGroupJDom = Array.from(rootAiraDoms)
                                .slice(0, i)
                                .reverse()
                                .find((dom) => dom.getAttribute('data-a-group'));
                            break;
                        }
                    }
                } catch (error) {
                    console.log('getCurrentDomOwnGroupJDom', error);
                }
            }

            return currentDomOwnGroupJDom;
        };

        public getWalkJDoms = (currentDom: HTMLElement) => {
            const rootJDom = document.getElementById(this.rootId);

            const currentKey = currentDom.getAttribute('data-a-l');

            const firstChildJDom = this.getFirstChildDom(rootJDom, currentKey, currentDom);

            const { prevJDom, isVirtuallyPrevJDom } = this.getPreJDom(rootJDom, currentKey);

            const nextJDom = this.getNextJDom(rootJDom, currentKey, currentDom);

            const isCurrentDomIsGroup = !!currentDom.getAttribute('data-a-group');

            const isCurrentDomAriaExpanded = currentDom.getAttribute('aria-expanded') === 'true';

            const currentDomOwnGroupJDom = this.getCurrentDomOwnGroupJDom(rootJDom, currentDom);

            const leftKeyDisabled = currentDom.getAttribute('data-a-left-key-disabled') === 'true';

            return {
                rootJDom,
                currentKey,
                currentDom,
                firstChildJDom,
                prevJDom,
                isVirtuallyPrevJDom,
                nextJDom,
                isCurrentDomAriaExpanded,
                isCurrentDomIsGroup,
                currentDomOwnGroupJDom,
                leftKeyDisabled,
            } as All_DOM_Nodes_Type;
        };

        render() {
            return <Wrappee ref={this.props.forwardedRef} {...this.props} />;
        }
    }

    return React.forwardRef<any, any>((props, ref) => {
        const wrapperProps: IWrapperComponentProps = Object.assign(
            {
                forwardedRef: ref,
            },
            props,
        );
        return <WrapperComponent {...wrapperProps} />;
    });
};

export { withA11y };
