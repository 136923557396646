import React, { useState, useRef } from 'react';
import { Button } from '@zoom/zoom-react-ui';
import { sendFeedbackToWebAction } from '../redux/feedback-thunk-actions';
import {
    feedbackRandomlyTitle,
    contactMe,
    submit,
    enterEmailAddress,
    pleaseEnterEmail,
    pleaseEnterValidEmail,
} from '../resource';
import { useAppDispatch, useAppSelector } from '../../../Phone/types';
import Captcha from '../components/captcha';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import './feedback-randomly.scss';
import { isVaildEmail } from '../../../../store/common/common-utils';
import { setModal } from '../../../../store/modal/modal-store';

export default function FeedbackRandomly() {
    const [text, setText] = useState('');
    const [displayEmail, setDisplayEmail] = useState(false);
    const userInfo = useAppSelector((state) => state.common.userInfo);
    const userEmail = userInfo?.email;
    const [email, setEmail] = useState(userEmail);
    const [displayValidEmail, setDisplayValidEmail] = useState(false);
    const captchaRef = useRef<any>();
    const dispatch = useAppDispatch();

    function handleClick() {
        const isValid = isVaildEmail(email);
        if (!displayEmail || (displayEmail && isValid)) {
            const validEmail = isValid ? email : userEmail;
            const recaptcha = captchaRef.current?.getCaptchaValidateResult();
            dispatch(
                sendFeedbackToWebAction({
                    addComments: text,
                    email: validEmail,
                    quality: undefined,
                    recaptcha,
                }),
            );
        }
        dispatch(setModal({ name: 'feedback', data: { show: false } }));
        setDisplayValidEmail(!isValid);
    }

    return (
        <div className="feedback-randomly">
            <div style={{ display: 'none' }}>
                <Captcha ref={captchaRef} />
            </div>
            <div className="feedback-randomly__title"> {feedbackRandomlyTitle} </div>
            <div className="feedback-randomly__content">
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    aria-label="Additional comments"
                    className="feedback-randomly__content__textinput"
                />
                <Checkbox
                    checked={displayEmail}
                    onChange={(val: boolean) => {
                        const emailVal = email || userEmail;
                        setEmail(emailVal);
                        setDisplayEmail(val);
                        setDisplayValidEmail(false);
                    }}
                    containerClass="feedback-randomly__content__checkbox"
                >
                    {contactMe}
                </Checkbox>
                {displayEmail && (
                    <textarea
                        value={email}
                        placeholder={enterEmailAddress}
                        onChange={(e) => {
                            setDisplayValidEmail(false);
                            setEmail(e.target.value);
                        }}
                        className="feedback-randomly__content__email"
                    />
                )}
                {displayEmail && (
                    <div className="feedback-randomly__content__notification">
                        {!email && pleaseEnterEmail}
                        {displayValidEmail && pleaseEnterValidEmail}
                    </div>
                )}
            </div>
            <Button
                type="primary"
                className="feedback-randomly__button"
                disabled={!text || (displayEmail && !email)}
                onClick={() => handleClick()}
            >
                {submit}
            </Button>
        </div>
    );
}
