export enum ItemType {
    Zoom,
    Calendar,
}

export enum PrivateEventCalendarType {
    Unkown,
    Outlook,
    Google,
    MaxNum,
}

export enum ExtendMeetingType {
    Normal,
    Pmi,
    Webinar,
    SimuliveWebinar,
}

export enum MeetingStatus {
    NotStart,
    InProgress,
    Finished,
}

export enum MeetingScheduleType {
    PreSchedule,
    Instant,
    Schedule,
    Repeat,
}

export enum CalendarParseMeetingType {
    Zoom = 0,
    OnZoom = 1,
}

export enum CalendarEventConferenceProvider {
    None = 0,
    Zoom = 1,
    OnZoom = 11,
}

export enum OnZoomEventType {
    ONZOOM_EVENT_TYPE_NONE,
    ONZOOM_EVENT_TYPE_NORMAL,
    ONZOOM_EVENT_TYPE_LOBBY,
}

export enum EOccurrenceStatus {
    INIT,
    DELETED,
    UPDATED,
}

// server response
export interface WebMeetingItem {
    meetingNumber: string; // 2156759460
    originalMtgNumber: string; // 2156759460
    hostId: string;
    hostName: string;
    topic: string;
    startTime: number; // unix timestamp second
    duration: number; // minutes
    joinUrl: string;

    channelId?: string;

    type: MeetingScheduleType;
    exType: ExtendMeetingType;
    newExtType: ExtendMeetingType;
    occurrence: number;
    scheduleOptions: string; // 64bit => "4566777789"
    scheduleOptions2: string;
    scheduleOptions3: string;
    scheduleOptions4: string;
    scheduleOptions5: string;
    scheduleOptions6: string;
    scheduleOptions7: string;
    calendarType: PrivateEventCalendarType;
    canViewDetail: boolean;
    eventDirectMeetingUrl?: string; // for onzoom
    extInfo?: any;

    // for recurring main meeting
    occurrenceMeetings?: Array<Omit<WebMeetingItem, 'occurrenceMeetings'>>;
    meetingMasterEventId?: string;

    // for recurring single meeting
    occurrenceStatus?: EOccurrenceStatus;

    // for detail
    inviteEmailWithTime?: string;
}

export interface MeetingItem extends WebMeetingItem {
    id: string; // web doesn't give id
    itemType: ItemType.Zoom;
    password: string; // we extract it from joinUrl
}

export interface WebCalendarEvent {
    eventId: string;
    startTime: string; // ISO format "2021-10-27T12:30:00Z"
    endTime: string; // ISO format "2021-10-27T12:30:00Z"
    duration: number; // minutes
    timeZone: string; // "America/New_York"
    deleted: boolean;

    // what we need to specify in the request
    organizerEmail: string;
    topic: string;
    allDay: boolean;
    conferenceInfo?: {
        meetingId: string;
        meetingPassword: string;
        realMeetingNum: string;
        digitPassword: string;
        conferenceProvider: CalendarEventConferenceProvider;
        dialString: string;
        preferredDialMethod: any;
        properties: Record<string, unknown>;
        onZoomJoinLink?: string;
        onZoomViewLink?: string;
    };
}

export interface CalendarEvent extends Omit<WebCalendarEvent, 'startTime' | 'endTime'> {
    itemType: ItemType.Calendar;
    id: string;
    startTime: number; // unix timestamp
    endTime: number; // unix timestamp
}

export type ZnpsMeetingEventTypes =
    | 'delete'
    | 'add'
    | 'edit'
    | 'delete_occurrence'
    | 'edit_antendee'
    | 'add_antendee'
    | 'delete_antendee';

export interface ZnpsMeetingItemData {
    mn: string;
    originMn: string;
    type: ZnpsMeetingEventTypes;
    occurrenceTime: string;
}
