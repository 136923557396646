import React from 'react';
import { Modal } from 'Modal';
import { connect } from 'react-redux';
import { IconAdvancedFeatures } from '@zoom/icons-react';
import Button from '../../../components/Button/Button';
import { FORCED_BREAK_SPECIAL_OFFER, CANCEL, UPGRADE } from '../../../resource';
import './index.scss';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { openTab } from '../../../utils';

type IForceBreakMeetingModal = {
    data: {
        breakTitle: string;
        breakDesc: string;
        offerDesc: string;
        upgradeLink: string;
    };
};

const ForcedBreakMeeting = ({ data: { breakTitle, breakDesc, offerDesc, upgradeLink } }: IForceBreakMeetingModal) => {
    const dispatch = useAppDispatch();
    const closeMe = () => {
        dispatch(setModal({ name: 'forcedBreakMeeting', data: null }));
    };

    const upgrade = () => {
        openTab(upgradeLink);
        closeMe();
    };

    return (
        <Modal isOpen={true} needBackdrop={true} shouldCloseOnOverlayClick={false} isDraggable={false} centered={true}>
            <div className="modal-forced-break-meeting">
                <span className="subtitle" title={breakTitle}>
                    {breakTitle}
                </span>
                <span className="subtitle-desc" title={breakDesc}>
                    {breakDesc}
                </span>
                <div className="special-offer">
                    <div className="tag">{FORCED_BREAK_SPECIAL_OFFER}</div>
                    <IconAdvancedFeatures className="star-icon" />
                    <span className="desc" title={offerDesc}>
                        {offerDesc}
                    </span>
                </div>
                <div className="modal-buttons">
                    <Button extraCls="upgrade" size={Button.Size24} type={Button.Normal} onClick={upgrade}>
                        {UPGRADE}
                    </Button>
                    <Button extraCls="cancel" size={Button.Size24} type={Button.Dark} onClick={closeMe}>
                        {CANCEL}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default connect()(ForcedBreakMeeting);
