import { isInStandaloneMode, isTeslaMode } from '../../utils';
import { onLoadLogger, uploadOnloadLogs } from '../pwa-loggers';

export const logOnLoad = () => {
    const logInfo = {
        ua: window.navigator.userAgent,
        url: window.location.href,
        uid: window.PwaConfig.uid || '---none---',
        isStandalone: isInStandaloneMode(),
        isTesla: isTeslaMode(),
    };

    const logAndUpload = () => {
        onLoadLogger.log(logInfo).then(() => {
            uploadOnloadLogs();
        });
    };

    if (window.requestIdleCallback) {
        window.requestIdleCallback(logAndUpload);
    } else {
        logAndUpload();
    }
};
