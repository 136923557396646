import { isThisBitSetByMask, isThisBitSetByOffset } from '../longNumBitOps';

// options
export const USER_OPTIONS_ENABLE_CAMERA_CONTROL = 29; //536870912 --> 10^29

// options 3
export const USER_OPTIONS3_DISPLAY_EVERYONE_MEETING_LIST = 0x400000;

// options 5
// const USER_OPTIONS5_SUPPORT_CLIENT_RECURRING_MEETING = 34; // 0x1LL << 34
const USER_OPTIONS5_ENABLE_ZOOM_CCI = 4;
export const USER_OPTIONS5_ENABLE_TRANSFER_MEETING = 0x400;

// options 6
export const USER_OPTIONS6_ALLOW_NEW_CHANNEL = 6; // 0x1LL << 6
export const ENABLE_CLINET_LIST_EXPANDED_RECURRING_MEETING = 22;
export const USER_OPTIONS6_AUTO_ACCEPT_FAR_CAMERA_CONTROL = 26; //  67108864 --> 10^26

export const isSupportClientReurringMeetingEnabled = (featureOptions6: string) => {
    if (!featureOptions6) return false;
    return isThisBitSetByOffset(featureOptions6)(ENABLE_CLINET_LIST_EXPANDED_RECURRING_MEETING);
};

export const isShowEveryoneMeetingListEnabled = (featureOptions3: string) => {
    if (!featureOptions3) return false;
    return isThisBitSetByMask(featureOptions3)(USER_OPTIONS3_DISPLAY_EVERYONE_MEETING_LIST);
};

export const isPMCEnabled = (featureOptions6: string) => {
    if (!featureOptions6) return false;
    return isThisBitSetByOffset(featureOptions6)(USER_OPTIONS6_ALLOW_NEW_CHANNEL);
};

export const isContactCenterEnabled = (featureOptions5: string) => {
    if (!featureOptions5) {
        return false;
    }
    return isThisBitSetByOffset(featureOptions5)(USER_OPTIONS5_ENABLE_ZOOM_CCI);
};

export const isMeetingTransferEnabled = (featureOptions5: string) => {
    if (!featureOptions5) return false;
    return isThisBitSetByMask(featureOptions5)(USER_OPTIONS5_ENABLE_TRANSFER_MEETING);
};

export const isFarEndCameraControlEnabled = (featureOptions: string) => {
    if (!featureOptions) {
        return false;
    }
    return isThisBitSetByOffset(featureOptions)(USER_OPTIONS_ENABLE_CAMERA_CONTROL);
};

export const isAutoFarEndCameraControlEnabled = (featureOptions6: string) => {
    if (!featureOptions6) {
        return false;
    }
    return isThisBitSetByOffset(featureOptions6)(USER_OPTIONS6_AUTO_ACCEPT_FAR_CAMERA_CONTROL);
};
