import { createSlice } from '@reduxjs/toolkit';
import { WebimStatus, WebimStatusErrorType } from '@zoom/pwa-webim';
import { signoutWhenXMPPConflictThunk, signoutWhenXMPPRevokeTokenThunk } from '../../store/common/sign-out-thunk';

const initialState = {
    status: WebimStatus.INIT,
    statusInfo: null,
    willReconnect: true,
    networkConnected: true,
};

const xmppSlice = createSlice({
    name: 'xmpp',
    initialState,
    reducers: {
        setXmppStatus(state, { payload = {} }) {
            let { status, statusInfo, willReconnect, networkConnected } = payload;
            state.status = status;
            state.statusInfo = statusInfo;
            state.willReconnect = willReconnect;
            state.networkConnected = networkConnected;
        },
    },
});

export const { setXmppStatus } = xmppSlice.actions;

export const setXmppStatusThunk =
    ({ data }) =>
    (dispatch) => {
        let { status, statusInfo, willReconnect, networkConnected } = data;
        if (status === WebimStatus.ERROR) {
            // we only receive conflict event ONCE.
            if (statusInfo === WebimStatusErrorType.CONFLICT) {
                console.log('setXmppStatusThunk CONFLICT');
                dispatch(signoutWhenXMPPConflictThunk());
            } else if (statusInfo === WebimStatusErrorType.REVOKE_TOKEN) {
                dispatch(signoutWhenXMPPRevokeTokenThunk());
            } else {
                console.log('Unknown error');
            }
        } else if (status === WebimStatus.DISCONNECTED) {
            console.log('xmpp Disconected');
        } else if (status === WebimStatus.AUTHFAIL) {
            console.log('xmpp Auth Failed');
        } else if (status === WebimStatus.CONNECTED) {
            console.log('xmpp connected');
        } else if (status === WebimStatus.CONNECTING || status === WebimStatus.RECONNECTING) {
            console.log('xmpp connecting ...');
        }
        dispatch(setXmppStatus({ status, statusInfo, willReconnect, networkConnected }));
    };

/************************ selector */

export const isXmppConnectedSelector = (state) =>
    state?.xmpp.status === WebimStatus.CONNECTED && state?.xmpp.networkConnected;

export default xmppSlice.reducer;
