// bit masks
// better to be in string format.

import { isThisBitSetByOffset } from '../longNumBitOps';

export const ENABLE_BUSY_PRESENCE = 56; // 72057594037927936
export const ENABLE_OOO_Presence = 49; // 562949953421312

export const isSupportBusyPresenceEnabled = (opClientOptions: string) => {
    if (!opClientOptions) return false;
    return isThisBitSetByOffset(opClientOptions, 10)(ENABLE_BUSY_PRESENCE);
};

export const isSupportOOOPresenceEnabled = (opClientOptions: string) => {
    if (!opClientOptions) return false;
    return isThisBitSetByOffset(opClientOptions, 10)(ENABLE_OOO_Presence);
};
