import inviteRingSrc from '../assets/audio/ring.mp3';

export const ringType = {
    invite: 'invite',
};

const ringSrc = {
    [ringType.invite]: inviteRingSrc,
};

export default class AudioRing {
    static audioMap: Map<string, AudioRing> = new Map();
    static playAudio = (srcName: string, cycle = false, deviceId = '') => {
        const audioInstance = AudioRing.audioMap.get(srcName);
        if (audioInstance) {
            audioInstance.play(cycle, deviceId);
        }
        return audioInstance;
    };

    static stopAudio = (srcName: string) => {
        const audioInstance = AudioRing.audioMap.get(srcName);
        if (audioInstance) {
            audioInstance.stop();
        }
        return audioInstance;
    };

    static getAudioInstance = (srcName: string) => {
        let audioInstance = AudioRing.audioMap.get(srcName);
        if (!audioInstance) {
            audioInstance = new AudioRing(srcName);
            AudioRing.audioMap.set(srcName, audioInstance);
        }
        return audioInstance;
    };

    static loadAudioResource = () => {
        Object.keys(ringSrc).forEach((srcName) => {
            AudioRing.getAudioInstance(ringSrc[srcName]).load();
        });
    };

    audio: HTMLAudioElement;
    playing: boolean;

    constructor(srcName: string) {
        this.audio = new Audio(ringSrc[srcName]);
        this.playing = false;
        this.audio.addEventListener('ended', this.onEnded);
    }

    onEnded = () => {
        this.playing = false;
    };

    changeSpeaker = async (deviceId: string) => {
        if (!deviceId) return false;
        try {
            await (this.audio as any).setSinkId(deviceId);
            return true;
        } catch (_e) {
            return false;
        }
    };

    changeVolume = (volume: number): void => {
        this.audio.volume = volume;
    };

    play = async (cycle = false, deviceId: string = undefined) => {
        this.audio.loop = cycle;
        if (deviceId) {
            await this.changeSpeaker(deviceId);
        }
        await this.audio.play();
        this.playing = true;
    };

    load = () => {
        this.audio.load();
    };

    stop = () => {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio.loop = false;
        this.playing = false;
    };
}
