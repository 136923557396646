import { PolicyName } from '../presence-policy-types';

type DurationInSeconds = number;
interface Policy {
    duration: DurationInSeconds;
}

interface IProps {
    name: PolicyName;
    policy: Policy;
}

export default class PolicyTimer {
    name: PolicyName;
    timer = -1;
    policy: Policy = null;

    constructor(props: IProps) {
        this.timer = -1;
        this.name = props.name;
        this.policy = props.policy;
    }

    setPolicy(policy: Policy) {
        if (policy.duration === 0) {
            window.clearTimeout(this.timer);
        } else {
            this.policy = { ...policy };
            this.tick();
        }
        this.onDurationUpdate();
    }

    // controller manually unsets this policy
    // we don't notify him later.
    unsetPolicy() {
        window.clearTimeout(this.timer);
        if (this.policy) {
            this.policy.duration = 0;
        }
        this.onDurationUpdate();
    }

    private onTickStop() {
        window.clearTimeout(this.timer);
        this.onDurationEnd();
    }

    private onTick(stepInSeconds: number) {
        this.policy.duration -= stepInSeconds;
        this.onDurationUpdate();
        this.tick();
    }

    private tick = () => {
        window.clearTimeout(this.timer);

        const timeLeftInSeconds = this.policy.duration;

        if (timeLeftInSeconds <= 0) {
            this.onTickStop();
            return;
        }

        let timeOut = 1;

        if (timeLeftInSeconds > 10) {
            timeOut = 10; // 10 seconds
        }

        this.timer = window.setTimeout(() => {
            this.onTick(timeOut);
        }, timeOut * 1000);
    };

    // subclass must implements this function
    onDurationEnd() {}

    // subclass must implements this function
    onDurationUpdate() {}
}
