import { ContactPresence } from '@zoom/zpns-presence-handler';
import EventEmitter from '../event-emitter';
import { ZpnsStatus } from '../../services/zpns/zpns-defs';

export interface PresenceChageEvent {}

export const zpnsEvents = {
    connectStatus: new EventEmitter<ZpnsStatus>({ name: 'zpns connection status' }),
    presenceChange: new EventEmitter<Array<ContactPresence>>({ name: 'when contact presence change' }),
    message: new EventEmitter<any>({ name: 'got server push messages' }),
};
