import { AxiosError, AxiosRequestConfig } from 'axios';
import HttpClient, { IRequestConfig } from '../../../services/http/HttpClient';
import { IUCSTokenInfo } from './ucs-types';
export const GET_UCS_TOKEN_PATH = '/wc/pwa-groups';

export class UcsHttpClient extends HttpClient {
    uid: string;

    constructor() {
        super({ tokenUrl: GET_UCS_TOKEN_PATH, tokenMethod: 'post' });
        this.name = 'UCS';
    }

    setUserId(uid: string) {
        this.uid = uid;
    }

    injectToken(config: IRequestConfig, token: IUCSTokenInfo): IRequestConfig {
        config.headers['zak'] = token.ucsToken;
        return config;
    }

    extractToken(data: any) {
        return {
            ucsToken: data.result.ucsToken,
            ucsDomain: data.result.ucsDomain,
        };
    }

    fetchTokenConfig(): AxiosRequestConfig {
        return {
            params: { uid: this.uid },
        };
    }

    isTokenInvalidated(error: AxiosError): boolean {
        // if token expired, server responds with http status 490
        // 490 is not a standard http code, so Axios doesn't handler it correctly

        if (!error.response) {
            return true;
        }
        return false;
    }
}
