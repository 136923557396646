import { WebimPresence, PresencePolicy } from '@zoom/pwa-webim';
import XmppAgent from '../../services/xmpp/XmppAgent';
import EventEmitter from '../event-emitter';

export interface PresenceChageEvent {
    data: Array<WebimPresence>;
    target: XmppAgent;
}

export const xmppEvents = {
    presenceChange: new EventEmitter<PresenceChageEvent>({ name: 'contact presence change' }),
    policyChange: new EventEmitter<PresencePolicy>({ name: 'presence policy changes' }),
    xmppReady: new EventEmitter({ name: 'xmpp connection ready' }),
};
