import { isMobileDevice } from '@zoom/zoom-react-ui';
import { ModalData } from '../../store/modal/modal-store';

export const isMobileFeedbackUser = (feedback: ModalData): boolean => {
    if (!feedback) return false;

    const hasMeetingDetails = typeof feedback?.meetingDetails === 'string';

    return isMobileDevice() && feedback?.show && hasMeetingDetails;
};

export const isFeedbackFromWebinar = (feedback: any) => {
    const meetingDetailsBinary = feedback?.meetingDetails;

    if (!meetingDetailsBinary) return false;

    return meetingDetailsBinary.startsWith('1')
}