import React, { useEffect, useMemo } from 'react';
import './pwa.scss';
import { Switch, Route } from 'react-router-dom';
import Index from '../../features/Index/Index';
import SignedIn from '../../features/SignedIn/SignedIn';
import JoinMeeting from '../../features/JoinMeeting/JoinMeeting';
import InvitationContainer from '../../features/Meeting/Invitation/InvitationContainer';
import {
    isUserSigninSelector,
    getUserInfoThunk,
    setUserInfo,
    checkUserLoginMethodWithChromePolicyThunk,
} from '../../store/common/common-store';
import { fetchPbxPermission } from '../../services/http/zoomPhone';
import WebClient from '../../features/Meeting/WebClient';
import ModalContainer from '../../features/ModalContainer/ModalContainer';
import { RoutePath, MeetingRoutes } from '../../routes/routePath';
import PhoneAudioElement from '../../features/PhoneAudioElement';
import asyncComponent from '../../hoc/asyncComponent';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { GetUserInfoError } from '../../store/common/error';
import { meetingAgent, featureOptionManager, broadcastChannelAgent } from '../../app-init';
import BrowserNotification from '../../utils/BrowserNotification';
import { getAsyncPhoneModule } from '../../asyncModulesStore';
import { ToastContainer } from 'Toast';
import { TOAST_PATH } from '../../utils/constants';
import { IconAlertCircleFill, IconCheckmarkCircleFill, IconCloseCircleFill, IconMegaphone } from '@zoom/icons-react';
import { testUcs } from '../../features/Contacts/redux';
import FooterLink from './FootLink';
import { usePrevious } from '../../hooks/usePrevious';
import { canStopRouting, replaceRoute, routeBack, routeMeeting } from '../../routes';
import MeetingStarter from '../../features/Meeting/MeetingStarter';
import { sendChromeOSAdminPolicyToServer } from '../../utils/chrome-policy';
import { maybeAutoLoginWithGoogle } from '../../utils/chrome-policy/policy-checks';
import useIsUserSignedInEffect from '../../hooks/useIsUserSignedInEffect';
import useCanLoadPWAComp from '../../hooks/useCanLoadPWAComp';
import { shallowEqual } from 'react-redux';
import useHIDHookSwitch from '../../hooks/useHIDHookSwitch';
import serviceContainer from '../../services/container';
import useRouteNavigationForLog from '../useRouteNavigationForLog';

export default function () {
    const dispatch = useAppDispatch();

    const { showWebclient, isMeetingMinimized, meetingUrlNotOnIframe, isPhoneModuleLoaded, showJoinMeeting } =
        useAppSelector((state) => {
            const {
                meeting: { showWebclient, isMeetingMinimized, meetingUrlNotOnIframe },
                common: { isPhoneModuleLoaded, showJoinMeeting },
            } = state;
            return {
                showWebclient,
                isMeetingMinimized,
                meetingUrlNotOnIframe,
                isPhoneModuleLoaded,
                showJoinMeeting,
            };
        }, shallowEqual);

    const isUserSignin = useAppSelector(isUserSigninSelector);
    const hashedUserId = useAppSelector((state) => state.common.hashedUserId);
    const hasUserId = Boolean(hashedUserId);
    const isPrevMeetingMinimized = usePrevious(isMeetingMinimized);
    const canLoadPWAComp = useCanLoadPWAComp();

    useEffect(() => {
        sendChromeOSAdminPolicyToServer();
        if (!canLoadPWAComp) {
            return;
        }

        BrowserNotification.requestPermission();
        dispatch(getUserInfoThunk())
            .then(async (userInfo) => {
                await dispatch(checkUserLoginMethodWithChromePolicyThunk(userInfo));
                dispatch(setUserInfo(userInfo));

                fetchPbxPermission().catch((e) => {
                    console.log(e);
                });

                featureOptionManager.setOpClientOptions(userInfo.opClientOptions);

                dispatch(testUcs());


                // userInfo is ready
                const eventBus = serviceContainer.getEventBus();
                eventBus.app.userInfoReady.dispatchEvent(userInfo);
            })
            .catch(async (e) => {
                if (e.message === GetUserInfoError.NotLogin) {
                    if (await maybeAutoLoginWithGoogle()) {
                        return;
                    }
                }

                // TODO: do we need this???
                if (canStopRouting()) return;

                if (window.PwaConfig.uid) {
                    replaceRoute({ pathname: RoutePath.Index });
                }

                console.log(e.toString());
            });
    }, [canLoadPWAComp]);

    useEffect(() => {
        return () => {
            meetingAgent.uninit();
            broadcastChannelAgent.uninit();
        };
    }, []);

    const InBoundCallContainer = useMemo(
        () =>
            asyncComponent(() => {
                return getAsyncPhoneModule().then(({ InBoundCallContainer }) => InBoundCallContainer);
            }, isPhoneModuleLoaded),
        [isPhoneModuleLoaded],
    );

    useEffect(() => {
        // meeting minmized
        if (!isPrevMeetingMinimized && isMeetingMinimized) {
            routeBack();
        }
        // meeting maxmized
        if (isPrevMeetingMinimized && !isMeetingMinimized) {
            if (meetingUrlNotOnIframe && window.location.pathname !== new URL(meetingUrlNotOnIframe).pathname) {
                routeMeeting(meetingUrlNotOnIframe);
            }
        }
    }, [isMeetingMinimized, isPrevMeetingMinimized, meetingUrlNotOnIframe]);

    useIsUserSignedInEffect(hasUserId);

    useHIDHookSwitch();

    useRouteNavigationForLog();

    return (
        <>
            <Route path={MeetingRoutes} render={() => <MeetingStarter key="meetingStarter" />} />
            {canLoadPWAComp && (
                <div className="pwa">
                    <Switch>
                        <Route exact path={RoutePath.Index} render={() => <Index footerLink={<FooterLink />} />} />
                        {hasUserId ? <SignedIn /> : null}
                    </Switch>
                    {window.PwaConfig?.enableMeetingRoute && (
                        <Route exact path={RoutePath.Join} render={() => <JoinMeeting />} />
                    )}
                    {showJoinMeeting ? <JoinMeeting /> : null}
                </div>
            )}
            <PhoneAudioElement />
            {isUserSignin && <InvitationContainer />}
            {isUserSignin && isPhoneModuleLoaded && <InBoundCallContainer />}
            {showWebclient && <WebClient />}
            <ModalContainer />
            <ToastContainer
                TOAST_PATH={TOAST_PATH}
                icons={{
                    success: <IconCheckmarkCircleFill />,
                    info: <IconMegaphone />,
                    failure: <IconCloseCircleFill />,
                    warn: <IconAlertCircleFill />,
                }}
            />
        </>
    );
}
