import React from 'react';
import ReactDOM from 'react-dom/client';
import './utils/MicroApp/CustomMicroElement';
import './config.js';
import './app-init'; // must before 'import store'
import store from './store';
import { AuthRoutes, RoutePath } from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import './assets/styles/main.scss';
import App from './vendors/App';
import { Router, history as appHistory } from './routes';
import { matchPath } from 'react-router-dom';
import { isChatEnabled, isWhiteBoardEnabled, isZCCEnabled } from './utils/featureOptions';
import { feedbackDialogThunk } from './features/Meeting/feedback/redux/feedback-thunk-actions';
import { maybeJoinMeetingWhenSignedin, maybeLinkCaptureJoinMeeting } from './app-init/handle-join-meeting';
import { onLoadLogger } from './logger/pwa-loggers';
import { logOnLoad } from './logger/log-pieces/log-onload';

logOnLoad();

const isRouteMatch = (pathname: string, route: string) => {
    return !!matchPath(pathname, { path: route, exact: true });
};

const maybeHandleJoinMeeting = () => {
    if (maybeJoinMeetingWhenSignedin()) {
        onLoadLogger.log('Join meeting after signin');
        return true;
    }

    if (maybeLinkCaptureJoinMeeting()) {
        onLoadLogger.log('Join meeting on capturing meeting links');
        return true;
    }

    return false;
};

const maybeChangeFirstRouteBySignedInState = () => {
    const location = appHistory.location;
    if (window.PwaConfig.uid) {
        if (
            [
                isRouteMatch(location.pathname, RoutePath.Index),
                isRouteMatch(location.pathname, RoutePath.Chat) && !isChatEnabled(),
                isRouteMatch(location.pathname, RoutePath.Whiteboards) && !isWhiteBoardEnabled(),
                isRouteMatch(location.pathname, RoutePath.ContactCenter) && !isZCCEnabled(),
            ].some(Boolean)
        ) {
            appHistory.replace(
                {
                    pathname: RoutePath.Home,
                    search: location.search,
                },
                { isFirstRoute: true },
            );

            if(!isRouteMatch(location.pathname, RoutePath.Index)) {
                onLoadLogger.log('route to Home && no access');
            }

        }
    } else {
        if (AuthRoutes.some((route) => !!matchPath(location.pathname, { path: route, exact: true }))) {
            appHistory.replace(
                {
                    pathname: RoutePath.Index,
                    search: location.search,
                },
                { isFirstRoute: true },
            );
            onLoadLogger.log('route to Index && no session');
        }
    }
};

const checkIfNeedShowFeedbackDialog = () => {
    const params = new URL(window.location.href).searchParams;
    if (params.has('feedback') && params.has('meetingNumber')) {
        onLoadLogger.log('show feedback dialog');
        store.dispatch(
            feedbackDialogThunk({
                feedback: params.get('feedback'),
                meetingNumber: params.get('meetingNumber'),
                postAttendeeUrl: params.get('paurl'),
                meetingId: decodeURIComponent(params.get('meetingId')),
                nodeId: params.get('nodeId'),
                meetingDetails: params.get('meetingDetails'),
                deviceType: decodeURIComponent(params.get('deviceType'))
            }),
        );
    }
};

export const renderApp = () => {
    checkIfNeedShowFeedbackDialog();
    if (!maybeHandleJoinMeeting()) {
        // if we handled join meeting url, we'll change url to meeting url
        // so we don't need to change url here
        maybeChangeFirstRouteBySignedInState();
    }

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root &&
        root.render(
            <Provider store={store}>
                <Router history={appHistory}>
                    <App />
                </Router>
            </Provider>,
        );
};
