import React, { useEffect, useRef } from 'react';

import FocusLock from 'react-focus-lock';
import { SettingsContainer, AUDIO_OPTIONS, TYPE } from '@zoom/pwa-settings';
import '@zoom/pwa-settings/lib/index.css';
// import './pwa-settings.css';


import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import Settings from './Settings';

import { setMediaConfigThunk } from '../../store/common/common-store';
import { usePhoneModule } from '../../asyncModulesStore';
import { setIsEnableHID } from './redux/settings-store';
import { isEmpty } from 'lodash-es';

const ringUrl = require('../../assets/audio/ring.mp3');
const MergedSettings = () => {
    const dispatch = useAppDispatch();
    const { initHIDThunk } = usePhoneModule();

    const { showSettingsDialog, isEnableHID, hidAvalible } = useAppSelector(
        ({ settings: { showSettingsDialog, isEnableHID, hidAvalible } }) => ({
            showSettingsDialog,
            isEnableHID,
            hidAvalible,
        }),
    );
    const settingsApiRef = useRef(null);
    const microphoneRef = useRef(null);

    const { wc_hash, domain, previewOptions } = window.PwaConfig;

    useEffect(() => {
        microphoneRef.current = settingsApiRef.current.getTransaction().getActiveMicrophone();
    }, [showSettingsDialog]);

    const onClose = () => {
        let params = {};

        // Speacker
        const currentSpeaker = settingsApiRef.current.getTransaction().getActiveSpeaker();
        if (currentSpeaker.deviceId) {
            params = Object.assign(params, {
                speakerDeviceId: currentSpeaker.deviceId,
                speakerDeviceLabel: currentSpeaker.label,
            });
        }

        // volume
        const speakerVolume = settingsApiRef.current.getTransaction().getSpeakerVolume();
        if (speakerVolume >= 0) {
            params = Object.assign(params, {
                speakerVolume: speakerVolume / 100,
            });
        }

        // microphone
        const microphone = settingsApiRef.current.getTransaction().getActiveMicrophone();
        if (microphone.deviceId) {
            params = Object.assign(params, {
                microphoneDeviceId: microphone.deviceId,
                microphoneDeviceLabel: microphone.label,
            });
        }

        if (!isEmpty(params)) {
            // console.log('pwa settings params ==>', params);
            dispatch(setMediaConfigThunk(params));
        }

        // HID
        try {
            const currentIsEnableHID = settingsApiRef.current
                .getTransaction()
                .getOptionValue(AUDIO_OPTIONS.SYNC_BUTTONS_ON_HEADSET);
            const deviceChanged = microphoneRef.current.deviceId !== microphone.deviceId;
            if (isEnableHID !== currentIsEnableHID || deviceChanged || !hidAvalible) {
                dispatch(setIsEnableHID(currentIsEnableHID));
                initHIDThunk && dispatch(initHIDThunk(deviceChanged));
            }
        } catch (error) {
            console.log(error, 'error');
        }
    };

    return (
        <FocusLock disabled returnFocus={true} noFocusGuards={true}>
            <SettingsContainer
                defaultPreviewOptions={previewOptions}
                ringUrl={ringUrl}
                wcHash={wc_hash}
                baseUrl={domain}
                ref={settingsApiRef}
                type={TYPE.PWA}
            >
                <Settings onClose={onClose} />
            </SettingsContainer>
        </FocusLock>
    );
};

export default MergedSettings;
