import React, { useEffect, useRef } from 'react';

const useClickOutside = ({ callback, excludeRefs = [] }) => {
    let rootEleRef = useRef(null);
    const handleClickOutside = (event) => {
        let flag = false;
        if (rootEleRef && rootEleRef.current && !rootEleRef.current.contains(event.target)) {
            flag = true;
            if (excludeRefs) {
                excludeRefs.forEach((ref) => {
                    if (ref.current && ref.current.contains(event.target)) {
                        flag = false;
                    }
                });
            }
        }
        callback(flag, event);
    };

    useEffect(() => {
        // element click evet maybe bubbling here sometimes, that will cause some error, so set capture is true.
        document.addEventListener('click', handleClickOutside, { capture: false });
        return () => {
            document.removeEventListener('click', handleClickOutside, { capture: false });
        };
    }, []);

    return rootEleRef;
};

export { useClickOutside };
