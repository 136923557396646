import serviceContainer from '../../container';
import { ZpnsStatus } from '../zpns-defs';
import type PresenceManager from './PresenceManager';

export const waitUntilZpnsConnected = (delay: number) => {
    return (_target: PresenceManager, _key: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;

        // should not be arrow function
        descriptor.value = function (...args: any[]) {
            const zpns = serviceContainer.getZpns();
            if (zpns.isConnected()) {
                originalMethod.apply(this, args);
            } else {
                const eventBus = serviceContainer.getEventBus();
                eventBus.zpns.connectStatus.subscribe((status: ZpnsStatus, { id }) => {
                    if (status === ZpnsStatus.Connected) {
                        eventBus.zpns.connectStatus.unsubscribe(id);
                        setTimeout(() => {
                            originalMethod.apply(this, args);
                        }, delay);
                    }
                });
            }
        };

        return descriptor;
    };
};
