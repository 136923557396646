export enum InviteContactModalType {
    DEFAULT = 'DEFAULT',
    LOADDING = 'LOADDING',
    INVITE_SEND_SUCCESS = 'INVITE_SEND_SUCCESS',
    DELETE_CONTACT = 'DELETE_CONTACT',
    ALREADY_CONTACT = 'ALREADY_CONTACT',
}

export interface InviteContactData {
    jid?: string;
    avatar?: string;
    name?: string;
    modalType?: InviteContactModalType;
}
