import { useEffect, useRef, useCallback } from 'react';
import useLatest from './useLatest';

// unit: ms
const useTimeout = (callback: (clear: () => void) => void, delay = 1000) => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const callbackRef = useLatest(callback);

    const clear = useCallback(() => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
    }, []);

    const set = useCallback(() => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            callbackRef.current(clear);
        }, delay);
    }, [delay]);

    useEffect(() => {
        set();
        return clear;
    }, [delay]);

    return clear;
};

export default useTimeout;
