import { isFunction } from 'lodash-es';
import { IDispatchReturn, ISubscribeId, ITask, ITaskCallbackFunc, ITaskOptions, TaskType } from './types';
import { DEFAULT_OPTION, getId, isTestOrDevEnv } from './utils';

interface IProps {
    name: string;
    abortOnError?: boolean;
}

export default class Hook<Event> {
    protected name: string;
    protected tasks: Array<ITask<Event>>;
    protected isRunning: boolean;
    protected abortOnError: boolean;

    constructor({ name, abortOnError }: IProps) {
        this.name = name;
        this.abortOnError = abortOnError || false;
        this.tasks = [];
        this.isRunning = false;
    }

    subscribe(callback: ITaskCallbackFunc<Event>, options?: ITaskOptions) {
        return this.addTask('sync', callback, options);
    }

    subscribePromise(callback: ITaskCallbackFunc<Event>, options?: ITaskOptions) {
        return this.addTask('promise', callback, options);
    }

    unsubscribe(idOrFunc: ISubscribeId | ITaskCallbackFunc<Event>) {
        const index = this.tasks.findIndex((task) => {
            if (isFunction(idOrFunc)) {
                return task.fn === idOrFunc;
            } else {
                return task.id === idOrFunc;
            }
        });

        if (index > -1) {
            this.tasks.splice(index, 1);
        }
    }

    protected prepareDispatch() {
        if (this.isRunning) {
            let errMsg = `${this.name} is dispatching`;
            if (isTestOrDevEnv()) {
                errMsg = `you are dispatching an event of ${this.name} while it's running. this may be an infinite loop`;
            }
            throw Error(errMsg);
        }

        // we get a copy of all the tasks
        const tasks = this.tasks.slice();

        // high priority, comes first
        tasks.sort((left, right) => {
            return right.options.priority - left.options.priority;
        });

        return tasks;
    }

    clear() {
        this.tasks = [];
    }

    protected addTask(type: TaskType, callback: ITaskCallbackFunc<Event>, options: ITaskOptions) {
        const id = getId();
        const _options = Object.assign({}, DEFAULT_OPTION, options);

        const task = {
            id,
            type,
            fn: callback,
            options: _options,
        };

        if (isTestOrDevEnv()) {
            if (this.tasks.find((_) => _.fn === callback)) {
                throw Error(`you have subscribed ${this.name} with this callback already, ${callback}`);
            }
        }

        this.tasks.push(task);
        return id;
    }

    protected maybeWarn(ret: any, task: ITask<Event>) {
        const { type, fn } = task;

        if (!isTestOrDevEnv()) {
            return;
        }

        if (ret instanceof Promise) {
            if (type === 'sync') {
                throw Error(`${fn} should NOT return a Promise`);
            }
        } else {
            if (type === 'promise') {
                throw Error(`${fn} should return a Promise`);
            }
        }
    }

    dispatchEvent(_event: Event): Promise<IDispatchReturn> {
        throw Error('sub class must provide this implementation: dispatchEvent');
    }

    protected async callSeries(_tasks: Array<ITask<Event>>, _event: Event): Promise<IDispatchReturn> {
        throw Error('sub class must provide this implementation: callSeries');
    }

    protected async callParallel(_tasks: Array<ITask<Event>>, _event: Event): Promise<IDispatchReturn> {
        throw Error('sub class must provide this implementation: callParallel');
    }
}
