import React from 'react';
import { Modal } from 'Modal';
import './index.scss';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import Button from '../../../components/Button/Button';
import { OTP_CHANGE, OTP_CHANGE_PASSWORD, OTP_DO_NOT_CHANGE, OTP_ENABLE_TWO_FACTOR_AUTH } from '../../../resource';

type IResetPasswordModal = {
    data: {
        resetPasswordURL: string;
    };
};

const ResetPasswordModal = ({ data }: IResetPasswordModal) => {
    const dispatch = useAppDispatch();

    const onDoNotChangePassword = () => {
        // hide this modal
        dispatch(setModal({ name: 'resetPassword', data: null }));
    };

    const onChangePassword = () => {
        window.open(data.resetPasswordURL, '_blank');
        dispatch(setModal({ name: 'resetPassword', data: null }));
    };

    return (
        <Modal
            isOpen={true}
            needBackdrop={true}
            isDraggable={false}
            aria={{ labelledby: 'zm-change-your-password' }}
            centered={true}
        >
            <div className="modal-reset">
                <p className="change-your-password" id="zm-change-your-password">
                    {OTP_CHANGE_PASSWORD}
                </p>
                <p>{OTP_ENABLE_TWO_FACTOR_AUTH}</p>
                <div className="button-group">
                    <Button onClick={onDoNotChangePassword} type={Button.Secondary}>
                        {OTP_DO_NOT_CHANGE}
                    </Button>
                    <Button onClick={onChangePassword} type={Button.Primary} autoFocus>
                        {OTP_CHANGE}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ResetPasswordModal;
