import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs } from '../../../components/Tabs';
import { OVERALL, Phone_Text } from '../../../resource';
import { RootState } from '../../../store';
import './statistics.scss';

interface IStatistics {
    [key: string]: any;
}

const Statistics = ({ isPhoneModuleLoaded }: IStatistics) => {
    const [currentActiveTab, setCurrentActiveTab] = useState('phone');
    const [StatsPhone, setStatsPhone] = useState(null);
    const { TabPane } = Tabs;
    const tabPanes = [
        {
            text: OVERALL,
            key: 'Overall',
            content: <div></div>,
        },
        isPhoneModuleLoaded
            ? {
                  text: Phone_Text,
                  key: 'phone',
                  content: StatsPhone,
              }
            : null,
    ];

    const onTabChange = (key: string) => setCurrentActiveTab(key);

    const TabBarNode = ({ text }: any) => {
        return <div className={classNames('phone-panel__tab-bar')}>{text}</div>;
    };

    useEffect(() => {
        isPhoneModuleLoaded &&
            import(/* webpackChunkName: "phone" */ '../../Phone').then(({ StatsPhone }) => {
                setStatsPhone(<StatsPhone />);
            });
    }, [isPhoneModuleLoaded]);

    return (
        <div className="settings-content__statistics">
            <Tabs className="phone-panel__tabs" activeKey={currentActiveTab} onChange={onTabChange}>
                {tabPanes
                    .filter((i) => !!i)
                    .map((tabPane) => {
                        return (
                            <TabPane
                                key={tabPane.key}
                                className="statistics-panel__phone"
                                tab={<TabBarNode text={tabPane.text} />}
                            >
                                {tabPane.content}
                            </TabPane>
                        );
                    })}
            </Tabs>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const {
        common: { isPhoneModuleLoaded },
    } = state;
    return {
        isPhoneModuleLoaded,
    };
};

export default connect(mapStateToProps)(Statistics);
