interface PathInfo {
    count: number;
    stay: number;
}

interface IProps {
    reportLog: Function;
}

export default class NavigationMetrics {
    private lastTimeStamp = 0;
    private currentPathname = '';
    private data: { [key: string]: PathInfo } = {};
    private reportLog: Function = null;

    constructor({ reportLog }: IProps) {
        document.addEventListener('visibilitychange', this.onVisibilityChange);
        window.addEventListener('beforeunload', this.onBeforeUnload);
        this.reportLog = reportLog;
    }

    onVisibilityChange = () => {
        if (document.hidden) {
            this.updateWhenDestroy();
        }
    };

    onBeforeUnload = () => {
        this.updateWhenDestroy();
        this.reportLog(this.getMetrics());
    };

    getMetrics() {
        return this.transformLog();
    }

    switchRoute(pathname: string) {
        if (pathname === this.currentPathname) {
            return;
        }
        this.updatePathInfo(pathname);
    }

    transformLog() {
        return Object.keys(this.data).map((key) => {
            return {
                name: key,
                duration: this.data[key].stay,
                count: this.data[key].count,
            };
        });
    }

    private updatePathInfo(pathname: string) {
        let pathInfo = this.data[pathname];

        if (!pathInfo) {
            pathInfo = {
                count: 0,
                stay: 0,
            };
            this.data[pathname] = pathInfo;
        }

        // update {}.count
        pathInfo.count++;

        const now = Date.now();

        // update {}.stay
        if (this.currentPathname) {
            this.data[this.currentPathname].stay += now - this.lastTimeStamp;
        }

        // update util state
        this.lastTimeStamp = now;
        this.currentPathname = pathname;
    }

    updateWhenDestroy() {
        const currentInfo = this.data[this.currentPathname];
        if (!this.currentPathname || !currentInfo) {
            return;
        }

        const now = Date.now();

        currentInfo.stay += now - this.lastTimeStamp;

        this.lastTimeStamp = now;
    }
}
