import { LogFilter } from '@zoom/laplace/build/interface';
import {
    checkLaplace,
    getLaplace,
    getLaplaceLogPrecedingTags,
    getLaplaceSync,
    getLaplaceTrackingId,
    reportUrl,
    shouldReportPerformance,
    toLogConfig,
} from './laplace';
import { stringify, timestampToTime } from './utils';
export { default as makeLoggerWithEncryption } from './loggerWithEncryption';
export { default as makeLoggerWithoutCache } from './loggerWithOutCache';

export const reportToGlobalTracing = (config: any) => {
    if (!reportUrl) {
        return Promise.resolve();
    }
    const { filter } = config || {};

    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.reportByHttps(filter, {
            gzip: false,
            encrypt: false,
            xhrOptions: {
                url: reportUrl,
            },
            additionalContext: {
                trackingId: getLaplaceTrackingId(),
            },
        });
    });
};

export const reportToGlobalTracingByBeacon = (data: any, tag: string) => {
    if (navigator.sendBeacon) {
        navigator.sendBeacon(
            reportUrl,
            stringify({
                messages: data
                    .map((dataItem: any) =>
                        stringify({
                            logLevel: 'log',
                            time: timestampToTime(Date.now()),
                            tags: [...getLaplaceLogPrecedingTags(), tag],
                            message: dataItem,
                            trackingId: getLaplaceTrackingId(),
                            ...toLogConfig,
                        }),
                    )
                    .join('\n'),
                meta: {
                    version: '000001',
                    flag: '0',
                },
            }),
        );
    }
};

export const reportPerformance = (config: any) => {
    if (shouldReportPerformance) {
        return reportToGlobalTracing(config);
    }
    return Promise.resolve();
};

export const reportWithNotificationToGlobalTracing = (config: any, notification: any) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace
            .log('', [getLaplaceTrackingId(), 'REPORT_NOTIFICATION'], {
                IM_NOTIFICATION: `${notification}\nTrackingId: ${getLaplaceTrackingId()}`,
            })
            .then(() => {
                return reportToGlobalTracing({
                    filter: 'REPORT_NOTIFICATION',
                    // userId: config.userId,
                    // confID: config.confID,
                    // conID: config.conID,
                    // svcUrl: config.svcUrl,
                    res: config.res,
                });
            })
            .then(() => {
                reportToGlobalTracing(config);
            });
    });
};

export const getLogs = (filter: any) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.getLogs(filter);
    });
};

export const getLogsSync = (filter: LogFilter) => {
    if (!checkLaplace()) {
        return null;
    }
    const laplace = getLaplaceSync();
    return laplace.getLogs(filter);
};

export const doNotUseDirtyGetStore = () => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.doNotUseDirtyGetStore();
    });
};
