/**
 * Signal can not be chained like Promise!!!!!!
 * maybe: we could change _promise to new promise afer Signal.(then|catch|finall)???
 */

import { isFunction } from 'lodash-es';

interface IData {
    checker?: (value: any, signal: Signal) => boolean;
    timeout?: number;
    state?: any;
}

const TIMEOUT = 10 * 1000;

class Signal<T = any> {
    static TimeOutCode = 1;
    static DuplicateTagCode = 2;
    private static signals: Map<string, Signal> = new Map();

    static create(tag: string, data?: IData) {
        const signal = new Signal(tag, data);
        this.set(tag, signal);

        return signal;
    }

    static get(tag: string) {
        return this.signals.get(tag);
    }

    static set(tag: string, signal: Signal) {
        if (this.signals.has(tag)) {
            throw {
                errorCode: Signal.DuplicateTagCode,
                errorMsg: `duplicate tag for Signal - ${tag}`,
            };
        }
        return this.signals.set(tag, signal);
    }

    static has(tag: string) {
        return this.signals.has(tag);
    }

    static delete(tag: string) {
        if (!this.signals.has(tag)) {
            return;
        }
        this.signals.delete(tag);
    }

    static resolve(tag: string, value: any) {
        if (!this.signals.has(tag)) {
            return;
        }

        const signal = this.signals.get(tag);

        return signal.resolve(value);
    }

    static reject(tag: string, err: any) {
        if (!this.signals.has(tag)) {
            return;
        }

        const signal = this.signals.get(tag);

        return signal.reject(err);
    }

    readonly tag: string;
    private _promise: Promise<T> = null;
    private _resolve: any;
    private _reject: any;
    private _timer: number;
    private _status: 'pending' | 'resolved' | 'rejected';

    readonly timeout: number;
    readonly checker: IData['checker'];
    state: any;
    timeoutHandler: {
        timeout(s: Signal): void;
        others(e: any): void;
    } = null;

    constructor(tag: string, data?: IData) {
        this.tag = tag;
        this._promise = new Promise<T>((resolve, reject) => {
            this._status = 'pending';
            this._reject = reject;
            this._resolve = resolve;
        }).catch((err) => {
            const isTimeout = err?.errorCode === Signal.TimeOutCode;
            if (isTimeout && isFunction(this.timeoutHandler?.timeout)) {
                return this.timeoutHandler.timeout(this) as T;
            } else if (!isTimeout && isFunction(this.timeoutHandler?.others)) {
                return this.timeoutHandler.others(err) as T;
            } else {
                throw err;
            }
        });

        this.state = data?.state;
        this.checker = data?.checker;
        this.timeout = data?.timeout || TIMEOUT;

        this._timer = window.setTimeout(() => {
            this.reject({
                errorCode: Signal.TimeOutCode,
                errorMsg: `Signal ${this.tag} timed out after ${this.timeout} ms`,
            });
        }, this.timeout);
    }

    then<TResult1 = T, TResult2 = never>(
        onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    ): Promise<TResult1 | TResult2> {
        return this._promise.then(onfulfilled, onrejected);
    }

    catch<TResult = never>(
        onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null,
    ): Promise<T | TResult> {
        return this._promise.catch(onrejected);
    }

    finally(...args: any[]) {
        return this._promise.finally(...args);
    }

    catchTimeout(timeoutHandler: (signal: Signal) => void, otherErrorHander?: (err: any) => void) {
        this.timeoutHandler = {
            timeout: timeoutHandler,
            others: otherErrorHander,
        };
        return this._promise;
    }

    toString() {
        return this._promise.toString();
    }

    resolve(value: any) {
        if (isFunction(this.checker)) {
            const checked = this.checker(value, this);
            if (checked) {
                this._settle('resolved');
                return this._resolve(value);
            } else {
                return;
            }
        } else {
            this._settle('resolved');
            return this._resolve(value);
        }
    }

    reject(err: any) {
        this._settle('rejected');
        return this._reject(err);
    }

    getPromise() {
        // in order to chain you callbacks (then, catch);
        return this._promise;
    }

    get isPending() {
        return this._status === 'pending';
    }

    get isResolved() {
        return this._status === 'resolved';
    }

    get isRejected() {
        return this._status === 'rejected';
    }

    private _settle(status: 'resolved' | 'rejected') {
        this._status = status;
        window.clearTimeout(this._timer);

        Signal.delete(this.tag);
    }
}

if (process.env.NODE_ENV === 'development') {
    (window as any).signal = Signal;
}

export default Signal;
