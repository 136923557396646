import { selectAllContacts } from './contact-selector';
import type { RootState } from '../../../store';

export const searchContactsByKeyLocally = (key: string, state: RootState) => {
    const contacts = selectAllContacts(state);
    const result = contacts.filter((con) => {
        const { firstName = '', lastName = '', displayName = '', email = '' } = con;
        const emailName = email.split('@')[0];
        const candidates = [`${firstName} ${lastName}`, displayName, emailName];
        return candidates.some((cand) => cand.toLowerCase().indexOf(key.toLowerCase()) >= 0);
    });
    return result;
};
