import { renderApp } from './App';
import { renderLoading } from './AppLoading';
import { isInStandaloneMode } from './utils';

const originalUrl = window.location.href;
const localDomain = window.location.hostname;

const getDomain = (name: string) => {
    const hostname = window.location.hostname;
    if (/zoomdev/.test(hostname)) {
        return `${name}.zoomdev.us`;
    } else {
        return `${name}.zoom.us`;
    }
};

// if window.PwaConfig.domain is empty, it defaults to be 'https://pwa.zoom.us'
// NOTICE, window.PwaConfig.domain contains https://
const getWebConfigurePwaDomain = () => {
    return window.PwaConfig.domain || getDomain('pwa');
};

const isAppDomainEnabled = /app/.test(getWebConfigurePwaDomain());
const isPwaDomain = /pwa/.test(localDomain);
const isAppDomain = /app/.test(localDomain);

const isGovDevZipow = /devgov\.zipow\.com/;
const isGovEnv = /zoomgov(dev)?/.test(localDomain);
const isDevepEnv = /devep/.test(localDomain);
const isDevDevEnv = /dev\.zoom(dev)?/.test(localDomain);
const isDevIntEnv = /dev-integration/.test(localDomain);

const redirectToDomain = (domain: string, params?: Record<string, string | number>) => {
    try {
        const url = new URL(originalUrl);
        url.hostname = domain;
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                if (!url.searchParams.has(key)) {
                    url.searchParams.set(key, String(value));
                }
            });
        }
        window.location.href = url.href;
    } catch (e) {
        console.error(e);
    }
};

const redirectToApp = (params: Record<string, string | number> = null) => {
    redirectToDomain(getDomain('app'), params);
};

const redirectToPwa = (params: Record<string, string | number> = null) => {
    redirectToDomain(getDomain('pwa'), params);
};

if (isGovEnv || isDevepEnv || isDevIntEnv || isDevDevEnv || isGovDevZipow) {
    renderApp();
} else {
    if (isInStandaloneMode()) {
        if (isAppDomainEnabled) {
            if (isAppDomain) {
                renderApp();
            } else {
                renderLoading({
                    redirectToApp: () => {
                        redirectToApp({ rfd: 1 });
                    },
                });
            }
        } else {
            if (isPwaDomain) {
                renderApp();
            } else {
                redirectToPwa({ rfd: 1 });
            }
        }
    } else {
        if (isAppDomainEnabled) {
            if (isAppDomain) {
                renderApp();
            } else {
                redirectToApp();
            }
        } else {
            if (isPwaDomain) {
                renderApp();
            } else {
                redirectToPwa();
            }
        }
    }
}
