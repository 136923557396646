import { isFunction } from 'lodash-es';

export const createMessagHandlerDecorator = () => {
    // 1 to 1 correspondence
    const handlerMap = new Map();

    const onReceiveMessage = (_target: any, _key: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        descriptor.value = function onMessage(...args: Array<any>) {
            const handlerKey = originalMethod?.apply(this, args);
            const handler = handlerMap.get(handlerKey);
            if (!isFunction(handler)) {
                if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
                    throw Error(`no handler registered for ${handlerKey}`);
                } else {
                    return;
                }
            }

            // apply to 'this', not 'target'
            // for method decorator target is constructor.prototype, not constructor instance itself
            return handler.apply(this, args);
        };
    };

    // class method decorator
    const handleMessage = (handlerKey: string) => (_target: any, _key: string, descriptor: PropertyDescriptor) => {
        if (handlerMap.has(handlerKey)) {
            if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
                throw Error(`duplicate hanlder for ${handlerKey}`);
            } else {
                return;
            }
        }

        handlerMap.set(handlerKey, descriptor.value);
    };

    return {
        onReceiveMessage,
        handleMessage,
    };
};
