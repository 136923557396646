import { AxiosResponse } from 'axios';
import { IWebResponse } from '../../../services/http/http';
import {
    IUCSTokenInfo,
    IUCSGroupsCompact,
    IGetUCSGroupMembersProps,
    IGetUCSGroupMembersProps_UserInfo,
    IGetUCSGroupMembersResponse,
} from './ucs-types';
import { GET_UCS_TOKEN_PATH, UcsHttpClient } from './UcsHttpClient';
const GET_UCS_GROUP_MEMBERS_PATH = '/api/v3/ucs/diff'; // v1 is for json; v2 is for protobuf; v3 is made only for pwa (json format);

const ucsHttpClient = new UcsHttpClient();
const httpRequest = ucsHttpClient.getAxiosInstance({});

function callUcsPwaGroupApi(uid: string, needGroups: false): Promise<IUCSTokenInfo>;
function callUcsPwaGroupApi(uid: string, needGroups: true): Promise<IUCSGroupsCompact & IUCSTokenInfo>;

// this calls to Web server, same domain as current page
function callUcsPwaGroupApi(uid: string, needGroups?: boolean) {
    const params = { uid, needGroups: false };
    if (needGroups) {
        params.needGroups = true;
    }
    const config = {
        params,
        withCredentials: true,
        headers: {
            'Access-Control-Max-Age': 2,
        },
        needToken: false,
    };
    return httpRequest.post(GET_UCS_TOKEN_PATH, null, config).then((res: AxiosResponse<IWebResponse<any>>) => {
        const { errorCode, errorMessage, result } = res.data;
        if (errorCode !== 0) {
            throw errorMessage;
        }
        return result;
    });
}

export const refreshUcsToken = (uid: string) => {
    return callUcsPwaGroupApi(uid, false);
};

export const getUcsGroups = (uid: string) => {
    return callUcsPwaGroupApi(uid, true).then((result) => {
        const { ucsToken, ucsDomain } = result;
        ucsHttpClient.setToken({ ucsToken, ucsDomain }); // set the initial ucs token
        ucsHttpClient.setUserId(uid); // set uid for refreshing token  later
        return result;
    });
};

// this calls to UCS server, not in the same domain as current page.
export const getUcsGroupMembers = (
    groupInfo: IGetUCSGroupMembersProps,
    userInfo: IGetUCSGroupMembersProps_UserInfo,
): Promise<IGetUCSGroupMembersResponse> => {
    const { ucsDomain, ucsToken } = ucsHttpClient.getToken(); // we should manage the ucs token info
    const { accountId, userId } = userInfo;

    if (!ucsDomain || !ucsToken || !accountId || !userId) {
        return Promise.reject(
            `ucs member fetch - incomplete params: <${ucsDomain ? '1' : 'domain'}>-<${ucsToken ? '1' : 'token'}>-<${
                accountId ? 1 : 'aid'
            }>-<${userId ? '1' : 'uid'}>`,
        );
    }

    const url = `https://${ucsDomain}${GET_UCS_GROUP_MEMBERS_PATH}`;

    const config = {
        headers: {
            'x-zm-account-id': accountId,
            'x-zm-user-id': userId,
        },
        needToken: true,
    };

    return httpRequest.post(url, groupInfo, config).then((res: AxiosResponse<IGetUCSGroupMembersResponse>) => {
        const { result } = res.data;
        // refer to https://zoomvideo.atlassian.net/wiki/spaces/ABS/pages/443647194/UCS+Client+API
        if (result === 1) {
            // should discard current contacts data and refetch the whole data
            // but i think this is not suitable for us
            // because native clients fetch all the data with one transaction, if this transaction is interrupted, they have to refetch from scratch
            // but we now are lazily fetching data page by page
            throw result;
        }
        return res.data;
    });
};
