import React, { ComponentType, Fragment, createRef, useRef, useState } from 'react';
import '../Header/Header.scss';
import { A11Y_FOCUS_WALKER_POLICY, DOM_KEY_CODE, headerTabType } from '../../../utils/constants';
import { makeA11yListInfoInjector } from '../../../utils';
import classnames from 'classnames';
import { ChatPopover } from '../../Chat';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { OverlayTrigger } from 'react-bootstrap';
import { Button } from '@zoom/zoom-react-ui';
import { IconPinFill, IconPinUnpinFill } from '@zoom/icons-react';
import { ADD_PINNED_TEXT, DRAG_TO_MORE_TEXT, REMOVE_PINNED_TEXT } from '../../../resource';
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import { useAppSelector } from '../../Phone/types';
import { withFocusWalker } from '../../../hoc/withFocusWalker';
import { keyBoardEventHandler } from '../../../utils/keyboard-event';
import classNames from 'classnames';

type Props = {
    hideMorePanel(): void;
    moreTabs: any;
    lastTab: number;
    initialTab: number;
    isDragging: boolean;
    organizableTabList: any;
    handleOnClick(tag: string): void;
    handleMorePinUnpin(tag: string, pinned: boolean, e: React.MouseEvent<HTMLElement>): void;
    selectedTab(tag: string): boolean;
    style: React.CSSProperties;
    moreButtonRef: React.MutableRefObject<HTMLButtonElement>;
    onDragStart(e: React.DragEvent<HTMLElement>, tag: string): void;
    onDragEnter(e: React.DragEvent<HTMLElement>, tag: string): void;
    onDragEnd(): void;
    handleReorganize(e: React.DragEvent<HTMLElement>): void;
    handleMove(e: React.DragEvent<HTMLElement>): void;
    phoneUnreadCount: number;
};

const MORE_TAB_HEIGHT = 30;
const SEPARATOR_HEIGHT = 10;

const HeaderMoreTab = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        hideMorePanel,
        moreTabs,
        lastTab,
        initialTab,
        isDragging,
        organizableTabList,
        handleOnClick,
        handleMorePinUnpin,
        selectedTab,
        style,
        moreButtonRef,
        onDragEnter,
        onDragStart,
        onDragEnd,
        handleReorganize,
        handleMove,
        phoneUnreadCount,
    } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const { dataALGenerator } = makeA11yListInfoInjector('0', containerRef);

    const chatUnread = useAppSelector((state) => state.chat.unreadCount);
    const [showMorePinUnpin, setShowMorePinUnpin] = useState('');

    const availableTabsLength = organizableTabList.length;

    const separator = moreTabs.findLastIndex((tab: any) => tab.pinned === true);
    const hasUnpinnedTab = moreTabs.findIndex((tab: any) => tab.pinned === false);

    const morePinUnpinRef = useClickOutside({
        callback: (flag: boolean) => {
            if (flag) {
                setShowMorePinUnpin('');
            }
        },
        excludeRefs: [],
    });

    const moreTabsHeight = moreTabs.length * MORE_TAB_HEIGHT + (separator > -1 ? SEPARATOR_HEIGHT : 0);
    const moreTabsHeightDrag = (moreTabs.length + 1) * MORE_TAB_HEIGHT + (separator > -1 ? SEPARATOR_HEIGHT : 0);

    const morePanelRef = useClickOutside({
        callback: (flag: boolean) => {
            if (flag) {
                hideMorePanel();
            }
        },
        excludeRefs: [moreButtonRef, ref],
    });

    const tabRefs = moreTabs.reduce((current: any, { tag }: any) => {
        current[tag] = createRef<HTMLElement>();
        return current;
    }, {});

    const onRightClick = (e: React.MouseEvent<HTMLElement>, tag: string) => {
        e.preventDefault();
        if (e.nativeEvent.button === 2 && showMorePinUnpin === '') {
            setShowMorePinUnpin(tag);
        } else {
            setShowMorePinUnpin('');
        }
    };
    const onKeyDownMoreIcon = (e: React.KeyboardEvent) => {
        if (e.keyCode === DOM_KEY_CODE.ESC) {
            hideMorePanel();
        }
    };

    // Bootstrap sending a warning if I dont spread these props
    const renderPinUnpinButton = (
        { placement, scheduleUpdate, arrowProps, outOfBoundaries, show, hasDoneInitialMeasure, ...props }: any,
        tag: string,
        pinned: boolean,
    ) => (
        <div ref={morePinUnpinRef} className="home-header__pinUnpin-tab" {...props}>
            <Button role="tab" onClick={(e) => handleMorePinUnpin(tag, pinned, e)}>
                {pinned ? <IconPinUnpinFill /> : <IconPinFill />}
                <span> {pinned ? REMOVE_PINNED_TEXT : ADD_PINNED_TEXT}</span>
            </Button>
        </div>
    );

    const allTabs = moreTabs.map((tabData: any, index: number, array: any) => {
        const { text, tag, banner, icon, pinned } = tabData;
        const isSelected = selectedTab(tag);
        const Icon = isSelected ? icon[1] : icon[0];
        const actualIndex = availableTabsLength - moreTabs.length + index;

        const isCurrDragged = isDragging && initialTab === actualIndex;

        const topFromPinned =
            initialTab > actualIndex &&
            actualIndex >= lastTab &&
            lastTab !== -1 &&
            initialTab + 1 <= availableTabsLength - moreTabs.length;
        const bottomFromPinned =
            lastTab < actualIndex &&
            actualIndex <= availableTabsLength &&
            lastTab !== -1 &&
            initialTab + 1 <= availableTabsLength - moreTabs.length;

        const isInitialInMore = moreTabs.includes(organizableTabList[initialTab]);
        const top = isInitialInMore && initialTab < actualIndex && actualIndex <= lastTab;

        const bottom = isInitialInMore && initialTab > actualIndex && actualIndex >= lastTab;

        const unreadCount =
            tag === headerTabType.chat
                ? `${chatUnread.shown} unread messages`
                : tag === headerTabType.phone
                ? `${phoneUnreadCount} unread messages`
                : '';

        let itemView = (
            <button
                draggable
                onDragStart={(e) => {
                    onDragStart(e, tag);
                    setShowMorePinUnpin('');
                }}
                onDragEnter={(e) => onDragEnter(e, tag)}
                onDrop={(e) => handleReorganize(e)}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDragEnd={onDragEnd}
                feature-type={tag}
                key={`${tag}-${index}`}
                ref={tabRefs[tag]}
                id={`more-tab-${tag}`}
                data-testid={`${tag} ${index}-more`}
                tabIndex={0}
                role="tab"
                aria-controls={`more-tabPanel-${tag}`}
                aria-selected={isSelected}
                className={classnames(
                    'more-tab-block',
                    `more-tab-${tag}`,
                    { isDragging: isCurrDragged },
                    { stop: isDragging && !isCurrDragged },
                    { orderBarTop: topFromPinned || top },
                    { orderBarBottom: bottomFromPinned || bottom },
                )}
                data-a-l={dataALGenerator()}
                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                onClick={() => handleOnClick(tag)}
                onContextMenu={(e) => onRightClick(e, tag)}
                aria-label={`${text}, ${unreadCount}`}
            >
                <OverlayTrigger
                    key={`${tag}-more-options`}
                    overlay={(props: OverlayTriggerRenderProps) => renderPinUnpinButton(props, tag, pinned)}
                    placement="bottom"
                    show={tag === showMorePinUnpin}
                >
                    <div className="more-tab-item-wrapper">
                        <span className="more-tab-icon">
                            <Icon />
                        </span>
                        <span className="more-banner">{banner}</span>

                        <span className="header-text">{text}</span>
                    </div>
                </OverlayTrigger>
            </button>
        );

        if (separator === index && hasUnpinnedTab !== -1) {
            itemView = (
                <Fragment key="separator">
                    {itemView}
                    <hr
                        className={classNames(
                            'separator',
                            {
                                moveUp:
                                    topFromPinned ||
                                    top ||
                                    (isDragging && initialTab === actualIndex && initialTab < lastTab),
                            },
                            { moveDown: bottomFromPinned || bottom },
                        )}
                    ></hr>
                </Fragment>
            );
        }

        if (tag === headerTabType.chat)
            itemView = (
                <ChatPopover key={`${tag}-popover-more`} tabsAmount={array.length}>
                    {itemView}
                </ChatPopover>
            );
        return itemView;
    });

    const renderMoveHereText = () => {
        return allTabs.length === 0 ? (
            <div
                className={classNames('empty-more-menu', {
                    fadeOutText: lastTab !== -1 && initialTab < availableTabsLength - moreTabs.length,
                })}
                aria-label="empty more menu"
                onDrop={(e) => handleMove(e)}
                onDragEnter={(e) => onDragEnter(e, 'more')}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {DRAG_TO_MORE_TEXT}
            </div>
        ) : (
            ''
        );
    };

    return (
        <div
            ref={ref}
            className="home-header__more-tab"
            id="homeHeaderMoreTab"
            role="tablist"
            style={style}
            onKeyDown={onKeyDownMoreIcon}
        >
            <div ref={morePanelRef}>
                <div ref={containerRef}>
                    <div
                        className="tabs-list"
                        onDrop={(e) => {
                            if (separator === -1 && organizableTabList[lastTab].pinned === true) {
                                handleMove(e);
                            } else {
                                handleReorganize(e);
                            }
                        }}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        style={
                            lastTab !== -1 && initialTab < availableTabsLength - moreTabs.length
                                ? { height: `${moreTabsHeightDrag}px` }
                                : { height: `${moreTabsHeight}px` }
                        }
                    >
                        {allTabs}
                    </div>
                    {renderMoveHereText()}
                </div>
            </div>
        </div>
    );
});

export default withFocusWalker(
    HeaderMoreTab as unknown as ComponentType<Record<string, unknown>>,
    'homeHeaderMoreTab',
    () => true,
    ['.home-header__more-tab'],
    keyBoardEventHandler,
);
