import { AppStore, RootState } from '../../store';
import { PWAMeetingEvent } from '../Meeting/meeting-agent-msg-type';
import { EAudioOccupyStatus, EClientZmCallStatusType } from './types';

export const getZoomMeetingStatus = (store: AppStore | RootState) => {
    const state = 'getState' in store ? store.getState() : store;
    const {
        meeting: { showWebclient, meetingInfo },
    } = state;

    let meetingStatus = EClientZmCallStatusType.MeetingStatus_Idle;
    if (!showWebclient || (showWebclient && !meetingInfo)) {
        meetingStatus = EClientZmCallStatusType.MeetingStatus_Idle;
    }

    if (showWebclient && meetingInfo && meetingInfo.meetingStatus === PWAMeetingEvent.JOINING) {
        meetingStatus = EClientZmCallStatusType.MeetingStatus_Connecting;
    }

    if (showWebclient && meetingInfo && meetingInfo.meetingStatus === PWAMeetingEvent.SUCCESS) {
        meetingStatus = EClientZmCallStatusType.MeetingStatus_OnCall;
    }
    return meetingStatus;
};

export const getZoomPhoneStatus = (store: AppStore | RootState) => {
    const state = 'getState' in store ? store.getState() : store;
    const { phone } = state;

    let phoneStatus = EClientZmCallStatusType.PhoneStatus_Off;
    if (phone) {
        const { currentSessionId } = phone;
        if (Boolean(currentSessionId)) {
            phoneStatus = EClientZmCallStatusType.PhoneStatus_On;
        } else {
            phoneStatus = EClientZmCallStatusType.PhoneStatus_Off;
        }
    }
    return phoneStatus;
};

export const isAudiOccupiedByZoom = (store: AppStore) => {
    const {
        meeting: { showWebclient, meetingInfo, isMeetingAudioConnected },
        phone,
    } = store.getState();

    let status = EAudioOccupyStatus.NA;

    if (showWebclient && meetingInfo && isMeetingAudioConnected) {
        status = EAudioOccupyStatus.Meeting;
    } else {
        if (phone && phone.currentSessionId) {
            status = EAudioOccupyStatus.Phone;
        }
    }
    return status;
};
