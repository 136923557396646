import { URLSearchParams } from 'url';
import { isDuringMeetingSelector } from '../features/Meeting/redux/meeting-selector';
import serviceContainer from '../services/container';
import { RootState } from '../store';
import { setModal } from '../store/modal/modal-store';
import { paramsToObject } from '../utils';

// listen for launch event
if ('launchQueue' in window && 'targetURL' in window?.LaunchParams.prototype) {
    window.launchQueue.setConsumer((launchParams) => {
        const meetingAgent = serviceContainer.getMeetingAgent();
        const store = serviceContainer.getReduxStore();

        if (launchParams.targetURL) {
            console.log(launchParams.targetURL, 'launchParams.targetURL');
            let params: URLSearchParams;
            try {
                params = new URL(launchParams.targetURL).searchParams;
            } catch (_e) {
                return;
            }
            const meetingNo = params.get('mn');
            params.delete('mn');

            if (!meetingNo || meetingNo.length < 9 || meetingNo.length > 11) {
                return;
            }

            if (params.has('pwd')) {
                params.set('password', params.get('pwd'));
                params.delete('pwd');
            }

            const extraParams = paramsToObject(params.entries());
            const isDuringMeeting = isDuringMeetingSelector(store.getState() as RootState);

            if (isDuringMeeting) {
                store.dispatch(
                    setModal({
                        name: 'ifJoinMeeting',
                        data: {
                            meetingNo,
                            extraParams,
                        },
                    }),
                );
            } else {
                // 1. meetingAgent is undefined when page reload
                // 2. join meeting logic will be handled by `maybeLinkCaptureJoinMeeting()`
                meetingAgent?.endCurrentAndJoinThisMeeting({ meetingNo, ...extraParams });
            }
        }
    });
} else if (process.env.NODE_ENV !== 'test') {
    console.warn("your browser doesn't support launch handler");
}
