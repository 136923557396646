import axios, { Method, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IRequestConfig extends AxiosRequestConfig {
    needToken?: boolean;
    hasTried?: boolean;
}

export interface IProps {
    tokenMethod?: Method;
    tokenUrl: string;
    initialToken?: any;
}

export default class HttpClient {
    name = '';
    token: any = null;
    tokenMethod: Method = 'post';
    tokenUrl = '';

    private axiosInstance: AxiosInstance = null;
    private fetchTokenPromise: Promise<any> = null;

    constructor({ tokenMethod, tokenUrl, initialToken }: IProps) {
        this.tokenMethod = tokenMethod ? tokenMethod : 'post';
        this.token = initialToken ? initialToken : null;
        this.tokenUrl = tokenUrl;
    }

    getAxiosInstance(config: AxiosRequestConfig) {
        if (!this.axiosInstance) {
            this.axiosInstance = this.createAxios(config);
        }
        return this.axiosInstance;
    }

    setToken(token: any) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    // subclass should override this method
    injectToken(config: IRequestConfig, token: any): IRequestConfig {
        config.headers = {
            ...config.headers,
            zak: token,
        };
        return config;
    }

    /**
     * subclass should override this method
     * @param data {any}: this is waht server returns
     * @returns
     */
    extractToken(data: any) {
        return data;
    }

    /**
     * subclass should override this method
     * @param response {any}: this is what server returns, not axios response
     */
    isTokenInvalidated(_response: AxiosError): boolean {
        return false;
    }

    /**
     * if refresh token's request need additional config, headers etc.
     */
    fetchTokenConfig(): AxiosRequestConfig {
        return {};
    }

    fetchToken() {
        const config = this.fetchTokenConfig();
        config.method = this.tokenMethod;
        config.url = this.tokenUrl;

        return axios(config).then((response) => {
            this.fetchTokenPromise = null;
            if (response.status === 200) {
                this.token = this.extractToken(response.data);
                return this.token;
            } else {
                throw Error(response.data || `get ${this.name} token failed`);
            }
        });
    }

    private createAxios(config: AxiosRequestConfig) {
        const instance = axios.create(config);
        instance.interceptors.request.use(this.requestBeforeHandler as any, this.requestErrorHandler);
        instance.interceptors.response.use(this.responseSuccessHandler, this.responseErrorHandler);
        return instance;
    }

    requestBeforeHandler = (config: IRequestConfig) => {
        if (!config.needToken) {
            return Promise.resolve(config);
        } else {
            if (!this.token && !this.fetchTokenPromise) {
                this.fetchTokenPromise = this.fetchToken();
            }
            if (this.fetchTokenPromise) {
                return this.fetchTokenPromise.then((token) => {
                    return this.injectToken(config, token);
                });
            } else {
                return this.injectToken(config, this.token);
            }
        }
    };

    requestErrorHandler = (error: AxiosError) => {
        return Promise.reject(error);
    };

    responseSuccessHandler = (response: AxiosResponse) => {
        // http code 2xx
        return response;
    };

    responseErrorHandler = (error: AxiosError) => {
        if (this.isTokenInvalidated(error)) {
            return this._refreshToken(error);
        }
        return Promise.reject(error);
    };

    private _refreshToken = (error: AxiosError) => {
        const config: IRequestConfig = error.config;
        if (config.hasTried) {
            throw error;
        }

        if (!this.fetchTokenPromise) {
            this.fetchTokenPromise = this.fetchToken();
        }

        return this.fetchTokenPromise
            .then(() => {
                config.hasTried = true;
                return this.axiosInstance(config);
            })
            .catch((e) => {
                throw e;
            });
    };

    handleNeedLogin = () => {
        console.error('you need to (re)login');
    };
}
