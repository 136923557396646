import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { getAsyncPhoneModule } from '../../asyncModulesStore';
import { IconLoadingSpikes } from '@zoom/icons-react';
import { Error_Contact_Us } from '../../resource';
import { usePhoneLocationDetect } from './hooksAboutPhone';
import { canWeShowPhoneLoading } from '../../store/appModules/app-modules-selector';
import { useAppSelector } from '../../store/store-hooks';
import { i18nWithLink } from '../../components/I18nStrings/i18nStringWithLink';
import { phoneLogger, uploadPhoneLogs } from '../../logger/pwa-loggers';

const Phone = React.lazy(() => {
    return getAsyncPhoneModule().then((_) => _);
});

const Loading = () => {
    const style = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const iconStyle = {
        fontSize: '32px',
    };

    return (
        <div style={style}>
            <IconLoadingSpikes style={iconStyle} />
        </div>
    );
};

const ErrorUI = () => {
    const style = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.125rerm',
    };

    const renderLink = (content: string) => {
        return (
            <a href="https://explore.zoom.us/contact" target="__blank">
                {content}
            </a>
        );
    };

    return (
        <div style={style}>
            <span>{i18nWithLink(Error_Contact_Us, [renderLink])}</span>
        </div>
    );
};

const uploadError = (error: any) => {
    phoneLogger
        .error(
            {
                message: error.message,
                stack: error.stack,
            },
            'Error Boundary',
        )
        .then(() => {
            uploadPhoneLogs();
        });
};

export default () => {
    const { E911Container } = usePhoneLocationDetect();
    const showLoading = useAppSelector(canWeShowPhoneLoading);

    if (showLoading) {
        return <Loading />;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorUI} onError={uploadError}>
            <Suspense fallback={<Loading />}>
                <Phone />
            </Suspense>
            {E911Container ? <E911Container /> : null}
        </ErrorBoundary>
    );
};
