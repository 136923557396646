import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'Modal';
import { hideModal } from '../../../store/modal/modal-helper';
import styles from './index.module.scss';
import Button from '../../../components/Button/Button';
import { OK_Text, Transfer_Participant_To_Waiting_Room, MESSAGE_WILL_DISAPPEAR_F } from '../../../resource';

const TransferParticipantToWaitingRoom = () => {

    const timerRef = useRef(null);
    const [time, setTime] = useState(5);

    const closeMe = () => {
        hideModal('transferParticipantToWaitingRoom')
    };

    useEffect(() => {
        timerRef.current = setInterval(() => {
            if(time <= 0) {
                closeMe();
            }else{
                setTime(time - 1)
            }
        }, 1000);

        return () =>{
            clearInterval(timerRef.current)
        }
    }, [time]);

    return (
    
        <Modal
            isOpen={true}
            isDraggable={false}
            centered={true}
            onRequestClose={closeMe}
        >
            <div className={styles['modal-container']}>
                <span className={styles['header']}>
                    <span className={styles['header-logo']} />
                    <span tabIndex={0} className={styles['header-txt']}>
                       {Transfer_Participant_To_Waiting_Room}
                    </span>
                </span>

                <span tabIndex={0} className={styles['content-text']}>{MESSAGE_WILL_DISAPPEAR_F(time)}</span>

                <div className={styles["modal-buttons"]}>
                    <Button size={Button.Size32} type={Button.Normal} onClick={closeMe}>
                        {OK_Text}
                    </Button>
                </div>
            </div>
        </Modal>
     
    );
};

export default TransferParticipantToWaitingRoom;
