import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import LogoutModal from './LogoutModal/LogoutModal';
import MeetingsDeleteModal from './MeetingsDeleteModal';
import About from './About';
import Lang from './Lang';
import JoinMeetingModal from './JoinMeetingModal';
import AccountTakeoverModal from './AccountTakeoverModal';
import ResetPasswordModal from './ResetPasswordModal';
import ForcedBreakMeeting from './ForcedBreakMeeting';
import { useAppSelector } from '../../store/store-hooks';
import { getAsyncPhoneModule } from '../../asyncModulesStore/index';
import SessionExpireTimerModal from './SessionExpireTimerModal/index';
import AVConflict from './AVConflict';
import InviteContactModal from './InviteContactModal';
import CanNotShowZoomPhone from './CanNotShowZoomPhone';
import Feedback from '../Meeting/feedback/container/feedback-container';
import AddToCameraControlGroupModal from './AddToCameraControlGroupModal';
import TransferPaticipantToWaitingRoomModal from './TransferPaticipantToWaitingRoomModal';
import GiveFeedback from './GiveFeedback';

const MaxRecipients = React.lazy(() => getAsyncPhoneModule().then(({ MaxRecipients }) => ({ default: MaxRecipients })));

const IncorrectNumber = React.lazy(() =>
    getAsyncPhoneModule().then(({ IncorrectNumber }) => ({ default: IncorrectNumber })),
);

const AlreadyContact = React.lazy(() =>
    getAsyncPhoneModule().then(({ AlreadyContact }) => ({ default: AlreadyContact })),
);

const DeleteMessage = React.lazy(() => getAsyncPhoneModule().then(({ DeleteMessage }) => ({ default: DeleteMessage })));
const OptOutCallQueueModal = React.lazy(() =>
    getAsyncPhoneModule().then(({ OptOutCallQueueModal }) => ({ default: OptOutCallQueueModal })),
);

const DisallowHideCallerId = React.lazy(() => {
    return getAsyncPhoneModule().then(({ DisallowHideCallerId }) => ({ default: DisallowHideCallerId }));
});

const ClickToDial = React.lazy(() => getAsyncPhoneModule().then(({ ClickToDial }) => ({ default: ClickToDial })));

const UpgradeChooseDialog = React.lazy(() =>
    getAsyncPhoneModule().then(({ UpgradeChooseDialog }) => ({ default: UpgradeChooseDialog })),
);

export default () => {
    const {
        modal: {
            modals: {
                logout,
                meetingsDelete,
                about,
                language,
                ifJoinMeeting,
                maxSmsRecipients,
                incorrectPhoneNumber,
                alreadySmsContact,
                accountTakeover,
                resetPassword,
                forcedBreakMeeting,
                sessionExpireTimeModal,
                inviteContactModal,
                disallowHideCallerId,
                avConflict,
                clickToDial,
                canNotShowZoomPhone,
                feedback,
                optOutCallQueue,
                addToCameraControlGroup,
                upgradeMeetingChoose,
                transferParticipantToWaitingRoom,
                giveFeedback,
            },
        },
        common: { userInfo },
    } = useAppSelector((state) => state);

    return (
        <div className="modal-container">
            {logout ? <LogoutModal data={logout} /> : null}
            {meetingsDelete ? <MeetingsDeleteModal data={meetingsDelete} userInfo={userInfo} /> : null}
            {about ? <About data={about} /> : null}
            {language ? <Lang data={language} /> : null}
            {ifJoinMeeting ? <JoinMeetingModal data={ifJoinMeeting} /> : null}
            {accountTakeover ? <AccountTakeoverModal data={accountTakeover} /> : null}
            {resetPassword ? <ResetPasswordModal data={resetPassword} /> : null}
            {forcedBreakMeeting ? <ForcedBreakMeeting data={forcedBreakMeeting} /> : null}
            {userInfo?.sessionExpireTime && sessionExpireTimeModal.show ? (
                <SessionExpireTimerModal userInfo={userInfo} />
            ) : null}
            {inviteContactModal.show ? <InviteContactModal userInfo={userInfo} data={inviteContactModal.data} /> : null}

            {avConflict ? <AVConflict data={avConflict} /> : null}
            {feedback.show ? <Feedback /> : null}
            {canNotShowZoomPhone.show ? <CanNotShowZoomPhone /> : null}
            {addToCameraControlGroup ? <AddToCameraControlGroupModal data={addToCameraControlGroup} /> : null}
            {transferParticipantToWaitingRoom.show ? <TransferPaticipantToWaitingRoomModal /> : null}
            {giveFeedback ? <GiveFeedback /> : null}
            <Suspense fallback={null}>
                {clickToDial ? <ClickToDial data={clickToDial} /> : null}
                {maxSmsRecipients ? <MaxRecipients data={maxSmsRecipients} /> : null}
                {incorrectPhoneNumber ? <IncorrectNumber data={incorrectPhoneNumber} /> : null}
                {alreadySmsContact ? <AlreadyContact data={alreadySmsContact} /> : null}
                {optOutCallQueue.show ? <OptOutCallQueueModal /> : null}
                {disallowHideCallerId ? <DisallowHideCallerId data={disallowHideCallerId} /> : null}
                {upgradeMeetingChoose?.show ? <UpgradeChooseDialog key={upgradeMeetingChoose?.sid} /> : null}
            </Suspense>
        </div>
    );
};
