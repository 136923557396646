export enum BroadcastMessageSource {
    'PWA' = 'PWA',
}

export enum BroadcastChannelMessageType {
    XMPP_GET_CONNECT_STATUS = 'XMPP_GET_CONNECT_STATUS', // get xmpp connect status
    XMPP_COLLECT_CONNECTED_STATUS = 'XMPP_COLLECT_CONNECTED_STATUS', // collect other tabs xmpp connect status
    SIGN_OUT = 'SIGN_OUT', // trigger sign out
    WEB_SESSION_EXPIRED = 'WEB_SESSION_EXPIRED', // web session expired (cookie)
}

export interface PostMessage {
    from: BroadcastMessageSource; // where the message come from
    type: BroadcastChannelMessageType; // action type
    payload?: any;
}
