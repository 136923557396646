import React from 'react';
import Button from '../../../components/Button/Button';
import { IconLoadingSpikes } from '@zoom/icons-react';
import { CANCEL, CONTACTS_LOADING } from '../../../resource';

interface IProps {
    closeModal: () => void;
}

const LoadingContent = ({ closeModal }: IProps) => {
    return (
        <>
            <div className="loading-content">
                <IconLoadingSpikes className="invite-loading" aria-hidden="true" />
            </div>
            <div title={CONTACTS_LOADING} className="modal-buttons center">
                <Button extraCls="cancel" size={Button.Size24} type={Button.Dark} onClick={closeModal}>
                    {CANCEL}
                </Button>
            </div>
        </>
    );
};

export default LoadingContent;
