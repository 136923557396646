interface IRequester<T> {
    (arg: T): Promise<any>;
}

export const requestLimiter = <T>(sources: Array<T>, request: IRequester<T>, limit = 3) => {
    let pendingCount = 0;
    let lastFiredIndex = -1;
    let _resolve: any = null;
    let _reject: any = null;
    const rejected: Array<[T, any]> = [];

    const fireOne = (index: number) => {
        pendingCount++;
        const value = sources[index];
        return request(value)
            .then((_) => {
                pendingCount--;
                return _;
            })
            .catch((e) => {
                pendingCount--;
                rejected.push([value, e]);
                return e;
            })
            .finally(() => {
                fire();
            });
    };

    const fire = () => {
        while (pendingCount < limit && lastFiredIndex < sources.length - 1) {
            fireOne(++lastFiredIndex);
        }

        // fired all the sources
        if (pendingCount === 0 && lastFiredIndex >= sources.length - 1) {
            if (rejected.length === 0) {
                _resolve();
            } else {
                _reject(rejected);
            }
        }
    };

    setTimeout(fire, 0);

    return new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    });
};
