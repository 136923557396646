import React, { lazy, Suspense, useLayoutEffect, useEffect } from 'react';
import './tesla-app.scss';
import { Switch, Route } from 'react-router-dom';
import Index from '../../features/Index/Index';
import JoinMeeting from '../../features/JoinMeeting/JoinMeeting';
import { meetingAgent } from '../../app-init';
import { getUserInfoThunk, setUserInfoThunk } from '../../store/common/common-store';
import WebClient from '../../features/Meeting/WebClient';
import ModalContainer from '../../features/ModalContainer/ModalContainer';
import { RoutePath } from '../../routes/routePath';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import SignedInTesla from '../../features/SignedIn/SignedInTesla';
import { ToastContainer } from 'Toast';
import { TOAST_PATH } from '../../utils/constants';
import { IconAlertCircleFill, IconCheckmarkCircleFill, IconCloseCircleFill, IconMegaphone } from '@zoom/icons-react';
import { ErrorBoundary } from 'react-error-boundary';
import { canStopRouting, replaceRoute } from '../../routes';
import MeetingStarter from '../../features/Meeting/MeetingStarter';
import useIsUserSignedInEffect from '../../hooks/useIsUserSignedInEffect';
import { shallowEqual } from 'react-redux';

const FooterLink = lazy(() => import('./FooterLink' /* webpackChunkName: "privacy" */));

export default function Tesla() {
    const dispatch = useAppDispatch();
    const { showWebclient, showJoinMeeting } = useAppSelector((state) => {
        const {
            meeting: { showWebclient },
            common: { showJoinMeeting },
        } = state;
        return {
            showWebclient,
            showJoinMeeting,
        };
    }, shallowEqual);

    const hashedUserId = useAppSelector((state) => state.common.hashedUserId);
    const hasUserId = Boolean(hashedUserId);

    useEffect(() => {
        dispatch(getUserInfoThunk())
            .then((userInfo: any) => {
                dispatch(setUserInfoThunk(userInfo));
            })
            .catch((err: any) => {
                console.log(err.toString());
                if (canStopRouting()) return;

                replaceRoute({ pathname: RoutePath.Index });
            });
        return () => {
            meetingAgent.uninit();
            dispatch(setUserInfoThunk(null));
        };
    }, [dispatch]);

    useLayoutEffect(() => {
        document.body.classList.add('tesla');

        return () => {
            document.body.classList.remove('tesla');
        };
    }, []);

    useIsUserSignedInEffect(hasUserId);

    const footerLink = (from: 'index' | 'join') => {
        return (
            <ErrorBoundary fallbackRender={() => null}>
                <Suspense fallback={null}>
                    <FooterLink from={from} />
                </Suspense>
            </ErrorBoundary>
        );
    };

    return (
        <>
            <div className="tesla-app">
                <Route
                    path={[
                        RoutePath.StartMeeting,
                        RoutePath.JoinMeeting,
                        RoutePath.JoinMeeting2,
                        RoutePath.StartVideoMeeting,
                        RoutePath.StartWebMeeting,
                        RoutePath.MyMeeting,
                    ]}
                    render={() => <MeetingStarter key="meetingStarter" />}
                />
                {window.PwaConfig?.enableMeetingRoute && (
                    <Route exact path={RoutePath.Join} render={() => <JoinMeeting />} />
                )}
                <Switch>
                    <Route
                        exact
                        path={RoutePath.Index}
                        render={() => <Index footerLink={footerLink('index')} />}
                    ></Route>
                    {hasUserId ? <SignedInTesla /> : null}
                </Switch>
                {showJoinMeeting ? <JoinMeeting /> : null}
            </div>
            {showWebclient && <WebClient />}
            <ModalContainer />
            <ToastContainer
                TOAST_PATH={TOAST_PATH}
                icons={{
                    success: <IconCheckmarkCircleFill />,
                    info: <IconMegaphone />,
                    failure: <IconCloseCircleFill />,
                    warn: <IconAlertCircleFill />,
                }}
            />
        </>
    );
}
