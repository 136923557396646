import React from 'react';

import HeaderLogo from './HeaderLogo';
import HeaderActions from './HeaderActions';



function HeaderLeft() {
    return (
        <div className="home-header__left">
            <HeaderLogo />
            <HeaderActions />
        </div>
    );
}

export default HeaderLeft;