import { useAppSelector } from '../../../store/store-hooks';
import { WebimPresenceType } from '@zoom/pwa-webim';

export default function useInMeetingDeviceList() {
    const { deviceList } = useAppSelector((state) => state.common);
    return (deviceList || []).filter(
        (device) =>
            (device.presenceType === WebimPresenceType.zoommeeting ||
                device.presenceType === WebimPresenceType.presenting) &&
            device.meetingInfo &&
            !device.isCurrentDevice,
    );
}
