import Crypto from './Crypto';
import db from './indexedDB';
import { STORE_NAME_MAIN } from './config';

const crypto = new Crypto();

let serverKey: CryptoKey | null;
let serverKeyVersion = '';
let currentServerKeyData: IPwaDataStorationEncryptedKey;

const mixUserId = (key: string) => {
    return btoa((window.PwaConfig?.uid || '') + key);
};

const keyTester = {
    data: '123',
    dbKey: 'keyOfKeyTester',
    salt: new Uint8Array([12]),
    async validate(currentServerKey: CryptoKey) {
        const data = await DBManager.getDataFromeDB(keyTester.dbKey, STORE_NAME_MAIN);
        // db have no data, means the key can be serverKey
        if (!data) {
            const result = await crypto.encrypt(this.data, currentServerKey, keyTester.salt);
            DBManager.addData2DB(result, keyTester.dbKey, STORE_NAME_MAIN);
            return true;
        }
        const result = await crypto.decrypt(data, currentServerKey, keyTester.salt).catch((error) => {
            console.log('[Key Tester]decrypt error:', error);
        });
        return result === this.data;
    },
    clear() {
        // serverKey not found
        console.error('serverKey not found, the encrypted data is unable to decrypt, the version is removed from db');
        DBManager.clear(keyTester.dbKey, STORE_NAME_MAIN);
        DBManager.clear(DBManager.dbKeyOfServerKeyVersion, STORE_NAME_MAIN);
        serverKeyVersion = '';
    },
};

export const DBManager = {
    dbKeyOfLocalKey: 'dbKeyOfLocalKey',
    dbKeyOfServerKeyVersion: 'dbKeyOfServerKeyVersion',
    dbKeyOfSalt: 'dbKeyOfSalt',
    serverKeyExpired: false,
    init(serverKeyList: Array<IPwaDataStorationEncryptedKey>) {
        db.onOpenSuccessCallback = async () => {
            serverKeyVersion = await this.getDataFromeDB(this.dbKeyOfServerKeyVersion, STORE_NAME_MAIN);
            serverKey = await this.getServerKey(serverKeyList);
        };
        db.init();
    },
    async getServerKey(serverKeyList: Array<IPwaDataStorationEncryptedKey>) {
        currentServerKeyData = serverKeyList.find((key) => key.isCurrent);
        const possibleServerKeys = serverKeyList
            .filter((key) => +key.version <= +currentServerKeyData.version)
            .sort((a, b) => +b.version - +a.version);

        if (serverKeyVersion) {
            const serverKey = possibleServerKeys.find((key) => key.version === serverKeyVersion);
            if (serverKey?.value) {
                if (!serverKey.isCurrent) {
                    this.serverKeyExpired = true;
                }
                return await crypto.getServerKey(serverKey.value);
            }

            keyTester.clear();
        }

        // reading in sequence
        for (const possibleServerKey of possibleServerKeys) {
            const serverKey = await crypto.getServerKey(possibleServerKey.value);
            if (await keyTester.validate(serverKey)) {
                if (!possibleServerKey.isCurrent) {
                    // we need to set rotate flag
                    this.serverKeyExpired = true;
                }
                serverKeyVersion = possibleServerKey.version;
                return serverKey;
            }
        }
        // serverKey not found
        console.error('serverKey not found, the encrypted data is unable to decrypt');
        return null;
    },
    async save(data: any, key: string, objectStore: string) {
        const salt = crypto.getSalt();
        const localKey = await crypto.getLocalKey();

        const middleData = await crypto.encrypt(data, localKey, salt);
        if (this.serverKeyExpired) {
            serverKey = await crypto.getServerKey(currentServerKeyData.value);
            serverKeyVersion = currentServerKeyData.version;
        }

        const result = await crypto.encrypt(middleData, serverKey, salt, true);
        this.addData2DB(result, key, objectStore);
        this.addData2DB(serverKeyVersion, this.dbKeyOfServerKeyVersion, STORE_NAME_MAIN);
        this.addData2DB(localKey, this.dbKeyOfLocalKey, objectStore);
        this.addData2DB(salt, this.dbKeyOfSalt, objectStore);
    },
    async load(key: string, objectStore: string) {
        let decryptFailed = false;
        const data = await this.getDataFromeDB(key, objectStore);
        const salt = await this.getDataFromeDB(this.dbKeyOfSalt, objectStore);
        const localKey = await this.getDataFromeDB(this.dbKeyOfLocalKey, objectStore);
        if (!salt || !localKey) {
            console.error('decrypt salt or localKey losted, the data is unable to decrypt');
        }

        const middleData = await crypto.decrypt(data, serverKey, salt, true).catch((error) => {
            console.error('decrypt using serverKey failed:', error);
            decryptFailed = true;
        });
        if (decryptFailed) {
            return;
        }
        const result = await crypto.decrypt(middleData, localKey, salt).catch((error) => {
            console.error('decrypt using localKey failed:', error);
            decryptFailed = true;
        });
        if (decryptFailed) {
            return;
        }
        // refresh the serverKey
        this.save(result, key, objectStore);
        return result;
    },
    addData2DB(data: any, key: string, storeName: string) {
        return db.putData(data, mixUserId(key), storeName);
    },
    async getDataFromeDB(key: string, storeName: string) {
        return (await db.getData(mixUserId(key), storeName))?.data;
    },
    clear(key: string, storeName: string) {
        db.delete(mixUserId(key), storeName);
    },
};
