export default class Crypt {
    enc: TextEncoder;
    dec: TextDecoder;
    localKey: CryptoKey;
    constructor() {
        this.enc = new TextEncoder();
        this.dec = new TextDecoder();
    }
    getLocalKey() {
        return window.crypto.subtle.generateKey(
            {
                name: 'AES-GCM',
                length: 256,
            },
            false,
            ['encrypt', 'decrypt'],
        );
    }
    getSalt() {
        return window.crypto.getRandomValues(new Uint8Array(12));
    }
    encrypt(data: any, key: CryptoKey, encryptSalt: Uint8Array, doNotTransform?: boolean) {
        const encodedData = doNotTransform ? data : this.enc.encode(JSON.stringify(data));

        return window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: encryptSalt,
            },
            key,
            encodedData,
        );
    }
    async decrypt(data: any, key: CryptoKey, encryptSalt: Uint8Array, doNotTransform?: boolean) {
        const result = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: encryptSalt,
            },
            key,
            data,
        );

        const decryptedData = doNotTransform ? result : JSON.parse(this.dec.decode(result));
        return decryptedData;
    }

    async getServerKey(originKeyString: string) {
        // AES-GCM only support 16 or 32 bytes key
        const validString = originKeyString.slice(0, 32);

        return await window.crypto.subtle.importKey('raw', this.enc.encode(validString), 'AES-GCM', false, [
            'encrypt',
            'decrypt',
        ]);
    }
}
