/**
 * it's better use here methods as the signout logic entry.
 */

import { broadcastChannelAgent } from '../../app-init';
import { signoutThunk } from './common-store';
import { setUserInfoThunk } from './common-thunk';
import { AppDispatch, AppGetState } from '../../store';
import { history as appHistory } from '../../routes/';
import { RoutePath } from '../../routes/routePath';
import { setModal } from '../modal/modal-store';
import { BroadcastChannelMessageType, BroadcastMessageSource } from '../../utils/BroadcastChannel/types';
import { showModal } from '../modal/modal-helper';
import { uploadUserSessionLogs, userSessionLogger } from '../../logger/pwa-loggers';

enum SignOutType {
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    XMPP_CONFLICT = 'XMPP_CONFLICT',
    XMPP_REVOKE_TOKEN = 'XMPP_REVOKE_TOKEN',
}

export const showSessionOverModal = (type: SignOutType) => (dispatch: AppDispatch) => {
    dispatch(setModal({ name: 'accountTakeover', data: null }));
    showModal('logout', { signOutType: type });
};

export const switchToUnsignedIn = () => (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setUserInfoThunk(null));

    const {
        meeting: { isMeetingMinimized, showWebclient },
    } = getState();

    if (isMeetingMinimized || !showWebclient) {
        appHistory.push(RoutePath.Index);
    }
};

/**
 * @param needSignoutApi , true: need call clean session api
 */
const signoutNeedApiThunk =
    (signOutType: SignOutType, needSignoutApi = true) =>
    async (dispatch: AppDispatch) => {
        dispatch(switchToUnsignedIn());

        dispatch(showSessionOverModal(signOutType));

        if (needSignoutApi) {
            await dispatch(signoutThunk());
        }
    };

/*
 * session expired
 */
const signoutWhenSeesionExpiredThunk = () => (dispatch: AppDispatch) => {
    console.log('signoutWhenSeesionExpired ==> Seesion Expired');
    broadcastChannelAgent.postMessage({
        from: BroadcastMessageSource.PWA,
        type: BroadcastChannelMessageType.SIGN_OUT,
        payload: {
            signOutType: SignOutType.SESSION_EXPIRED,
        },
    });
    dispatch(signoutNeedApiThunk(SignOutType.SESSION_EXPIRED, false));
};

let conflictTimerHandler: any = null;

/*
 * xmpp conflict
 */
const signoutWhenXMPPConflictThunk = () => (dispatch: AppDispatch) => {
    userSessionLogger.log('', ['Session_Expire', 'Xmpp_Conflict']);
    /* change UI to un-signed in state first */
    dispatch(switchToUnsignedIn());
    dispatch(showSessionOverModal(SignOutType.XMPP_CONFLICT));

    /* next, we decide if we need to actually clear user's session by calling backend api */

    const handleResult = (payload?: { alive: boolean; ssid: string }) => {
        conflictTimerHandler && clearTimeout(conflictTimerHandler);
        broadcastChannelAgent.emitter.off(
            BroadcastChannelMessageType.XMPP_COLLECT_CONNECTED_STATUS,
            onReceiveMessageFromOtherTabs,
        );

        if (payload?.alive === true) {
            userSessionLogger
                .log(
                    {
                        ssid: payload.ssid,
                    },
                    ['Session_Expire', 'Xmpp_Conflict', 'Other_Tab'],
                )
                .then(() => {
                    uploadUserSessionLogs();
                });
        } else {
            // no other tabs
            dispatch(signoutThunk());
        }
    };
    const onReceiveMessageFromOtherTabs = (event: any) => {
        const {
            data: { payload },
        } = event;

        handleResult(payload);
    };

    broadcastChannelAgent.emitter.on(
        BroadcastChannelMessageType.XMPP_COLLECT_CONNECTED_STATUS,
        onReceiveMessageFromOtherTabs,
    );

    // post message to collect xmpp-connect-status from other tab
    broadcastChannelAgent.postMessage({
        from: BroadcastMessageSource.PWA,
        type: BroadcastChannelMessageType.XMPP_GET_CONNECT_STATUS,
    });

    conflictTimerHandler && clearTimeout(conflictTimerHandler);
    conflictTimerHandler = setTimeout(handleResult, 1000);
};

/*
 * xmpp revoken token
 */
const signoutWhenXMPPRevokeTokenThunk = () => (dispatch: AppDispatch) => {
    console.log('signoutWhenXMPPRevokeToken ==> REVOKE_TOKEN');
    broadcastChannelAgent.postMessage({
        from: BroadcastMessageSource.PWA,
        type: BroadcastChannelMessageType.SIGN_OUT,
        payload: {
            signOutType: SignOutType.XMPP_REVOKE_TOKEN,
        },
    });
    dispatch(signoutNeedApiThunk(SignOutType.XMPP_REVOKE_TOKEN, true));
};

export {
    SignOutType,
    signoutNeedApiThunk,
    signoutWhenSeesionExpiredThunk,
    signoutWhenXMPPConflictThunk,
    signoutWhenXMPPRevokeTokenThunk,
};
