import { setUpdateIsAvailable } from './store/common/common-store';
import { isSupportServiceWorker } from './utils';

export const EventsToSW = {
    UPDATE: 'UPDATE',
};

export const EventsFromSW = {
    UPDATE_RESPONSE: 'UPDATE_RESPONSE',
};

class ServiceWorkerManager {
    constructor({ store }) {
        this.store = store;
        this.listenEventsFromSW();
        this.onMessageFromSW = this.onMessageFromSW.bind(this);
    }

    listenEventsFromSW() {
        if (isSupportServiceWorker()) {
            window.navigator.serviceWorker.ready.then(() => {
                // reg
                window.navigator.serviceWorker.addEventListener('message', this.onMessageFromSW);
            });
        }
    }

    uninit() {
        if (isSupportServiceWorker()) {
            window.navigator.serviceWorker.ready.then(() => {
                // reg
                window.navigator.serviceWorker.removeEventListener('message', this.onMessageFromSW);
            });
        }
    }

    /**
     * because we server will inject cdn address and release hash into index.html.
     * eg. https://gost1.zoom.us/web_client_pwa/j2drnf/js/pwa-webim.js
     * we don't cache index.html.
     * so everytime, we deploy a new realse, we get a new release hash.
     * the url to our assets will change.
     * current index.html will just use the assets specifed in the the page, not those precahed before.
     *
     * in another word, pwa updates and fetches all the assets.
     * there is no need to give user choice to update or not. they have no choice, we've updated.
     */
    onUpdateAvailable() {
        // let isUserSignin = isUserSigninSelector(this.store.getState());
        // if (isUserSignin) {
        //     this.store.dispatch(setUpdateIsAvailable(true));
        // } else {
        //     this.userConfirmUpdate();
        // }
        this.userConfirmUpdate();
    }

    userConfirmUpdate() {
        this.store.dispatch(setUpdateIsAvailable(false));
        if (!isSupportServiceWorker()) return;
        window.navigator.serviceWorker.getRegistration().then((reg) => {
            if (reg && reg.waiting) {
                reg.waiting.postMessage({ type: EventsToSW.UPDATE });
            }
        });
    }

    userDeclineUpdate() {
        this.store.dispatch(setUpdateIsAvailable(false));
    }

    doUpdate() {
        window.location.reload();
    }

    onMessageFromSW(e) {
        let {
            type,
            // data
        } = e.data;
        switch (type) {
            case EventsFromSW.UPDATE_RESPONSE:
                break;
            default:
                break;
        }
    }

    sendMsgToSW(message) {
        if (!isSupportServiceWorker()) return;

        return navigator.serviceWorker.getRegistration().then((registration) => {
            if (!registration) {
                return null;
            }
            return navigator.serviceWorker.ready.then((reg) => reg.active.postMessage(message));
        });
    }

    sendMsgToWebClient(event, data = {}) {
        const payload = {
            message: { event, data },
            type: 'WINDOW_IPC',
        };
        this.sendMsgToSW(payload);
    }
}

export default ServiceWorkerManager;
