import { useEffect } from 'react';
import { useAppSelector } from '../../../store/store-hooks';
import { chatAgent } from '../ChatAgent';
import eventBus from '../../../events/eventBus';

interface IisUserBlockedByIMProps {
    jid: string;
}

export const useIsUserBlockedByIM = ({ jid }: IisUserBlockedByIMProps) => {
    const blockList = useAppSelector((state) => {
        return state.chat.blockedList;
    });
    const found = blockList.find((item) => item === jid);
    return Boolean(found);
};

export const useZoomUserSessionStatus = () => {
    useEffect(() => {
        const id = eventBus.app.willSingout.subscribe(() => {
            chatAgent.toNotifyDestroy('User session expired, need to re-login');
            eventBus.app.willSingout.unsubscribe(id);
        });
    }, []);
};
