import {
    PWA_DB_NAME,
    PWA_DB_VERSION,
    STORE_NAME_MAIN,
    STORE_NAME_MEETING_INFO,
    STORE_NAME_PENDING_CONTACT_INFO,
    STORE_NAME_CAMERA_CONTROL,
} from './config';

class DB {
    dbName: string;
    version: number;
    mainRequest: IDBOpenDBRequest;
    keyPath: string;
    storeNames: Array<string>;
    objectStore: IDBObjectStore;
    db: IDBDatabase;

    constructor(dbName: string, version: number, storeNames: Array<string>) {
        this.db = null;
        this.storeNames = storeNames;
        this.objectStore = null;
        this.dbName = dbName;
        this.version = version || 0;
        this.keyPath = 'id';
    }

    private openDB(
        onSuccess = (_e: any, _request: any) => {},
        onError = (_request: any) => {},
        onUpgradeneeded = (_e: any, _request: any) => {},
    ) {
        const openRequest = global.indexedDB.open(this.dbName, this.version);

        console.log('openDB request ==>', openRequest);
        console.log('openDB PWA_DB_VERSION ==>', PWA_DB_VERSION);
        console.log('openDB this.version ==>', this.version);

        openRequest.onsuccess = (e) => {
            onSuccess && onSuccess(e, openRequest);
        };

        openRequest.onerror = () => {
            // https://developer.mozilla.org/zh-CN/docs/Web/API/IDBRequest#attributes
            if (
                openRequest?.error?.name === 'VersionError' &&
                (this.version === PWA_DB_VERSION || this.version === PWA_DB_VERSION + 1) // try two verisons up.
            ) {
                this.version = this.version + 1;
                this.openDB(onSuccess, onError, onUpgradeneeded);
            }

            onError && onError(openRequest);
        };

        openRequest.onupgradeneeded = (e) => {
            onUpgradeneeded && onUpgradeneeded(e, openRequest);
        };
    }

    init() {
        if (!this.dbName) {
            throw new Error('db name not provided');
        }

        const onSuccess = (e: any) => {
            // @ts-ignore
            this.db = e.target.result;
            console.log('db open success');
            this.onOpenSuccessCallback();
        };

        const onError = () => {
            console.log('db open failed');
        };

        // Attention!!! if you change the below function, do remember to upgrade db version
        const onUpgradeneeded = (e: any) => {
            console.log('onupgradeneeded');
            // @ts-ignore
            this.db = e.target.result;
            this.storeNames.forEach((storeName) => {
                if (!this.db.objectStoreNames.contains(storeName)) {
                    this.db.createObjectStore(storeName, {
                        keyPath: this.keyPath,
                        autoIncrement: true,
                    });
                }
            });
        };

        this.openDB(onSuccess, onError, onUpgradeneeded);
    }
    // db -> objectStore -> transaction -> add/put
    addData(data: any, keyName: string, storeName: string) {
        if (!this.db) return;
        const transaction = this.db.transaction([storeName], 'readwrite');
        transaction.objectStore(storeName).add({ id: keyName, data });

        transaction.oncomplete = () => console.log('add data success');
        transaction.onerror = (e) => console.log('add data failed', e);
    }
    putData(data: any, keyName: string, storeName: string) {
        if (!this.db) return;
        const transaction = this.db.transaction([storeName], 'readwrite');
        transaction.objectStore(storeName).put({ id: keyName, data });

        transaction.oncomplete = () => console.log('put data success');
        transaction.onerror = (e) => console.log('put data failed', e);
    }
    async getData(keyName: string, storeName: string): Promise<{ data: any }> {
        if (!this.db) return Promise.reject('this.db is unfinished');
        return new Promise((res, rej) => {
            // if sotre name not exist ?
            const transaction = this.db.transaction([storeName], 'readwrite');
            const request = transaction.objectStore(storeName).get(keyName);

            request.onsuccess = (e) => {
                // @ts-ignore
                return res(e.target.result);
            };
            request.onerror = (e) => {
                return rej(e);
            };

            transaction.oncomplete = () => {
                console.log('getData ' + keyName + ' success');
            };
            transaction.onerror = () => {
                console.log('getData ' + keyName + ' failed');
            };
        });
    }
    delete(keyName: string, storeName: string) {
        if (!this.db) return;
        const transaction = this.db.transaction([storeName], 'readwrite');
        transaction.objectStore(storeName).delete(keyName);

        transaction.oncomplete = () => console.log('delete ' + keyName + ' data success');
        transaction.onerror = (e) => console.log('delete ' + keyName + ' data failed', e);
    }
    close() {
        if (!this.db) return;
        this.db.close();
        console.log('database closed');
    }
    onOpenSuccessCallback() {
        console.log('the open success callback has not be implemented');
    }
}

const db = new DB(PWA_DB_NAME, PWA_DB_VERSION, [
    STORE_NAME_MAIN,
    STORE_NAME_MEETING_INFO,
    STORE_NAME_PENDING_CONTACT_INFO,
    STORE_NAME_CAMERA_CONTROL,
]);

export default db;
