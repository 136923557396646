import mitt, { Emitter } from 'mitt';
import { PostMessage } from './types';

export default class BroadcastChannelAgent {
    static isSupportBroadcastChannel(): boolean {
        return 'BroadcastChannel' in window;
    }
    broadcastChannel: BroadcastChannel = null;
    emitter: Emitter = null;

    constructor(channelName = 'BroadcastChannel') {
        this.init(channelName);
    }

    init(name: string) {
        if (BroadcastChannelAgent.isSupportBroadcastChannel()) {
            console.log('BroadcastChannelManager init');
            this.emitter = mitt();
            this.broadcastChannel = new BroadcastChannel(name);
            this.broadcastChannel.addEventListener('message', this.onMessage);
            this.broadcastChannel.addEventListener('onmessageerror', this.onMessageError);
        }
    }

    uninit() {
        if (this.broadcastChannel) {
            this.broadcastChannel.removeEventListener('message', this.onMessage);
            this.broadcastChannel.removeEventListener('onmessageerror', this.onMessageError);
            this.broadcastChannel.close();
            this.broadcastChannel = null;
            this.emitter.all.clear();
        }
    }

    getChannelName() {
        return this.broadcastChannel?.name || undefined;
    }

    postMessage(message: PostMessage) {
        if (this.broadcastChannel) {
            console.log('BroadcastChannelManager postMessage ==>', message);
            this.broadcastChannel.postMessage(message);
        }
    }

    onMessage = (e: any) => {
        if (this.broadcastChannel) {
            console.log('BroadcastChannelManager onMessage ==>', e);
            if (e?.data?.type) {
                this.emitter.emit(e.data.type, e);
            }
        }
    };

    onMessageError = (e: any) => {
        if (this.broadcastChannel) {
            console.log('BroadcastChannelManager onMessageError ==>', e);
        }
    };
}
