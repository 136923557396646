import { DBManager, STORE_NAME_CAMERA_CONTROL } from '../../../utils/db';

export class CameraControlGroupDBManager {
    private static dbKey = 'cameraControlData';
    static userId: string;

    static setUserId(userId: string): void {
        this.userId = userId;
    }

    static async add(value: string) {
        const _data = await DBManager.getDataFromeDB(this.dbKey, STORE_NAME_CAMERA_CONTROL);
        if (_data) {
            const rawData = await DBManager.load(this.dbKey, STORE_NAME_CAMERA_CONTROL);
            if (!rawData) {
                DBManager.save({ [this.userId]: [value] }, this.dbKey, STORE_NAME_CAMERA_CONTROL);
                return;
            }

            if (!rawData[this.userId]) {
                const result = Object.assign({}, rawData, { [this.userId]: [value] });
                DBManager.save(result, this.dbKey, STORE_NAME_CAMERA_CONTROL);
                return;
            }

            if (!rawData[this.userId].includes(value)) {
                rawData[this.userId].push(value);
                DBManager.save(rawData, this.dbKey, STORE_NAME_CAMERA_CONTROL);
            }
        } else {
            DBManager.save({ [this.userId]: [value] }, this.dbKey, STORE_NAME_CAMERA_CONTROL);
        }
    }

    static async get() {
        const _data = await DBManager.getDataFromeDB(this.dbKey, STORE_NAME_CAMERA_CONTROL);
        if (!_data) {
            return [];
        }
        const result = await DBManager.load(this.dbKey, STORE_NAME_CAMERA_CONTROL);
        console.log('getByUserId ==>', result);

        if (result[this.userId]) {
            return result[this.userId];
        }

        return [];
    }

    static async delete(jid: string) {
        const _data = await DBManager.getDataFromeDB(this.dbKey, STORE_NAME_CAMERA_CONTROL);
        if (_data) {
            const rawData = await DBManager.load(this.dbKey, STORE_NAME_CAMERA_CONTROL);
            if (!rawData || !rawData[this.userId]) {
                return;
            }

            const index = rawData[this.userId].indexOf(jid);
            if (index > -1) {
                rawData[this.userId].splice(index, 1);
                const result = Object.assign({}, rawData);
                console.log('deleteByUserIdAndJid result ==>', result);
                DBManager.save(result, this.dbKey, STORE_NAME_CAMERA_CONTROL);
            }
        } else {
            return;
        }
    }

    static clear() {
        DBManager.clear(this.dbKey, STORE_NAME_CAMERA_CONTROL);
    }
}
