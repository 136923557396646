import { WebimEventType, WebimPresenceType, WebimStatus } from '@zoom/pwa-webim';
import { isUserSigninSelector, signoutThunk } from '../../store/common/common-store';
import { isInStandaloneMode, getBareJidFromJid, isTeslaMode } from '../../utils/index';
import {
    getJoinMeetingPath,
    getJoinMeetingPersonLink,
    getSigninPath,
    getSignOutPath,
    getStartMeetingPath,
    getSchedulePagePath,
    getScheduleEditPagePath,
    getStartMeetingPathViaMeetingNumber,
} from '../../utils/meeting-url.js';
import {
    setLaunchMeeting,
    setMeetingInfo,
    setIsMeetingMinimized,
    setMeetingInfoThunk,
    isDuringMeetingSelector,
    setIsMeetingAudioConnected,
    checkMeetingForceBreakThunk,
    setIsStartDisabled,
    setIsPresenting,
} from './redux';
import { invitationManager, xmppAgent } from '../../app-init';
import { pwaToast } from '../Components/minimize/Toast';
import { ICON_TYPE, toast } from 'Toast';
import {
    BO_TIME_UP_TEXT,
    ENTER_WAITING_F_1,
    ENTER_WAITING_F_2,
    INVITED_TO_BO_TEXT_F,
    LIVE_TRANSCRIPTION_ENABLED_TEXT,
    LIVE_TRANSCRIPTION_REQUEST_TEXT_F,
    MUTED_BY_HOST_TEXT,
    VIDEO_REQUEST_TEXT,
    UNMUTE_REQUEST_TEXT,
    END_BY_HOST_TEXT,
    POLL_INBOX_TEXT,
    PROMOTE_TO_PANELIST_TEXT,
    BAN_VIDEO_TEXT,
    REMOVED_BY_HOST_TEXT,
    RETURN_MAIN_SESSION_TEXT_F,
    RENAMED_BY_HOST_TEXT_F,
    BAN_UNMUTE_AUDIO_TEXT,
    YOU_HAVE_N_NOTIFICATIONS_F,
    POLL_RESULT_SHARED_TEXT,
    BO_WILL_CLOSE_TEXT,
    HELP_REQUEST_TEXT_F,
} from '../../resource';
import { isGroupAddon, isGroupZoomRoom } from '../Contacts/redux';
import { fetchContactsProfileThunk } from '../Contacts/redux/contact-thunk';
import { isPendingContactJid } from '../Contacts/utils/stringUtils';
import { isChatEnabled } from '../../utils/featureOptions';
import { chatAgent } from '../Chat/ChatAgent';
import { cookies } from '../../utils/Cookies/cookie';
import { reload, openTab } from '../../utils/index';
import { JoinMeetingDBManager } from '../JoinMeeting/JoinMeetingDBManager';
import { WC_COOKIE_KEY } from '../../utils/constants';
import { history, replaceRoute, routeBack } from '../../routes';
import { checkIsMeetingRoute, getRouteFromURL } from '../Meetings/Meetings/utils';
import { trackingId } from '../../logger/laplace';
import { showModal } from '../../store/modal/modal-helper';
import { feedbackDialogThunk } from './feedback/redux/feedback-thunk-actions';
import serviceContainer from '../../services/container';
import { cameraControlGroupHelper } from '../Contacts/utils/CameraControlGroupHelper';
import { goToPostAttendeeUrl } from './feedback/utils';
import { uploadWebclientLogs, webclientLogger } from '../../logger/pwa-loggers';
import { PWAMeetingEvent, PWAMeetingEvent_MINIMIZE } from './meeting-agent-msg-type';
import type { ReduxStore } from '../../store';
import { JID } from '../Contacts/types';
import { WebimConnectionEvent, WebimEvent, WebimMessageEvent } from '@zoom/pwa-webim/dist/types/Types';
import { ContactPresence } from '@zoom/zpns-presence-handler';

export const MESSAGE_TYPE_WITH_WEBCLIENT = 'PWA_IPC';

type PostMessage = typeof window.postMessage;
type WebclientMessageTypeEnum = typeof PWAMeetingEvent;
type WebclientMessageType = WebclientMessageTypeEnum[keyof WebclientMessageTypeEnum];
export type WebclientMessage<T = any> = {
    event: WebclientMessageType;
    data: T;
};

type MeetingEndEventData = {
    feedback: any;
    postAttendeeUrl?: string;
};

type LaunchMeetingParams = any;
type JoinMeeringParams = any;
type MeetingId = string;
type EndMeetingReason = {
    reason: string;
    data: any;
};
type MeetingInfoFromInvitation = {
    meetingNo: MeetingId;
    password?: string;
}; // not complete

type WaitingToJoinMeetingInfo = {
    invite: number; // 0 | 1
    start?: { withVideo: boolean };
    meetingNo: string;
    password?: string;
};

type MeetingToastOptions = {
    toastId: string;
    closeButton?: boolean;
    autoClose?: boolean;
};

type PWAToast = typeof pwaToast;
type PWAToastParameters = Parameters<PWAToast>;
type XmppStatus = {
    status: WebimStatus;
    statusInfo: any;
    willReconnect: boolean;
    isContactLoading?: boolean;
};

type InviteToMeetingItem = {
    jid: string;
    iak: string;
    credential: string;
};

type MeetingInfo = {
    pwd: string;
};

type Base64String = string;
export type TransferLcp = Base64String;

export default class MeetingAgent {
    store: ReduxStore;
    isWebclientInviting = false;
    waitingToJoinMeetingInfo: WaitingToJoinMeetingInfo; // when in meeting, you receive an invitation and accept we ask webclient to leave and join this meeting.
    webClientPostMessage: PostMessage;
    currentMeetingToastIDs: Set<string>;
    lcpReqResolve: Function;
    lcpReqTimer: number;
    toEndMeetingReason: EndMeetingReason;

    constructor({ store = null }: { store: ReduxStore }) {
        this.store = store;
        this.isWebclientInviting = false;
        this.waitingToJoinMeetingInfo = null; // when in meeting, you receive an invitation and accept we ask webclient to leave and join this meeting.
        this.webClientPostMessage = null;
        this.currentMeetingToastIDs = new Set();
        this.lcpReqResolve = null;
        this.lcpReqTimer = null;
        this.toEndMeetingReason = null;
        this.init();
    }

    init() {
        window.addEventListener('message', this.onWebclientMessage);
        window.addEventListener('beforeunload', this.onPwaWindowBeforeunload);
        window.addEventListener('unload', this.onPwaWindowUnload);
    }

    // called when App.js component unmount hook
    uninit() {
        window.removeEventListener('message', this.onWebclientMessage);
        window.removeEventListener('beforeunload', this.onPwaWindowBeforeunload);
        window.removeEventListener('unload', this.onPwaWindowUnload);
        this.store = null;
    }

    // this function will be called by WebClient.js [react component];
    setPostMessage(postMessage: PostMessage) {
        this.webClientPostMessage = postMessage;
    }

    sendMsgToWebClient = (event: WebclientMessageType, data = {}) => {
        const payload = {
            message: { event, data },
            type: MESSAGE_TYPE_WITH_WEBCLIENT,
        };
        if (event && this.webClientPostMessage && typeof this.webClientPostMessage === 'function') {
            console.log('[PWA] [To] Webclient]', payload);
            this.webClientPostMessage(payload);
        }
    };

    onPwaWindowBeforeunload = () => {
        let {
            meeting: { meetingInfo },
        } = this.store.getState();
        if (!meetingInfo) return;
    };

    closeMinimizeRelatedToasts = () => {
        Object.keys(PWAMeetingEvent_MINIMIZE).forEach((key) => pwaToast.dismiss(key));
        this.currentMeetingToastIDs.clear();
    };

    onPwaWindowUnload = () => {
        const {
            meeting: { meetingInfo },
        } = this.store.getState();

        if (!meetingInfo) return;

        this.clearZakSessionIfPossible();
    };

    // for api user starts/joins meeting, when they end/leave current meeting, we try to remove use's session built by zak.
    clearZakSessionIfPossible = () => {
        if (window.PwaConfig.isCustUser) {
            this.sendMsgToWebClient(PWAMeetingEvent.PWA_LEAVE_MEETING, {
                reason: 'Pwa_Unload',
            });

            this.store.dispatch(signoutThunk());
        }
    };

    handleMeetingEnd = (event: WebclientMessageType, data?: MeetingEndEventData) => {
        console.log('ME');
        const {
            meeting: { meetingInfo },
        } = this.store.getState();
        this.dispatchLaunchMeeting();
        this.store.dispatch(setMeetingInfoThunk({ ...meetingInfo, meetingStatus: event }));
        this.registerXmppEventsForWebclient_1(false);
        this.registerXmppEventsForWebclient_2(false);

        const logInfo = {
            mid: meetingInfo?.mid,
            isStandalone: isInStandaloneMode(),
            isTesla: isTeslaMode(),
        };

        const hasWaitingToJoin = Boolean(this.waitingToJoinMeetingInfo);
        // this is for inmeeting invitation
        if (this.waitingToJoinMeetingInfo) {
            const { start } = this.waitingToJoinMeetingInfo;
            if (start) {
                const { withVideo } = start;
                this.startMeeting(start, withVideo);
            } else {
                let { meetingNo, password, ...rest } = this.waitingToJoinMeetingInfo;
                if (meetingNo) {
                    this.joinMeetingDelayed(meetingNo, { pwd: password, ...rest });
                } else {
                    invitationManager.setAcceptedInvitation(null);
                }
            }
        } else {
            invitationManager.setAcceptedInvitation(null);
            if (data?.feedback) {
                this.store.dispatch(feedbackDialogThunk(data));
            } else {
                goToPostAttendeeUrl(data?.postAttendeeUrl);
            }
            Object.assign(logInfo, {
                feedback: Boolean(data?.feedback),
                postAttendeeUrl: Boolean(data?.postAttendeeUrl),
            });
        }

        this.waitingToJoinMeetingInfo = null;

        // update presence
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            presenceManager.updatePresence_Meeting_End();
        }

        this.store.dispatch(setIsMeetingMinimized(false));
        this.closeMinimizeRelatedToasts();

        const reason = this.clearEndMeetingReason();

        webclientLogger.log(logInfo, ['END'])?.then(() => {
            uploadWebclientLogs();
        });

        this.clearZakSessionIfPossible();

        const eventBus = serviceContainer.getEventBus();
        if (eventBus) {
            eventBus.meeting.end.dispatchEvent({
                meetingInfo,
                reason,
                hasWaitingToJoin,
            });
        }
    };

    meetingToast = (params = {}, options: MeetingToastOptions) => {
        this.currentMeetingToastIDs.add(options?.toastId);
        if (this.currentMeetingToastIDs.size > 1) {
            pwaToast.updateContent([...this.currentMeetingToastIDs][0], {
                desc: YOU_HAVE_N_NOTIFICATIONS_F(this.currentMeetingToastIDs.size),
            });
            return;
        }
        pwaToast(
            params as PWAToastParameters[0],
            {
                ...options,
                onClose: () => this.currentMeetingToastIDs.clear(),
            } as PWAToastParameters[1],
        );
    };

    // this function will be called by WebClient.js [react component];
    onWebclientMessage = async (
        e: MessageEvent<{ type: typeof MESSAGE_TYPE_WITH_WEBCLIENT; message: WebclientMessage }>,
    ) => {
        if (!e || !e.data || e.data.type !== MESSAGE_TYPE_WITH_WEBCLIENT) {
            return;
        }
        const { message } = e.data;
        // console.log(e.data, 998801)
        const { event } = message;
        let state = this.store.getState();
        let {
            xmpp: { status, statusInfo, willReconnect },
            meeting: { isMeetingMinimized, isInvitedByXmpp, transferMeetingData, meetingInfo },
        } = state;

        const acceptedInvitation = invitationManager.getAcceptedInvitation();
        console.log('[PWA] [From Webclient]', message);
        switch (event) {
            case PWAMeetingEvent.JOINING: {
                const {
                    mid,
                    meetingNo,
                    password,
                    pwd,
                    caption,
                    displayName,
                    startBySelf,
                    hasBypassWaitingroomCode,
                    isWebinar,
                    isSimuliveWebinar,
                    isHost,
                    needRegister,
                } = message.data || {};

                let meetingInfo = {
                    mid,
                    meetingNo,
                    password,
                    pwd,
                    caption,
                    displayName,
                    startBySelf,
                    meetingStatus: event,
                    hasBypassWaitingroomCode,
                    isWebinar,
                    isSimuliveWebinar,
                    isHost,
                    needRegister,
                };

                console.log('MJ');
                const logInfo = {
                    mid: mid,
                };

                webclientLogger.log(logInfo, ['JOINING']);

                this.waitingToJoinMeetingInfo = null;
                let isUserSignin = isUserSigninSelector(state);
                this.sendMsgToWebClient(PWAMeetingEvent.PWA_EXIST, {
                    showContacts: isUserSignin ? true : false,
                    reason: isUserSignin ? '' : 'Not_Signin',
                    isInvited: isInvitedByXmpp,
                    iak: acceptedInvitation?.iak || '',
                    credential: acceptedInvitation?.credential || '',
                    hasBypassWaitingroomCode,
                    pwaLaplaceTracingId: trackingId, // pass pwa global tracing id to web client;
                });

                this.sendXmppStatusToWebclient({ status, statusInfo, willReconnect });
                this.registerXmppEventsForWebclient_1(true);

                this.store.dispatch(setMeetingInfoThunk(meetingInfo));
                this.handleMeetingUrl(meetingInfo);
                break;
            }

            case PWAMeetingEvent.SUCCESS: {
                console.log('MS');

                const { meeting } = this.store.getState();
                const { meetingInfo } = meeting;

                JoinMeetingDBManager.addHistoryMeeting(meetingInfo.meetingNo, meetingInfo.caption);
                this.store.dispatch(setMeetingInfoThunk({ ...meetingInfo, meetingStatus: event }));
                const { meetingNo: number, password, mid } = meetingInfo;

                webclientLogger.log({ mid }, ['SUCCESS']).then(() => {
                    uploadWebclientLogs();
                });

                // update presence
                const presenceManager = serviceContainer.getPresenceManager();
                if (presenceManager) {
                    presenceManager.updatePresence_Meeting_Start();
                }

                xmppAgent.queryRecentChat();

                const eventBus = serviceContainer.getEventBus();
                if (eventBus) {
                    const event = {
                        meetingNumber: number,
                        password,
                    };
                    eventBus.meeting.success.dispatchEvent(event);
                }

                break;
            }

            case PWAMeetingEvent.LEAVE:
            case PWAMeetingEvent.END: {
                this.handleMeetingEnd(event, message.data);
                break;
            }

            case PWAMeetingEvent.SEND_CHANNEL_ID: {
                const { channelId, topic } = message.data;
                chatAgent.handlePmcMeetingEnd({ topic, channelId });
                break;
            }

            case PWAMeetingEvent.INVITE_START: {
                // send all contacts to web-client
                console.log('MIO');
                this.isWebclientInviting = true;
                this.registerXmppEventsForWebclient_2(true);

                this.sendXmppStatusToWebclient({ status, statusInfo, willReconnect });

                if (xmppAgent.isConnected()) {
                    xmppAgent.queryRecentChat();
                    this.sendMsgToWebClient(PWAMeetingEvent.INVITE_CONTACTS, this.getContactsForWebclient());
                }
                break;
            }

            case PWAMeetingEvent.INVITE_STOP: {
                // stop push user details change to web-client
                console.log('MIC');
                this.isWebclientInviting = false;
                this.registerXmppEventsForWebclient_2(false);
                break;
            }

            case PWAMeetingEvent.INVITE_FETCH_USER_DETAILS: {
                // add user list to user details listener for web-client request
                let { data = [] } = message;

                const jids = data.map((jid: string) => {
                    return {
                        jid,
                    };
                });

                // query contact presence status and profile info
                this.store.dispatch(fetchContactsProfileThunk(jids));
                break;
            }

            case PWAMeetingEvent.INVITE_INVITE_TO_MEETING: {
                let { data = [] } = message;
                // the `jid: item.jid || item` is for compatibility
                data = data.map((item: InviteToMeetingItem) => ({
                    jid: item?.jid || item,
                    iak: item.iak,
                    credential: item.credential,
                }));
                invitationManager.inviteContacts(data);
                break;
            }

            case PWAMeetingEvent.WAITING_ROOM_NEW: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.WAITING_ROOM_NEW;
                const { attendeeName, count } = message.data || {};
                const desc = count <= 1 ? ENTER_WAITING_F_1(attendeeName) : ENTER_WAITING_F_2(count);
                if (pwaToast.isActive(toastId)) {
                    pwaToast.updateContent(toastId, {
                        desc,
                    });
                    return;
                }
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.LIVE_TRANSCRIPTION_ENABLED: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.LIVE_TRANSCRIPTION_ENABLED;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: LIVE_TRANSCRIPTION_ENABLED_TEXT,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.MUTED_BY_HOST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.MUTED_BY_HOST;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: MUTED_BY_HOST_TEXT,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.VIDEO_REQUEST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.VIDEO_REQUEST;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: VIDEO_REQUEST_TEXT,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.UNMUTE_REQUEST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.UNMUTE_REQUEST;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: UNMUTE_REQUEST_TEXT,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.RECORDING: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.RECORDING;
                const { title } = message.data || {};
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: title,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.INVITED_TO_BO: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.INVITED_TO_BO;
                const { hostName, roomName } = message.data || {};
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: INVITED_TO_BO_TEXT_F(hostName, roomName),
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.BO_TIME_UP: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.BO_TIME_UP;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: BO_TIME_UP_TEXT,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.POLL_INBOX: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.POLL_INBOX;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: POLL_INBOX_TEXT,
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.LIVE_TRANSCRIPTION_REQUEST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.LIVE_TRANSCRIPTION_REQUEST;
                const { name } = message.data || {};
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: LIVE_TRANSCRIPTION_REQUEST_TEXT_F(name),
                    },
                    {
                        closeButton: true,
                        autoClose: false,
                        toastId,
                    },
                );
                break;
            }

            case PWAMeetingEvent.MEETING_MINIMIZE: {
                this.store.dispatch(setIsMeetingMinimized(true));
                break;
            }

            case PWAMeetingEvent.MEETING_MAXIMIZE: {
                this.store.dispatch(setIsMeetingMinimized(false));
                this.closeMinimizeRelatedToasts();
                break;
            }

            case PWAMeetingEvent.END_BY_HOST: {
                if (!isMeetingMinimized) {
                    return;
                }
                toast({
                    type: ICON_TYPE.INFO,
                    desc: END_BY_HOST_TEXT,
                });
                this.handleMeetingEnd(event);
                break;
            }

            case PWAMeetingEvent.RETURN_MAIN_SESSION: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.RETURN_MAIN_SESSION;
                const { inviterName } = message.data || {};
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: RETURN_MAIN_SESSION_TEXT_F(inviterName),
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }

            case PWAMeetingEvent.PROMOTE_TO_PANELIST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.PROMOTE_TO_PANELIST;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: PROMOTE_TO_PANELIST_TEXT,
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }

            case PWAMeetingEvent.BAN_VIDEO: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.BAN_VIDEO;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: BAN_VIDEO_TEXT,
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }

            case PWAMeetingEvent.RENAMED_BY_HOST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const { newName } = message.data || {};
                const toastId = PWAMeetingEvent.RENAMED_BY_HOST;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: RENAMED_BY_HOST_TEXT_F(newName),
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }

            case PWAMeetingEvent.BAN_UNMUTE_AUDIO: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.BAN_UNMUTE_AUDIO;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: BAN_UNMUTE_AUDIO_TEXT,
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }

            case PWAMeetingEvent.AUDIO_CONNECTED_CHANGE: {
                let { connected } = message.data;
                this.store.dispatch(setIsMeetingAudioConnected(connected));
                break;
            }
            case PWAMeetingEvent.REMOVED_BY_HOST: {
                if (!isMeetingMinimized) {
                    return;
                }
                // const toastId = PWAMeetingEvent.REMOVED_BY_HOST;
                toast({
                    type: ICON_TYPE.INFO,
                    desc: REMOVED_BY_HOST_TEXT,
                });
                this.handleMeetingEnd(event);
                break;
            }
            case PWAMeetingEvent.POLL_RESULT_SHARED: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.POLL_RESULT_SHARED;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: POLL_RESULT_SHARED_TEXT,
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }
            case PWAMeetingEvent.BO_WILL_CLOSE: {
                if (!isMeetingMinimized) {
                    return;
                }
                const toastId = PWAMeetingEvent.BO_WILL_CLOSE;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: BO_WILL_CLOSE_TEXT,
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }
            case PWAMeetingEvent.HELP_REQUEST: {
                if (!isMeetingMinimized) {
                    return;
                }
                const { roomName, attendeeName } = message.data || {};
                const toastId = PWAMeetingEvent.HELP_REQUEST;
                this.meetingToast(
                    {
                        type: ICON_TYPE.INFO,
                        desc: HELP_REQUEST_TEXT_F(attendeeName, roomName),
                    },
                    {
                        toastId,
                        autoClose: false,
                        closeButton: true,
                    },
                );
                break;
            }
            case PWAMeetingEvent.PAC: // for PAC meeting
            case PWAMeetingEvent.CROSS_SITE:
            case PWAMeetingEvent.NEED_REGISTER: {
                // for gov <-> master meeting
                const {
                    data: { pwa_link },
                } = message;
                this.dispatchLaunchMeeting(); // close current meeting(iframe);
                openTab(pwa_link);
                break;
            }

            case PWAMeetingEvent.EXTERNAL_AUTH: {
                // for sso external auth
                const {
                    data: { pwa_link },
                } = message;
                if (!pwa_link) return;
                const prefix = 'product=pwa';
                reload(`${pwa_link}?${prefix}`);
                break;
            }

            case PWAMeetingEvent.START_PRESENTING: {
                this.store.dispatch(setIsPresenting(true));

                // update presence
                const presenceManager = serviceContainer.getPresenceManager();
                if (presenceManager) {
                    presenceManager.updatePresence_MeetingPresenting_Start();
                }
                break;
            }

            case PWAMeetingEvent.STOP_PRESENTING: {
                this.store.dispatch(setIsPresenting(false));

                // update presence
                const presenceManager = serviceContainer.getPresenceManager();
                if (presenceManager) {
                    presenceManager.updatePresence_MeetingPresenting_End();
                }
                break;
            }

            case PWAMeetingEvent.MEETING_TOPIC_UPDATE: {
                const {
                    data: { name },
                } = message;

                if (name) {
                    this.store.dispatch(setMeetingInfoThunk({ ...meetingInfo, caption: name }));
                }
                break;
            }

            case PWAMeetingEvent.CHECK_IS_IN_CHANNEL: {
                // if pwa chat disabled，web-client's jump channel button need block.
                if (!isChatEnabled()) {
                    this.sendMsgToWebClient(PWAMeetingEvent.CHECK_IS_IN_CHANNEL, 'PWA_CHAT_DISABLED');
                }

                const eventBus = serviceContainer.getEventBus();
                eventBus.app.userInfoReady.subscribe(() => {
                    const { channelIds = [] } = message.data || {};
                    xmppAgent
                        .checkMemberIsInChannel(channelIds)
                        .then((result) => {
                            this.sendMsgToWebClient(PWAMeetingEvent.CHECK_IS_IN_CHANNEL, result);
                        })
                        .catch((error) => {
                            console.error('CHECK_IS_IN_CHANNEL error: ', error);
                            this.sendMsgToWebClient(PWAMeetingEvent.CHECK_IS_IN_CHANNEL, error);
                        });
                });

                break;
            }

            case PWAMeetingEvent.JUMP_CHAT_CHANNEL: {
                const { channelId } = message.data;
                if (!channelId) return;
                chatAgent.chatWithUserOrChannel({ id: channelId });
                break;
            }

            case PWAMeetingEvent.LCP_REQUEST: {
                if (transferMeetingData) {
                    this.sendMsgToWebClient(PWAMeetingEvent.LCP_RESPONSE, { transferMeetingData });
                    cookies.remove(WC_COOKIE_KEY.NAME);
                    cookies.remove(WC_COOKIE_KEY.EMAIL);
                }
                break;
            }

            case PWAMeetingEvent.LCP_RESPONSE: {
                if (this.lcpReqResolve) {
                    const { lcp } = message.data;
                    this.lcpReqResolve(lcp);
                    this.lcpReqTimer && clearTimeout(this.lcpReqTimer);
                }
                break;
            }
            case PWAMeetingEvent.FECC_GROUP_REQUEST: {
                const { data = [] } = message;
                // send to web client when get result
                // sendFarEndCameraControlGroupResponseFromPWA
                cameraControlGroupHelper.wclGetCameraControlGroupData(data);
                break;
            }
            // just support add one by one
            case PWAMeetingEvent.FECC_GROUP_ADD: {
                const { data = [] } = message;
                if (data.length < 1) return;
                try {
                    // check contacts
                    const jid = await cameraControlGroupHelper.checkContactByUserId(data[0]);
                    await cameraControlGroupHelper.addToCameraControlGroup(jid, false);
                    this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_ADD_RESPONSE, {
                        jid: data[0],
                        isSuccess: true,
                    });
                } catch (error) {
                    console.error('FECC_GROUP_ADD ==>', error);
                    this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_ADD_RESPONSE, {
                        jid: data[0],
                        isSuccess: false,
                    });
                }
                break;
            }
            // just support remove one by one
            case PWAMeetingEvent.FECC_GROUP_REMOVE: {
                const { data = [] } = message;
                if (data.length < 1) return;
                try {
                    // remove need not check if in contacts
                    await cameraControlGroupHelper.removeCameraControlGroup(data[0], false);
                    this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_REMOVE_RESPONSE, {
                        jid: data[0],
                        isSuccess: true,
                    });
                } catch (error) {
                    console.error('FECC_GROUP_REMOVE ==>', error);
                    this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_REMOVE_RESPONSE, {
                        jid: data[0],
                        isSuccess: false,
                    });
                }

                break;
            }

            case PWAMeetingEvent.ATTENDEE_WILL_TRANSFER_TO_WR: {
                showModal('transferParticipantToWaitingRoom');
                break;
            }

            case PWAMeetingEvent.ZOOM_PHONE_CALL:
            case PWAMeetingEvent.ZOOM_PHONE_CHANGE:
            case PWAMeetingEvent.ZOOM_PHONE_CALL_HANGUP: {
                const eventBus = serviceContainer.getEventBus();
                eventBus.meeting.receiveWebclientMessage.dispatchEvent(message);
                break;
            }

            default:
                break;
        }
    };

    handleMeetingUrl(meetingInfo: MeetingInfo) {
        const { pwd } = meetingInfo;
        const urlObj = new URL(window.location.href);

        if (urlObj.searchParams.has('pwd') || !pwd) {
            return;
        }

        urlObj.searchParams.set('pwd', pwd);
        let pathname = urlObj.pathname;
        const baseRoute = '/wc';
        if (pathname.startsWith(baseRoute)) {
            pathname = pathname.slice(baseRoute.length);
        }

        replaceRoute({
            pathname: pathname,
            search: `?${urlObj.searchParams.toString()}`,
        });
    }

    askWC2Invite = (jids: Array<{ jid: JID }>) => {
        const state = this.store.getState();
        const isUserSignin = isUserSigninSelector(state);
        if (jids.length < 1 || !isUserSignin) {
            return;
        }
        this.sendMsgToWebClient(PWAMeetingEvent.ASK_TO_INVITE_TO_MEETING, {
            jids,
        });
    };

    getContactsForWebclient = () => {
        let {
            contacts: { groups, contacts, presences, groupToContactTable },
            chat: { recentChat },
        } = this.store.getState();
        let isChannel = (jid: string) => /conference/.test(jid);

        let _zoomrooms: Array<JID> = [];
        let _contacts: Array<JID> = [];

        const groupList = Object.values(groups);

        const LIMIT = 500;
        for (let i = 0; i < groupList.length; i++) {
            const group = groupList[i];
            if (isGroupAddon(group)) {
                continue;
            }

            if (_zoomrooms.length >= LIMIT && _contacts.length >= LIMIT) {
                break;
            }

            const contactsLocal = groupToContactTable[group.id] || [];
            if (isGroupZoomRoom(group)) {
                // only take LIMIT
                _zoomrooms = _zoomrooms.concat(contactsLocal);
                if (_zoomrooms.length > LIMIT) {
                    _zoomrooms.splice(LIMIT);
                }
            } else {
                _contacts = _contacts.concat(contactsLocal).filter((item) => !isPendingContactJid(item));
                if (_contacts.length > LIMIT) {
                    _contacts.splice(LIMIT);
                }
            }
        }

        let _recentChat = recentChat.filter((jid) => !isChannel(jid)); // they are just jids

        console.log(' getContactsForWebclient _contacts _contacts', _contacts);

        // duplicate
        _contacts = [].concat(_recentChat, _contacts).filter((first, index, arr) => {
            return index === arr.findIndex((second) => second === first);
        });

        setTimeout(() => {
            const jids = _recentChat.map((jid) => {
                return {
                    jid,
                };
            });

            // query contact presence status and profile info
            this.store
                .dispatch(fetchContactsProfileThunk(jids))
                .then((contact) => {
                    console.log(' getContactsForWebclient fetchContactsProfileThunk contact', contact);

                    contact.forEach((vcard) => {
                        let { jid, picUrl: avatar, firstName, lastName, displayName } = vcard;
                        this.sendMsgToWebClient(PWAMeetingEvent.INVITE_USER_DETAILS_UPDATE, {
                            jid,
                            avatar,
                            firstName,
                            lastName,
                            displayName,
                        });
                    });
                })
                .catch((error) => {
                    console.error('getContactsForWebclient fetchContactsProfileThunk  error: ', error);
                });
        }, 0);

        const mapJidToContact = (jid: JID) => {
            const {
                firstName = '',
                lastName = '',
                type,
                picUrl = '',
                displayName = '',
                email = '',
            } = contacts[jid] || {};
            const { presenceType = WebimPresenceType.offline } = presences[jid] || {};
            return {
                jid,
                firstName,
                lastName,
                type,
                presenceType,
                displayName,
                email,
                avatar: picUrl,
            };
        };

        return {
            zoomrooms: _zoomrooms.map(mapJidToContact),
            contacts: _contacts.map(mapJidToContact),
        };
    };

    onPresenceChange = (preseceList: ContactPresence[]) => {
        preseceList.forEach((p) => {
            this.sendMsgToWebClient(PWAMeetingEvent.INVITE_USER_DETAILS_UPDATE, { ...p });
        });
    };

    onInvitationReceived = (e: WebimEvent) => {
        const invitation = (e as WebimMessageEvent).data;
        if (!invitation || invitation.delay) return;
        let { fromJid, toJid, isTimeoutDecline } = invitation;
        if (invitation && invitation.action === 'decline') {
            if (getBareJidFromJid(fromJid) === getBareJidFromJid(toJid)) return;
            let params = {
                action: isTimeoutDecline ? 'unavailable' : 'decline',
                jid: invitation.fromJid,
                displayName: invitation.fromName,
            };
            this.sendMsgToWebClient(PWAMeetingEvent.INVITE_USER_DECLINED, params);
        }
    };

    onGroupChange = () => {
        this.sendMsgToWebClient(PWAMeetingEvent.INVITE_CONTACTS, this.getContactsForWebclient());
    };

    onXmppStatusChange = (e: WebimEvent) => {
        const { status, statusInfo, willReconnect } = (e as WebimConnectionEvent).data;
        this.sendXmppStatusToWebclient({ status, statusInfo, willReconnect });
    };

    sendXmppStatusToWebclient({ status, statusInfo, willReconnect, isContactLoading }: XmppStatus) {
        this.sendMsgToWebClient(PWAMeetingEvent.PWA_STATUS, {
            status,
            statusInfo,
            willReconnect,
            isContactLoading,
        });
    }

    // these events will be emitted to webclient from a meeting JOINING to meeting END|LEAVE
    registerXmppEventsForWebclient_1(isRegister: boolean) {
        if (xmppAgent.isInitiated()) {
            if (isRegister) {
                xmppAgent.webimInstance.on(WebimEventType.ConnectionStatusChange, this.onXmppStatusChange);
                xmppAgent.webimInstance.on(WebimEventType.MessageChange, this.onInvitationReceived);
            } else {
                xmppAgent.webimInstance.off(WebimEventType.ConnectionStatusChange, this.onXmppStatusChange);
                xmppAgent.webimInstance.off(WebimEventType.MessageChange, this.onInvitationReceived);
            }
        }
    }

    // these events will be emitted to webclient during its Invite dialog opens: INVITE_START to INVTIE_STOP
    registerXmppEventsForWebclient_2(isRegister: boolean) {
        // handle subscriptio of presence changes
        const eventBus = serviceContainer.getEventBus();
        if (isRegister) {
            eventBus.zpns.presenceChange.subscribe(this.onPresenceChange);
        } else {
            eventBus.zpns.presenceChange.unsubscribe(this.onPresenceChange);
        }

        // handle contacts changes
        if (xmppAgent.isInitiated()) {
            if (isRegister) {
                xmppAgent.webimInstance.on(WebimEventType.GroupChange, this.onGroupChange);
            } else {
                xmppAgent.webimInstance.off(WebimEventType.GroupChange, this.onGroupChange);
            }
        }
    }

    eventListenersMap = {};

    signIn() {
        const url = getSigninPath();
        reload(url);
    }

    signOut() {
        const url = getSignOutPath();
        reload(url);
    }

    open = (_url: string) => {
        // this no longer used, can not search anywhere
        // this.openedBrowsingContext = isInStandaloneMode() ? openWindow(url) : openTab(url);
    };

    openSchedulePage = () => {
        const url = getSchedulePagePath();
        openTab(url);
    };

    canMeeting = async () => {
        const {
            common: { userInfo },
            meeting: { isStartDisabled },
        } = this.store.getState();

        if (!!userInfo?.meetingForceBreakUser && !isTeslaMode()) {
            if (isStartDisabled) return Promise.resolve(false);
            this.store.dispatch(setIsStartDisabled(true));
            try {
                const can = await this.store.dispatch(checkMeetingForceBreakThunk());
                this.store.dispatch(setIsStartDisabled(false));
                if (!can) return Promise.resolve(false);
            } catch (error) {
                console.error(error);
            }
            this.store.dispatch(setIsStartDisabled(false));
        }

        return Promise.resolve(true);
    };

    startMeeting = async (params = {}, withVideo = false, preState = {}) => {
        // check whether allow start meeting
        const can = await this.canMeeting();
        if (!can) return;

        const url = getStartMeetingPath(params, withVideo);
        this.startMeetingWithUrl(url, params, preState);
    };

    openScheduleEditPage = (number: MeetingId, isWebinar: boolean) => {
        if (!number) return;
        const url = getScheduleEditPagePath(number, isWebinar);
        openTab(url);
    };

    startMeetingWithMeetingNumber(number: MeetingId, params = {}, preState = {}) {
        const url = getStartMeetingPathViaMeetingNumber(number, params);
        this.startMeetingWithUrl(url, params, preState);
    }

    setParamsInCookieBeforeMeeting() {
        if (!isTeslaMode()) {
            return;
        }

        const data = sessionStorage.getItem('teslaData') || '{}';
        const { name } = JSON.parse(data) || {};

        if (!name) {
            return;
        }

        const now = new Date();
        const duration = 1000 * 60 * 60 * 2; // 2 hours
        const cookieOptions = {
            expires: new Date(now.getTime() + duration),
            path: '/',
            sameSite: 'none' as const,
            secure: true,
        };

        cookies.remove('wc_join');
        cookies.set('wc_dn', name, cookieOptions);

        const enableTeslaNameOrder = window?.PwaConfig?.enableTeslaNameOrder || false;
        if (!enableTeslaNameOrder) {
            cookies.set('_zm_wc_remembered_name', name, cookieOptions);
        }
    }

    startMeetingWithUrl(url: string, _params = {}, preState = {}) {
        console.log('SM');
        this.setParamsInCookieBeforeMeeting();
        if (window.PwaConfig?.enableMeetingRoute) {
            this.store.dispatch(setLaunchMeeting(preState));
            history.push(`${getRouteFromURL(url)}`);
        } else {
            const launch = {
                showWebclient: true,
                type: 'start',
                meetingUrl: url,
                ...preState,
            };
            this.store.dispatch(setLaunchMeeting(launch));
        }
        webclientLogger
            .log({ isStandalone: isInStandaloneMode(), isTesla: isTeslaMode(), toStart: true }, ['Start'])
            .then(() => {
                uploadWebclientLogs();
            });
    }

    dispatchLaunchMeeting(params?: LaunchMeetingParams) {
        if (!params && checkIsMeetingRoute(window.location.pathname, window.location.href)) {
            routeBack(true);
        }

        this.store.dispatch(setLaunchMeeting(params));
    }

    joinMeeting(meetingId: MeetingId, params = {}) {
        let url = '';
        if (/^\d+$/.test(meetingId)) {
            url = getJoinMeetingPath(meetingId, params);
        } else {
            url = getJoinMeetingPersonLink(meetingId, params);
        }
        console.log('JM');
        this.joinMeetingWithMeetingUrl(url, params);
    }

    joinMetingPersonalLink(personal: MeetingId, params = {}) {
        const url = getJoinMeetingPersonLink(personal, params);
        console.log('JMP');
        this.joinMeetingWithMeetingUrl(url, params);
    }

    joinMeetingWithMeetingUrl(url: string, params?: JoinMeeringParams) {
        this.setParamsInCookieBeforeMeeting();
        if (window.PwaConfig?.enableMeetingRoute) {
            const _url = `${getRouteFromURL(url)}${params?.invite === 1 ? '?isInvitedByXmpp=1' : ''}`;
            if (params?.replaceUrl) {
                replaceRoute(_url);
                return;
            }
            history.push(_url);
        } else {
            this.store.dispatch(
                setLaunchMeeting({
                    showWebclient: true,
                    type: 'join',
                    meetingUrl: url,
                    isInvitedByXmpp: params?.invite === 1 ? 1 : 0,
                }),
            );
        }
        webclientLogger
            .log({ isStandalone: isInStandaloneMode(), isTesla: isTeslaMode(), toJoin: true }, ['Join'])
            .then(() => {
                uploadWebclientLogs();
            });
    }

    joinMeetingDelayed(meetingId: MeetingId, params = {}, delay = 600) {
        return new Promise((resolve) => {
            setTimeout(() => {
                this.joinMeeting(meetingId, params);
                resolve(true);
            }, delay);
        });
    }

    /**
     *
     * @param {*} reason
     * reason.reason: string to describe your intension
     * reason.data: extra info
     */
    setEndMeetingReason(reason: EndMeetingReason) {
        this.toEndMeetingReason = reason;
        return reason;
    }

    getEndMeetingReason() {
        return this.toEndMeetingReason;
    }

    clearEndMeetingReason() {
        const reason = this.toEndMeetingReason;
        this.toEndMeetingReason = null;
        return reason;
    }

    endCurrentOngoingMeeting() {
        let isDuringMeeting = isDuringMeetingSelector(this.store.getState());
        if (!isDuringMeeting) {
            this.dispatchLaunchMeeting();
            this.store.dispatch(setMeetingInfo(null));
        } else {
            this.clearEndMeetingReason();
            this.sendMsgToWebClient(PWAMeetingEvent.PWA_LEAVE_MEETING, {
                reason: 'Accept_ZCC',
            });
            this.setEndMeetingReason({ reason: 'Accept_ZCC', data: null });
            webclientLogger.log(
                {
                    desc: 'ask webclient to leave for joining ZCC meeting',
                },
                ['End', 'Prompt'],
            );
        }
    }

    leaveCurrentMeetingAudio() {
        const {
            meeting: { meetingInfo },
        } = this.store.getState();
        if (!meetingInfo) {
            return false;
        }

        return this.sendMsgToWebClient(PWAMeetingEvent.LEAVE_AUDIO);
    }

    /**
     * we ask webclient to leave/end meeting.
     * it will show a dialog for user to choose. then the meeting ends.
     */
    requestToEndOrLeaveMeeting(reason: EndMeetingReason) {
        this.clearEndMeetingReason();
        this.store.dispatch(setIsMeetingMinimized(false));
        this.sendMsgToWebClient(PWAMeetingEvent.BACK_TO_MEETING);
        this.sendMsgToWebClient(PWAMeetingEvent.END_MEETING_REQUEST);
        this.setEndMeetingReason(reason);
        webclientLogger.log(
            {
                desc: 'notify user to end/leave meeting for upgrading phone to meeting',
            },
            ['End', 'Prompt'],
        );
    }

    // this will be called when inmeeting user accepts a new invitation
    endCurrentAndJoinThisMeeting(meetingInfoFromInvitation: MeetingInfoFromInvitation, isInvited = false) {
        let { meetingNo, password, ...rest } = meetingInfoFromInvitation;
        let isDuringMeeting = isDuringMeetingSelector(this.store.getState());
        const inviteParams = { invite: isInvited ? 1 : 0 };

        // we have not reach webclient page, so we will not receive end/leave message
        if (!isDuringMeeting) {
            this.dispatchLaunchMeeting();
            this.store.dispatch(setMeetingInfo(null));
            this.joinMeetingDelayed(meetingNo, { pwd: password, ...rest, ...inviteParams });
        } else {
            this.clearEndMeetingReason();
            this.sendMsgToWebClient(PWAMeetingEvent.PWA_LEAVE_MEETING, {
                reason: 'Accept_Invitation',
            });
            this.setEndMeetingReason({
                reason: 'Accept_Invitation',
                data: null,
            });
            this.waitingToJoinMeetingInfo = { ...inviteParams, ...meetingInfoFromInvitation };

            webclientLogger.log({ desc: 'ask webclient to end/leave meeting for accepting another xmpp inviation' }, [
                'End',
                'Prompt',
            ]);
        }
    }
    // Request lcp from rwg
    requestLcpFromRWG() {
        this.sendMsgToWebClient(PWAMeetingEvent.LCP_REQUEST);
        return new Promise<TransferLcp>((resolve, reject) => {
            this.lcpReqResolve = resolve;
            this.lcpReqTimer = window.setTimeout(() => {
                reject('Timeout');
            }, 30000);
        });
    }

    sendFarEndCameraControlGroupResponseFromPWA(userIds: Array<string>) {
        this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_RESPONSE, userIds);
    }

    addFarEndCameraControlGroupFromPWA(userId: string) {
        this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_PWA_ADD, {
            jid: userId,
            isSuccess: true,
        });
    }

    removeFarEndCameraControlGroupFromPWA(userId: string) {
        this.sendMsgToWebClient(PWAMeetingEvent.FECC_GROUP_PWA_REMOVE, {
            jid: userId,
            isSuccess: true,
        });
    }

    requestWebclientToMinimize() {
        this.sendMsgToWebClient(PWAMeetingEvent.MEETING_MINIMIZE_REQUEST);
        this.store.dispatch(setIsMeetingMinimized(true));
    }
}
