import Storage from './BaseStorage';
import { NOTHING, decodeBase64, encodeBase64 } from './utils';

export interface IStorageInitProps {
    storageKey?: string;
}

export class MemoryStorage extends Storage {
    constructor({ storageKey }: IStorageInitProps) {
        super();
        this.saveAfterUpdate = false;
        if (storageKey) {
            this.storageKey = storageKey;
        }
        this.load();
    }

    clear() {
        this.data = {};
    }
}

export class SessionStorage extends Storage {
    constructor({ storageKey }: IStorageInitProps) {
        super();
        this.saveAfterUpdate = true;
        if (storageKey) {
            this.storageKey = storageKey;
        }
        this.load();
    }

    load() {
        this.data = JSON.parse(decodeBase64(window.sessionStorage.getItem(this.storageKey) || NOTHING));
    }
    save() {
        sessionStorage.setItem(this.storageKey, encodeBase64(JSON.stringify(this.data)));
    }
    clear() {
        this.data = {};
        sessionStorage.removeItem(this.storageKey);
    }
}

export class LocalStorage extends Storage {
    constructor({ storageKey }: IStorageInitProps) {
        super();
        this.saveAfterUpdate = true;
        if (storageKey) {
            this.storageKey = storageKey;
        }
        this.load();
    }

    load() {
        this.data = JSON.parse(decodeBase64(window.localStorage.getItem(this.storageKey) || NOTHING));
    }
    save() {
        window.localStorage.setItem(this.storageKey, encodeBase64(JSON.stringify(this.data)));
    }
    clear() {
        this.data = {};
        window.localStorage.removeItem(this.storageKey);
    }
}
