import { useRef } from 'react';
import { useAppSelector } from '../../Phone/types';
import { isMobileFeedbackUser } from '../util';

export const useFeedback = () => {
    const feedback = useAppSelector((state) => state.modal.modals.feedback);

    const stored = useRef(null);

    if (!stored.current) stored.current = feedback;

    const shouldShowThankYouPage = isMobileFeedbackUser(stored.current);

    return {
        feedbackModal: stored.current || {},
        shouldShowThankYouPage: shouldShowThankYouPage,
    };
};
