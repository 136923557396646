import { DBManager, STORE_NAME_PENDING_CONTACT_INFO } from '../../../utils/db';

export const PendingContactDBManager = {
    key: 'pendingContactData',
    async addPendingContact(email: string) {
        const _data = await DBManager.getDataFromeDB(this.key, STORE_NAME_PENDING_CONTACT_INFO);

        if (_data) {
            const rawData = await DBManager.load(this.key, STORE_NAME_PENDING_CONTACT_INFO);
            if (!rawData) {
                DBManager.save([email], this.key, STORE_NAME_PENDING_CONTACT_INFO);
                return;
            }

            rawData.push(email);
            const data = [...new Set(rawData)];
            DBManager.save(data, this.key, STORE_NAME_PENDING_CONTACT_INFO);
        } else {
            DBManager.save([email], this.key, STORE_NAME_PENDING_CONTACT_INFO);
        }
    },
    async getPendingContactList() {
        const _data = await DBManager.getDataFromeDB(this.key, STORE_NAME_PENDING_CONTACT_INFO);
        if (!_data) {
            return [];
        }
        return await DBManager.load(this.key, STORE_NAME_PENDING_CONTACT_INFO);
    },
    async deletePendingContact(email: string | Array<string>) {
        const _data = await DBManager.getDataFromeDB(this.key, STORE_NAME_PENDING_CONTACT_INFO);

        if (_data) {
            const rawData = await DBManager.load(this.key, STORE_NAME_PENDING_CONTACT_INFO);

            if (!rawData || !Array.isArray(rawData)) {
                return;
            }

            let newData;
            if (Array.isArray(email)) {
                newData = rawData.filter((item: string) => !email.includes(item));
            } else {
                if (!rawData.includes(email)) {
                    return;
                }
                newData = rawData.filter((item: string) => item !== email);
            }

            DBManager.save(newData, this.key, STORE_NAME_PENDING_CONTACT_INFO);
        } else {
            return;
        }
    },
    clear() {
        DBManager.clear(this.key, STORE_NAME_PENDING_CONTACT_INFO);
    },
};
