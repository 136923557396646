import serviceContainer from '../../../services/container';
import { maybeWaitUserInfoReady } from '../../../services/utils/index';
import { CameraControlGroupDBManager } from '../DBManagers';
import { showFarEndCameraControlGroupSelector } from '../redux/contact-selector';
import { getUserIdFromJid, isZoomRoomEmail } from '../../../utils/index';
import { isDuringMeetingSelector } from '../../Meeting/redux/meeting-selector';
import { ICON_TYPE, toast } from 'Toast';
import { ADD_CAMERA_CONTROL_GROUP_F, REMOVE_CAMERA_CONTROL_GROUP_F, CAMERA_CONTROL_GROUP } from '../../../resource';
import {
    setThisGroupContacts,
    setCameraControlGroupMemberCount,
    setGroups,
    refreshCameraContolContactGroupMember,
    deleteCameraContolContact,
} from '../redux/contact-store';
import { fetchContactsProfileThunk } from '../redux/contact-thunk';
import { CAMERA_CONTROL_GROUP_ID } from '../utils/stringUtils';
import { GroupFrom, IContact } from '../types';

class CameraControlGroupHelper {
    private memorySet = new Set<string>();
    private hasGetFromIndexedDB = false; // just read IndexDB once, get from memorySet at other times

    hasExisted(jid: string): boolean {
        return this.memorySet.has(jid);
    }

    // get data from indexDB and memory
    private async getData(): Promise<any> {
        try {
            if (this.hasGetFromIndexedDB) return [...this.memorySet];

            const reduxStore = serviceContainer.getReduxStore();
            const {
                common: {
                    userInfo: { userId },
                },
            } = reduxStore.getState();

            CameraControlGroupDBManager.setUserId(userId);

            const data: Array<string> = await CameraControlGroupDBManager.get();
            console.log('getCameraControlGroupThunk data ==>', data);
            this.hasGetFromIndexedDB = true;

            // put into memorySet
            data.forEach((value) => {
                this.memorySet.add(value);
            });

            return data;
        } catch (error) {
            console.error('getData error:', error);
            return [];
        }
    }

    // add data to indexDB and memory
    private async addData(jid: string): Promise<any> {
        try {
            if (!jid) return false;

            const reduxStore = serviceContainer.getReduxStore();
            const {
                common: { userInfo },
            } = reduxStore.getState();

            if (!userInfo?.userId) return false;
            if (this.hasExisted(jid)) return false;

            // store to indexDB
            await CameraControlGroupDBManager.add(jid);

            // put into memorySet
            this.memorySet.add(jid);

            return true;
        } catch (error) {
            console.error('addData error:', error);
            return false;
        }
    }

    // remove data from indexDB and memory
    private async removeData(jid: string): Promise<any> {
        try {
            if (!jid) return false;

            const reduxStore = serviceContainer.getReduxStore();
            const {
                common: { userInfo },
            } = reduxStore.getState();

            if (!userInfo?.userId) return false;

            const memory = [...this.memorySet];
            const currentUserId = getUserIdFromJid(jid);
            // Jid is userId if Jid get from web client, so we need to compare with userId
            const filterResult = memory.filter((item) => currentUserId === getUserIdFromJid(item));
            // not existing in memory
            if (filterResult.length === 0) return false;

            // jid
            const key = filterResult[0];
            // remove from IndexedDB
            await CameraControlGroupDBManager.delete(key);

            // remove from memory
            if (this.hasExisted(key)) {
                this.memorySet.delete(key);
            }

            return key;
        } catch (error) {
            console.error('removeData error:', error);
            return false;
        }
    }

    private filterJid(jids: Array<string>, resource: Array<string>) {
        console.log('filterJid jids ==>', jids);
        console.log('filterJid resource ==>', resource);

        const resourceString = resource.join('');
        return jids.filter((item) => {
            if (!item) return false;
            return resourceString.includes(item);
        });
    }

    private renderCameraControlGroup(data: Array<string>) {
        if (data.length <= 0) return;

        const reduxStore = serviceContainer.getReduxStore();
        const {
            contacts: { contacts, groups },
        } = reduxStore.getState();

        const mermbers = data.map((item) => {
            if (contacts[item]) {
                return contacts[item];
            }

            return {
                jid: item,
            };
        });

        console.log('setCameraControlGroupThunk mermbers ==>', mermbers);
        // set and display camera control group memeber
        reduxStore.dispatch(
            setThisGroupContacts({
                groupId: CAMERA_CONTROL_GROUP_ID,
                contacts: mermbers as any,
            }),
        );

        const group = groups[CAMERA_CONTROL_GROUP_ID];
        // set camera control group info, just change mermber count if exist
        if (group) {
            console.log('group.total  ==> ', group.total);
            const total = parseInt(group.total as unknown as string) + data.length;
            reduxStore.dispatch(setCameraControlGroupMemberCount(total));
        } else {
            // create camera control group
            const group = {
                name: CAMERA_CONTROL_GROUP,
                id: CAMERA_CONTROL_GROUP_ID,
                // type: group.type,
                groupFrom: GroupFrom.local,
                total: `${data.length}`,
            };
            // display camera control group
            reduxStore.dispatch(setGroups({ groups: [group] } as any));
        }
    }

    /**
     * pwa use
     * called after getUserWebSettingsThunk, then user has get user id
     */
    async pwaGetCameraControlGroupData() {
        try {
            // fecc indexedDB operate dependence on userInfo's userId
            await maybeWaitUserInfoReady();

            const reduxStore = serviceContainer.getReduxStore();
            const state = reduxStore.getState();

            // check fecc permissions
            const permission = showFarEndCameraControlGroupSelector(state);
            if (!permission) return;

            const result = await this.getData();

            // refresh camera control group UI,
            if (result.length > 0) {
                // set camera control group UI (group、member、count)
                this.renderCameraControlGroup(result);

                const params = result.map((jid: string) => {
                    return {
                        jid,
                    };
                });

                // get camera control group contact data (vcard、 presence)
                reduxStore.dispatch(fetchContactsProfileThunk(params));
            }
        } catch (error) {
            console.log('pwaGetCameraControlGroupData error', error);
        }
    }

    /**
     * webclient use
     *
     * 1. userinfo has requested, get data directly, web client will check permissions
     * 2. because webclient loaded first, so maybe userInfo is null when webclient loaded, such as when reload page, so async get data.
     */
    async wclGetCameraControlGroupData(jids: Array<string> = []) {
        try {
            // fecc indexedDB operate dependence on userInfo's userId
            await maybeWaitUserInfoReady();

            const data = await this.getData();
            const result = this.filterJid(jids, data);
            const userIds = result.map((item: string) => getUserIdFromJid(item));

            const meetingAgent = serviceContainer.getMeetingAgent();
            meetingAgent.sendFarEndCameraControlGroupResponseFromPWA(userIds);
        } catch (error) {
            console.log('wclGetCameraControlGroupData error', error);
        }
    }

    /**
     * 1. Add fecc in pwa portal，post meesage to web client if in meeting
     * 2. Add fecc in web client, return response to web client if add successfully
     *
     *  here jid is full jid, like xth3tn0uqtsykslzy_biyg@xmppdev.zoom.us
     */
    async addToCameraControlGroup(jid: string, addFromPWA = true) {
        const result = await this.addData(jid);
        if (!result) return Promise.reject();

        const reduxStore = serviceContainer.getReduxStore();
        const {
            meeting: { showWebclient },
            contacts: { contacts },
        } = reduxStore.getState();

        // set camera control group UI (group、member、count)
        this.renderCameraControlGroup([jid]);

        const isDuringMeeting = isDuringMeetingSelector(reduxStore.getState());
        // send message to web client
        if (addFromPWA && showWebclient && isDuringMeeting) {
            const id = getUserIdFromJid(jid);
            const meetingAgent = serviceContainer.getMeetingAgent();
            meetingAgent.addFarEndCameraControlGroupFromPWA(id);
        }

        const { displayName = '' } = contacts[jid];
        // add success
        toast(
            {
                type: ICON_TYPE.INFO,
                desc: ADD_CAMERA_CONTROL_GROUP_F(displayName),
            },
            {
                closeButton: true,
            },
        );

        return Promise.resolve(jid);
    }

    /**
     * 1. Remove fecc in pwa portal，post meesage to web client if in meeting
     * jid like :  xth3tn0uqtsykslzy_biyg@xmppdev.zoom.us
     *
     * 2. Remove fecc in web client, return response to web client if add successfully
     * jid like: xth3tn0uqtsykslzy_biyg
     */
    async removeCameraControlGroup(jid: string, addFromPWA = true) {
        const key = await this.removeData(jid);
        if (!key) return Promise.reject();

        const reduxStore = serviceContainer.getReduxStore();
        const {
            meeting: { showWebclient },
            contacts: { contacts },
        } = reduxStore.getState();

        const current = [...this.memorySet];

        // refresh camera control group UI
        if (current.length > 0) {
            reduxStore.dispatch(refreshCameraContolContactGroupMember(current));
        }
        // delete group
        else if (current.length === 0) {
            reduxStore.dispatch(deleteCameraContolContact());
        }

        const isDuringMeeting = isDuringMeetingSelector(reduxStore.getState());
        // send message to web client
        if (addFromPWA && showWebclient && isDuringMeeting) {
            const id = getUserIdFromJid(key);

            const meetingAgent = serviceContainer.getMeetingAgent();
            meetingAgent.removeFarEndCameraControlGroupFromPWA(id);
        }

        const displayName = contacts[key]['displayName'] || '';

        // remove success
        toast(
            {
                type: ICON_TYPE.INFO,
                desc: REMOVE_CAMERA_CONTROL_GROUP_F(displayName),
            },
            {
                closeButton: true,
            },
        );

        return Promise.resolve();
    }

    /**
     *
     * web client add camera control group, need check whether is contact
     * add is one by one
     *
     * get contact vcard if contact
     * */
    async checkContactByUserId(userId: string) {
        if (!userId) return Promise.reject();

        const xmppAgent = serviceContainer.getXmppAgent();
        // check if contact
        const data: any = await xmppAgent.checkContactByJids([userId]);
        const checkData = data.filter((item: { [key: string]: boolean }) => item[userId] === true);

        if (checkData.length === 0) return Promise.reject();

        const reduxStore = serviceContainer.getReduxStore();

        // get vcard information
        const params = [{ jid: userId }];
        const contacts = await reduxStore.dispatch(fetchContactsProfileThunk(params, true));
        const result = contacts.filter((item: IContact) => getUserIdFromJid(item.jid) === userId);

        if (result.length > 0 && result[0].email && !isZoomRoomEmail(result[0].email)) {
            return Promise.resolve(result[0].jid);
        }

        return Promise.reject();
    }
}

export const cameraControlGroupHelper = new CameraControlGroupHelper();
