// Todo: to delete this after walker complete his work
const getDocsDomain_Optionally = () => {
    const hostname = window.location.hostname;
    const maps = [
        {
            predicate: /^devep/,
            value: 'https://devepdocs.zoomdev.us',
        },
        {
            predicate: /^(app\.)?zoomdev\.us/,
            value: 'https://docs.zoomdev.us',
        },
        {
            predicate: /^(app\.)zoom\.us/,
            value: 'https://dg01docs.zoom.us/',
        },
        {
            predicate: /.*/,
            value: 'https://docs.zoom.us',
        },
    ];

    return maps.find(({ predicate, value }) => {
        if (predicate.test(hostname)) {
            return value;
        } else {
            return '';
        }
    }).value;
};

export const getDocsEntryHtmlUrl = () => {
    const docsDomain = window.PwaConfig.docsDomain;
    return docsDomain || getDocsDomain_Optionally();
};

export const DocsAppName = 'docs';
