export const CONTACT_CENTER_HTML_LOCATION = '/cci/multi/zcc-pwa';
export const CONTACT_CENTER_NAME = 'contact-center';
export const CHAT_HISTORY_NAME = 'chat-history';

const getDomain = () => {
    return window.location.hostname;
};

export const getZCCUrl = () => {
    const domain = getDomain();
    const path = CONTACT_CENTER_HTML_LOCATION;

    return `https://${domain}${path}`;
};

let _previewHtmlURL = '';
export const getPreviewHtmlUrl = () => {
    return _previewHtmlURL;
};
export const setPreviewHtmlUrl = (url: string) => {
    _previewHtmlURL = url;
};
