import React, { memo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../../routes';
import styles from './docs-app.module.scss';
import { docsAgent } from '../DocsAgent';
import { useAppSelector } from '../../../store';
import LoadingSpike from '../../../components/LoadingSpike';
import { DocsAppName, getDocsEntryHtmlUrl } from '../../../configs';

const Docs =  () => {
    const match = useRouteMatch(RoutePath.Docs);
    const isMatch = Boolean(match);
    const style = {
        display: isMatch ? 'block' : 'none',
    };

    const isLoading = useAppSelector((state) => {
        return state.docs.status === 'loading';
    });

    useEffect(() => {
        docsAgent.init();
        return () => {
            docsAgent.uninit();
        };
    }, []);

    return (
        <ErrorBoundary fallback={<div style={style} />}>
            {/* @ts-ignore */}
            <micro-app-doc className={styles.docsApp} class={styles.docsApp} data-main-body style={style}>
                {isLoading ? <LoadingSpike className={styles.loading} size={16} /> : null}
                <micro-app
                    style={style}
                    name={DocsAppName}
                    baseroute={`/wc/docs`}
                    url={getDocsEntryHtmlUrl()}
                    iframe
                    disable-scopecss
                    router-mode="pure"
                />
                {/* @ts-ignore */}
            </micro-app-doc>
        </ErrorBoundary>
    );
};

export default memo(Docs);
