import { useEffect } from 'react';
import { isUserSigninSelector } from '../../store/common/common-store';
import { useAppSelector } from '../../store/store-hooks';
import { cookies } from '../../utils/Cookies/cookie';

function getCookieDomain() {
    const hostname = window.location.hostname;
    return '.' + hostname.split('.').slice(-2).join('.');
}

const finger_print_cookie_key = '_zm_fingerprint';
const finger_print_cookie_duration = 365 * 24 * 3600 * 1000;

export const useFingerPrint = () => {
    const isUserSignin = useAppSelector(isUserSigninSelector);

    useEffect(() => {
        if (cookies.get(finger_print_cookie_key) || isUserSignin) {
            return;
        }

        if (window.FingerprintJS) {
            const fpPromise = window.FingerprintJS.load();
            // Get the visitor identifier when you need it.
            fpPromise
                .then(function (fp) {
                    return fp.get();
                })
                .then(function (result) {
                    window.visitorInfo = result;
                    const ue_cookie_time = new Date();
                    ue_cookie_time.setTime(ue_cookie_time.getTime() + finger_print_cookie_duration);
                    cookies.set(finger_print_cookie_key, result.visitorId, {
                        expires: ue_cookie_time,
                        path: '/',
                        domain: getCookieDomain(),
                        secure: true,
                    });
                });
        }
    }, [isUserSignin]);

    return;
};
