import React from 'react';
import { Modal } from 'Modal';
import Button from '../../../components/Button/Button';
import { SESSION_EXPIRE_TIME_SUBTITLE, SESSION_EXPIRE_TIME_CONTENT, SIGN_IN_BUTTON } from '../../../resource';
import './index.scss';
import { setUserInfoThunk } from '../../../store/common/common-store';
import useSessionExpireTimeControl, {
    formatExpiredTime,
} from '../../SignedIn/SessionExpireTimerBanner/useSessionExpireTimeControl';
import I18nStrings from '../../../components/I18nStrings';

import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { IUserInfo } from '../../../store/common/common-store';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
dayjs.extend(Duration);

interface IProps {
    userInfo: IUserInfo;
}

const SessionExpireTimerModal = ({ userInfo }: IProps) => {
    const dispatch = useAppDispatch();
    const [duration, _isDisplay, relogin] = useSessionExpireTimeControl();

    const signin = () => {
        relogin();
    };

    const later = () => {
        dispatch(setUserInfoThunk({ ...userInfo, sessionExpireTime: duration / 1000 }));
        dispatch(setModal({ name: 'sessionExpireTimeModal', data: { show: false, hasClickExpiredModalLater: true } }));
    };

    return (
        <Modal isOpen={true} needBackdrop={true} shouldCloseOnOverlayClick={false} isDraggable={false} centered={true}>
            <div className="modal-session-expire-time">
                <span className="subtitle" tabIndex={0}>
                    {SESSION_EXPIRE_TIME_SUBTITLE}
                </span>

                <div className="content" tabIndex={0}>
                    <I18nStrings
                        i18nString={SESSION_EXPIRE_TIME_CONTENT}
                        stubs={[<span className="content-time">{formatExpiredTime(duration / 1000)}</span>]}
                    />
                </div>

                <div className="modal-buttons">
                    <Button extraCls="signin" size={Button.Size24} type={Button.Normal} onClick={signin}>
                        {SIGN_IN_BUTTON}
                    </Button>
                    <Button extraCls="later" size={Button.Size24} type={Button.Dark} onClick={later}>
                        Later
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SessionExpireTimerModal;
