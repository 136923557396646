import React from 'react';
import { useAppSelector } from '../../../store/store-hooks';
import SessionExpireTimerBanner from './SessionExpireTimerBanner';

export default () => {
    const userInfo = useAppSelector((state) => state.common.userInfo);
    const sessionExpireTimeModal = useAppSelector((state) => state.modal.modals.sessionExpireTimeModal);
    const show = userInfo?.sessionExpireTime && !sessionExpireTimeModal.show;

    if (!show) {
        return null;
    }
    return <SessionExpireTimerBanner />;
};
