/* eslint-disable react/prop-types */
import React, { useRef, useState, useImperativeHandle, useEffect } from 'react';
import CaptchaContainer from './Captcha/index';
import { CaptchaTypes } from './Captcha/type';

function getCaptchaKey(type: string) {
    let ret = window.gRecaptchaInvisible;
    if (type === CaptchaTypes.HCaptcha) {
        ret = window.hCaptchaSiteKey;
    } else if (type === CaptchaTypes.VisibleRecaptcha) {
        ret = window.gRecaptchaVisible;
    }
    return ret;
}

const Captcha = React.forwardRef(({ captchaType = CaptchaTypes.Recaptcha }: any, ref) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const show = captchaType !== null;
    const captchaRef = useRef<any>();
    const captchaValueRef = useRef<any>('');
    const isGoogleOrHCaptcha = captchaType === CaptchaTypes.Recaptcha || captchaType === CaptchaTypes.HCaptcha;

    async function getRecaptchaToken() {
        return new Promise((resolve) => {
            captchaRef?.current?.getCaptchaCode(async (data: any) => {
                if (data?.success) {
                    resolve(data?.code);
                } else {
                    setErrorMsg('Captcha validate error, please try again.');
                    resolve(data?.code);
                }
            });
        });
    }

    const onSmartCaptchaInputChange = () => {
        if (errorMsg) {
            setErrorMsg(null);
        }
    };

    const getCaptchaValidateResult = async function () {
        let ret;
        if (isGoogleOrHCaptcha) {
            ret = await getRecaptchaToken();
        } else if (captchaType === CaptchaTypes.SmartCaptcha || captchaType === CaptchaTypes.VisibleRecaptcha) {
            captchaRef?.current?.getCaptchaCode((data: any) => {
                ret = data?.code;
            });
        }
        return ret;
    };

    useImperativeHandle(ref, () => ({
        refreshSmartCaptcha() {
            captchaRef?.current?.refreshSmartCaptcha();
        },
        setErrorMessage(msg: any) {
            setErrorMsg(msg);
        },
        getCaptchaValidateResult: () => captchaValueRef.current,
        restCaptcha() {
            captchaRef?.current?.restCaptcha();
        },
    }));

    useEffect(() => {
        let isUnmounted = false;
        const fetchCaptcha = (count = 10) => {
            if (isUnmounted) {
                return;
            }
            getCaptchaValidateResult().then((recaptcha: any) => {
                if (recaptcha === 'captcha error') {
                    if (count > 0) {
                        count--;
                        setTimeout(() => {
                            fetchCaptcha(count);
                        }, 50);
                    }
                } else {
                    captchaValueRef.current = recaptcha;
                }
            });
        };
        fetchCaptcha();

        return () => {
            isUnmounted = true;
        };
    }, []);

    if (!show) {
        return null;
    }

    return (
        <div style={{ width: '100%', marginBottom: '12px' }}>
            <CaptchaContainer
                className={{
                    error: !!errorMsg,
                }}
                ref={captchaRef}
                captchaType={captchaType}
                invisibleSiteKey={getCaptchaKey(captchaType)}
                onSmartCaptchaInputChange={onSmartCaptchaInputChange}
            />
            {errorMsg && <div className="preview-meeting-info__field-error">{errorMsg}</div>}
        </div>
    );
});
Captcha.displayName = 'Captcha';

export default Captcha;
