export const ERR_401 = 401;
export const ERR_MISSING_TOKEN = 30031002;
export const ERR_NEED_LOGIN = 30010201;
export const ERR_TOKEN_EXPIRE = 30010202;
export const ERR_TOKEN_REVOKED = 30010203;
export const ERR_INVALID_OR_EXPIRED = 30031004;
export const ERR_OAUTH_APPID_NOT_EXIST = 30091004;

export const ERR_OAUTH2_EXPIRED = 4037; // "CalendarError - 4037 - OAuth 2.0 Token has expired, please reauthorize this account."

// calendar service authentication is not found - need authenticate -> add calendar
export const ERROR_RESOURCE_GROUP_NOT_EXIST = 30092006; // web didn't sync to nws,  room/user/device can not be found

// after you delete calendar service integration
export const ERROR_CALENDAR_SERVICE_ACCOUNT_NOT_FOUND = 30091001; // CalendarError - 30091001 - calendar service account not found

// never do calendar service authentication - need authenticate -> add a calendar
export const ERROR_RESOURCE_GROUP_BINDING_NOT_EXIST = 30092003; // clientId is not bound with calendar resource

// calendar service authentication is expired -> need re-authenticate

// google
export const ERROR_GOOGLE_P12_DATA = 30096005; // No google refreshToken not supported, maybe p12 data
export const ERROR_GOOGLE_REFRESH_TOKEN_EXPIRED = 30096010; // Token has been expired or revoked, please re-authorize again
export const ERROR_GOOGLE_REFRESH_TOKEN_EXPIRED_2 = 30096016; // please re-authorize again
export const ERROR_GOOGLE_QUERY_FAILED = 30093001; // please re-authorize again

// exchange
export const ERROR_EXCHANGE_AADSTS50057 = 30096211; // The user account is disabled
export const ERROR_EXCHANGE_AADSTS70008 = 30096217; // The refresh token has expired due to inactivity
export const ERROR_EXCHANGE_AADSTS50173 = 30096240; // The provided grant has expired due to it being revoked, a fresh auth token is needed. The user might have changed or reset their password

// graph
export const ERROR_GRAPH_AADSTS50057 = 30096105; // The user account is disabled
export const ERROR_GRAPH_AADSTS50173 = 30096106; // The provided grant has expired due to it being revoked, a fresh auth token is needed. The user might have changed or reset their password
export const ERROR_GRAPH_AADSTS700082 = 30096108; // The refresh token has expired due to inactivity
export const ERROR_GRAPH_AADSTS500341 = 30096122; // The user account has been deleted from the directory
export const ERROR_GRAPH_AADSTS50135 = 30096123; //Password change is required due to account risk
