import { AppDispatch } from '../../../store';
import easyStore from '../../../utils/storage';
import { setPostMeetingPopover } from './chat-store';

type PopoverNames = 'chat-available' | 'post-meeting';
interface IProps {
    popover: PopoverNames;
    info: any;
}

export const updateChatPopover = (props: IProps) => (dispatch: AppDispatch) => {
    const { popover, info } = props;

    if (popover === 'post-meeting') {
        if (!Boolean(info)) {
            // close
            easyStore.localStore.easySet('showChatPostMeetingPopover', false);
        }
        dispatch(setPostMeetingPopover(info));
    }
};
