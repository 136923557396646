import { addInvitation, removeInvitation, isDuringMeetingSelector } from './redux';
import { getBareJidFromJid } from '../../utils/index';
import { meetingAgent, xmppAgent } from '../../app-init';
import BrowserNotification, { NOTIFICATION_TAGS } from '../../utils/BrowserNotification';
import { ONE_IS_UNAVAILABLE } from '../../resource';
import { isZCCInVideoCall } from '../../store/appModules/contact-center';
export default class InvitationManager {
    constructor({ store }) {
        this.store = store;
    }

    // current meeting comes from this invitation
    _acceptedInvitation = null;

    waitingToInviteList = []; // if we invite someone by start a new meeting, add jid here
    invitedList = []; // for a meeting, if we send invitation to someone, we add jid here

    init() {
        // emtpy
    }

    uninit() {
        this.waitingToInviteList = [];
        this.invitedList = [];
    }

    // Received from others.
    onInvitationMessage = (e) => {
        let invitation = e.data;
        if (!this.validateInvitation(invitation)) return;

        if (isZCCInVideoCall(this.store)) {
            return;
        }

        if (invitation.action === 'invite') {
            this.store.dispatch(addInvitation(invitation));
        } else if (invitation.action === 'cancel') {
            // others end meeting before we accept or decline
            this.store.dispatch(removeInvitation(invitation));
        } else if (invitation.action === 'accept') {
            // we invite others, they accept
            // or our other resoure accept
            this.store.dispatch(removeInvitation(invitation));
            this.removeInvitedInvitee({ jid: invitation.fromJid });
        } else if (invitation.action === 'decline') {
            // we invite others, they decline
            // or our other resoure decline
            this.onDeclined(invitation);
        }
    };

    setAcceptedInvitation(invitation) {
        this._acceptedInvitation = invitation;
    }

    getAcceptedInvitation() {
        return this._acceptedInvitation;
    }

    validateInvitation(invitation) {
        if (!invitation) return false;
        let {
            fromJid,
            toJid,
            meetingNo,
            // receiveTime,
            // expireTime,
        } = invitation;
        if (!fromJid || !toJid || !meetingNo) {
            console.error('Invalid invitation');
            return false;
        }

        if (invitation.delay) {
            // native will ignore this delayed invitation
            // and add an chat message in chat list
            // but we do not have chat, so just ignore it
            return false;
        }
        let {
            meeting: { invitationList },
        } = this.store.getState();

        if (invitation.action === 'invite') {
            const acceptedInvitation = this.getAcceptedInvitation();
            // sync to all device
            if (getBareJidFromJid(fromJid) === getBareJidFromJid(toJid)) {
                return false;
            }
            // users in the same meeting invite me
            if (acceptedInvitation && String(invitation.meetingNo) === String(acceptedInvitation.meetingNo)) {
                return false;
            }
            // duplicated invitation before accept
            if (
                invitationList.some(
                    (_invitation) =>
                        _invitation.meetingNo === invitation.meetingNo && _invitation.fromJid === invitation.fromJid,
                )
            ) {
                return false;
            }

            // duplicated invitation when joiningment
        }

        return true;
    }

    onDeclined(invitation) {
        this.store.dispatch(removeInvitation(invitation));
        this.removeInvitedInvitee({ jid: invitation.fromJid });
        if (invitation.isTimeoutDecline) {
            // show unavaliable
            let { fromName } = invitation;
            if (!fromName) return;
            let bNotification = new BrowserNotification();
            bNotification.notify({
                tag: NOTIFICATION_TAGS.DECLINE_UNAVAILABLE,
                body: ONE_IS_UNAVAILABLE(fromName),
            });
        }
    }

    addInviteList(inviteeJidList = []) {
        inviteeJidList.forEach((jid) => {
            if (jid) {
                this.addInvitee({ jid });
            }
        });
    }

    removeInvitedInvitee(item) {
        // console.log('remove invited invitee: ', item);
        this.invitedList = this.invitedList.filter((i) => i.jid !== item.jid);
    }

    removeAllInvitee() {
        // console.log('remove all invitee',);
        this.waitingToInviteList = [];
        this.invitedList = [];
    }

    // iList: [{jid}]
    inviteContacts(iList, meetingParams = {}, withVideo = false, isInviteFromPWA = false) {
        const {
            meeting: { meetingInfo },
        } = this.store.getState();

        const isZCCInMeeting = isZCCInVideoCall(this.store);

        if (isZCCInMeeting) {
            return;
        }

        if (this.waitingToInviteList.length === 0) {
            // first time to call inviteContacts
            this.waitingToInviteList = iList;
        } else {
            // 2nd time to call inviteContacts, here we have to deep merge the `waitingToInviteList` with `iList`
            this.waitingToInviteList = this.waitingToInviteList.map((invite) => {
                const inviteFromWcl = iList.find((item) => item.jid === invite.jid);
                return Object.assign({}, invite, inviteFromWcl);
            });
        }
        const isDuringMeeting = isDuringMeetingSelector(this.store.getState());
        if (isDuringMeeting) {
            if (isInviteFromPWA && meetingInfo.hasBypassWaitingroomCode) {
                meetingAgent.askWC2Invite(iList);
                return;
            }
            // is in meeting
            this.doInviteContacts(meetingInfo);
        } else {
            meetingAgent.startMeeting(meetingParams, withVideo);
        }
    }

    doInviteContacts = (meetingInfo) => {
        let {
            common: { userInfo },
        } = this.store.getState();

        if (xmppAgent.isConnected()) {
            this.waitingToInviteList.forEach((item) => {
                this.sendInvitation({
                    toJid: item.jid,
                    meetingNo: meetingInfo.meetingNo,
                    password: meetingInfo.pwd ? meetingInfo.pwd : meetingInfo.password,
                    displayName: meetingInfo.displayName || (userInfo && userInfo.displayName) || '',
                    name: item.name,
                    type: item.type,
                    iak: item.iak,
                    credential: item.credential,
                    caption: meetingInfo.caption,
                    mid: meetingInfo.mid,
                });

                this.invitedList.push({
                    jid: item.jid,
                    name: item.name,
                    type: item.type,
                    meetingNo: meetingInfo.meetingNo,
                });
            });
        }
        this.waitingToInviteList = [];
    };

    getWaitingToInviteJids = () => {
        return this.waitingToInviteList.map((invite) => ({ jid: invite.jid }));
    };

    onMeetingEnded = (meetingInfo) => {
        this.waitingToInviteList = [];
        this.sendInvitationCancel(this.invitedList, meetingInfo);
        this.invitedList = [];
    };

    /**
     *
     * @param {Object} invitationInfo
     * {
     *      toJid,
     *      meetingNo,
     *      password,
     *      name,
     *      caption
     * }
     */
    sendInvitation(invitationInfo) {
        xmppAgent.invite(invitationInfo);
    }

    sendInvitationCancel(cancelList = [], meetingInfo) {
        let {
            common: { userInfo },
        } = this.store.getState();
        if (!xmppAgent.isConnected()) return;
        cancelList.forEach((item) => {
            if (item.meetingNo && item.meetingNo === meetingInfo.meetingNo) {
                xmppAgent.cancel({
                    toJid: item.jid,
                    meetingNo: meetingInfo.meetingNo,
                    name: item.name,
                    type: item.type,
                    jid: userInfo.jid,
                    toName: meetingInfo.displayName || (userInfo && userInfo.displayName) || '', // current user's name
                });
            }
        });
    }
}
