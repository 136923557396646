import store from '../store';
import { meetingAgent as _meetingAgent } from '../features/Meeting/init-meeting-agent';
import { xmppAgent as _xmppAgent } from '../services/xmpp/init-xmppAgent';
import InvitationManager from '../features/Meeting/InvitationManager';
export { default as featureOptionManager } from '../utils/featureOptions';
import BroadcastChannelAgent from '../utils/BroadcastChannel/BroadcastChannelAgent';
import WhiteboardAgent from '../features/Whiteboard/WhiteboardAgent';
import { default as _zpns } from '../services/zpns';

export const broadcastChannelAgent = new BroadcastChannelAgent('PWA_BROADCAST_CHANNEL');
export const xmppAgent = _xmppAgent;
export const meetingAgent = _meetingAgent;
export const whiteboardAgent = new WhiteboardAgent();
export const zpns = _zpns;
export const invitationManager = new InvitationManager({ store });
