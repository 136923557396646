import React from 'react';
import { Modal } from 'Modal';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import type { AppDispatch } from '../../../store';
import styles from './av-conflict.module.scss';
import Button from '../../../components/Button/Button';
import { shallowEqual } from 'react-redux';
import { getAsyncPhoneModule } from '../../../asyncModulesStore';
import { meetingAgent } from '../../../app-init';
import { getContactCenterModule } from '../../../store/appModules/contact-center';
import {
    CANCEL,
    Continue,
    Has_Zoom_Meeting_InProgress,
    Has_Zoom_Phone_InProgress,
    ZCC_Phone_Over_Zoom_Meeting,
    ZCC_Phone_Over_Zoom_Phone,
    ZCC_Video_Over_Zoom_Meeting,
    ZCC_Video_Over_Zoom_Phone,
} from '../../../resource';

interface IZCCData {
    jsCallId: string;
    requester: 'zcc';
    taskSid: string;
    taskType: 'phone' | 'video';
}

interface IZoomData {
    requester: 'zoom' | 'zcc';
    taskType: 'phone' | 'video';
}

type IData = IZCCData | IZoomData;

const noop = () => {};

const notifyContactCenterConflictResult = (willContinue: boolean, data: IZCCData) => {
    return getContactCenterModule().then(({ contactCenterAgent }) => {
        contactCenterAgent.notifyAVconflictResult(willContinue, data);
    });
};

// you must confirm that zoom phone is enabled
const holdZoomPhoneCall = (dispatch: AppDispatch) => {
    return getAsyncPhoneModule().then(({ holdCall }) => {
        return dispatch(holdCall(true));
    });
};

interface IProps {
    data: IData;
}

const AVConflict = ({ data }: IProps) => {
    const dispatch = useAppDispatch();
    const { showWebclient, meetingInfo } = useAppSelector((state) => {
        const {
            meeting: { showWebclient, meetingInfo, isMeetingAudioConnected },
        } = state;

        return { showWebclient, meetingInfo, isMeetingAudioConnected };
    }, shallowEqual);

    const zoomPhoneSid = useAppSelector((state) => {
        return state.phone?.currentSessionId;
    });

    const hasPhoneCall = () => {
        return Boolean(zoomPhoneSid);
    };

    const hasMeetingCall = () => {
        return showWebclient && Boolean(meetingInfo?.meetingStatus);
    };

    const { requester, taskType } = data;

    let titleTxt = '';
    let descTxt = '';
    let okHandler = noop;
    let cannelHandler = noop;

    if (requester === 'zcc') {
        cannelHandler = () => {
            return notifyContactCenterConflictResult(false, data as IZCCData);
        };

        if (taskType === 'phone') {
            if (hasMeetingCall()) {
                titleTxt = Has_Zoom_Meeting_InProgress;
                descTxt = ZCC_Phone_Over_Zoom_Meeting;
                okHandler = () => {
                    meetingAgent.leaveCurrentMeetingAudio();
                    return notifyContactCenterConflictResult(true, data as IZCCData);
                };
            } else if (hasPhoneCall()) {
                titleTxt = Has_Zoom_Phone_InProgress;
                descTxt = ZCC_Phone_Over_Zoom_Phone;
                okHandler = () => {
                    return holdZoomPhoneCall(dispatch)
                        .then(() => {
                            return notifyContactCenterConflictResult(true, data as IZCCData);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                };
            }
        }

        if (taskType === 'video') {
            if (hasMeetingCall()) {
                titleTxt = Has_Zoom_Meeting_InProgress;
                descTxt = ZCC_Video_Over_Zoom_Meeting;
                okHandler = () => {
                    meetingAgent.endCurrentOngoingMeeting();
                    return notifyContactCenterConflictResult(true, data as IZCCData).catch((e) => {
                        console.log(e);
                    });
                };
            } else if (hasPhoneCall()) {
                titleTxt = Has_Zoom_Phone_InProgress;
                descTxt = ZCC_Video_Over_Zoom_Phone;
                okHandler = () => {
                    return holdZoomPhoneCall(dispatch).then(() => {
                        return notifyContactCenterConflictResult(true, data as IZCCData).catch((e) => {
                            console.log(e);
                        });
                    });
                };
            }
        }
    }

    return (
        <Modal
            isOpen={true}
            needBackdrop={true}
            isDraggable={false}
            aria={{ labelledby: 'av-conflict-dialog' }}
            centered={true}
            onRequestClose={noop}
        >
            <div className={styles.dialog}>
                <p className={styles.title} id="av-conflict-dialog">
                    {titleTxt}
                </p>
                <p className={styles.desc}>{descTxt}</p>
                <div className={styles.btns}>
                    <Button type={Button.Normal} size={Button.Size32} onClick={okHandler}>
                        {Continue}
                    </Button>
                    <Button type={Button.Tertiary} size={Button.Size32} onClick={cannelHandler}>
                        {CANCEL}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AVConflict;
