import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import { Modal } from 'Modal';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { Noop, openTab } from '../../../utils';
import { shallowEqual } from 'react-redux';
import styles from './index.module.scss';
import { IconPopWindowPopOutV2 } from '@zoom/icons-react';
import {
    NOT_NOW,
    Phone_Setup_Btn,
    Phone_Setup_Desc,
    Phone_Setup_Title,
    Phone_Support_Btn,
    Phone_Support_Desc,
    Phone_Support_Title,
    Phone_Upgrade_Desc,
    Phone_Upgrade_Title,
    UPGRADE,
} from '../../../resource';
import {
    getBuyZoomPhonePlanUrl,
    getPwaZoomPhoneSupportUrl,
    getZoomPhoneModuleState,
    getZoomPhoneSetUpUrl,
    isFalse,
    isTrue,
} from './utils';

export default () => {
    const dispatch = useAppDispatch();
    const closeMe = () => {
        dispatch(setModal({ name: 'canNotShowZoomPhone', data: { show: false } }));
    };

    const { hasZoomPhonePlan, pwaFlagEnabled } = useAppSelector(getZoomPhoneModuleState, shallowEqual);

    const showSetup = Boolean(isFalse(hasZoomPhonePlan) && getZoomPhoneSetUpUrl());
    const showUpgrade = Boolean(isFalse(hasZoomPhonePlan) && getBuyZoomPhonePlanUrl());
    const showSupport = Boolean(isTrue(hasZoomPhonePlan) && isFalse(pwaFlagEnabled));

    const contentData = {
        show: false,
        imgCls: '',
        title: '',
        desc: '',
        okText: '',
        okHandler: Noop,
    };

    if (showUpgrade) {
        contentData.show = true;
        contentData.imgCls = styles.upgrade;
        contentData.title = Phone_Upgrade_Title;
        contentData.desc = Phone_Upgrade_Desc;
        contentData.okText = UPGRADE;
        contentData.okHandler = () => {
            openTab(getBuyZoomPhonePlanUrl());
            closeMe();
        };
    } else if (showSetup) {
        contentData.show = true;
        contentData.imgCls = styles.setUp;
        contentData.title = Phone_Setup_Title;
        contentData.desc = Phone_Setup_Desc;
        contentData.okText = Phone_Setup_Btn;
        contentData.okHandler = () => {
            openTab(getZoomPhoneSetUpUrl());
            closeMe();
        };
    } else if (showSupport) {
        contentData.show = true;
        contentData.imgCls = styles.support;
        contentData.title = Phone_Support_Title;
        contentData.desc = Phone_Support_Desc;
        contentData.okText = Phone_Support_Btn;
        contentData.okHandler = () => {
            openTab(getPwaZoomPhoneSupportUrl());
            closeMe();
        };
    }

    useLayoutEffect(() => {
        if (!contentData.show) {
            closeMe();
        }
    }, [contentData.show]);

    if (!contentData.show) {
        return null;
    }

    return (
        <Modal
            isOpen={contentData.show}
            needBackdrop={true}
            shouldCloseOnOverlayClick={false}
            isDraggable={false}
            centered={true}
        >
            <div className={styles.body}>
                <div>
                    <div className={classNames(styles.img, contentData.imgCls)}></div>
                </div>

                <p className={styles.title}>{contentData.title}</p>

                <p className={styles.desc}>{contentData.desc}</p>

                <div className={styles.actions}>
                    <Button
                        size={Button.Size32}
                        type={Button.Normal}
                        onClick={contentData.okHandler}
                        RightIcon={<IconPopWindowPopOutV2 />}
                    >
                        {contentData.okText}
                    </Button>
                    <Button size={Button.Size32} type={Button.Dark} onClick={closeMe}>
                        {NOT_NOW}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
