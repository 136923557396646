/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
'use strict';

var $protobuf = require('protobufjs/light');

var $root = ($protobuf.roots['default'] || ($protobuf.roots['default'] = new $protobuf.Root())).addJSON({
    MeetingProtos: {
        nested: {
            LaunchConfParameter: {
                oneofs: {
                    _meeting: {
                        oneof: ['meeting'],
                    },
                    _userName: {
                        oneof: ['userName'],
                    },
                    _userEmail: {
                        oneof: ['userEmail'],
                    },
                    _boID: {
                        oneof: ['boID'],
                    },
                    _boToken: {
                        oneof: ['boToken'],
                    },
                    _boTitle: {
                        oneof: ['boTitle'],
                    },
                },
                fields: {
                    meeting: {
                        type: 'MeetingProto',
                        id: 2,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    userName: {
                        type: 'string',
                        id: 3,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    userEmail: {
                        type: 'string',
                        id: 10,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    boID: {
                        type: 'string',
                        id: 45,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    boToken: {
                        type: 'string',
                        id: 46,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    boTitle: {
                        type: 'string',
                        id: 47,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    appRecords: {
                        rule: 'repeated',
                        type: 'LCPRecordItem',
                        id: 66,
                    },
                },
            },
            LCPRecordItem: {
                oneofs: {
                    _key: {
                        oneof: ['key'],
                    },
                    _value: {
                        oneof: ['value'],
                    },
                    _section: {
                        oneof: ['section'],
                    },
                },
                fields: {
                    key: {
                        type: 'string',
                        id: 1,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    value: {
                        type: 'string',
                        id: 2,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    section: {
                        type: 'string',
                        id: 3,
                        options: {
                            proto3_optional: true,
                        },
                    },
                },
            },
            MeetingProto: {
                oneofs: {
                    _hostId: {
                        oneof: ['hostId'],
                    },
                    _password: {
                        oneof: ['password'],
                    },
                    _options: {
                        oneof: ['options'],
                    },
                },
                fields: {
                    hostId: {
                        type: 'string',
                        id: 2,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    password: {
                        type: 'string',
                        id: 5,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    options: {
                        type: 'int64',
                        id: 9,
                        options: {
                            proto3_optional: true,
                        },
                    },
                    alternativeHost: {
                        rule: 'repeated',
                        type: 'AlternativeHost',
                        id: 73,
                    },
                },
            },
            AlternativeHost: {
                oneofs: {
                    _id: {
                        oneof: ['id'],
                    },
                },
                fields: {
                    id: {
                        type: 'string',
                        id: 1,
                        options: {
                            proto3_optional: true,
                        },
                    },
                },
            },
        },
    },
});

module.exports = $root;
