import { ZoomPhoneModule } from '../../asyncModulesStore';
import { ICallSession } from '../../features/Phone/redux/phone-types';
import EventEmitter from '../event-emitter';

export const enum EPhoneLoadType {
    Success,
    NoLicence,
    NotEnabled,
}

export interface IPhoneLoadEvent {
    type: EPhoneLoadType;
    reason?: string;
    module?: ZoomPhoneModule;
}

export interface IPhoneSession {
    session: ICallSession;
}

export const phoneEvents = {
    load: new EventEmitter<IPhoneLoadEvent>({ name: 'check and load phone module' }),
    newSession: new EventEmitter<IPhoneSession>({ name: 'new inbound or outboud call' }),
    sessionConnected: new EventEmitter<IPhoneSession>({ name: 'inbound or outbound call connected' }),
    sessionEnd: new EventEmitter<IPhoneSession>({ name: 'inbound or outbound call ends' }),
};
