import React, { forwardRef } from 'react';
import classnames from 'classnames';
import './Button.scss';

const Button = forwardRef(
    (
        {
            text = '',
            size = Button.Size24,
            type = Button.Normal,
            onClick = () => {
                /** noop */
            },
            extraCls = '',
            children,
            disabled = false,
            tabIndex = 0,
            LeftIcon = null,
            RightIcon = null,
            ...rest
        },
        ref,
    ) => {
        const btnCls = classnames(
            'z-button',
            `z-button-size-${size}`,
            {
                [`z-button-type-${type}`]: type,
                disabled: disabled,
            },

            extraCls,
        );

        const onButtonClick = (e) => {
            if (!disabled && onClick) {
                onClick(e);
            }
        };

        const _text = text || children || null;
        return (
            <button className={btnCls} disabled={disabled} onClick={onButtonClick} ref={ref} {...rest}>
                {LeftIcon ? LeftIcon : null}
                {_text ? <span>{_text}</span> : null}
                {RightIcon ? RightIcon : null}
            </button>
        );
    },
);

// size
Button.Size24 = '24';
Button.Size32 = '32';
Button.Size40 = '40';
Button.Size48 = '48';

// type
Button.Normal = 'normal';
Button.Primary = 'primary';
Button.Secondary = 'secondary';
Button.Tertiary = 'tertiary';
Button.Quaternary = 'quaternary';
Button.Quinary = 'quinary';
Button.CTA = 'cta'; // call to action
Button.Icon = 'icon';

Button.Destructive = 'destructive';
Button.Text = 'text';

// dark outline
Button.Dark = 'dark';

export default Button;
