import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import {
    Phone_Text,
    Statistics_Text,
    Audio_Text,
    PROFILE_DROP_SETTINGS,
    Chat_Text_2,
    Video_Text,
    Close_Text,
    General_Text,
} from '../../resource';
import {
    IconPhoneFill,
    IconHeadphone,
    IconPollFill,
    IconChatFill,
    IconVideoOnFill,
    IconCloseSmallFill,
    IconSettingFill,
} from '@zoom/icons-react';
import { RootState } from '../../store';
import { setShowSettingsDialog, setCurrentNavKey, defaultActiveKey, SETTING_NAV_KEY } from './redux/settings-store';
import './settings.scss';
import Statistics from './Statistics/Statistics';
import { Modal } from 'Modal';
import { useCloseWhenLogout } from '../../store/modal/modal-hooks';
import { ChatSetting } from '../Chat';
import { getAsyncPhoneModule } from '../../asyncModulesStore';
import { AudioPane, VideoPane } from '@zoom/pwa-settings';
import '@zoom/pwa-settings/lib/index.css';
import { isChatEnabled } from '../../utils/featureOptions';
import General from './General/General';

interface ISettings {
    onClose: () => void;
    currentNavKey: string;
    [key: string]: any;
}

const Settings = ({ isPhoneModuleLoaded, showSettingsDialog, currentNavKey, onClose }: ISettings) => {
    const [PhonePanel, setPhonePanel] = useState(null);
    const navData = [
        {
            show: true,
            eventKey: SETTING_NAV_KEY.GENERAL,
            icon: <IconSettingFill aria-hidden={true} className="settings-icon settings-icon__general" />,
            text: General_Text,
        },
        {
            show: true,
            eventKey: SETTING_NAV_KEY.AUDIO,
            icon: <IconHeadphone aria-hidden={true} className="settings-icon settings-icon__audio" />,
            text: Audio_Text,
        },
        {
            show: true,
            eventKey: SETTING_NAV_KEY.VIDEO,
            icon: <IconVideoOnFill aria-hidden={true} className="settings-icon settings-icon__video" />,
            text: Video_Text,
        },
        {
            show: isPhoneModuleLoaded,
            eventKey: SETTING_NAV_KEY.PHONE,
            icon: <IconPhoneFill aria-hidden={true} className="settings-icon settings-icon__phone" />,
            text: Phone_Text,
        },
        {
            show: isChatEnabled(),
            eventKey: SETTING_NAV_KEY.CHAT,
            icon: <IconChatFill aria-hidden={true} className="settings-icon settings-icon__chat" />,
            text: Chat_Text_2,
        },
        {
            // currently we only show phone's audio statistics, not in-meeting video/audio
            show: isPhoneModuleLoaded,
            eventKey: SETTING_NAV_KEY.STATISTICS,
            icon: <IconPollFill aria-hidden={true} className="settings-icon settings-icon__statistics" />,
            text: Statistics_Text,
        },
    ];
    const settingsContent = {
        general: () => <General />,
        audio: () => <AudioPane />,
        video: () => <VideoPane />,
        phone: () => PhonePanel,
        chat: () => <ChatSetting />,
        statistics: () => <Statistics />,
    };

    const renderContent = (key: string) => {
        const render = settingsContent[key];
        if (!render) {
            return null;
        }
        return render();
    };

    const dispatch = useDispatch();
    const onSettingsClose = () => {
        dispatch(setShowSettingsDialog(false));
        onClose && onClose();
    };

    const handleNavSelect = (eventKey: string, _event: SyntheticEvent<unknown, Event>) => {
        dispatch(setCurrentNavKey(eventKey));
    };

    useCloseWhenLogout(onSettingsClose);

    useEffect(() => {
        isPhoneModuleLoaded &&
            getAsyncPhoneModule().then(({ SettingsPhone }) => {
                setPhonePanel(<SettingsPhone />);
            });
    }, [isPhoneModuleLoaded]);

    return (
        <Modal
            isOpen={showSettingsDialog}
            draggableProps={{
                handle: '.settings-dialog__header',
            }}
        >
            <div className="settings-dialog">
                <div className="settings-dialog__header" tabIndex={0}>
                    {PROFILE_DROP_SETTINGS}
                </div>
                <button className="modal-dialog__close-icon" onClick={onSettingsClose}>
                    <IconCloseSmallFill aria-label={Close_Text} />
                </button>
                <div className="settings-dialog__hr" />
                <div className="settings-body">
                    <div className="left-sidebar">
                        <Nav
                            className="flex-column"
                            variant="pills"
                            activeKey={currentNavKey}
                            defaultActiveKey={defaultActiveKey}
                            onSelect={handleNavSelect}
                            tabIndex={0}
                            aria-label={PROFILE_DROP_SETTINGS}
                            role="tablist"
                        >
                            {navData
                                .filter((item) => item.show)
                                .map((navItem) => (
                                    <Nav.Item key={navItem.eventKey}>
                                        <Nav.Link eventKey={navItem.eventKey} className="settings-nav-item" role="tab">
                                            {navItem.icon}
                                            {navItem.text}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                        </Nav>
                    </div>
                    <div className="right-content">{renderContent(currentNavKey)}</div>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    const {
        common: { isPhoneModuleLoaded },
        settings: { currentNavKey, showSettingsDialog },
    } = state;
    return {
        isPhoneModuleLoaded,
        showSettingsDialog,
        currentNavKey,
    };
};

export default connect(mapStateToProps)(Settings);
