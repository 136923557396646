import EventEmitter from '../event-emitter';

// common events put here
export const appEvents = {
    // events here can be aborted
    beforeSignOut: new EventEmitter({ name: 'before sign out', abortOnError: true }),
    // events here can't be aborted
    willSingout: new EventEmitter({ name: 'will sign out' }),
    userInfoReady: new EventEmitter({ name: 'userInfo ready' }),
};
