import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../components/Button/Button';
import { isEmail, isMaybeMultiEmail } from '../../../utils/index';
import { IUserInfo } from '../../../store/common/common-store';
import { xmppAgent } from '../../../app-init';
import { InviteContactModalType, InviteContactData } from './types';
import classnames from 'classnames';
import {
    INVITE_TO_CONTACT,
    EMAIL_ADDRESS,
    ENTER_EMAIL_ADDRESS,
    EMAIL_ADDRESS_DESC,
    CANCEL,
    INVITE,
    ENTER_ONE_EMAIL,
    CANNOT_ADD_SELF,
} from '../../../resource';
import { useAppDispatch } from '../../../store/store-hooks';
import { addPendingContactsThunk } from '../.././Contacts/redux/contact-thunk';
import { InviteContactSyncMessageType } from '@zoom/pwa-webim';
import { getContactDisplayName } from '../../Contacts/redux/contact-utils';

interface IProps {
    userInfo: IUserInfo;
    setModalType: (type: InviteContactModalType) => void;
    closeModal: () => void;
    emailRef: React.MutableRefObject<string>;
    dataRef: React.MutableRefObject<InviteContactData>;
}

const InviteContent = ({ userInfo, setModalType, closeModal, emailRef, dataRef }: IProps) => {
    const dispatch = useAppDispatch();
    const inputRef = useRef(null);
    const [inviteDisabled, setInviteDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const { email, displayName } = userInfo;

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, []);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value.trim();

        emailRef.current = value;
        setErrorMessage('');
        setInviteDisabled(false);

        const verify = isEmail(value);
        if (!verify) {
            setInviteDisabled(true);
            return;
        }

        // can not input multi email addresses
        if (isMaybeMultiEmail(value)) {
            setInviteDisabled(true);
            setErrorMessage(ENTER_ONE_EMAIL);
            return;
        }

        // can not input self email
        if (email === value) {
            setInviteDisabled(true);
            setErrorMessage(CANNOT_ADD_SELF);
            return;
        }
    };

    const checkAlreadyContact = async () => {
        try {
            // check is alreay a contact, if true, start chat directly
            const checkData: any = await xmppAgent.checkAlreadyContact(emailRef.current);
            console.log('checkData ==>', checkData);
            if (checkData && Array.isArray(checkData) && checkData.length > 0) {
                const { vcard } = checkData[0];

                if (!vcard) {
                    return Promise.resolve({
                        needSendInvite: true,
                    });
                }

                const displayName = getContactDisplayName(vcard);
                dataRef.current = {
                    jid: vcard.jid,
                    avatar: vcard.picUrl,
                    name: displayName,
                    modalType: InviteContactModalType.ALREADY_CONTACT,
                };
                setModalType(InviteContactModalType.ALREADY_CONTACT);
                return Promise.resolve({
                    needSendInvite: false,
                });
            }
            return Promise.resolve({
                needSendInvite: true,
            });
        } catch (error) {
            console.log('checkAlreadyContact ==>', error);
            return Promise.resolve({
                needSendInvite: true,
            });
        }
    };

    const Invite = async () => {
        if (!emailRef.current) return;
        setModalType(InviteContactModalType.LOADDING);

        // check is alreay a contact, if true, start chat directly
        const { needSendInvite } = await checkAlreadyContact();
        if (!needSendInvite) return;

        // send invite
        const result = await xmppAgent.inviteContactByEmail({
            inviterName: displayName,
            inviterEmail: email,
            inviteeEmail: emailRef.current,
        });
        console.log('result ==>', result);
        if (result.type === 'INVITE_MESSAGE_SEND_SUCCESS' && emailRef.current === result.email) {
            setModalType(InviteContactModalType.INVITE_SEND_SUCCESS);
            // render and store pending contact data
            dispatch(addPendingContactsThunk(result.email));

            // send sync invite message to my other devices
            xmppAgent.sendInviteContactSyncMessage({
                action: InviteContactSyncMessageType.SUBSENT,
                inviteeJid: '',
                inviteeName: '',
                inviteeEmail: emailRef.current,
                inviterName: displayName,
            });
        }
    };

    return (
        <>
            <div className="invite-content">
                <div tabIndex={0} role={'dialog'} title={INVITE_TO_CONTACT} className="title" id="invite-title">
                    {INVITE_TO_CONTACT}
                </div>
                <span className="email-address-text">{EMAIL_ADDRESS}</span>
                <input
                    className={classnames('email-input', { 'email-input-error': errorMessage })}
                    placeholder={ENTER_EMAIL_ADDRESS}
                    onChange={onInputChange}
                    ref={inputRef}
                    aria-labelledby="invite-title"
                />
                <span tabIndex={0} className={classnames('description', { 'description-error': errorMessage })}>
                    {errorMessage ? errorMessage : EMAIL_ADDRESS_DESC}
                </span>
            </div>
            <div className="modal-buttons">
                <Button
                    extraCls="invite"
                    disabled={inviteDisabled}
                    size={Button.Size24}
                    type={Button.Normal}
                    onClick={Invite}
                >
                    {INVITE}
                </Button>
                <Button extraCls="cancel" size={Button.Size24} type={Button.Dark} onClick={closeModal}>
                    {CANCEL}
                </Button>
            </div>
        </>
    );
};

export default InviteContent;
