import React, { forwardRef } from 'react';
import './start-meeting-option-panel.scss';
import ZmCheckbox from '../../components/Checkbox/Checkbox';
import FocusLock from 'react-focus-lock';
import { DOM_KEY_CODE } from '../../utils/constants';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { useAppSelector, useAppDispatch } from '../../store/store-hooks';
import { shallowEqual } from 'react-redux';
import PMIMenuTrigger from './PmiMenuTrigger';
import { updateUserSettingsThunk } from '../../store/common/common-thunk';
import { USE_PMI_TEXT } from '../../resource';
import classNames from 'classnames';

interface Props extends OverlayInjectedProps {
    close(): void;
    ariaId: string;
}

export default forwardRef<HTMLDivElement, Props>(({ close, ariaId, style }, ref) => {
    const dispatch = useAppDispatch();
    const { pmi, usePMI } = useAppSelector((state) => {
        const userInfo = state.common.userInfo;
        const userSettings = state.common.userSettings;
        return {
            pmi: userInfo.pmi,
            usePMI: userSettings.video.startWithPmi
        };
    }, shallowEqual);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === DOM_KEY_CODE.ESC) {
            close();
        }
    };

    const onUsePMIChange = () => {
        dispatch(
            updateUserSettingsThunk({
                video: {
                    startWithPmi: !usePMI,
                },
            }),
        );
    }

    const lockAlwaysUsePMIInstant = window.PwaConfig?.lockAlwaysUsePMIInstant;

    return (
        <div ref={ref} style={style} id={ariaId} onKeyDown={onKeyDown} className="home-main-new-meeting-option-panel">
            <FocusLock disabled returnFocus={true}>
                {pmi &&
                    <>
                        <div className={classNames('new-meeting-option-panel-block new-meeting-option-panel-block__no-border', lockAlwaysUsePMIInstant && 'meeting-option__disabled')}>
                            <ZmCheckbox disabled={lockAlwaysUsePMIInstant} text={USE_PMI_TEXT} checked={usePMI} onChange={onUsePMIChange} />
                        </div>
                        <PMIMenuTrigger className="new-meeting-option-panel-block" pmi={pmi} close={close} />
                    </>
                }
            </FocusLock>
        </div>
    );
});
