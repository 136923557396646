import {
    Action,
    ThunkAction,
    configureStore,
    combineReducers,
    AnyAction,
    Dispatch,
    ThunkDispatch,
    PreloadedState,
    Reducer,
    CombinedState,
} from '@reduxjs/toolkit';
import commonReducer from './common/common-store';
import contactReducer from '../features/Contacts/redux';
import meetingReducer from '../features/Meeting/redux';
import xmppReducer from './xmpp/xmpp-store';
import modalReducer from './modal/modal-store';
import chatReducer from '../features/Chat/redux';
import settingsReducer from '../features/Settings/redux/settings-store';
import meetingsReducer from '../features/Meetings/Meetings/redux/meetings-store';
import whiteboardReducer from '../features/Whiteboard/redux';
import appModuleReducer from './appModules/app-modules-store';
import docsReducer from '../features/Docs/redux';
import type { IPhoneState, ISmsState } from '../features/Phone/types';
import type { IContactCenterState } from '../features/ContactCenter';
import serviceContainer from '../services/container';
import zoomAppsReducer from '../features/ZApps/redux/zoom-apps-slice';
import calendarReducer from '../features/Calendar/redux';

const reducers = {
    common: commonReducer,
    contacts: contactReducer,
    meeting: meetingReducer,
    xmpp: xmppReducer,
    modal: modalReducer,
    chat: chatReducer,
    settings: settingsReducer,
    meetings: meetingsReducer,
    whiteboard: whiteboardReducer,
    appModules: appModuleReducer,
    docs: docsReducer,
    zoomApps: zoomAppsReducer,
    calendar: calendarReducer,
};

export type RootState = ReturnType<typeof rootReducer> & { phone: IPhoneState } & { sms: ISmsState } & {
    contactCenter: IContactCenterState;
};

export type TRootReducer = Reducer<CombinedState<RootState>, AnyAction>;

const rootReducer = combineReducers(reducers);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer as unknown as TRootReducer,
        devTools: process.env.NODE_ENV === 'development',
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ['sms/uploadFile/fulfilled', 'sms/downloadFile/fulfilled'],
                    ignoredActionPaths: ['payload.data.resolve'],
                    ignoredPaths: ['modal.modals.confirmDelete.resolve'],
                },
            }),
    });
};

const store = setupStore({});

export type ReduxStore = typeof store;

store.injectReducer = (key, newReducer) => {
    if (!store.asyncReducers) {
        store.asyncReducers = {};
    }
    store.asyncReducers[key] = newReducer;
    const newRootReducer: any = combineReducers({
        ...reducers,
        ...store.asyncReducers,
    });

    store.replaceReducer(newRootReducer);
};

export * from './store-hooks';

interface IGetState {
    (): RootState;
}

export type AppDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppGetState = IGetState;
export type AppStore = ReturnType<typeof setupStore>;

serviceContainer.setReduxStore(store);

export default store;
