import React from 'react';
import classnames from 'classnames';
import './ContactList.scss';
import { formatContactGroupByIdSelector, getLabelText, getLabelbyGroupIdSelector } from '../../redux';
import { RootState, useAppSelector } from '../../../../store';
import { IconChevronDown, IconChevronRight, IconLoadingSpikes } from '@zoom/icons-react';
import { FetchStatus } from '../../../../utils/constants';
import { connect, ConnectedProps } from 'react-redux';
import { A11Y_POLICY } from '../../a11y';
import { LABLE_GROUP } from '../../types';
import { GROUPING, LEVEL_F, NOT_SELECTED, ITEMS_ENCLOSE_F, CONTACT_INDEX_OF_F } from '../../../../resource';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PropsFromParent {
    style: React.CSSProperties;
    onClickGroup(id: string, folded: boolean): void;
    groupId: string;
    isStickGroup?: boolean;
    a11yIndex?: string;
    renderIndex?: number;
}

type Props = PropsFromParent & PropsFromRedux;

const Group = ({ style, onClickGroup, groupId, isSelected, a11yIndex, isStickGroup = false, renderIndex }: Props) => {
    const { folded } = useAppSelector((state) => state.contacts.ui.groups[groupId]);
    const status = useAppSelector((state) => {
        return state.contacts.groupsLoadingState[groupId]?.status;
    });

    const { labelId, labelGroups } = useAppSelector((state) => getLabelbyGroupIdSelector(state, groupId));

    const { total: contactTotal, groupName } = useAppSelector((state) =>
        formatContactGroupByIdSelector(state, groupId),
    );
    const isLoadData = contactTotal >= 0; // _total === -1, means the group don't have member data now, need lazy load.

    const getArrow = () => {
        if (!isLoadData) {
            return <span className="empty-seat" />;
        }

        return folded ? <IconChevronRight className="group-arrow" /> : <IconChevronDown className="group-arrow" />;
    };

    const getAriaLabelText = () => {
        const groupTotal = labelGroups.length;
        const groupInLabelIndex = labelGroups.findIndex((item) => item.id === groupId);

        const labelName = `${getLabelText(labelId as LABLE_GROUP)} ${GROUPING}`;
        const level = LEVEL_F(2);
        const selectText = isSelected ? '' : NOT_SELECTED;
        const totalCountText = ITEMS_ENCLOSE_F(contactTotal);
        const positionText = CONTACT_INDEX_OF_F(groupInLabelIndex + 1, groupTotal);

        return `${labelName}, ${groupName}, ${totalCountText}, ${level}, ${positionText}, ${selectText}`;
    };

    return (
        <div className="contacts-group" style={style}>
            <div
                className={classnames('group-container', { 'group-selected': isSelected, 'group-stick': isStickGroup })}
                onClick={() => {
                    isLoadData && onClickGroup(groupId, !folded);
                }}
                tabIndex={isSelected ? 0 : -1}
                role="treeitem"
                aria-label={getAriaLabelText()}
                aria-selected={isSelected}
                aria-expanded={!folded}
                data-a-stick={isStickGroup}
                data-a-group={true}
                data-a-render-index={renderIndex}
                data-a-l={a11yIndex}
                data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_ITEM_POLICY}
            >
                {getArrow()}
                <span className="group-name">{groupName}</span>
                {status === FetchStatus.loading ? (
                    <IconLoadingSpikes className="group-loading" aria-hidden={true} />
                ) : null}
                {isLoadData && <span className="group-total">{contactTotal}</span>}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState, props: PropsFromParent) => {
    const { selectedItem } = state.contacts;
    return {
        isSelected: selectedItem && !('jid' in selectedItem) && selectedItem?.groupId === props.groupId,
    };
};

const connector = connect(mapStateToProps);

export default connector(Group);
