import { A11Y_FOCUS_WALKER_POLICY, DOM_KEY_CODE } from './constants';

export type allDOMNodesType = {
    rootJDom: Node | null;
    currentKey: string | null;
    currentDom: Node | null;
    currentLevelFirstJDom: Node | null;
    nextJDom: Node | null;
    prevJDom: Node | null;
    parentJDom: Node | null;
    parentNextJDom: Node | null;
    parentPrevJDom: Node | null;
    firstChildJDom: Node | null;
    firstChildFirstChildJDom: Node | null;
    parentParentNextJDom: Node | null;
    parentListLastNodeJDom: Node | null;
    parentListFirstNodeJDom: Node | null;
    firstListNodeJDom: Node | null;
    prevFirstListNodeJDom: Node | null;
    lastListNodeJDom: Node | null;
    nextLastListNodeJDom: Node | null;
};

function keyBoardEventHandler(
    walkerPolicy: A11Y_FOCUS_WALKER_POLICY,
    performFocus: (
        keyCode: DOM_KEY_CODE,
        rootJDom: Node | null,
        targetJDom: Node | null,
        backupTargetJDom?: Node | null,
    ) => void,
    performClick: (
        keyCode: DOM_KEY_CODE,
        rootJDom: Node | null,
        targetJDom: Node | null,
        backupTargetJDom?: Node | null,
    ) => void,
    { keyCode, shiftKey }: KeyboardEvent,
    {
        rootJDom,
        currentKey,
        currentDom,
        currentLevelFirstJDom,
        nextJDom,
        prevJDom,
        parentJDom,
        firstChildJDom,
        parentParentNextJDom,
        parentListLastNodeJDom,
        parentListFirstNodeJDom,
        firstListNodeJDom,
        prevFirstListNodeJDom,
        lastListNodeJDom,
        nextLastListNodeJDom,
    }: allDOMNodesType,
) {
    switch (walkerPolicy) {
        case A11Y_FOCUS_WALKER_POLICY.LIST_CHECKBOX_ITEM_POLICY: {
            if (keyCode === DOM_KEY_CODE.TAB) {
                if (shiftKey) {
                    if (prevJDom) {
                        performFocus(keyCode, rootJDom, lastListNodeJDom);
                        return true;
                    }
                    return false;
                }
                if (nextJDom) {
                    performFocus(keyCode, rootJDom, firstListNodeJDom);
                    return true;
                }
                return false;
            }
            return false;
        }
        case A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.ENTER:
                case DOM_KEY_CODE.SPACE:
                    performClick(keyCode, rootJDom, currentDom);
                    return true;
                case DOM_KEY_CODE.TAB: {
                    if (shiftKey) {
                        performFocus(keyCode, rootJDom, parentJDom);
                        return true;
                    }

                    if (firstChildJDom) {
                        performFocus(keyCode, rootJDom, firstChildJDom, currentDom);
                        return true;
                    }
                    return false;
                }
                case DOM_KEY_CODE.UP:
                    if (currentDom.isSameNode(parentListFirstNodeJDom)) {
                        performFocus(keyCode, rootJDom, parentListLastNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, prevJDom);
                    }
                    return true;
                case DOM_KEY_CODE.DOWN:
                    if (currentDom.isSameNode(parentListLastNodeJDom)) {
                        performFocus(keyCode, rootJDom, parentListFirstNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.TRANSCRIPT_ITEM_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB: {
                    if (shiftKey) {
                        performFocus(keyCode, rootJDom, rootJDom);
                        return true;
                    }
                    performFocus(keyCode, rootJDom, firstChildJDom, currentDom);
                    return true;
                }
                case DOM_KEY_CODE.DOWN:
                    if (currentDom.isSameNode(parentListFirstNodeJDom)) {
                        performFocus(keyCode, rootJDom, parentListLastNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, prevJDom);
                    }
                    return true;
                case DOM_KEY_CODE.UP:
                    if (currentDom.isSameNode(parentListLastNodeJDom)) {
                        performFocus(keyCode, rootJDom, parentListFirstNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.LIST_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.DOWN: {
                    performFocus(keyCode, rootJDom, firstChildJDom, currentDom);
                    return true;
                }
                default:
                    return false;
            }

        case A11Y_FOCUS_WALKER_POLICY.TAB_TO_FIRST_CHILD_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB: {
                    performFocus(keyCode, rootJDom, firstChildJDom, currentDom);
                    return true;
                }
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.TAB_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB:
                    if (shiftKey) {
                        performFocus(keyCode, rootJDom, prevFirstListNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextLastListNodeJDom);
                    }
                    return true;
                case DOM_KEY_CODE.UP:
                case DOM_KEY_CODE.LEFT:
                    if (currentDom.isSameNode(firstListNodeJDom)) {
                        performFocus(keyCode, rootJDom, lastListNodeJDom);
                        performClick(keyCode, rootJDom, lastListNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, prevJDom);
                        performClick(keyCode, rootJDom, prevJDom);
                    }
                    return true;
                case DOM_KEY_CODE.DOWN:
                case DOM_KEY_CODE.RIGHT:
                    if (currentDom.isSameNode(lastListNodeJDom)) {
                        performFocus(keyCode, rootJDom, firstListNodeJDom);
                        performClick(keyCode, rootJDom, firstListNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom);
                        performClick(keyCode, rootJDom, nextJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_CHILD_NODE_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB:
                    if (shiftKey) {
                        performFocus(keyCode, rootJDom, prevJDom, parentJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom, parentParentNextJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.NORMAL_NODE_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB:
                    if (shiftKey) {
                        if (currentKey === '0-0') {
                            return false;
                        }
                        performFocus(keyCode, rootJDom, prevJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom, currentLevelFirstJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.PREV_TRIGGER_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB:
                    if (shiftKey) {
                        performFocus(keyCode, rootJDom, prevJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom, currentLevelFirstJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.NEXT_TRIGGER_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.TAB:
                    if (shiftKey) {
                        performFocus(keyCode, rootJDom, prevJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom, currentLevelFirstJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.MENU_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.LEFT:
                    if (currentDom.isSameNode(parentListFirstNodeJDom)) {
                        performClick(keyCode, rootJDom, parentListLastNodeJDom);
                    } else {
                        performClick(keyCode, rootJDom, prevJDom);
                    }
                    return true;
                case DOM_KEY_CODE.RIGHT:
                    if (currentDom.isSameNode(parentListLastNodeJDom)) {
                        performClick(keyCode, rootJDom, parentListFirstNodeJDom);
                    } else {
                        performClick(keyCode, rootJDom, nextJDom);
                    }
                    return true;
                default:
                    return false;
            }
        case A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_CONTENT_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.ENTER:
                case DOM_KEY_CODE.SPACE:
                    performClick(keyCode, rootJDom, currentDom);
                    return true;
                case DOM_KEY_CODE.LEFT:
                    if (currentDom.isSameNode(parentListFirstNodeJDom)) {
                        performFocus(keyCode, rootJDom, parentListLastNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, prevJDom);
                    }
                    return true;
                case DOM_KEY_CODE.RIGHT:
                    if (currentDom.isSameNode(parentListLastNodeJDom)) {
                        performFocus(keyCode, rootJDom, parentListFirstNodeJDom);
                    } else {
                        performFocus(keyCode, rootJDom, nextJDom);
                    }
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
}

export { keyBoardEventHandler };
