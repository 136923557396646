import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'Modal';
import './index.scss';
import ListGroup from 'react-bootstrap/ListGroup';
import { setModal } from '../../../store/modal/modal-store';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { setLanguageInCookie } from '../../../utils/Cookies/utils';
import Button from '../../../components/Button/Button';
import { CHOOSE_LANG, CANCEL, SAVE } from '../../../resource';

export default () => {
    const dispatch = useAppDispatch();
    const { language } = useAppSelector((state) => state.common);
    const [Selected, setSelected] = useState(language?.key);
    if (!language) {
        return null;
    }
    const langs = window.PwaConfig.supportLanguages;
    const closeMe = () => {
        dispatch(setModal({ name: 'language', data: null }));
    };

    const selectMe = (code: string) => {
        setSelected(code);
    };

    const saveMe = () => {
        closeMe();
        if (Selected !== language.key) {
            setLanguageInCookie(Selected);
        }
    };

    const closeRef = useRef(null);
    useEffect(() => {
        closeRef.current && closeRef.current.focus();
    }, [closeRef]);

    return (
        <Modal
            isOpen={true}
            centered={true}
            needBackdrop={true}
            isDraggable={false}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            onRequestClose={closeMe}
        >
            <div className="lang-dialog">
                <header>{CHOOSE_LANG}</header>
                <div className="dialog-body">
                    <ListGroup bsPrefix="list-group">
                        {Object.keys(langs).map((code) => (
                            <ListGroup.Item
                                key={code}
                                onClick={() => selectMe(code)}
                                className={Selected === code ? 'active' : ''}
                            >
                                {langs[code]}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                <footer>
                    <Button
                        size={Button.Size32}
                        type={Button.Normal}
                        onClick={saveMe}
                        disabled={Selected === language.key ? true : false}
                    >
                        {SAVE}
                    </Button>
                    <Button size={Button.Size32} type={Button.Secondary} onClick={closeMe}>
                        {CANCEL}
                    </Button>
                </footer>
            </div>
        </Modal>
    );
};
