import { useEffect, useState } from 'react';
import { usePrevious } from './usePrevious';
import useLatest from './useLatest';

const isVisible = () => {
    return document.visibilityState === 'visible';
};

interface INetworkState {
    onHide?: () => void;
    onShow?: () => void;
}

export default function usePageVisible({ onHide, onShow }: INetworkState): boolean {
    const [state, setState] = useState(isVisible);
    const preState = usePrevious(state);

    const _onHide = useLatest(onHide);
    const _onShow = useLatest(onShow);

    const visibilitychange = () => {
        setState(isVisible());
    };
    useEffect(() => {
        document.addEventListener('visibilitychange', visibilitychange);
        return () => {
            document.removeEventListener('visibilitychange', visibilitychange);
        };
    }, []);

    useEffect(() => {
        if (preState && !state) {
            _onHide.current && _onHide.current();
        }

        if (!preState && state) {
            _onShow.current && _onShow.current();
        }
    }, [state]);

    return state;
}
