import qs from 'querystring';

const PwaIdentifier = 'pwa';
const SigninWebPath = '/signin';
const StartMeetingWithoutVideoPath = '/start/webmeeting';
const StartMeetingWithVideoPath = '/start/videomeeting';
const ProfilePath = '/profile';
const LogoutPath = '/logout';
const PwaPath = '/wc';
const OpenSourceLinkUrl = '/opensource';
const SchedulePagePath = '/meeting/schedule';

const joinUrlPattern1 = /^\/wc\/join\/(\d{9,11})/;
const joinUrlPattern2 = /^\/wc\/(\d{9,11})\/join/;
const startUrlPattern1 = /^\/wc\/start\/(\d{9,11})/;
const startUrlPattern2 = /^\/wc\/(\d{9,11})\/start/;
const startUrlPattern3 = /^\/start\/videomeeting/;
const startUrlPattern4 = /^\/start\/webmeeting/;
const startUrlPattern5 = /^\/wc\/start\/videomeeting/;
const startUrlPattern6 = /^\/wc\/start\/webmeeting/;
const startUrlPattern7 = /^\/wc\/my\/(.+)/;

export const isJoinMeetingUrlPath = (pathname) => joinUrlPattern1.test(pathname) || joinUrlPattern2.test(pathname);
export const isStartMeetingUrlPath = (pathname) =>
    startUrlPattern1.test(pathname) ||
    startUrlPattern2.test(pathname) ||
    startUrlPattern3.test(pathname) ||
    startUrlPattern4.test(pathname);

export const isNoMeetingNumberUrl = (pathname) =>
    startUrlPattern5.test(pathname) || startUrlPattern6.test(pathname) || startUrlPattern7.test(pathname);

// zoomdev.us
export function getDomain(hostname) {
    if (!hostname) {
        hostname = window.location.hostname;
    }
    hostname = hostname.split('.');
    if (hostname[hostname.length - 1] === 'cn') {
        // zoom.com.cn
        hostname = hostname.slice(-3).join('.');
    } else {
        // zoomdev.us, zoom.us, zoomgov.com
        hostname = hostname.slice(-2).join('.');
    }
    return hostname;
}

export const getSubDomain = (domain) => {
    return domain.split('.').slice(-2).join('.');
};

function getQueryStringFromObject(params) {
    if (window.PwaConfig.enableMeetingRoute) {
        params.fromPWA = 1;
    } else {
        params.from = PwaIdentifier;
    }
    let search = qs.stringify(params);
    if (search.length > 0) {
        search = '?' + search;
    }
    return search;
}

// always be pwa.zoomdev.us, pwa.zoom.us, pwa.zoomgov.com
export const getPwaPath = () => {
    return `${window.location.origin}${PwaPath}?from=${PwaIdentifier}`;
};

export const getSigninPath = () => {
    return `${window.location.origin}${SigninWebPath}?from=${PwaIdentifier}`;
};

export const getSignOutPath = () => {
    return `${window.location.origin}${LogoutPath}?from=${PwaIdentifier}`;
};

export const getStartMeetingPath = (params = {}, withVideo = false) => {
    const path = `${window.location.origin}${withVideo ? StartMeetingWithVideoPath : StartMeetingWithoutVideoPath}`;
    const search = getQueryStringFromObject(params);
    return `${path}${search}`;
};

export const getStartMeetingPathViaMeetingNumber = (number, params = {}) => {
    const path = `${window.location.origin}/wc/${number}/start`;
    const search = getQueryStringFromObject(params);
    return `${path}${search}`;
};

export const getSchedulePagePath = () => {
    return `${window.location.origin}${SchedulePagePath}?from=${PwaIdentifier}`;
};

export const getScheduleEditPagePath = (number, isWebinar) => {
    let path = `/meeting/${number}/edit`;
    if (isWebinar) {
        path = `/webinar/${number}/edit`;
    }
    return `${window.location.origin}${path}?from=${PwaIdentifier}`;
};

export const getJoinMeetingPath = (meetingNumber, params = {}) => {
    const path = `${window.location.origin}/wc/${meetingNumber}/join`;
    const search = getQueryStringFromObject(params);
    return `${path}${search}`;
};

export const getJoinMeetingPersonLink = (personal, params = {}) => {
    const path = `${window.location.origin}/my/${personal}`;
    const search = getQueryStringFromObject(params);
    return `${path}${search}`;
};

export const getProfilePath = () => {
    return `https://${getDomain()}${ProfilePath}`;
};

export const getOpenSourceLinkUrl = () => {
    return `https://${getDomain()}${OpenSourceLinkUrl}?product=${PwaIdentifier}`;
};

export const getDeleteMeetingUrl = () => {
    return `${window.location.origin}/meeting/delete`;
};

export const getMeetingNoFromMeetingUrl = (url = '') => {
    const urlPatterns = [joinUrlPattern1, joinUrlPattern2, startUrlPattern1, startUrlPattern2, startUrlPattern7];

    let number = '';
    for (const pattern of urlPatterns) {
        const result = url.match(pattern);
        if (result) {
            number = result[1];
            break;
        }
    }
    return number;
};
