import { cookies } from './cookie';

const Lang_Cookie_Key = '_zm_lang';

export const checkLanguageInCookie = () => {
    const zm_lang = cookies.get(Lang_Cookie_Key);
    const default_lang = 'en-US';
    if (window.PwaConfig.supportLanguages && !window.PwaConfig.supportLanguages[zm_lang]) {
        setLanguageInCookie(default_lang);
    }
};

export const getLanguageInCookie = () => {
    const zm_lang = cookies.get(Lang_Cookie_Key);
    if (window.PwaConfig.supportLanguages && window.PwaConfig.supportLanguages[zm_lang]) {
        return { key: zm_lang, value: window.PwaConfig.supportLanguages[zm_lang] };
    } else {
        return null;
    }
};

export const setLanguageInCookie = (value: string, reload = true) => {
    let domain = window.location.hostname;
    if (domain.includes('zoom.us')) {
        domain = 'zoom.us';
    } else {
        domain = 'zoomdev.us';
    }
    cookies.set(Lang_Cookie_Key, value, {
        path: '/',
        domain,
        secure: true,
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
    });

    if (reload) {
        window.location.reload();
    }
};
