import { useEffect, useRef, useCallback } from 'react';
import useLatest from './useLatest';

const useInterval = (callback: (clear: () => void) => void, delay = 1000) => {
    const intervalRef = useRef<ReturnType<typeof setInterval>>();
    const callbackRef = useLatest(callback);

    const clear = useCallback(() => {
        intervalRef.current && clearInterval(intervalRef.current);
    }, []);

    const set = useCallback(() => {
        intervalRef.current && clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            callbackRef.current(clear);
        }, delay);
    }, [delay]);

    useEffect(() => {
        set();
        return clear;
    }, [delay]);

    return clear;
};

export default useInterval;
