import axios from 'axios';

export interface IWebResponse<Data> {
    errorCode: number;
    errorMessage: string | null;
    status: boolean;
    result: Data;
}

const axiosInstance = axios.create({
    responseType: 'json',
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    params: {
        from: 'pwa',
    },
});

axiosInstance.interceptors.request.use(
    function before(config) {
        // Do something before request is sent
        return config;
    },
    function fail(error) {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    function success(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    function error(error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
        }
        if (!error.response && error.request) {
            // maybe timeout
            // maybe network error
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
