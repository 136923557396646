import dayjs, { Dayjs } from 'dayjs';
import Long from 'long';
import classnames from 'classnames';
import {
    CalendarEvent,
    WebMeetingItem,
    MeetingItem,
    ExtendMeetingType,
    MeetingScheduleType,
    ItemType,
    WebCalendarEvent,
} from './types';
import {
    All_Day_Event_Text,
    InProgress_Text,
    Now_Text,
    Starts_In_1_Minute,
    Starts_In_Minutes_Text,
    Today_Text,
    Tomorrow_Text,
} from '../LanguageResource';

import {
    ERROR_CALENDAR_SERVICE_ACCOUNT_NOT_FOUND,
    ERROR_RESOURCE_GROUP_NOT_EXIST,
    ERROR_RESOURCE_GROUP_BINDING_NOT_EXIST,
    ERROR_GOOGLE_P12_DATA,
    ERROR_GOOGLE_REFRESH_TOKEN_EXPIRED,
    ERROR_EXCHANGE_AADSTS50057,
    ERROR_EXCHANGE_AADSTS50173,
    ERROR_EXCHANGE_AADSTS70008,
    ERROR_GRAPH_AADSTS500341,
    ERROR_GRAPH_AADSTS50057,
    ERROR_GRAPH_AADSTS50135,
    ERROR_GRAPH_AADSTS50173,
    ERROR_GRAPH_AADSTS700082,
    ERR_OAUTH2_EXPIRED,
    ERROR_GOOGLE_REFRESH_TOKEN_EXPIRED_2,
    ERROR_GOOGLE_QUERY_FAILED,
} from '../apis/errcode';
import { MeetingRoutes } from '../../../routes';
import { matchPath } from 'react-router-dom';

export const SCHEDULE_OPTION_USE_PMIASID = 0x8000000;
export const MTG_OPTIONS4_DISABLE_INVITE_FUNCTION = 0x0008;
export const MTG_OPTIONS4_IS_EVENT_DIRECT_MEETING = 0x80;
export const MTG_OPTIONS4_ON_ZOOM_MEETING_ENABLE_LOBBY = 0x200000000000; // todo too long risky
// featureOption5
export const MTG_OPTIONS5_CALENDAR_PRIVATE_EVENT = 0x1 << 9;

export const NUM_MAX_EVENT_TOPIC_LEN = 200;

export const isScheduleOptionsBitSet = (options: string, which: number) => {
    const pattern = Long.fromString(options, 10);
    const flag = Long.fromNumber(which);
    return !pattern.and(flag).isZero();
};

// it includes webinar and recurring meetings
export function isWebinarMeeting(item: MeetingItem | CalendarEvent) {
    return 'exType' in item && ExtendMeetingType.Webinar === item.exType;
}

export function isRealWebinarMeeting(item: MeetingItem | CalendarEvent) {
    return isWebinarMeeting(item) && !isWebRecurrenceMeeting(item);
}

export function isSimuliveWebinar(item: MeetingItem | CalendarEvent) {
    return (
        'exType' in item &&
        ExtendMeetingType.Webinar === item.exType &&
        'newExtType' in item &&
        ExtendMeetingType.SimuliveWebinar === item.newExtType
    );
}

// OnZoom events
export function IsEventDirectMeeting(item: MeetingItem | CalendarEvent) {
    if (item.itemType === ItemType.Calendar) return false;
    if (!item.scheduleOptions4) return false;
    const options = Long.fromString(item.scheduleOptions4, 10);
    const flag = Long.fromNumber(MTG_OPTIONS4_IS_EVENT_DIRECT_MEETING);
    return !options.and(flag).isZero();
}

export function isEventSummitConference(item: MeetingItem | CalendarEvent) {
    if (item.itemType === ItemType.Calendar) return false;
    if (!item.scheduleOptions4) return false;
    const options = Long.fromString(item.scheduleOptions4, 10);
    const flag = Long.fromNumber(MTG_OPTIONS4_ON_ZOOM_MEETING_ENABLE_LOBBY);
    return IsEventDirectMeeting(item) && !options.and(flag).isZero();
}

export function isSupportInvite(item: MeetingItem | CalendarEvent) {
    if (item.itemType === ItemType.Calendar) return false;

    if (!item.scheduleOptions4) return false;
    const options = Long.fromString(item.scheduleOptions4, 10);
    const flag = Long.fromNumber(MTG_OPTIONS4_DISABLE_INVITE_FUNCTION);
    return !!options.and(flag).isZero();
}

export function isPrivateCalendarMeeting(item: MeetingItem | CalendarEvent) {
    if (item.itemType === ItemType.Calendar) return false;
    if (!item.scheduleOptions5) return false;
    return isScheduleOptionsBitSet(item.scheduleOptions5, MTG_OPTIONS5_CALENDAR_PRIVATE_EVENT);
}

export function canWeViewDetail(item: MeetingItem | CalendarEvent) {
    if (item.itemType === ItemType.Calendar) return true;
    return item.canViewDetail;
}

export function isAllDayEvent(item: MeetingItem | CalendarEvent) {
    return 'allDay' in item && !!item.allDay;
}

// at first, native client can not schedule recuring meeting, only web page could
export function isWebRecurrenceMeeting(item: WebMeetingItem | CalendarEvent) {
    return (
        'exType' in item &&
        ExtendMeetingType.Webinar === item.exType &&
        'newExtType' in item &&
        ExtendMeetingType.Normal === item.newExtType
    );
}

export function isPMIMeetingSetting(item: MeetingItem | CalendarEvent) {
    return 'exType' in item && ExtendMeetingType.Pmi === item.exType;
}

export function isPeriodicMeeting(item: WebMeetingItem | CalendarEvent) {
    return 'type' in item && MeetingScheduleType.Repeat === item.type;
}

// Todo, this is not enough, we need web backend to add item.recurringType
export function isPeriodicMeetingWithFixedTime(item: WebMeetingItem | CalendarEvent) {
    return isPeriodicMeeting(item) && item?.startTime !== 0;
}

// Todo, this is not enough, we need web backend to add item.recurringType
export function isPeriodicMeetingWithNoFixedTime(item: WebMeetingItem | CalendarEvent) {
    return isPeriodicMeeting(item) && item?.startTime === 0;
}

export function isPMIMeeting(item: MeetingItem | CalendarEvent) {
    if (item.itemType === ItemType.Calendar) return false;
    if (!item.scheduleOptions) return false;
    const options = Long.fromString(item.scheduleOptions, 10);
    const flag = Long.fromNumber(SCHEDULE_OPTION_USE_PMIASID);
    return !options.and(flag).isZero();
}

export function getNoticeTxt(data: any) {
    const { startTime, duration, status } = data;
    const start = dayjs.unix(startTime);
    const end = start.add(duration, 'minute');
    const now = dayjs();

    let noticeTxt = '';
    let noticeCls = '';
    if (status) {
        noticeTxt = InProgress_Text;
    } else {
        // meeting not start
        if (isAllDayEvent(data)) {
            noticeTxt = All_Day_Event_Text;
        }

        if (now.isBetween(start.subtract(30, 'minute'), start.subtract(1, 'minute'), null, '[)')) {
            noticeTxt = Starts_In_Minutes_Text(start.diff(now, 'minute'));
        }

        if (now.isBetween(start.subtract(1, 'minute'), start, null, '[)')) {
            noticeTxt = Starts_In_1_Minute;
        }

        if (now.isBetween(start, end, null, '[]')) {
            noticeTxt = Now_Text;
            noticeCls = 'meetings__detail-time-notice-now';
        }
    }

    noticeCls = classnames('meetings__detail-time-notice', {
        'meetings__detail-time-notice-now': !!noticeCls,
    });

    return {
        noticeTxt,
        noticeCls,
    };
}

export function compareBasicInfoOfMeetingAndCalendar(meeting: MeetingItem, calendar: CalendarEvent) {
    const mTopic = meeting.topic.trim();
    let cTopic = calendar.topic.trim();
    let ret = false;
    if (cTopic.length > NUM_MAX_EVENT_TOPIC_LEN) {
        cTopic = cTopic.substring(0, NUM_MAX_EVENT_TOPIC_LEN);
    }

    if (meeting.startTime === calendar.startTime && meeting.duration === calendar.duration && mTopic === cTopic) {
        ret = true;
    }
    return ret;
}

export function isRemoveCalendarAgainstMeetings(meetingList: Array<MeetingItem>, calendar: CalendarEvent) {
    const { startTime, duration } = calendar;
    if (!startTime || !duration) return false;

    // if it's just a calendar event, not releated with a meeting, let it pass.
    if (!calendar.conferenceInfo) return false;

    // if calendar event is releated with an zoom meeting, that meeting will be in the result of /pwa-meeting/list
    // so we will remove this calendar event, because it's duplicated.
    for (let i = 0; i < meetingList.length; i++) {
        const meeting = meetingList[i];
        const { extInfo } = meeting;

        if (extInfo && extInfo.GoogleEventInfo && extInfo.GoogleEventInfo.EventId === calendar.id) {
            return true;
        }

        if (!isPMIMeeting(meeting)) {
            // not a pmi meeting
            if (!isPMIMeetingSetting(meeting)) {
                // not pmi setting
                if (String(meeting.meetingNumber) === calendar.conferenceInfo.meetingId) {
                    return true;
                }
            }
        } else {
            // pmi meeting
            if (
                String(meeting.meetingNumber) === calendar.conferenceInfo.meetingId &&
                compareBasicInfoOfMeetingAndCalendar(meeting, calendar)
            ) {
                return true;
            }
        }
    }
    return false; // not to remove;
}

// in response of /pwa-meeting/list and /pwa-meeting/deatil
// meetingNumber, originMtgNumber is number, occurence is null or number
// in zpns event
// mn, originMn and occurenceTime is string;
export function produceMeetingItemId(
    meetingNumber: string | number,
    originalMtgNumber: string | number,
    occurrence: number | string | null,
) {
    const number = String(originalMtgNumber) !== '0' ? originalMtgNumber : meetingNumber;
    return `meeting-${number}-${occurrence || 0}`;
}

export function transformZoomMeeting(item: WebMeetingItem): MeetingItem {
    if (!item) return null;
    const { meetingNumber, topic, hostName, originalMtgNumber, extInfo, occurrence, joinUrl } = item;
    if (meetingNumber === undefined || topic === undefined || hostName === undefined) {
        return null;
    }

    // joinUrl: https://xxxx/xx?pwd=yyyyyyyy
    // pwd is entryped password and will always be there, even you disable 'embed passcode into one-click join link' in your user setting
    let password = '';
    try {
        const url = new URL(joinUrl);
        password = url.searchParams.get('pwd') || '';
    } catch (e) {
        password = '';
    }

    const result: MeetingItem = {
        ...item,
        itemType: ItemType.Zoom,
        password,
        id: produceMeetingItemId(String(meetingNumber), String(originalMtgNumber), occurrence), // when conf/m return, same item's id has changed
    };
    if (extInfo) {
        if (typeof extInfo === 'string') {
            result.extInfo = JSON.parse(extInfo);
        }
    }
    return result;
}

export function transformCalendarEvent(item: WebCalendarEvent): CalendarEvent {
    if (!item) return null;
    const { eventId, topic, startTime, endTime, organizerEmail, timeZone = dayjs.tz.guess() } = item;
    if (!topic || !eventId || !startTime || !organizerEmail) {
        return null;
    }

    const result: CalendarEvent = {
        ...item,
        id: eventId,
        itemType: ItemType.Calendar,
        startTime: dayjs(startTime).tz(timeZone).unix(),
        endTime: dayjs(endTime).tz(timeZone).unix(),
    };

    return result;
}

export function getDayComponents(dayjsObj: Dayjs) {
    if (!dayjsObj.isValid()) {
        return null;
    }
    const day = dayjsObj.format('ddd'); // day of week, Thu
    const date = dayjsObj.format('D'); // day of month, 1, 3
    const month = dayjsObj.format('MMM'); // Jan
    const year = dayjsObj.format('YYYY'); // 2099
    return {
        day,
        date,
        month,
        year,
        formated: `${day}, ${month} ${date}`,
    };
}

export function getRouteFromURL(url: string) {
    const urlComponent = new URL(url);
    return urlComponent.pathname.replace('/wc', '') + urlComponent.search;
}

// return Thursday, November 18, 2021
export function getTimeLabel(time: number) {
    const _param = getDayComponents(dayjs.unix(time));
    const _today = getDayComponents(dayjs());
    const _tomorow = getDayComponents(dayjs().endOf('day').add(10, 'minutes'));

    if (!_param) {
        console.error('wrong time stamp');
        return null;
    }
    let result = _param.formated;

    // today
    if (_param.formated === _today.formated) {
        result = Today_Text;
    } else if (_param.formated === _tomorow.formated) {
        result = Tomorrow_Text;
    }

    const { day, month, date, year } = _param;
    // not the same year
    if (_param.year !== _today.year) {
        result = `${day}, ${month} ${date}, ${year}`; // append year
    }

    // this year: not today, not tomorrow
    return result;
}

// 18:23 - 20-44
export function getTimeSpan(startTime: number, duration: number): string {
    if (!startTime) {
        return '';
    }
    const start = dayjs.unix(startTime);
    const end = start.add(duration, 'minute');
    return `${start.format('H:mm')} - ${end.format('H:mm')}`;
}

export const sortMeetings = <T extends MeetingItem | CalendarEvent>(left: T, right: T) => {
    return left.startTime - right.startTime >= 0 ? 1 : -1;
};

export const getNwsCalendarListUrl = () => {
    const calendar_list_url_path = '/nws/calendar/1.0/person/events';
    let calendar_list_url_domain = '';
    const hostname = window.location.hostname;

    if (/zoom(dev)?\.us/.test(hostname)) {
        // zoom clusters
        if (/zoomdev\.us/.test(hostname)) {
            if (/dev-integration/.test(hostname)) {
                calendar_list_url_domain = 'edge.zoomdev.us';
            } else {
                calendar_list_url_domain = 'nws.zoomdev.us';
            }
        } else {
            calendar_list_url_domain = 'nws.zoom.us';
        }
    } else if (/zoomgov(dev)?\.com/.test(hostname)) {
        // gov clusters
        if (/zoomgovdev/.test(hostname)) {
            calendar_list_url_domain = 'nws.zoomgovdev.com';
        } else {
            calendar_list_url_domain = 'nws.zoomgov.com';
        }
    }

    return `https://${calendar_list_url_domain}${calendar_list_url_path}`;
};

export const isUserNotIntegrateCalendar = (code: number) => {
    return (
        code === ERROR_RESOURCE_GROUP_NOT_EXIST ||
        code === ERROR_RESOURCE_GROUP_BINDING_NOT_EXIST ||
        code === ERROR_CALENDAR_SERVICE_ACCOUNT_NOT_FOUND
    );
};

export const isUserNeedReAuthCalendar = (code: number) => {
    return (
        code === ERROR_GOOGLE_P12_DATA ||
        code === ERROR_GOOGLE_REFRESH_TOKEN_EXPIRED ||
        code === ERROR_GOOGLE_REFRESH_TOKEN_EXPIRED_2 ||
        code === ERROR_GOOGLE_QUERY_FAILED ||
        code === ERROR_EXCHANGE_AADSTS50057 ||
        code === ERROR_EXCHANGE_AADSTS50173 ||
        code === ERROR_EXCHANGE_AADSTS70008 ||
        code === ERROR_GRAPH_AADSTS500341 ||
        code === ERROR_GRAPH_AADSTS50057 ||
        code === ERROR_GRAPH_AADSTS50135 ||
        code === ERROR_GRAPH_AADSTS50173 ||
        code === ERROR_GRAPH_AADSTS700082 ||
        code === ERR_OAUTH2_EXPIRED
    );
};

export const checkIsMeetingRoute = (pathname: string, url?: string) => {
    const backUrl = url ? new URL(url).searchParams.get('backurl') : '';
    const _pathname = backUrl ? getRouteFromURL(backUrl) : pathname.replace('/wc', '');
    const isMeetingRoute = MeetingRoutes.some((path) => !!matchPath(_pathname, { path: path, exact: true }));
    return isMeetingRoute;
};
