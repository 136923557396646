import { iText } from '../../utils/i18n';

export const DRIVE_WARNING_TEXT = iText(
    'Distracted driving can lead to accidents resulting in serious injury or death. Please pay attention to traffic conditions and comply with local laws. If you are hosting a meeting, consider designating a co-host.',
    'pwa.drive.warning',
);

// export const CLICK_TO_AGREE_TEXT = iText('By clicking “Join”, you agree to the', 'pwa.click.to.agree');

// export const CLICK_TO_AGREE_TEXT = iText('By clicking "Join", you agree to the', 'pwa.click.to.agree.1');
export const CLICK_TO_AGREE_TEXT = (join: string, stub: string) =>
    iText('By clicking "{0}", you agree to the {1}', 'pwa.click.to.agree.2', [join, stub]);

export const SERVICE_AND_PRIVACY_TEXT = iText(
    "Zoom's Terms of Service and Privacy Statement",
    'pwa.service.and.privacy',
);
