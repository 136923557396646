import React from 'react';
import { Modal } from 'Modal';
import styles from './index.module.scss';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import Button from '../../../components/Button/Button';
import { cameraControlGroupHelper } from '../../Contacts/utils/CameraControlGroupHelper';
import {
    ADD_CAMERA_CONTROL_GROUP_MODAL_TITLE_F,
    ADD_CAMERA_CONTROL_GROUP_MODAL_CONTENT,
    CANCEL,
    Add_Text,
} from '../../../resource';

type IProps = {
    data: {
        name: string;
        jid: string;
    };
};
const AddToCameraControlGroupModal = ({ data: { name, jid } }: IProps) => {
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(
            setModal({
                name: 'addToCameraControlGroup',
                data: null,
            }),
        );
    };

    const add = () => {
        // render and store pending contact data
        cameraControlGroupHelper.addToCameraControlGroup(jid);
        close();
    };

    return (
        <Modal isOpen={true} needBackdrop={true} isDraggable={false} centered={true}>
            <div className={styles['modal-add-to-camera-control-group']}>
                <p className={styles.title}>{ADD_CAMERA_CONTROL_GROUP_MODAL_TITLE_F(name)}</p>
                <p className={styles.content}>{ADD_CAMERA_CONTROL_GROUP_MODAL_CONTENT}</p>
                <div className={styles['button-group']}>
                    <Button extraCls={styles.cancel} type={Button.Secondary} onClick={close}>
                        {CANCEL}
                    </Button>
                    <Button extraCls={styles.add} type={Button.Primary} onClick={add}>
                        {Add_Text}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddToCameraControlGroupModal;
