import React, { useState } from 'react';
import classnames from 'classnames';
import './ContactList.scss';
import { isDuringMeetingSelector } from '../../../Meeting/redux';
import { Chat_Text, INVITE_TO_MEETING, MEET_WITHOUT_VIDEO } from '../../../../resource';
import { GROUP_ID, IContact, CONTACT_MEMBER_OPTIONS_FROM } from '../../types';

import { ifCanShowChatBtn, getContactDisplayName, isGroupAddon } from '../../redux';
import { chatAgent, useIsUserBlockedByIM } from '../../../Chat';
import { inviteContact } from '../../hooks/useInviteContact';
import { IconChatFill, IconVideoOnFill } from '@zoom/icons-react';
import { PhoneCallIcon } from '../../PhoneCallToContact';
import { Call_Text } from '../../../Meetings/LanguageResource';
import ContactMemberOperation from './ContactMemberOperation/ContactMemberOperation';
import { interceptPropagation } from '../../../../utils/index';
import { useAppSelector } from '../../../../store';
import { isZCCInVideoCall } from '../../../../store/appModules/contact-center';

interface Props {
    contact: IContact;
    groupId?: GROUP_ID;
}

const ContactActionButtons = ({ contact, groupId }: Props) => {
    const [showActions, setShowActions] = useState(false);

    const { jid, email } = contact;

    const displayName = getContactDisplayName(contact);
    const canShowChatBtn = ifCanShowChatBtn(groupId);
    const isAddon = isGroupAddon(groupId);

    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);
    const showWebclient = useAppSelector((state) => state.meeting.showWebclient);
    const isDuringMeeting = useAppSelector(isDuringMeetingSelector);
    const isMeetingInviteDisabled = showWebclient && !isDuringMeeting;
    const isJoinOnly = window.PwaConfig.isNoMeetingsLicenseTypeUser;

    const isIMBlocked = useIsUserBlockedByIM({ jid });

    const onClickVideo = () => {
        if (isMeetingInviteDisabled || isZCCInMeeting) return;
        inviteContact([{ jid, name: displayName }]);
    };
    const onClickChat = () => {
        chatAgent.chatWithUserOrChannel({ id: jid });
    };

    const meetingButtonTitle = isDuringMeeting ? INVITE_TO_MEETING : MEET_WITHOUT_VIDEO;

    return (
        <div className={classnames('actions-btns', { 'show-actions': showActions })} role="group">
            {isJoinOnly || isAddon ? null : (
                <>
                    {canShowChatBtn ? (
                        <button
                            className={classnames('contact__action-btns--btn action-btns--chat')}
                            title={Chat_Text}
                            aria-label={Chat_Text}
                            onClick={(e) => interceptPropagation(e, onClickChat)}
                        >
                            <IconChatFill aria-hidden={true} />
                        </button>
                    ) : null}

                    {isIMBlocked ? null : (
                        <button
                            className={classnames('contact__action-btns--btn meet-with-video', {
                                disabled: isMeetingInviteDisabled || isZCCInMeeting,
                            })}
                            title={meetingButtonTitle}
                            aria-label={meetingButtonTitle}
                            onClick={(e) => interceptPropagation(e, onClickVideo)}
                        >
                            <IconVideoOnFill aria-hidden={true} />
                        </button>
                    )}

                    <PhoneCallIcon
                        contact={contact}
                        isAddon={isAddon}
                        isIMBlocked={isIMBlocked}
                        className="contact__action-btns--btn send-phone-call"
                        title={Call_Text}
                    />
                    <ContactMemberOperation
                        jid={jid}
                        groupId={groupId}
                        name={displayName}
                        email={email}
                        showActions={setShowActions}
                        from={CONTACT_MEMBER_OPTIONS_FROM.CONTACT}
                    />
                </>
            )}
        </div>
    );
};

export default ContactActionButtons;
