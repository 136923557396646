import { useEffect, useRef } from 'react';
import AudioRing from '../utils/audio';
import { useAppSelector } from '../store/store-hooks';

export default function useAudioPlay(ringType: string) {
    const { speakerDeviceId } = useAppSelector((state) => state.common.mediaConfig);
    const audioInstanceRef = useRef<AudioRing>(AudioRing.getAudioInstance(ringType));
    const audio = audioInstanceRef.current;
    useEffect(() => {
        if (audio) {
            audio.changeSpeaker(speakerDeviceId);
        }
    }, [speakerDeviceId, audio]);

    return {
        audio,
        speakerDeviceId,
    };
}
