import { iText } from '../../../utils/i18n';

export const hadissuesListLeft = [
    {
        text: iText('I could not hear others', 'apac.wc_feedback_hadissues_9'),
        id: 9,
    },
    {
        text: iText('Others could not hear me', 'apac.wc_feedback_hadissues_8'),
        id: 8,
    },
    {
        text: iText('Poor audio quality', 'apac.wc_feedback_hadissues_2'),
        id: 2,
    },
];

export const hadissuesListRight = [
    {
        text: iText('I could not see others', 'apac.wc_feedback_hadissues_11'),
        id: 11,
    },
    {
        text: iText('Others could not see me', 'apac.wc_feedback_hadissues_10'),
        id: 10,
    },
    {
        text: iText('Poor video quality', 'apac.wc_feedback_hadissues_1'),
        id: 1,
    },
];

export const feedbackTipText = iText(
    'The account owner can see who you are, your contact information (if you’re logged in or provided it) and your feedback — and can share them with apps and others.',
    'apac.wc_feedback_feedback_tip',
);

export const feedbackEveryoneTitle = iText('How was your experience?', 'apac.wc_feedback_everyone_title');

export const great = iText('Great', 'apac.wc_feedback_great');

export const hadIssues = iText('Had Issues', 'apac.wc_feedback_had_issues');

export const hadIssuesTitle = iText('What went wrong?', 'apac.wc_feedback_had_issues_title');

export const submit = iText('Submit', 'apac.wc_feedback_submit');

export const feedbackRandomlyTitle = iText('How was your experience?', 'apac.wc_feedback_randomly_title');

export const contactMe = iText('It’s OK to contact me about this feedback', 'apac.wc_feedback_contact_me');

export const enterEmailAddress = iText('Enter your email address', 'apac.wc_feedback_enter_email_address');

export const pleaseEnterEmail = iText('Please enter your email', 'apac.wc_feedback_please_enter_email');

export const pleaseEnterValidEmail = iText('Please enter a valid email', 'apac.wc_feedback_please_enter_valid_email');

export const whoCanSeeTip = iText('Who can see your feedback?', 'apac.wc_feedback_who_can_see_tip');
