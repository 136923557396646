import { useEffect, useLayoutEffect, useRef } from 'react';
import { isUserSigninSelector } from '../common/common-selector';
import { useAppSelector } from '../store-hooks';

export const useBaseZindex = () => {
    const {
        modal: { webclientIframeZIndex },
        meeting: { showWebclient },
    } = useAppSelector((_) => _);

    return showWebclient ? webclientIframeZIndex : 0;
};

export const useCloseWhenLogout = (callback: () => void) => {
    const callbackRef = useRef(callback);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const isUserSignin = useAppSelector(isUserSigninSelector);
    useEffect(() => {
        if (typeof callbackRef.current !== 'function') {
            return;
        }
        !isUserSignin && callbackRef.current();
    }, [isUserSignin]);
};
