import { invitationManager, xmppAgent, meetingAgent } from '../../../app-init';
import { setMeetingInfo, IMeetingInfo, clearInvitation, removeInvitation } from './meeting-store';
import { AppDispatch, AppGetState } from '../../../store';
import BrowserNotification, { NOTIFICATION_TAGS } from '../../../utils/BrowserNotification';
import { MISSED_CALL_TEXT } from '../../../resource';

import httpRequest from '../../../services/http/http';
import { setModal } from '../../../store/modal/modal-store';
import { PWAMeetingEvent } from '../meeting-agent-msg-type';

export const setMeetingInfoThunk = (meetingInfo: IMeetingInfo) => (dispatch: AppDispatch) => {
    if (PWAMeetingEvent.JOINING === meetingInfo.meetingStatus) {
        dispatch(setMeetingInfo(meetingInfo));
    } else if (PWAMeetingEvent.SUCCESS === meetingInfo.meetingStatus) {
        if (!meetingInfo.hasBypassWaitingroomCode) {
            invitationManager.doInviteContacts(meetingInfo);
            return;
        }
        const jids = invitationManager.getWaitingToInviteJids();
        meetingAgent.askWC2Invite(jids);
        dispatch(setMeetingInfo(meetingInfo));
    } else if (
        PWAMeetingEvent.LEAVE === meetingInfo.meetingStatus ||
        PWAMeetingEvent.END === meetingInfo.meetingStatus ||
        PWAMeetingEvent.END_BY_HOST === meetingInfo.meetingStatus ||
        PWAMeetingEvent.REMOVED_BY_HOST === meetingInfo.meetingStatus
    ) {
        invitationManager.onMeetingEnded(meetingInfo);
        dispatch(setMeetingInfo(null));
    }
};

export const acceptThunk = (invitation: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    const { meetingNo, password } = invitation;
    const {
        meeting: { invitationList },
        common: { userInfo },
    } = getState();
    const { displayName } = userInfo;

    invitationList.forEach((item) => {
        if (item.fromJid !== invitation.fromJid) {
            xmppAgent.declineInvitation({ ...item, toName: displayName });
        }
    });

    invitationManager.setAcceptedInvitation(invitation);

    xmppAgent.acceptInvitation({ ...invitation, toName: displayName });

    meetingAgent.endCurrentAndJoinThisMeeting(
        {
            meetingNo,
            password,
        },
        true,
    );

    dispatch(clearInvitation());
};

export const declineThunk = (invitation: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    const {
        common: { userInfo },
    } = getState();
    const { displayName } = userInfo;
    const { fromName } = invitation;
    if (invitation.isTimeoutDecline) {
        if (!fromName) return;
        const bNotification = new BrowserNotification();
        bNotification.notify({
            tag: NOTIFICATION_TAGS.DECLINE_MISSING, // 'decline_missing',
            title: MISSED_CALL_TEXT,
        } as any);
    }
    invitationManager.setAcceptedInvitation(null);
    xmppAgent.declineInvitation({ ...invitation, toName: displayName });
    dispatch(removeInvitation(invitation));
};

export const checkMeetingForceBreakThunk = () => (dispatch: AppDispatch) => {
    const url = '/wc/check_force_break';
    return httpRequest
        .get(url)
        .then((res: any) => {
            const { status, result } = res.data;
            if (!status && result) {
                dispatch(setModal({ name: 'forcedBreakMeeting', data: result }));
                return false;
            }

            return true;
        })
        .catch((error) => {
            return error;
        });
};
