import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
    isPeriodicMeeting,
    getTimeLabel,
    isPMIMeetingSetting,
    sortMeetings,
    isPeriodicMeetingWithNoFixedTime,
} from '../utils';
import { RootState } from '../../../../store';
import { CalendarEvent, MeetingItem } from '../types';
import { userInfoSelector } from '../../../../store/common/common-selector';
import { Everyone_Text, Me_Text, Recurring_Text } from '../../LanguageResource';
import {
    enableExpandRecurringMeetingsSelector,
    showEveryoneMeetingListSeletor,
} from '../../../../store/common/userWebSettingsSelector';

export const meetingsSelector = (state: RootState) => state.meetings.meetings;
export const calendarsSelector = (state: RootState) => state.meetings.calendars;
export const selectedMeetingItemIdSelector = (state: RootState) => state.meetings.selectedMeetingItemId;
export const selectedMeetingHostIdSelector = (state: RootState) => state.meetings.selectedMeetingHostId;
export const calculateUpcomingMeetingsAtSelector = (state: RootState) => state.meetings.calculateUpcomingMeetingsAt;

export const EveryoneId = 'Everyone';
export const MeId = 'Me';
export const meetingHostListSelector = createSelector(
    [userInfoSelector, showEveryoneMeetingListSeletor],
    (userInfo, showEveryoneList) => {
        if (!userInfo?.userId || !showEveryoneList) return [];
        const { email = '', userId = MeId } = userInfo;
        const initial = [
            { displayName: Everyone_Text, userId: EveryoneId, email: '' },
            { displayName: Me_Text, userId, email },
        ];
        return (userInfo.scheduleForList || []).reduce((acc, { firstName = '', lastName = '', userId, email }) => {
            return [...acc, { displayName: `${firstName} ${lastName}`, userId, email }];
        }, initial);
    },
);

// the first item of meetings, is current user's PMI setting
export const pmiMeetingSettingSelector = createSelector([userInfoSelector, meetingsSelector], (userInfo, meetings) => {
    if (!userInfo) return null;
    return meetings.find(isPMIMeetingSetting);
});

export const groupedMeetingsSelector = createSelector(
    [
        meetingsSelector,
        calendarsSelector,
        selectedMeetingHostIdSelector,
        userInfoSelector,
        enableExpandRecurringMeetingsSelector,
    ],
    (meetings, calendars, selectedMeetingHostId, userInfo, enableExpandRecurringMeetings) => {
        const userId = userInfo?.userId;
        if (!userId) {
            return [];
        }

        const filteredMeetings = meetings
            .filter((item) => !isPMIMeetingSetting(item)) // to remove pmi settings.
            .filter((_) => selectedMeetingHostId === EveryoneId || _.hostId === selectedMeetingHostId); // filter by selected host id

        let recurringMeetings = filteredMeetings.filter(isPeriodicMeeting);
        let normalMeetings = filteredMeetings.filter((item) => !isPeriodicMeeting(item));

        const recurringNoFixedTimeMeetings = recurringMeetings.filter(isPeriodicMeetingWithNoFixedTime);
        const recurringWithFixedTimeMeetings = recurringMeetings.filter((m) => !isPeriodicMeetingWithNoFixedTime(m));

        if (enableExpandRecurringMeetings) {
            normalMeetings = normalMeetings.concat(recurringWithFixedTimeMeetings);
            recurringMeetings = recurringNoFixedTimeMeetings;
        } else {
            recurringWithFixedTimeMeetings.sort(sortMeetings);
            recurringMeetings = recurringWithFixedTimeMeetings.concat(recurringNoFixedTimeMeetings);
        }

        let total = [];

        // calendars belongs to current user.
        if (selectedMeetingHostId === EveryoneId || selectedMeetingHostId === userId) {
            total = [...normalMeetings, ...calendars];
        } else {
            total = normalMeetings;
        }

        total.sort(sortMeetings);

        const initial: Array<{
            label: string;
            items: Array<MeetingItem | CalendarEvent>;
        }> = [];

        // group by date
        const result = total.reduce((acc, cur) => {
            const { startTime } = cur;
            const label = getTimeLabel(startTime);
            if (label) {
                // startTime is valid
                const _obj = acc.find((item) => item.label === label);
                if (!_obj) {
                    // create a new group
                    acc.push({ label, items: [cur] });
                } else {
                    _obj.items.push(cur);
                }
            }
            return acc;
        }, initial);

        if (recurringMeetings.length > 0) {
            result.push({
                label: Recurring_Text,
                items: recurringMeetings,
            });
        }
        return result;
    },
);

export const selectedMeetingItemSelector = createSelector(
    [meetingsSelector, calendarsSelector, selectedMeetingItemIdSelector],
    (meetings, calendars, itemId) => {
        return [...meetings, ...calendars].find((item) => item.id === itemId);
    },
);

export const upcomingMeetingsSelector = createSelector(
    [
        meetingsSelector,
        calendarsSelector,
        selectedMeetingHostIdSelector,
        userInfoSelector,
        calculateUpcomingMeetingsAtSelector,
        showEveryoneMeetingListSeletor,
    ],
    (meetings, calendars, selectedMeetingHostId, userInfo, _calculateAt, showEveryoneList) => {
        const userId = userInfo?.userId;
        if (!userId) {
            console.error('error, meetings no userId');
            return {
                availableMeetings: [],
                ongoings: [],
                nearestUpcomings: [],
            };
        }

        const now = dayjs();

        // start --- end --- now ---
        const endedPredicate = ({ startTime, duration }: MeetingItem | CalendarEvent) => {
            if (!startTime || !duration) return false;
            return now.isAfter(dayjs.unix(startTime).add(duration, 'minute'));
        };

        // start ------  now ------ end ---
        const startedNotEndedPredicate = ({ startTime, duration }: MeetingItem | CalendarEvent) => {
            if (!startTime || !duration) return false;
            return now.isBetween(dayjs.unix(startTime), dayjs.unix(startTime).add(duration, 'minute'), null, '[]');
        };

        // --- now --- start --- end ---
        const notStartPrecidate = ({ startTime }: MeetingItem | CalendarEvent) => {
            if (!startTime) return false;
            return now.isBefore(dayjs.unix(startTime));
        };

        // 00:00:00 --- start --- 23:59:59
        const inTodayPredicate = ({ startTime }: MeetingItem | CalendarEvent) => {
            if (!startTime) return false;
            return dayjs.unix(startTime).isBetween(now.startOf('day'), now.endOf('day'), null, '[]');
        };

        const filteredMeetings = meetings
            .filter((item) => !isPMIMeetingSetting(item)) // to remove pmi settings.
            .filter((item) => !isPeriodicMeeting(item))
            .filter((_) => showEveryoneList || _.hostId === userId) // only show current user's meetings
            .filter((_) => selectedMeetingHostId === EveryoneId || _.hostId === selectedMeetingHostId); // filter by selected host id

        let total = [];

        // calendars belongs to current user.
        if (selectedMeetingHostId === EveryoneId || selectedMeetingHostId === userId) {
            total = [...filteredMeetings, ...calendars];
        } else {
            total = filteredMeetings;
        }

        total.sort(sortMeetings);

        const inToday = total.filter((_) => !isPeriodicMeeting(_)).filter(inTodayPredicate);

        const remainings = inToday.filter((_) => !endedPredicate(_)).sort(sortMeetings);

        const ongoings = remainings.filter(startedNotEndedPredicate);
        const upcomings = remainings.filter(notStartPrecidate);

        // many meetings have same start time.
        const nearestUpcomings = upcomings.filter((_) => _.startTime === upcomings[0].startTime);
        return {
            availableMeetings: remainings,
            ongoings,
            nearestUpcomings,
        };
    },
);
