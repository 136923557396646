import { DOM_KEY_CODE } from '../../../utils/constants';
import { A11Y_POLICY, All_DOM_Nodes_Type, Perform_Focus_Type, Perform_Click_Type } from './type';

function keyBoardEventHandler(
    walkerPolicy: A11Y_POLICY,
    performFocus: Perform_Focus_Type,
    performClick: Perform_Click_Type,
    { keyCode }: KeyboardEvent, // shiftKey
    {
        rootJDom,
        currentDom,
        firstChildJDom,
        prevJDom,
        isVirtuallyPrevJDom,
        nextJDom,
        isCurrentDomAriaExpanded,
        isCurrentDomIsGroup,
        currentDomOwnGroupJDom,
        leftKeyDisabled,
    }: All_DOM_Nodes_Type,
) {
    switch (walkerPolicy) {
        case A11Y_POLICY.CONTACT_TREE_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.DOWN: {
                    performFocus(keyCode, rootJDom, firstChildJDom);
                    return true;
                }
                default:
                    return false;
            }
        case A11Y_POLICY.CONTACT_TREE_ITEM_POLICY:
            switch (keyCode) {
                case DOM_KEY_CODE.UP:
                    performFocus(keyCode, rootJDom, prevJDom);
                    if (isVirtuallyPrevJDom) {
                        prevJDom && (prevJDom as any)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                    return true;
                case DOM_KEY_CODE.DOWN: {
                    performFocus(keyCode, rootJDom, nextJDom);
                    return true;
                }
                case DOM_KEY_CODE.LEFT:
                    if (leftKeyDisabled) return false;

                    // close group item
                    if (isCurrentDomIsGroup && isCurrentDomAriaExpanded) {
                        performClick(keyCode, rootJDom, currentDom);
                    }
                    // jump to group item from contact item
                    else if (!isCurrentDomIsGroup && currentDomOwnGroupJDom) {
                        performFocus(keyCode, rootJDom, currentDomOwnGroupJDom);
                    }
                    return true;
                case DOM_KEY_CODE.RIGHT:
                    if (isCurrentDomIsGroup) {
                        // current item is expanded group item, jump to group first contact item
                        if (isCurrentDomAriaExpanded) {
                            performFocus(keyCode, rootJDom, nextJDom);
                        } else {
                            // click
                            performClick(keyCode, rootJDom, currentDom);
                        }
                    }
                    return true;
                case DOM_KEY_CODE.ENTER:
                case DOM_KEY_CODE.SPACE:
                    performClick(keyCode, rootJDom, currentDom);
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
}

export { keyBoardEventHandler };
