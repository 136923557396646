import { ICON_TYPE, toast } from 'Toast';
import { Announcement_Body_Text, Announcement_Text } from '../../../resource';
import { IUserInfo } from '../../../store/common/common-store';
import easyStore from '../../../utils/storage';

export interface IShowJoinOnlyToastProps {
    userId: string;
}
const tag = 'join-only-toast';
const storeKey = 'joinOnly';

const getIdFromUserId = () => {
    return window.PwaConfig.uid;
};

export const maybeShowJoinOnlyToast = (_userInfo?: IUserInfo) => {
    // https://zoomvideo.atlassian.net/browse/ZOOM-316242
    // no need to show the toast;
    return false;

    const id = getIdFromUserId();
    const value = easyStore.localStore.easyGet(storeKey);
    const isSet = value && value[id];
    if (window.PwaConfig.isNoMeetingsLicenseTypeUser && !isSet) {
        toast(
            {
                type: ICON_TYPE.WARN,
                title: Announcement_Text,
                desc: Announcement_Body_Text,
            },
            {
                closeButton: true,
                autoClose: false,
                toastId: tag,
                onClose: () => {
                    const value = {
                        ...easyStore.localStore.easyGet(storeKey),
                        [getIdFromUserId()]: 1,
                    };
                    easyStore.localStore.easySet(storeKey, value);
                },
            },
        );
    }
};
