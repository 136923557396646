import React from 'react';
import { Modal } from 'Modal';
import dayjs from 'dayjs';
import './index.scss';
import { allowOrDenyLoginRequest, LoginRequestType } from '../../../services/http/apis';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import Button from '../../../components/Button/Button';
import { toast, ICON_TYPE } from 'Toast';
import {
    OTP_SIGNING_IN_ON_ANOTHER_DEVICE,
    OTP_NOTIFICATION_TEXT,
    OTP_TIME,
    OTP_BROWSER,
    OTP_OS,
    OTP_LOCATION,
    OTP_ALLOW,
    OTP_DENY,
    OTP_ERROR_OCCURRED,
} from '../../../resource';

type IAccountTakeoverModal = {
    data: {
        code: string;
        from: string;
        operateTime: string;
        browser: string;
        os: string;
        location: string;
    };
};

// These error codes correspond to error messages that we should show to the user.
const isUserVisibleError = (errorCode: number) => {
    return errorCode === 1243 || errorCode === 1244;
};

const isUserHandledByOtherMethod = (errorCode: number) => {
    return errorCode === 1240;
};

const AccountTakeoverModal = ({ data }: IAccountTakeoverModal) => {
    const dispatch = useAppDispatch();

    const onAllow = async () => {
        let isError = false;
        let errorMessage = null;
        try {
            const response = await allowOrDenyLoginRequest(data.code, data.from, LoginRequestType.Allow);
            if (!response?.status) {
                isError = true;

                if (isUserHandledByOtherMethod(response?.errorCode)) {
                    isError = false;
                }

                if (isError && isUserVisibleError(response?.errorCode)) {
                    errorMessage = response?.errorMessage;
                }
            }
        } catch (e) {
            isError = true;
        } finally {
            dispatch(setModal({ name: 'accountTakeover', data: null }));
        }

        if (isError) {
            toast({
                type: ICON_TYPE.FAILURE,
                desc: errorMessage ?? OTP_ERROR_OCCURRED,
            });
        }
    };

    const onDeny = async () => {
        let isError = false;
        let errorMessage = null;
        try {
            const response = await allowOrDenyLoginRequest(data.code, data.from, LoginRequestType.Deny);
            if (!response?.status) {
                isError = true;

                if (isUserHandledByOtherMethod(response?.errorCode)) {
                    isError = false;
                }
                if (isError && isUserVisibleError(response?.errorCode)) {
                    errorMessage = response?.errorMessage;
                }
            } else {
                const resetPasswordURL = response?.result?.reset_pwd_url;
                if (resetPasswordURL) {
                    dispatch(setModal({ name: 'resetPassword', data: { resetPasswordURL } }));
                }
            }
        } catch (e) {
            isError = true;
        } finally {
            dispatch(setModal({ name: 'accountTakeover', data: null }));
        }

        if (isError) {
            toast({
                type: ICON_TYPE.FAILURE,
                desc: errorMessage ?? OTP_ERROR_OCCURRED,
            });
        }
    };

    const getFormattedTime = (utcTimestamp: string) => {
        return dayjs(utcTimestamp).format('MMM D H:m A');
    };

    return (
        <Modal isOpen={true} needBackdrop={true} isDraggable={false} aria={{ labelledby: 'zm-ato' }} centered={true}>
            <div className="modal-ato" data-testid="modal-ato">
                <p className="signing-in" id="zm-ato">
                    {OTP_SIGNING_IN_ON_ANOTHER_DEVICE}
                </p>
                <p>{OTP_NOTIFICATION_TEXT}</p>
                <table>
                    <tbody>
                        <tr>
                            <td>{OTP_TIME}</td>
                            <td>{getFormattedTime(data.operateTime)}</td>
                        </tr>
                        <tr>
                            <td>{OTP_BROWSER}</td>
                            <td>{data.browser}</td>
                        </tr>
                        <tr>
                            <td>{OTP_OS}</td>
                            <td>{data.os}</td>
                        </tr>
                        <tr>
                            <td>{OTP_LOCATION}</td>
                            <td>{data.location}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="button-group">
                    <Button onClick={onDeny} type={Button.Secondary}>
                        {OTP_DENY}
                    </Button>
                    <Button onClick={onAllow} type={Button.Primary} autoFocus>
                        {OTP_ALLOW}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AccountTakeoverModal;
