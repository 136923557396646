import React, { forwardRef, useEffect, useRef } from 'react';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { useAppDispatch } from '../../../../store/store-hooks';
import { setModal } from '../../../../store/modal/modal-store';
import { INVITE_ZOOM_CONTACT } from '../../../../resource';

interface Props extends OverlayInjectedProps {
    close(): void;
    ariaId: string;
}

export default forwardRef<HTMLDivElement, Props>(({ close, ariaId, style }, ref) => {
    const dispatch = useAppDispatch();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const option = [
        {
            title: INVITE_ZOOM_CONTACT,
            onClick: () => {
                close();
                dispatch(setModal({ name: 'inviteContactModal', data: { show: true } }));
            },
        },
    ];

    useEffect(() => {
        buttonRef.current?.focus();
    }, []);

    return (
        <div ref={ref} style={style} id={ariaId} className="contact-opertation__menu">
            {option.map(({ title, onClick }) => {
                return (
                    <button ref={buttonRef} key={title} className="contact-opertation__menu-item" onClick={onClick}>
                        {title}
                    </button>
                );
            })}
        </div>
    );
});
