import { I18nLoader } from './I18nLoader';

const i18n = new I18nLoader(window.langResource || {});

export function iText(defaultText, resourceKey, resourceParameter) {
    if (typeof i18n === 'undefined') {
        return defaultText || resourceKey;
    }
    const i18nText =
        typeof resourceParameter !== 'undefined' ? i18n.get(resourceKey, resourceParameter) : i18n.get(resourceKey);
    if (!!i18nText && i18nText !== resourceKey) {
        return i18nText;
    }
    return (resourceParameter ? i18n.print(defaultText, resourceParameter) : defaultText) || resourceKey;
}
