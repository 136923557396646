import { useCallback, useEffect } from 'react';
import type { AppDispatch } from '../../store';
import type { IUserInfo } from '../../store/common/common-store';
import { signoutWhenSeesionExpiredThunk } from '../../store/common/sign-out-thunk';
import { ping } from './ping';
import { userSessionLogger } from '../../logger/pwa-loggers';

interface IProps {
    dispatch: AppDispatch;
    userInfo: IUserInfo;
}

export const useObserveWebLoginSession = ({ dispatch, userInfo }: IProps) => {
    const userId = userInfo?.userId;
    const sessionDuration = userInfo?.sessionDuration;

    const sessionExpiredHandler = useCallback(() => {
        userSessionLogger.log('', ['Session_Expire', 'Ping']);
        dispatch(signoutWhenSeesionExpiredThunk());
    }, [userId, sessionDuration]);

    useEffect(() => {
        if (userId) {
            const props = {
                userId,
                sessionDuration,
                sessionExpiredHandler,
            };
            ping.start(props);
        }

        return () => {
            ping.stop();
        };
    }, [userId, sessionDuration, sessionExpiredHandler]);
};
