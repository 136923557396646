import React, { ReactElement } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store/store-hooks';
import ChatPostMeetingPopover from './ChatPostMeeetingPopover';
import './index.scss';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

interface IProps {
    children: React.ReactElement;
    tabsAmount: number;
}

export default function ChatPopover({ children, tabsAmount }: IProps) {
    const { postMeetingPopover } = useAppSelector((state) => {
        const { postMeetingPopover } = state.chat.uiState;
        return {
            postMeetingPopover,
        };
    }, shallowEqual);

    const show = Boolean(postMeetingPopover);

    let content: ReactElement = null;

    const renderOverlay = (injected: OverlayInjectedProps) => {
        if (Boolean(postMeetingPopover)) {
            content = <ChatPostMeetingPopover {...postMeetingPopover} positionUpdateDep={tabsAmount} {...injected} />;
        } else {
            content = <span></span>;
        }
        return content;
    };

    return (
        <OverlayTrigger show={show} placement="bottom" overlay={renderOverlay}>
            {children}
        </OverlayTrigger>
    );
}
