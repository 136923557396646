import { isUndefined } from 'lodash-es';
import Hook from './Hook';
import { IDispatchReturn, ITask } from './types';

interface IProps {
    name: string;
    abortOnError?: boolean;
}

export default class SeriesHook<Event> extends Hook<Event> {
    constructor(props: IProps) {
        super(props);
    }

    dispatchEvent(event: Event): Promise<IDispatchReturn> {
        let tasks = [];
        try {
            tasks = this.prepareDispatch();
        } catch (e) {
            return Promise.reject(e);
        }
        return this.callSeries(tasks, event);
    }

    protected async callSeries(tasks: Array<ITask<Event>>, event: Event): Promise<IDispatchReturn> {
        this.isRunning = true;

        const end = (err: unknown, value?: IDispatchReturn) => {
            this.isRunning = false;
            if (err) {
                return Promise.reject(err);
            } else {
                return Promise.resolve(value);
            }
        };

        let lastRetValue = undefined;
        const context = {};

        for (const task of tasks) {
            const { id, fn } = task;

            try {
                let ret = fn(event, { context, lastRetValue, id });

                this.maybeWarn(ret, task);

                if (ret instanceof Promise) {
                    ret = await ret;
                }

                if (!isUndefined(ret)) {
                    lastRetValue = ret;
                }
            } catch (e) {
                if (this.abortOnError === true) {
                    return end(e);
                } else {
                    console.error(e);
                }
            }
        }

        return end(null, { lastRetValue, context });
    }

    protected async callParallel(_tasks: Array<ITask<Event>>, _event: Event): Promise<IDispatchReturn> {
        throw Error('can not perform callParallel on SeriesHook');
    }
}
