export enum MeetingStatusToChat {
    Not_In_Meeting,
    In_Meeeting,
}

export enum EChatIncomingMessages {
    ROUTE_TO_CHAT = 'ROUTE_TO_CHAT',
    CHAT_APP_READY = 'CHAT_APP_READY',
    START_MEETING = 'START_MEETING',
    START_PHONE_CALL = 'START_PHONE_CALL',
    WEB_SESSION_EXPIRED = 'WEB_SESSION_EXPIRED',
    XMPP_SESSION_CONFLICT = 'XMPP_SESSION_CONFLICT',
    XMPP_SESSION_REVOKE_TOKEN = 'XMPP_SESSION_REVOKE_TOKEN',
    UNREAD_COUNT = 'UNREAD_COUNT',
    JOIN_MEETING = 'JOIN_MEETING',
    CHANNEL_MEETING_REQ = 'CHANNEL_MEETING_REQ',
    OPEN_SETTING = 'OPEN_SETTING',
    PMC_TIPS_CLOSE = 'PMC_TIPS_CLOSE',
}

export enum EChatOutgoingMessages {
    CAN_START_PHONE_CALL = 'CAN_START_PHONE_CALL',
    ROUTE_TO_CHAT_ITEM = 'ROUTE_TO_CHAT_ITEM',
    SEARCH_MOUNT_DOM = 'SEARCH_MOUNT_DOM',
    MEETING_STATUS = 'MEETING_STATUS',
    CHANNEL_MEETING_RES = 'CHANNEL_MEETING_RES',
    RENDER_SETTING = 'RENDER_SETTING',
    CHANNEL_MEETING_TIPS = 'CHANNEL_MEETING_TIPS',
    ROUTE_CHANGE = 'ROUTE_CHANGE',
    EXTERNAL_SIGNOUT = 'EXTERNAL_SIGNOUT',
}

export interface IChatInComingMessage {
    type: EChatIncomingMessages;
    payload: any;
}

export interface IChatOutgoingMessage {
    type: EChatOutgoingMessages;
    data?: any;
}

export interface IChatUIState {
    chatAvailablePopover: boolean;
    postMeetingPopover: { topic: string; channelId: string } | null;
}
export interface IChatState {
    blockedList: Array<string>;
    recentChat: Array<string>; // array of jid
    unreadCount: {
        total: number;
        shown: number;
    };
    uiState: IChatUIState;
}
