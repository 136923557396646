import React from 'react';
import { No_Upcoming_Meetings_Today_Text } from '../LanguageResource';
import { isTeslaMode } from '../../../utils';
import { AddCalendarInUpComingEmpty } from '../Meetings/CalendarIntegration/AddCalendar';
interface IProps {
    showAddCalendar: boolean;
}

export default ({ showAddCalendar }: IProps) => {
    const isTesla = isTeslaMode();
    return (
        <div className="upcoming__content-empty">
            {!isTesla && showAddCalendar ? <AddCalendarInUpComingEmpty /> : No_Upcoming_Meetings_Today_Text}
        </div>
    );
};
