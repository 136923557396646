import { AppDispatch } from '../../../store';
import { setUserSettingHeaderTabs, setHeaderTabsToStorage, setUserSettingTheme, ThemeKey } from './settings-store';

export const updateHeaderTabsPinned = (tabs: any[]) => (dispatch: AppDispatch) => {
    dispatch(setUserSettingHeaderTabs(tabs));
    setHeaderTabsToStorage(tabs);
};

export const setUserSettingThemeThunk = (theme: string) => (dispatch: AppDispatch) => {
    dispatch(setUserSettingTheme(theme));
    localStorage.setItem(ThemeKey, theme);
};
