import React, { KeyboardEventHandler, useCallback, useEffect, useRef } from 'react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import './MeetingHistory.scss';
import { A11Y_FOCUS_WALKER_POLICY, DOM_KEY_CODE } from '../../../utils/constants';
import classnames from 'classnames';
import FocusLock from 'react-focus-lock';
import { isTeslaMode, makeA11yListInfoInjector } from '../../../utils';
import { CLEAR_HISTORY_TEXT, meeting_History_Item_Text_F } from '../../../resource';

const HistoryItemHeight = isTeslaMode() ? 71 : 32;
const HistoryMaxDisplay = isTeslaMode() ? 7 : 8;

export type historyItem = {
    topic: string;
    meetingNo: number;
};

interface IMeetingHistory {
    historyList: Array<historyItem>;
    onClickHistoryItem: (item: historyItem, index: number) => void;
    selectedMeetingIdx: number;
    clearHistory: () => void;
    hideMe: () => void;
}

const MeetingHistory = ({
    clearHistory,
    historyList,
    selectedMeetingIdx,
    onClickHistoryItem,
    hideMe,
}: IMeetingHistory) => {
    const listRef = useRef(null);
    const listItemRef = useRef<HTMLDivElement>(null);

    const onClickOutside = useCallback(
        (flag: boolean) => {
            if (flag) hideMe();
        },
        [hideMe],
    );

    const historyRef = useClickOutside({ callback: onClickOutside });

    const onKeyDownClear: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.keyCode === DOM_KEY_CODE.ENTER) {
            clearHistory();
        } else if (e.keyCode === DOM_KEY_CODE.ESC) {
            hideMe();
        }
    };

    const { dataALGenerator } = makeA11yListInfoInjector('0', listRef);

    const handleESCPressed: KeyboardEventHandler = (e) => {
        if (e.key === 'Escape') {
            hideMe && hideMe();
            e.stopPropagation();
        }
    };

    const historyListContainerStyle = {
        height: (historyList.length + 1) * HistoryItemHeight,
    };

    if (historyList.length > HistoryMaxDisplay) {
        historyListContainerStyle.height = HistoryMaxDisplay * HistoryItemHeight;
    }

    const insertBlackSpace = (meetingNo: number) => {
        const result = meetingNo.toString().split('');
        result.splice(3, 0, ' ');
        result.splice(-4, 0, ' ');
        return result.join('');
    };

    useEffect(() => {
        listItemRef.current?.focus();
    }, []);

    return (
        <FocusLock disabled>
            <div className="meeting-history" onKeyDown={handleESCPressed} ref={historyRef}>
                <div
                    className="meeting-history-list"
                    style={historyListContainerStyle}
                    ref={listRef}
                    data-a-l={'0'}
                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_POLICY}
                >
                    {historyList.map((historyItem, index) => {
                        return (
                            <div
                                onClick={() => {
                                    onClickHistoryItem(historyItem, index);
                                }}
                                className={classnames('meeting-history-item')}
                                tabIndex={0}
                                ref={selectedMeetingIdx === index ? listItemRef : null}
                                aria-label={meeting_History_Item_Text_F(historyItem.topic, historyItem.meetingNo)}
                                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                data-a-l={dataALGenerator()}
                                aria-selected={selectedMeetingIdx === index}
                                style={{ height: HistoryItemHeight }}
                                role="option"
                                key={index}
                            >
                                <div className="meeting-history-item__content">
                                    <span className="meeting-history-item__topic">{historyItem.topic}</span>
                                    <span className="meeting-history-item__number">
                                        {insertBlackSpace(historyItem.meetingNo)}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                    {historyList.length > 0 && (
                        <div
                            onClick={clearHistory}
                            onKeyDown={onKeyDownClear}
                            className="meeting-history-item"
                            tabIndex={0}
                            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                            data-a-l={dataALGenerator()}
                            key={historyList.length}
                            style={{ height: HistoryItemHeight }}
                            role="button"
                            aria-label={CLEAR_HISTORY_TEXT}
                        >
                            <div className="meeting-history-item__content meeting-history-clear">
                                <span className="meeting-history-item__topic">{CLEAR_HISTORY_TEXT}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </FocusLock>
    );
};

export default MeetingHistory;
