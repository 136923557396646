import { ALL_CONTACT_TEXT, MY_CONTACT_TEXT, SHARED_CONTACT_LISTS_TEXT } from '../../../resource';
import { IUCSContact, IUCSGroup } from '../services/ucs-types';
import {
    GroupFrom,
    GROUP_ID,
    IContact,
    IGroup,
    ILABLE_GROUP_ITEM,
    IXmppGroup,
    IXmppVcard,
    LABLE_GROUP,
} from '../types';
import { createPendingContactJid, isCameraControlGroup } from '../utils/stringUtils';
import serviceContainer from '../../../services/container';
import { isEmpty, intersection } from 'lodash-es';

export const getContactSortKey = (contact: IContact) => {
    const { displayName, firstName, lastName, email } = contact;
    return `${displayName} ${firstName} ${lastName} ${email}`;
};

export const createEmptyGroup = (): IGroup => {
    return {
        groupFrom: GroupFrom.unset,
        id: '',
        name: '',
        type: -1,
        version: '',
        total: 0,
        status: -1,
        option: -1,
        startVersion: '',
        nextCursor: 0,
    };
};

export const createEmptyContact = (): IContact => {
    return {
        jid: '',
        displayName: '',
        firstName: '',
        lastName: '',
        picUrl: '',
        email: '',
        signature: {
            text: '',
        },
        orgUnit: null,
        type: -1,
        cmd: '',
        intro: '',
        userType: '',

        phone: null,
        pbx: null,
        phoneNumbers: null,
        cellPhoneNumber: '',
        sipPhone: null,
        countryCode: null,

        isSameAccount: -1,
        contactType: -1,
        ibOptions: -1,
        isMasterOrSub: -1,
        bindWithMobile: -1,

        terminate: -1,
        inactive: -1,
        hidden: -1,
        blocked: -1,

        action: '',

        companyNumber: '',
        directNumber: [],
        extension: '',
        ext: {
            adr: '',
            audit: false,
            audit_t: '',
            cmpy: '',
            dept: '',
            job: '',
            manager: '',
            platform: '',
            pronoun: '',
            wkcode: '',
            wkphone: '',
        },
        wkphones: [],
    };
};

// name: gName##gType##gId, gName may contains "##";
const extractGroupNameComponent = (name: string) => {
    const reg = /^(.+)##(.+)##(.+)$/;
    const [_, gName, gType, gId] = name.match(reg);
    return [gName, gType, gId];
};

export const createGroupFromXmppGroup = (group: IXmppGroup) => {
    const result: Partial<IGroup> = {};
    const { id, name, total, type, option, version } = group;

    result.groupFrom = GroupFrom.xmpp;

    if (id) {
        result.id = id;
    }
    if (name) {
        result.name = name;
    }

    if (total !== undefined) {
        result.total = total;
    }

    if (!Number.isNaN(Number.parseInt(type))) {
        result.type = Number.parseInt(type);
    }

    if (!Number.isNaN(Number.parseInt(option))) {
        result.option = Number.parseInt(option);
    }

    if (version) {
        result.version = version;
    }

    return result;
};

export const createGroupFromUcsGroup = (ucsGroup: IUCSGroup) => {
    const { memberCount, name, option, startVersion, status, type, version } = ucsGroup;
    const [gName, gType, gId] = extractGroupNameComponent(name);

    // Unknown: No permission to access the group Or group has been deleted
    if (gName === 'Unknown' || String(gType) === '-1') {
        return null; // maybe invalid
    }

    const group: Partial<IGroup> = {};

    group.name = gName;
    group.id = gId;
    group.version = version;
    group.groupFrom = GroupFrom.ucs;

    if (memberCount !== undefined) {
        group.total = memberCount;
    } else {
        group.total = 0;
    }

    if (type !== undefined) {
        group.type = type;
    }

    if (status !== undefined) {
        group.status = status;
    }
    if (option !== undefined) {
        group.option = option;
    }
    if (startVersion !== undefined) {
        group.startVersion = startVersion;
    }

    return group;
};

export const createContactFromXmppContact = (contact: IXmppVcard) => {
    const result: Partial<IContact> = {};
    const {
        jid,
        firstName,
        lastName,
        displayName,
        email,
        picUrl,
        signature,
        intro,
        userType,
        ext,
        pbx,
        phone,
        wkphones,
        orgUnit,
    } = contact;

    if (!jid) {
        return result;
    } else {
        result.jid = jid;
    }

    if (firstName) {
        result.firstName = contact.firstName;
    }

    if (lastName) {
        result.lastName = contact.lastName;
    }

    if (displayName) {
        result.displayName = displayName;
    }

    if (email) {
        result.email = email;
    }

    if (picUrl) {
        result.picUrl = picUrl;
    }

    if (signature) {
        result.signature = signature;
    }

    if (intro) {
        result.intro = intro;
    }

    if (userType !== undefined) {
        result.userType = userType;
    }

    if (ext) {
        result.ext = ext;
    }

    if (pbx) {
        result.companyNumber = pbx.cn;
        result.directNumber = pbx.dn;
        result.extension = pbx.ext;
    }

    if (phone) {
        result.phone = phone;
    }

    if (wkphones) {
        result.wkphones = wkphones;
    }

    if (orgUnit && Array.isArray(orgUnit)) {
        result.orgUnit = orgUnit;
        result.isExternal = isExternal(orgUnit);
    }

    return result;
};

export const createContactFromUcsContact = (ucsContact: IUCSContact) => {
    const contact: any = {};
    contact.jid = ucsContact.jid;
    const { name, fname, lname, email, type, pbx, pic_url, signature, cmd } = ucsContact;
    if (name) {
        contact.displayName = ucsContact.name;
    }
    if (fname) {
        contact.firstName = fname;
    }
    if (lname) {
        contact.lastName = lname;
    }
    if (email) {
        contact.email = email;
    }
    if (type !== undefined) {
        contact.type = type;
    }

    // zoom phone
    if (pbx) {
        try {
            contact.pbx = JSON.parse(pbx);
        } catch (_e) {}
    }

    // addon related
    if (pic_url) {
        contact.picUrl = pic_url;
    }
    if (signature) {
        contact.signature = signature;
    }
    if (cmd) {
        contact.cmd = cmd;
    }
    return contact;
};

export function getContactDisplayName(contact: IContact) {
    const { displayName, firstName, lastName, email } = contact;
    if (displayName) {
        return displayName;
    }

    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if (firstName || lastName) {
        return firstName || lastName;
    }

    if (email) {
        return email;
    }

    return '';
}

interface IPropGroup {
    id: string;
    type: number | string;
    name?: string;
    version?: string | number;
}

export function ifCanShowChatBtn(groupId: string) {
    const isZoomRoom = isGroupZoomRoom(groupId);
    const isSharedOffice = isGroupSharedOffice(groupId);
    return window.chatInitConfig.isChatEnabled && !isZoomRoom && !isSharedOffice;
}

export function isGroupZoomRoom(group: IPropGroup | string) {
    if (typeof group === 'string') {
        return group.startsWith('zm.');
    }

    return group.id.startsWith('zm.') || String(group.type) === '60';
}

export function getPhoneNumberOfThisContact(contact: IContact) {
    const { extension, directNumber, wkphones } = contact;
    if (extension) {
        return extension;
    }

    if (Array.isArray(directNumber) && directNumber.length > 0) {
        return directNumber[0];
    }

    if (Array.isArray(wkphones)) {
        const orders = ['Office', 'Home', 'Mobile'];

        for (let i = 0; i < orders.length; i++) {
            const phone = wkphones.find((_) => _.label === orders[i]);
            if (phone) {
                return `${phone.code}${phone.text}`;
            }
        }
    }

    return '';
}

export function isGroupAssginable(group: IPropGroup) {
    return group.id.startsWith('as.');
}

export function isGroupAddon(group: IPropGroup | string) {
    let gid = '';
    if (typeof group === 'string') {
        gid = group;
    } else {
        gid = group.id;
    }
    return gid.startsWith('addon.');
}

// shared office
export function isGroupSharedOffice(group: IPropGroup | string) {
    if (typeof group === 'string') {
        return group.startsWith('so.');
    }
    // FIXME: group.type ?
    return group.id.startsWith('so.');
}

export function isGroupPersonalContact(group: IPropGroup | string) {
    let gid = '';
    if (typeof group === 'string') {
        gid = group;
    } else {
        gid = group.id;
    }
    return gid.startsWith('pc.');
}

export function isGroupCoworker(group: Pick<IPropGroup, 'name'>) {
    return group?.name === 'Coworkers';
}

export function isXmppGroupCoworkers(group: IPropGroup) {
    return String(group.type) === '1';
}

export function isGroupFromUcs(group: IGroup) {
    return group.groupFrom === GroupFrom.ucs;
}

export function isGroupFromXmpp(group: IGroup) {
    return group.groupFrom === GroupFrom.xmpp;
}

export function putGroupIntoThisLabel(group: IGroup): LABLE_GROUP {
    // my
    if (isGroupFromXmpp(group) && !isGroupZoomRoom(group)) {
        return LABLE_GROUP.MY;
    }

    if (isGroupPersonalContact(group)) {
        return LABLE_GROUP.MY;
    }

    if (isCameraControlGroup(group.id)) {
        return LABLE_GROUP.MY;
    }

    // shared
    if (isGroupFromUcs(group) && isGroupAssginable(group)) {
        return LABLE_GROUP.SHARED;
    }

    // all
    return LABLE_GROUP.ALL;
}

export const getLabelText = (labelId: LABLE_GROUP) => {
    switch (labelId) {
        case LABLE_GROUP.MY:
            return MY_CONTACT_TEXT;
        case LABLE_GROUP.SHARED:
            return SHARED_CONTACT_LISTS_TEXT;
        case LABLE_GROUP.ALL:
            return ALL_CONTACT_TEXT;
        default:
            return '';
    }
};

export const sortLabelGroups = (
    labelId: LABLE_GROUP,
    groupIds: Array<ILABLE_GROUP_ITEM>,
    groups: Record<GROUP_ID, IGroup>,
): null | Array<ILABLE_GROUP_ITEM> => {
    groupIds.sort((left, right) => {
        const { id: leftId } = left;
        const { id: rightId } = right;
        const leftGroup = groups[leftId];
        const rightGroup = groups[rightId];
        return leftGroup.name.localeCompare(rightGroup.name);
    });

    if (labelId === LABLE_GROUP.MY) {
        return null;
    }

    if (labelId === LABLE_GROUP.SHARED) {
        return null;
    }

    if (labelId === LABLE_GROUP.ALL) {
        // 1. zoom room
        // 2. company contacts
        // others
        const zoomRooms: Array<ILABLE_GROUP_ITEM> = [];
        const coworkers: Array<ILABLE_GROUP_ITEM> = [];
        const others: Array<ILABLE_GROUP_ITEM> = [];
        groupIds.forEach((g) => {
            const { id } = g;
            const group = groups[id];
            if (isGroupZoomRoom(group)) {
                zoomRooms.push(g);
                return;
            }
            if (isGroupCoworker(group)) {
                coworkers.push(g);
                return;
            }
            others.push(g);
        });
        return [].concat(zoomRooms, coworkers, others);
    }

    return null;
};

export const createPendingContactFromDB = (data: Array<string>) => {
    const result = data.map((email: string) => {
        return {
            jid: createPendingContactJid(email),
            email: email,
        };
    });

    return result as any;
};

export const isExternal = (orgUnit: Array<string>) => {
    const reduxStore = serviceContainer.getReduxStore();
    const state = reduxStore.getState();
    const { jid, accountId } = state.common?.userInfo || {};

    if (!jid || !accountId || isEmpty(orgUnit)) return false;

    const currentUser = state.contacts.contacts[jid];

    const myOrgs = currentUser?.orgUnit ? currentUser.orgUnit : [accountId];

    return isEmpty(intersection(orgUnit, myOrgs));
};
