import { useEffect, useState } from 'react';
import useLatest from './useLatest';

interface INetworkState {
    onLine?: () => void;
    onOffLine?: () => void;
}

export default function useNetworkState({ onLine, onOffLine }: INetworkState): boolean {
    const [state, setState] = useState(navigator.onLine);

    const _onLine = useLatest(onLine);
    const _onOffLine = useLatest(onOffLine);

    const line = () => {
        _onLine.current && _onLine.current();
        setState(true);
    };

    const offLine = () => {
        _onOffLine.current && _onOffLine.current();
        setState(false);
    };

    useEffect(() => {
        window.addEventListener('online', line);
        window.addEventListener('offline', offLine);

        return () => {
            window.removeEventListener('online', line);
            window.removeEventListener('offline', offLine);
        };
    }, []);

    return state;
}
