import React, { Suspense } from 'react';
import { getContactCenterModule } from '../../store/appModules/contact-center';

const EmptyComponent = (): React.FC => {
    return null;
};

const ContactCenter = React.lazy(() => {
    return getContactCenterModule()
        .then((_) => {
            return _;
        })
        .catch(() => ({ default: EmptyComponent as any }));
});

export default () => {
    return (
        <Suspense fallback={null}>
            <ContactCenter />
        </Suspense>
    );
};
