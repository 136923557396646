/**
 * refer to https://dg01docs.zoom.us/doc/4GDC35EJRYC5eXodDMTb5w
 * now is (Jun 19 2024)
 * we get policy from xmpp. (not from zpns)
 * if we set/del policy, we do them both from xmpp and zpns. (the same as native client).
 */

export enum PolicyName {
    dnd = 'dnd',
    snooze = 'snooze',
    busy = 'busy',
    ooo = 'ooo', // out of office
}

export type DnDPolicy = {
    from: string; // '1:30' // utc time
    to: string; // '2:30' // utc time
};

export type DndSnoozePolicy = {
    from: number; // second
    to: number; // second
    duration: number; // minutes
};

// out of office
export type OOOPolicy = {
    /**
     * native client set this to (Jan 1 2050), and it's be cleared by manually set to other presence
     */
    duration: number; // minutes
};

export type BusyPolicy = {
    /**
     * native client set this to 24 hours by default.
     */
    duration: number; // minutes
};

export type PresencePolicy =
    | {
          name: PolicyName.dnd;
          value: DnDPolicy;
      }
    | {
          name: PolicyName.snooze;
          value: DndSnoozePolicy;
      }
    | {
          name: PolicyName.ooo;
          value: OOOPolicy;
      }
    | {
          name: PolicyName.busy;
          value: BusyPolicy;
      };
