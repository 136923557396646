import { IconMegaphone, IconClose } from '@zoom/icons-react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
    CLOUD_RECORDING_EXCEED_LIMIT_TEXT_ADMIN,
    VIEW_DETAILS_TEXT,
    CLOUD_RECORDING_EXCEED_LIMIT_TEXT,
} from '../../resource';
import { useAppSelector } from '../../store';
import easyStore from '../../utils/storage';
import './index.scss';

const CloudRecordingHint = () => {
    const cmrStorage = useAppSelector((state) => state.common.userInfo?.cmrStorage);
    const [_shouldRerender, set_shouldRerender] = useState(false);

    if (easyStore.localStore.easyGet('hasCmrClosedByhand')) {
        if (
            !cmrStorage ||
            cmrStorage.latestOverUsedDate === easyStore.localStore.easyGet('cmrStorage.latestOverUsedDate')
        ) {
            return null;
        }
    } else if (!cmrStorage?.overUsed) {
        return null;
    }

    const hasCmrEdit = cmrStorage.hasCmrEdit;

    const warningText = hasCmrEdit ? CLOUD_RECORDING_EXCEED_LIMIT_TEXT_ADMIN : CLOUD_RECORDING_EXCEED_LIMIT_TEXT;

    const handleCloseCmrHint = () => {
        easyStore.localStore.easySet('hasCmrClosedByhand', true);
        easyStore.localStore.easySet('cmrStorage.latestOverUsedDate', cmrStorage.latestOverUsedDate);
        set_shouldRerender(true);
    };

    return (
        <div className="cloud-record__hint">
            <span className="cloud-record__hint--left">
                <IconMegaphone color="#6E7680" size="20px" style={{ marginRight: '10px' }} />
                <span tabIndex={0} aria-label={warningText} style={{ marginRight: '8px' }}>
                    {warningText}
                </span>
                {hasCmrEdit && (
                    <a
                        href={cmrStorage.detailLink}
                        style={{
                            fontSize: '14px',
                            color: '#0956B5',
                            whiteSpace: 'nowrap',
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {VIEW_DETAILS_TEXT}
                    </a>
                )}
            </span>
            <Button style={{ fontSize: 0, padding: 0 }} variant="link" size="sm" onClick={handleCloseCmrHint}>
                <IconClose
                    style={{
                        marginRight: '13px',
                        cursor: 'pointer',
                    }}
                    size="14px"
                    color="#131619"
                />
            </Button>
        </div>
    );
};

export default CloudRecordingHint;
