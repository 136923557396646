import { useEffect, useState } from 'react';
import { checkIsUrl2Meeting } from '../routes';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../store';
import { PWAMeetingEvent } from '../features/Meeting/meeting-agent-msg-type';

const useCanLoadPWAComp = () => {
    const meetingInfo = useAppSelector((state) => state.meeting.meetingInfo);

    /**
     * if the first url is not a meeting url, then `canLoadPWAComp` should be true, then we can load the pwa portal
     */
    const [canLoadPWAComp, setCanLoadPWAComp] = useState(!checkIsUrl2Meeting());
    const location = useLocation<Record<string, any>>();
    useEffect(() => {
        /**
         * if the first url is a meeting url, then `canLoadPWAComp` should be false, then we can only load the pwa portal after the meeting succeed
         */
        if (!canLoadPWAComp) {
            setCanLoadPWAComp(PWAMeetingEvent.SUCCESS === meetingInfo?.meetingStatus);
        }
    }, [meetingInfo?.meetingStatus]);

    useEffect(() => {
        // if location changed, as long as we have been through the non-meeting url, the `canLoadPWAComp` should be true,
        if (!checkIsUrl2Meeting()) {
            setCanLoadPWAComp(true);
        }
    }, [location]);

    return canLoadPWAComp;
};

export default useCanLoadPWAComp;
