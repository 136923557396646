/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { DOM_KEY_CODE } from '../utils/constants';
import React, { Component } from 'react';
import { A11Y_FOCUS_WALKER_POLICY } from '../utils/constants';
import { allDOMNodesType } from '../utils/keyboard-event';

const performClick = (_keyCode: DOM_KEY_CODE, rootJDom: any, targetJDom: any, backupTargetJDom: any) => {
    if (targetJDom) {
        targetJDom.click();
    } else if (!targetJDom && backupTargetJDom) {
        backupTargetJDom.click();
    } else {
        const target = rootJDom?.querySelectorAll('[data-a-l]')[0] as HTMLElement;
        target.focus();
        target.click();
    }
};

const tryRestoreSelectedTab = (keyCode: DOM_KEY_CODE, rootJDom: HTMLElement, targetDom: HTMLElement) => {
    if (keyCode === DOM_KEY_CODE.TAB && targetDom.getAttribute('role') === 'tab') {
        (rootJDom.querySelectorAll('[aria-selected=true]')[0] as HTMLElement).focus();
        return false;
    }
    return true;
};

const focusFallbackNode = (fallbackDomSelectors: Array<string>) => {
    if (fallbackDomSelectors) {
        fallbackDomSelectors.every((s) => {
            const jDom = document.querySelector(s) as HTMLElement;
            if (jDom) {
                jDom.focus();
                return false;
            }
            return true;
        });
    }
};

function withFocusWalker<T extends Record<string, unknown>>(
    // fix: https://stackoverflow.com/questions/31815633/what-does-the-error-jsx-element-type-does-not-have-any-construct-or-call
    Wrappee: React.ComponentType<T>,
    rootId: string,
    openPropSubscriber: any,
    fallbackDomSelectors: Array<string>,
    keyboardEventHandler: (
        walkerPolicy: A11Y_FOCUS_WALKER_POLICY,
        performFocus: (
            keyCode: DOM_KEY_CODE,
            rootJDom: Node | null,
            targetJDom: Node | null,
            backupTargetJDom?: Node | null,
        ) => void,
        performClick: (
            keyCode: DOM_KEY_CODE,
            rootJDom: Node | null,
            targetJDom: Node | null,
            backupTargetJDom?: Node | null,
        ) => void,
        e: KeyboardEvent,
        allDomNodes: allDOMNodesType,
    ) => boolean | undefined,
) {
    const performFocus = (keyCode: DOM_KEY_CODE, rootJDom: any, targetJDom: any, backupTargetJDom: any) => {
        if (targetJDom) {
            if (tryRestoreSelectedTab(keyCode, rootJDom, targetJDom)) {
                targetJDom.focus();
            }
        } else if (!targetJDom && backupTargetJDom) {
            if (tryRestoreSelectedTab(keyCode, rootJDom, backupTargetJDom)) {
                backupTargetJDom.focus();
            }
        } else {
            focusFallbackNode(fallbackDomSelectors);
        }
    };
    if (!keyboardEventHandler) {
        throw new Error('Required parameter keyboardEventHandler');
    }

    type IWrapperComponentProps = T & { forwardedRef: React.ForwardedRef<any> };
    class WrapperComponent extends Component<IWrapperComponentProps> {
        eventBind: boolean;

        constructor(props: any) {
            super(props);
            this.eventBind = false;
        }

        getWalkJDoms = (currentDom: HTMLElement) => {
            const rootJDom = document.getElementById(rootId);

            // let currentJDom;
            let currentLevelFirstKey = null;
            let currentLevelFirstJDom = null;
            let nextKey = null;
            let nextJDom = null;
            let prevKey = null;
            let prevJDom = null;
            let parentJDom = null;
            let parentNextKey = null;
            let parentNextJDom = null;
            let parentNextFirstChildKey = null;
            let parentNextFirstChildJDom = null;
            let parentPrevKey = null;
            let parentPrevJDom = null;
            let firstChildKey = null;
            let firstChildJDom = null;
            let firstChildFirstChildKey = null;
            let firstChildFirstChildJDom = null;
            let parentParentKey = null;
            let parentParentJDom = null;
            let parentParentPrevKey = null;
            let parentParentPrevJDom = null;
            let parentParentNextKey = null;
            let parentParentNextJDom = null;
            let parentParentNextFirstChildKey = null;
            let parentParentNextFirstChildJDom = null;
            let firstListNodeKey = null;
            let firstListNodeJDom = null;
            let prevFirstListNodeKey = null;
            let prevFirstListNodeJDom = null;
            let prevListLastNodeKey = null;
            let prevListLastNodeJDom = null;
            let parentListLastNodeKey = null;
            let parentListLastNodeJDom = null;
            let parentListFirstNodeKey = null;
            let parentListFirstNodeJDom = null;
            let lastListNodeKey = null;
            let lastListNodeJDom = null;
            let nextLastListNodeKey = null;
            let nextLastListNodeJDom = null;
            let parentLevelFirstChildKey = null;
            let parentLevelFirstChildJDom = null;
            let parentParentLevelFirstChildKey = null;
            let parentParentLevelFirstChildJDom = null;
            let parentPrevSamePositionChildKey = null;
            let parentPrevSamePositionChildJDom = null;
            let parentNextSamePositionChildKey = null;
            let parentNextSamePositionChildJDom = null;

            const currentKey = currentDom.getAttribute('data-a-l');

            const prevTempArr = currentKey!.split('-').map(Number);
            prevTempArr[prevTempArr.length - 1] = +prevTempArr[prevTempArr.length - 1] - 1;
            prevKey = prevTempArr.join('-');
            prevJDom = rootJDom!.querySelector(`[data-a-l="${prevKey}"]`);

            const nextTempArr = currentKey!.split('-').map(Number);
            nextTempArr[nextTempArr.length - 1] = +nextTempArr[nextTempArr.length - 1] + 1;
            nextKey = nextTempArr.join('-');
            nextJDom = rootJDom!.querySelector(`[data-a-l="${nextKey}"]`);

            const parentKey = currentKey!.substring(0, currentKey!.lastIndexOf('-'));
            parentJDom = rootJDom!.querySelector(`[data-a-l="${parentKey}"]`);

            const currentLevelFirstTempArr = currentKey!.split('-').map(Number);
            currentLevelFirstTempArr[currentLevelFirstTempArr.length - 1] = 0;
            currentLevelFirstKey = currentLevelFirstTempArr.join('-');
            currentLevelFirstJDom = rootJDom!.querySelector(`[data-a-l="${currentLevelFirstKey}"]`);

            // same level node searching pending

            const parentPrevTempArr = parentKey.split('-').map(Number);
            parentPrevTempArr[parentPrevTempArr.length - 1] = +parentPrevTempArr[parentPrevTempArr.length - 1] - 1;
            parentPrevKey = parentPrevTempArr.join('-');
            parentPrevJDom = rootJDom!.querySelector(`[data-a-l="${parentPrevKey}"]`);

            const parentNextTempArr = parentKey.split('-').map(Number);
            parentNextTempArr[parentNextTempArr.length - 1] = +parentNextTempArr[parentNextTempArr.length - 1] + 1;
            parentNextKey = parentNextTempArr.join('-');
            parentNextJDom = rootJDom!.querySelector(`[data-a-l="${parentNextKey}"]`);
            parentNextTempArr[parentNextTempArr.length - 1] = +parentNextTempArr[parentNextTempArr.length - 1] + 1;

            parentNextFirstChildKey = `${parentNextKey}-0`;
            parentNextFirstChildJDom = rootJDom!.querySelector(`[data-a-l="${parentNextFirstChildKey}"]`);

            const getParentNextNthJDom = (x: number) => {
                const tempArr = parentKey.split('-').map(Number);
                tempArr[tempArr.length - 1] = +tempArr[tempArr.length - 1] + x;

                return rootJDom!.querySelector(`[data-a-l="${tempArr.join('-')}"]`);
            };

            firstChildKey = `[data-a-l="${currentKey}-0"]`;
            firstChildJDom = currentDom.querySelector(firstChildKey);

            firstChildFirstChildKey = `[data-a-l="${currentKey}-0-0"]`;
            firstChildFirstChildJDom = currentDom.querySelector(firstChildFirstChildKey);

            parentParentKey = parentKey.substring(0, parentKey.lastIndexOf('-'));
            parentParentJDom = parentParentKey ? document.querySelector(`[data-a-l="${parentParentKey}"]`) : null;

            parentLevelFirstChildKey = `${parentParentKey}-0`;
            parentLevelFirstChildJDom = rootJDom!.querySelector(`[data-a-l="${parentLevelFirstChildKey}"]`);

            parentParentLevelFirstChildKey = `${parentParentKey.substring(0, parentParentKey.lastIndexOf('-'))}-0`;
            parentParentLevelFirstChildJDom = rootJDom!.querySelector(`[data-a-l="${parentParentLevelFirstChildKey}"]`);

            const parentParentPrevTempArr = parentParentKey.split('-').map(Number);
            parentParentPrevTempArr[parentParentPrevTempArr.length - 1] =
                +parentParentPrevTempArr[parentParentPrevTempArr.length - 1] - 1;
            parentParentPrevKey = parentParentPrevTempArr.join('-');
            parentParentPrevJDom = rootJDom!.querySelector(`[data-a-l="${parentParentPrevKey}"]`);

            const parentParentNextTempArr = parentParentKey.split('-').map(Number);
            parentParentNextTempArr[parentParentNextTempArr.length - 1] =
                +parentParentNextTempArr[parentParentNextTempArr.length - 1] + 1;
            parentParentNextKey = parentParentNextTempArr.join('-');
            parentParentNextJDom = rootJDom!.querySelector(`[data-a-l="${parentParentNextKey}"]`);

            parentParentNextFirstChildKey = `${parentParentNextKey}-0`;
            parentParentNextFirstChildJDom = rootJDom!.querySelector(`[data-a-l="${parentParentNextFirstChildKey}"]`);

            const firstLastListNodeNodeInfo = currentDom.getAttribute('data-a-l-list-info');
            if (firstLastListNodeNodeInfo) {
                [firstListNodeKey, lastListNodeKey] = firstLastListNodeNodeInfo.split(',').map((v) => v.trim());
                firstListNodeJDom = rootJDom!.querySelector(`[data-a-l="${firstListNodeKey}"]`);
                lastListNodeJDom = rootJDom!.querySelector(`[data-a-l="${lastListNodeKey}"]`);

                const prevFirstListNodeTempArr = firstListNodeKey.split('-').map(Number);
                prevFirstListNodeTempArr[prevFirstListNodeTempArr.length - 1] =
                    +prevFirstListNodeTempArr[prevFirstListNodeTempArr.length - 1] - 1;
                prevFirstListNodeKey = prevFirstListNodeTempArr.join('-');
                prevFirstListNodeJDom = rootJDom!.querySelector(`[data-a-l="${prevFirstListNodeKey}"]`);

                const nextLastListNodeTempArr = lastListNodeKey.split('-').map(Number);
                nextLastListNodeTempArr[nextLastListNodeTempArr.length - 1] =
                    +nextLastListNodeTempArr[nextLastListNodeTempArr.length - 1] + 1;
                nextLastListNodeKey = nextLastListNodeTempArr.join('-');
                nextLastListNodeJDom = rootJDom!.querySelector(`[data-a-l="${nextLastListNodeKey}"]`);
            }

            const prevDomListInfo = prevJDom?.getAttribute('data-a-l-list-info');
            if (prevDomListInfo) {
                prevListLastNodeKey = prevDomListInfo.split(',')[1];
                prevListLastNodeJDom = rootJDom!.querySelector(`[data-a-l="${prevListLastNodeKey}"]`);
            }

            const parentDomListInfo = parentJDom?.getAttribute('data-a-l-list-info');
            if (parentDomListInfo) {
                [parentListFirstNodeKey, parentListLastNodeKey] = parentDomListInfo.split(',');
                parentListLastNodeJDom = rootJDom!.querySelector(`[data-a-l="${parentListLastNodeKey}"]`);

                parentListFirstNodeJDom = rootJDom!.querySelector(`[data-a-l="${parentListFirstNodeKey}"]`);
            }
            const parentSamePositionChildTempArr = currentKey!.split('-');
            if (parentKey && parentSamePositionChildTempArr.length >= 2) {
                const parentPrevSamePositionChildTempArr = [...parentSamePositionChildTempArr].map(Number);
                parentPrevSamePositionChildTempArr[parentPrevSamePositionChildTempArr.length - 2] =
                    +parentPrevSamePositionChildTempArr[parentPrevSamePositionChildTempArr.length - 2] - 1;
                parentPrevSamePositionChildKey = parentPrevSamePositionChildTempArr.join('-');
                parentPrevSamePositionChildJDom = rootJDom!.querySelector(
                    `[data-a-l="${parentPrevSamePositionChildKey}"]`,
                );

                const parentNextSamePositionChildTempArr = [...parentSamePositionChildTempArr].map(Number);
                parentNextSamePositionChildTempArr[parentNextSamePositionChildTempArr.length - 2] =
                    +parentNextSamePositionChildTempArr[parentNextSamePositionChildTempArr.length - 2] + 1;
                parentNextSamePositionChildKey = parentNextSamePositionChildTempArr.join('-');
                parentNextSamePositionChildJDom = rootJDom!.querySelector(
                    `[data-a-l="${parentNextSamePositionChildKey}"]`,
                );
            }
            const getParent = (dom: HTMLElement) => {
                if (!dom) return null;
                const currentKey = dom.getAttribute('data-a-l');
                const parentKey = currentKey?.substring(0, currentKey?.lastIndexOf('-'));
                const parentJDom = rootJDom!.querySelector(`[data-a-l="${parentKey}"]`);
                return parentJDom;
            };
            const getFirstChild = (dom: HTMLElement) => {
                if (!dom) return null;
                const domListInfo = dom?.getAttribute('data-a-l-list-info');
                if (domListInfo) {
                    const firstNodeKey = domListInfo.split(',')[0];
                    return rootJDom!.querySelector(`[data-a-l="${firstNodeKey}"]`);
                }
                return null;
            };
            const getNext = (dom: HTMLElement) => {
                if (!dom) return null;
                const currentKey = dom.getAttribute('data-a-l');

                const nextTempArr = currentKey!.split('-').map(Number);
                nextTempArr[nextTempArr.length - 1] = +nextTempArr[nextTempArr.length - 1] + 1;
                const nextKey = nextTempArr.join('-');
                return rootJDom!.querySelector(`[data-a-l="${nextKey}"]`);
            };
            return {
                rootJDom,
                currentKey,
                currentDom,
                currentLevelFirstKey,
                currentLevelFirstJDom,
                nextKey,
                nextJDom,
                prevKey,
                prevJDom,
                parentKey,
                parentJDom,
                parentNextKey,
                parentNextJDom,
                getParentNextNthJDom,
                parentPrevKey,
                parentPrevJDom,
                firstChildKey,
                firstChildJDom,
                firstChildFirstChildKey,
                firstChildFirstChildJDom,
                parentParentKey,
                parentParentJDom,
                parentParentPrevKey,
                parentParentPrevJDom,
                parentParentNextKey,
                parentParentNextJDom,
                firstListNodeKey,
                firstListNodeJDom,
                prevFirstListNodeKey,
                prevFirstListNodeJDom,
                lastListNodeKey,
                lastListNodeJDom,
                nextLastListNodeKey,
                nextLastListNodeJDom,
                parentLevelFirstChildKey,
                parentLevelFirstChildJDom,
                parentParentLevelFirstChildKey,
                parentParentLevelFirstChildJDom,
                prevListLastNodeJDom,
                parentListLastNodeKey,
                parentListLastNodeJDom,
                parentListFirstNodeKey,
                parentListFirstNodeJDom,
                parentNextSamePositionChildJDom,
                parentPrevSamePositionChildJDom,
                parentNextSamePositionChildKey,
                parentPrevSamePositionChildKey,
                parentNextFirstChildKey,
                parentNextFirstChildJDom,
                parentParentNextFirstChildKey,
                parentParentNextFirstChildJDom,
                getParent,
                getFirstChild,
                getNext,
            };
        };

        a11yKeyEventHandler = (e: KeyboardEvent) => {
            const { target: currentDom, keyCode } = e as { target: any; keyCode: DOM_KEY_CODE };
            const { TAB, UP, DOWN, LEFT, RIGHT, ENTER, SPACE } = DOM_KEY_CODE;

            if ([TAB, UP, DOWN, LEFT, RIGHT, ENTER, SPACE].every((whiteListKey) => whiteListKey !== keyCode)) {
                return true;
            }

            if (!currentDom?.getAttribute('data-a-l')) return true;

            const allDomNodes = this.getWalkJDoms(currentDom);
            const walkerPolicy = Number(currentDom.getAttribute('data-a-walk-policy'));
            const hasPerformed = keyboardEventHandler(walkerPolicy, performFocus, performClick, e, allDomNodes);
            // hasPerformed represent the focusWalker doing the perform successfully,
            // so it's necessary to prevent the Native focus behavior
            if (hasPerformed) {
                e.preventDefault();
                e.stopPropagation();
            }
            return false;
        };

        componentDidMount() {
            this.initEventListener();
        }

        initEventListener = () => {
            setTimeout(() => {
                const rootJDom = document.getElementById(rootId);
                if (!this.eventBind && rootJDom) {
                    this.eventBind = true;
                    rootJDom.addEventListener('keydown', this.a11yKeyEventHandler, false);
                }
            }, 0);
        };

        disposeEventListener = () => {
            const rootJDom = document.getElementById(rootId);
            if (rootJDom) {
                rootJDom.removeEventListener('keydown', this.a11yKeyEventHandler);
            }
            // rootJDom.unbind('focusout');
            this.eventBind = false;
        };

        componentDidUpdate(prevProps: any) {
            const previousOpenFlag = openPropSubscriber(prevProps);
            const currentOpenFlag = openPropSubscriber(this.props);
            if (previousOpenFlag !== currentOpenFlag && currentOpenFlag) {
                this.initEventListener();
            } else if (previousOpenFlag !== currentOpenFlag && !currentOpenFlag) {
                this.disposeEventListener();
            }
        }

        componentWillUnmount() {
            this.disposeEventListener();
        }

        render() {
            return <Wrappee ref={this.props.forwardedRef} {...this.props} />;
        }
    }

    return React.forwardRef<any, any>((props, ref) => {
        const wrapperProps: IWrapperComponentProps = Object.assign(
            {
                forwardedRef: ref,
            },
            props,
        );
        return <WrapperComponent {...wrapperProps} />;
    });
}

export { withFocusWalker };
