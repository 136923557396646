import React from 'react';
import classnames from 'classnames';
import './ContactList.scss';
import { BLOCKED_TEXT } from '../../../../resource';
import { GROUP_ID, IContact, JID } from '../../types';
import { getContactDisplayName, isGroupAddon, isGroupOrContactSelectedSelector } from '../../redux';
import { useIsUserBlockedByIM } from '../../../Chat';
import { getContactsInfoThunk } from '../../redux/contact-thunk';
import { useAppDispatch, useAppSelector } from '../../../../store';
import Avatar from '../../../../components/Avatar/Avatar';
import usePresence from '../../hooks/usePresence';
import { A11Y_POLICY } from '../../a11y';
import ContactActionButtons from './ContactActionButtons';
import { LEVEL_F, NOT_SELECTED, CONTACT_INDEX_OF_F } from '../../../../resource';
import { useIsExternalContact } from '../../hooks/useExternalContact';
import ExternalBadge from '../../../../components/ExternalBadge';

interface Props {
    style: React.CSSProperties;
    contact: IContact;
    setContact(jid: JID, groupId?: GROUP_ID): void;
    groupId?: GROUP_ID;
    a11yIndex?: string;
    a11yGroupIndex?: string;
    renderIndex?: number;
    contactInGroupIndex: number;
    groupTotal: number;
}

const Contact = ({
    style,
    contact,
    setContact,
    groupId = '',
    a11yIndex,
    a11yGroupIndex = '',
    renderIndex,
    contactInGroupIndex,
    groupTotal,
}: Props) => {
    const dispatch = useAppDispatch();
    const { jid, picUrl } = contact;

    const isSelected = useAppSelector((state) => isGroupOrContactSelectedSelector(state, jid, groupId));
    const isExternal = useIsExternalContact(jid);

    const isAddon = isGroupAddon(groupId);
    const displayName = getContactDisplayName(contact);

    const isIMBlocked = useIsUserBlockedByIM({ jid });
    const { PresenceBadge, presenceType } = usePresence({
        jid: jid,
        needQuery: false,
        isAddon,
    });

    const setCurrentContact = () => {
        setContact(contact.jid, groupId);

        const params = [
            {
                jid: contact.jid,
            },
        ];

        // query contact presence status and profile info
        dispatch(getContactsInfoThunk(params, true));
    };

    const getAriaLabelText = () => {
        const level = LEVEL_F(3);
        const selectText = isSelected ? '' : NOT_SELECTED;
        const positionText = CONTACT_INDEX_OF_F(contactInGroupIndex, groupTotal); // `${contactInGroupIndex} of ${groupTotal}`;

        return `${displayName}, ${level}, ${presenceType}, ${positionText}, ${selectText}`;
    };

    return (
        <div
            className="contacts-contact"
            style={style}
            onClick={setCurrentContact}
            role="treeitem"
            aria-label={getAriaLabelText()}
            aria-selected={isSelected}
            tabIndex={isSelected ? 0 : -1}
            data-a-render-index={renderIndex}
            data-a-own-group={a11yGroupIndex}
            data-a-l={a11yIndex}
            data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_ITEM_POLICY}
        >
            <div className={classnames('contact-container', { 'contact-selected': isSelected })}>
                <Avatar
                    extraClass="contact-avatar"
                    picUrl={picUrl}
                    jid={jid}
                    displayName={displayName}
                    bannerComponent={<PresenceBadge />}
                />

                <div className="contact-middle-content">
                    <span className="display-name">
                        {displayName} {isIMBlocked ? <span className="contact--blocked">({BLOCKED_TEXT})</span> : null}
                    </span>
                    <ExternalBadge isExternal={isExternal} />
                </div>
                <ContactActionButtons contact={contact} groupId={groupId} />
            </div>
        </div>
    );
};

export default Contact;
