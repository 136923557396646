import { history } from './history';
import { RoutePath } from './routePath';
import { matchPath } from 'react-router-dom';

export const matchChatRoute = () => {
    const location = history.location;

    return matchPath(location.pathname, {
        path: RoutePath.Chat,
        exact: true,
    });
};
