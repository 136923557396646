import React, { CSSProperties } from 'react';
import styles from './index.module.scss';
import cls from 'classnames';

import { IconLoadingSpikes } from '@zoom/icons-react';
import { isNumber } from 'lodash-es';

interface Props {
    className?: string;
    size?: number;
}

const LoadingSpike = (props: Props) => {
    const { className, size } = props;

    const clsNames = cls(styles.loading, {
        [className]: !!className,
    });

    const style: CSSProperties = {};

    if (isNumber(size)) {
        style.fontSize = `${size}px`;
    }

    return (
        <div className={clsNames} style={style}>
            <IconLoadingSpikes />
        </div>
    );
};

LoadingSpike.className = styles.loading;
export default LoadingSpike;
