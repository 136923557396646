import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

const MAX_UNREAD_COUNT = 99;

export default () => {
    const { total, shown } = useAppSelector((state) => state.chat.unreadCount, shallowEqual);

    if (total === 0) {
        return null;
    }

    if (shown > 0) {
        return <span className="header-icon__badge">{shown > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : shown}</span>;
    }

    if (shown === 0 && total > 0) {
        return <span className="header-icon__red-dot"></span>;
    }

    return null;
};
