import React from 'react';
import './general.scss';
import {
    AGAVE_THEME_TEXT,
    BLOOM_THEME_TEXT,
    CLASSIC_THEME_TEXT,
    GLOBAL_NAV_SETTING_TEXT,
    NAVIGATION_TEXT,
    RESET_TO_DEFAULT,
    ROSE_THEME_TEXT,
    SETTINGS_THEME_POPOVER_1,
    SETTINGS_THEME_POPOVER_2,
    SETTINGS_THEME_POPOVER_3,
    SETTINGS_THEME_TITLE,
    SETTING_THEME_TEXT,
} from '../../../resource';
import useTabList from '../../../hooks/useTabList';
import { ReactComponent as ClassicTheme } from '../../../assets/images/classic-theme.svg';
import { ReactComponent as BloomTheme } from '../../../assets/images/bloom-theme.svg';
import { ReactComponent as AgaveTheme } from '../../../assets/images/agave-theme.svg';
import { ReactComponent as RoseTheme } from '../../../assets/images/rose-theme.svg';
import { useAppDispatch, useAppSelector } from '../../Phone/types';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { IconQuestionCircle } from '@zoom/icons-react';
import { setUserSettingThemeThunk } from '../redux/setttings-thunk';
import { Button } from '@zoom/zoom-react-ui';

const General = () => {
    const { setData: setOrganizableTabList } = useTabList();

    const theme = useAppSelector((state) => state.settings.userSettingTheme);

    const dispatch = useAppDispatch();

    const handleOnClickReset = () => {
        setOrganizableTabList('reset');
    };

    const handleClickTheme = (theme: string) => {
        dispatch(setUserSettingThemeThunk(theme));
    };

    return (
        <>
            <div className="general-settings general-settings__theme">
                <div className="general-header general-header__theme">
                    <span className="theme-text">{SETTINGS_THEME_TITLE}</span>
                    <div className="theme-notice">
                        <span className="theme-notice-text">{SETTING_THEME_TEXT}</span>
                        <OverlayTrigger
                            placement="bottom"
                            trigger={['hover', 'focus']}
                            overlay={
                                <Popover>
                                    <Popover.Body style={{ fontSize: '12px' }}>
                                        {SETTINGS_THEME_POPOVER_1}
                                        <br></br>
                                        <br></br>
                                        {SETTINGS_THEME_POPOVER_2}
                                        <br></br>
                                        <br></br>
                                        {SETTINGS_THEME_POPOVER_3}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <IconQuestionCircle />
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="color-themes">
                    <div className="theme" onClick={() => handleClickTheme('classic')}>
                        <ClassicTheme className={classNames('theme-color', { selected: theme === 'classic' })} />
                        <span className="classic">{CLASSIC_THEME_TEXT}</span>
                    </div>
                    <div className="theme" onClick={() => handleClickTheme('bloom')}>
                        <BloomTheme className={classNames('theme-color', { selected: theme === 'bloom' })} />
                        <span className="bloom">{BLOOM_THEME_TEXT}</span>
                    </div>
                    <div className="theme" onClick={() => handleClickTheme('agave')}>
                        <AgaveTheme className={classNames('theme-color', { selected: theme === 'agave' })} />
                        <span className="agave">{AGAVE_THEME_TEXT}</span>
                    </div>
                    <div className="theme" onClick={() => handleClickTheme('rose')}>
                        <RoseTheme className={classNames('theme-color', { selected: theme === 'rose' })} />
                        <span className="rose">{ROSE_THEME_TEXT}</span>
                    </div>
                </div>
            </div>

            <div className="general-settings general-settings__navigation">
                <div className="general-header general-header__navigation">
                    <span className="navigation-text">{NAVIGATION_TEXT}</span>
                </div>
                <div className="navigation-instructions">
                    <span className="navigation-instructions-text">{GLOBAL_NAV_SETTING_TEXT}</span>
                    <Button className="reset-nav" onClick={handleOnClickReset}>
                        {RESET_TO_DEFAULT}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default General;
