import React, { useState } from 'react';
import { getPresenceTxt } from '../../../services/xmpp/utils';
import Avatar from '../../../components/Avatar/Avatar';
import { getProfilePath } from '../../../utils/meeting-url';
import InfoPanel from './InfoPanel';
import { DOM_KEY_CODE } from '../../../utils/constants';
import { setShowSettingsDialog } from '../../Settings/redux/settings-store';
import { useAppSelector, useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { isTeslaMode, openTab } from '../../../utils';
import usePresence from '../../Contacts/hooks/usePresence';
import HelpPanel from './HelpPanel';

export default function HeaderRight() {
    const state = useAppSelector((_) => _);
    const {
        common: { userInfo },
    } = state;

    const { presenceType, PresenceBadge } = usePresence({ jid: userInfo?.jid, byPassOpInternalPresenceCheck: true});

    const [infoPanelStatus, setInfoPanel] = useState(false);
    const dispatch = useAppDispatch();

    const isTesla = isTeslaMode();

    const onClickAvatar = (e?: any) => {
        e?.stopPropagation();
        setInfoPanel(!infoPanelStatus);
    };

    const hideMe = () => {
        setInfoPanel(false);
    };

    const openAboutModal = () => {
        dispatch(setModal({ name: 'about', data: {} }));
    };

    const openLangModal = () => {
        dispatch(setModal({ name: 'language', data: {} }));
    };

    const onProfile = () => {
        setInfoPanel(false);
        openTab(getProfilePath());
    };

    const onAbout = () => {
        openAboutModal();
        // setInfoPanel(false); // for a11y reason, let info panel close the panel
    };
    const onLang = () => {
        openLangModal();
        // setInfoPanel(false); // for a11y reason, let info panel close the panel
    };

    const onSettings = () => {
        dispatch(setShowSettingsDialog(true));
        setInfoPanel(false);
    };

    const onKeyDownAvatar = (e: React.KeyboardEvent) => {
        if (e.keyCode === DOM_KEY_CODE.ENTER || e.keyCode === DOM_KEY_CODE.SPACE) {
            setTimeout(() => {
                // fix problem, when Enter is received, [Profile] will receive a ClickEvent
                onClickAvatar();
            }, 0);
        } else if (e.keyCode === DOM_KEY_CODE.ESC) {
            setInfoPanel(false);
        }
    };

    const avaterStyle = isTesla ? { width: '60px', height: '60px' } : { width: '30px', height: '30px' };

    const showInfoPanel = infoPanelStatus && !!userInfo;

    return (
        <div className="home-header__right">
            <HelpPanel />
            <div className="home-header__avatar">
                <div
                    tabIndex={0}
                    role="button"
                    aria-haspopup={true}
                    aria-expanded={showInfoPanel}
                    className="header-avatar-container"
                    onClick={onClickAvatar}
                    onKeyDown={onKeyDownAvatar}
                    title={getPresenceTxt(presenceType)}
                >
                    <Avatar
                        tabIndex={-1}
                        picUrl={userInfo?.avatar}
                        jid={userInfo?.jid}
                        displayName={userInfo?.displayName}
                        bannerComponent={<PresenceBadge />}
                        style={avaterStyle}
                    />
                </div>
                {showInfoPanel ? (
                    <InfoPanel
                        userInfo={userInfo}
                        hideMe={hideMe}
                        isTeslaMode={isTesla}
                        onProfile={onProfile}
                        onAbout={onAbout}
                        infoPanelStatus={infoPanelStatus}
                        currentPresenceType={presenceType}
                        onLang={onLang}
                        onSettings={onSettings}
                    />
                ) : null}
            </div>
        </div>
    );
}
