import React from 'react';
import classnames from 'classnames';
import { WebimPresenceType } from '@zoom/pwa-webim';
import { getContactDisplayName } from '../redux';
import Avatar from '../../../components/Avatar/Avatar';
import { IContact } from '../types';
import { BLOCKED_TEXT } from '../../../resource';
import { PresenceIcon } from '../../../components/Presence';
import ExternalBadge from '../../../components/ExternalBadge';
import { isDisableInternalPresence } from '../../../store/common/common-utils';

interface IProps {
    contact: IContact;
    presenceType: WebimPresenceType;
    presenceText: string;
    isIMBlocked: boolean;
}

export default ({ contact, presenceType, presenceText, isIMBlocked }: IProps) => {
    const { picUrl, ext, signature, jid, isExternal } = contact;
    const displayName = getContactDisplayName(contact);
    const isDisableInternalPresenceOn = isDisableInternalPresence();
    const isMeeting = presenceType === WebimPresenceType.zoommeeting || presenceType === WebimPresenceType.presenting;

    return (
        <div className="contact-detail__profile">
            <div className="contact-detail-avatar-wrapper">
                <Avatar
                    picUrl={picUrl}
                    jid={jid}
                    displayName={displayName}
                    extraClass="contact-detail-left"
                    imgClass="contact-detail-avatar"
                />
                <ExternalBadge isExternal={isExternal} extraCls="contact-detail-avatar__badge" />
            </div>

            <div className="contact-detail-right" tabIndex={0}>
                {isIMBlocked ? (
                    <span className="contact--blocked">{BLOCKED_TEXT}</span>
                ) : isDisableInternalPresenceOn ? null : (
                    <div className="contact-deatil-status">
                        <PresenceIcon
                            presenceType={presenceType}
                            className={classnames({ 'contact-detail-meeting-presence': isMeeting })}
                        />
                        <span className={'contact-detail-presence-txt'}>{presenceText}</span>
                    </div>
                )}

                <div className="contact-detail-right-name">{displayName}</div>
                {ext?.pronoun && <div className="contact-detail-right-pronoun">{ext.pronoun}</div>}

                {signature && signature.text && <div className="contact-detail-right-notes">{signature.text}</div>}
            </div>
        </div>
    );
};
