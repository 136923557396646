import { useEffect } from 'react';
import { HIDControl } from '../features/Phone/HID';
import { RootState, useAppSelector } from '../store';
import { ICallSession } from '../features/Phone/redux/phone-types';
import { usePhoneModule } from '../asyncModulesStore';
import { shallowEqual } from 'react-redux';

/**
 * this hook is used to sync the phone/meeting call status with LED on/off on the HID devices.
 * when there is no call ongoing, the LED light will off, otherwise it will be on
 */
const useHIDHookSwitch = () => {
    const { showWebclient } = useAppSelector((state) => {
        const {
            meeting: { showWebclient },
        } = state;
        return {
            showWebclient,
        };
    }, shallowEqual);

    const emptySelector = (_state: RootState): Array<ICallSession> => [];
    const { connectedCallsessionListSelector, onHoldCallSessionListSelector } = usePhoneModule();
    const connectedCallsessionList: Array<ICallSession> = useAppSelector(
        connectedCallsessionListSelector || emptySelector,
    );
    const onHoldCallSessionList: Array<ICallSession> = useAppSelector(onHoldCallSessionListSelector || emptySelector);
    const connectedSession = connectedCallsessionList.length > 0 || onHoldCallSessionList.length > 0;

    const hasSessionOngoing = connectedSession || showWebclient;

    /**
     * setup hookswitch busy status
     */
    useEffect(() => {
        HIDControl.used = hasSessionOngoing;
        HIDControl.sendReport('hookswitch', hasSessionOngoing);
    }, [hasSessionOngoing]);
};

export default useHIDHookSwitch;
