import { Laplace } from '@zoom/laplace';
import { KeyUsage, LaplaceConfig } from '@zoom/laplace/build/interface';

import AutoCleanLogPlugin from '@zoom/laplace/build/plugins/auto-clean-log-plugin';
import PrintToConsolePlugin from '@zoom/laplace/build/plugins/print-to-console-plugin';
import { getSsid } from './utils';

export function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    // Time in microseconds since page-load or 0 if unsupported
    let d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16; // random number between 0 and 16
        if (d > 0) {
            // Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            // Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}

export const trackingId = generateUUID();
const MAXIMUM_MAX_SIZE = 1024 * 1024 * 47;
const DATABASE_NAME = 'laplace-pwa-portal-database';

let logConfigDefault = {
    enable: false,
    gatewayEndPoint: '',
    // gatewayEndPoint: 'https://devlog-gateway.zoomdev.us',
    logLevel: {
        debug: false,
        log: false,
        info: false,
        print: false,
        warn: false,
        error: false,
    },
    performanceReportRatio: 0.01,
    errorReportRatio: 0.01,
    trackingCount: 7,
};

try {
    logConfigDefault = JSON.parse(window.PwaConfig?.logConfig);
} catch (error) {
    console.error('parse log config error');
}

export const logConfig = logConfigDefault;
export const shouldReportPerformance = Math.random() < (logConfig?.performanceReportRatio ?? 0);
// const shouldAutoReportError = Math.random() < (logConfig?.performanceReportRatio ?? 0);
// const ignoredErrorMsgKeywords = [
//     'table index is out of bounds',
//     'ResizeObserver loop completed with undelivered notifications',
// ];

// same as web client
const makeReportUrl = () => {
    const { gatewayEndPoint } = logConfig;
    const LOG_GATEWAY_API_ROUTE = '/pwa/webclient?from=pwa';
    if (gatewayEndPoint) {
        return gatewayEndPoint + LOG_GATEWAY_API_ROUTE;
    }
    return '';
};

export const reportUrl = makeReportUrl();

const generateKeyFromServerKey = (originKeyString: string) => {
    // AES-GCM only support 16 or 32 bytes key
    const validString = originKeyString.slice(0, 32);

    const enc = new TextEncoder();
    return window.crypto.subtle.importKey('raw', enc.encode(validString), 'AES-GCM', false, ['encrypt', 'decrypt']);
};

// https://zoomvideo.atlassian.net/wiki/spaces/ZWC/pages/2533942373/ZOOM-440420+web+backend+offer+encrypt+key+for+specific+user
const getExternalKeys = (serverKeyList: Array<any>) => {
    const serverKeys = serverKeyList.sort((a, b) => +b.version - +a.version);
    const allKeys = Promise.all(
        serverKeys.map((serverKey) => {
            return generateKeyFromServerKey(serverKey?.value);
        }),
    ).then((keys) => {
        return keys.map((key) => {
            return {
                usage: KeyUsage.PROTECT_LOCAL_KEY,
                key,
            };
        });
    });

    return allKeys;
};

// max storage date
const weekTimestamp = 7 * 24 * 60 * 60 * 1000;
const expireBefore = new Date().getTime() - weekTimestamp;

const plugins: Array<any> =
    process.env.NODE_ENV === 'development'
        ? [
              new PrintToConsolePlugin({
                  verbose: false,
              }),
              new AutoCleanLogPlugin({
                  debug: true,
                  cleanAfterReport: true,
                  expireBefore: expireBefore,
              }),
          ]
        : [
              new AutoCleanLogPlugin({
                  debug: false,
                  cleanAfterReport: true,
                  expireBefore: expireBefore,
              }),
          ];

const laplaceConfig: LaplaceConfig = {
    databaseName: DATABASE_NAME,
    maxSize: MAXIMUM_MAX_SIZE,
    publicKey: '',
    reportUrl: reportUrl,
    plugins,
};

let laplace: Laplace;
let laplaceStatus: 'pending' | 'resolved' | 'rejected' = 'pending';
let laplaceInitPromise: Promise<Laplace> = null;
export const initLaplace = () => {
    if (laplaceInitPromise) {
        return laplaceInitPromise;
    }
    laplaceInitPromise = new Promise((resolve, reject) => {
        const serverKeyList = window.PwaConfig?.pwaDataStorationEncryptedKeys ?? [];
        getExternalKeys(serverKeyList)
            .then((keys) => {
                laplaceConfig.externalKeys = keys;
            })
            .finally(() => {
                Laplace.init(laplaceConfig)
                    .then((laplaceInstance) => {
                        laplace = laplaceInstance;
                        laplace.logWithEncryption(
                            '========== Laplace init success! New Laplace session, PWA PORTAL =========',
                            [trackingId, 'LAPLACE_NEW_SESSION'],
                            toLogConfig,
                        );
                        laplaceStatus = 'resolved';
                        resolve(laplaceInstance);
                    })
                    .catch((e) => {
                        laplaceStatus = 'rejected';
                        reject(e);
                    });
            });
    });
    return laplaceInitPromise;
};

export const checkLaplace = () => {
    return typeof laplace?.log === 'function';
};

export const getLaplaceSync = () => {
    if (!checkLaplace()) {
        return null;
    }
    return laplace;
};

export const getLaplace = (): Promise<null | Laplace> => {
    if (laplaceStatus === 'pending') {
        return laplaceInitPromise;
    }

    if (laplaceStatus === 'resolved') {
        return Promise.resolve(laplace);
    }

    return Promise.resolve(null);
};

export const getLaplaceTrackingId = () => {
    return trackingId;
};

export const toLogConfig = {
    uid: window.PwaConfig.uid || '---none---',
    pwa_version: `${process.env.BUILD_VERSION} (${process.env.BUILD_DATE})`,
};
console.log('989898', toLogConfig);

export const getLaplaceLogPrecedingTags = () => [getSsid(), trackingId, 'ZOOM_PWA_PORTAL'];
