/**
 * 2021-07-22
 * Ways to launch pwa:
 * 1. input pwa's index url in browser or click pwa icon if you'v installed pwa to your desktop
 * 2. login successfully.
 *      - if you start/join a meeting, and that action requires your logging into zoom,
 *      - you will be redirected to signin page, after that server goes back to pwa and pass the strat/join url to pwa.
 *      - we need to join that meeting if it exists.
 * 3. link captrue
 *      - when you click a invitation meeting link, you go to launch page. form there launch page will open pwa and pass meeting info in the link
 *      - we need to join that meeting
 *
 *
 * ChromeOs policy
 * some policy item need to be send to server and server will write them in cookie for later use.
 * we need to update that values everytime before join/start any meetings.
 *
 *
 * Extend web login session expiration time
 * by default, web login session expires in 2 hours (accout admin may set this value in web portal) if web page doesn't have any comminications with server.
 * in order to persist pwa's login session , web provide an api for pwa to keep logged in as long as possible.
 * we need to call that api peroidically.
 */

import httpRequest from '../../services/http/http';
import qs from 'querystring';
import { isFunction, noop } from 'lodash-es';

interface IStartProps {
    userId: string;
    sessionDuration: number;
    sessionExpiredHandler: () => void;
}

export const ping = (function (): any {
    let pingTimer = -1;
    const pingUrl = '/wc/pwa-ping';

    const _data: IStartProps = {
        userId: '',
        sessionDuration: -1,
        sessionExpiredHandler: noop,
    };

    function stop(): void {
        console.log('stop ping web');
        window.clearInterval(pingTimer);
        window.removeEventListener('online', ping);
    }

    function start(props: IStartProps): void {
        if (!props.userId) {
            console.error('ping: Not login');
            return;
        }

        _data.sessionDuration = props.sessionDuration;
        _data.userId = props.userId;

        if (isFunction(props.sessionExpiredHandler)) {
            _data.sessionExpiredHandler = props.sessionExpiredHandler;
        }

        stop();

        // when network is on, check it immediately
        window.addEventListener('online', ping);

        const { sessionDuration } = props;
        const time = sessionDuration ? sessionDuration * 0.75 : 15 * 60;
        pingTimer = window.setInterval(() => {
            ping();
        }, time * 1000);
    }

    async function ping(): Promise<void> {
        const { userId, sessionExpiredHandler } = _data;
        const payload = qs.stringify({ uid: userId });

        try {
            const response = await httpRequest.post(pingUrl, payload);
            const { errorCode, errorMessage, result } = response.data;
            if (errorCode === 201) {
                // not login
                console.error(errorMessage);
                sessionExpiredHandler?.();
            }

            if (errorCode === 0) {
                if (result === 1) {
                    // same user
                    // extend success
                }
                if (result === 2) {
                    // another account logs in on web page
                    // we should switch ui to logout-state
                    // do not reload pwa.
                    console.error(errorMessage);
                    sessionExpiredHandler?.();
                }
            }
        } catch (error) {
            // maybe network is off
            console.error(error);
        }
    }

    return {
        start,
        stop,
        ping,
    };
})();
