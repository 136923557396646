import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownButton } from '../../components/Dropdown/Dropdown';
import classnames from 'classnames';
import './Language.scss';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { setLanguageInCookie } from '../../utils/Cookies/utils';
import { isDuringMeetingSelector } from '../Meeting/redux';

const Language = () => {
    const dispatch = useAppDispatch();
    const { language } = useAppSelector((state) => state.common);
    const isInMeeting = useAppSelector(isDuringMeetingSelector);
    const [Lang, setLang] = useState(language?.key);
    if (!language) {
        return null;
    }

    const langs = window.PwaConfig.supportLanguages;

    const onClick = (e) => {
        setLang(e);
        if (Lang !== e) {
            setLanguageInCookie(e);
        }
    };

    const Interpretation = <i className="icon-interpretation" key="interpretation"></i>;
    const Title = (
        <span className="language-dropdown-box__title" key="title">
            {langs[Lang]}
        </span>
    );
    const ArrowUp = <i className="icon-arrow-up" key="arrow-up"></i>;

    return (
        <div className={classnames('language-dropdown-box', { disabled: isInMeeting })}>
            <DropdownButton
                bsPrefix="dropdown-initial"
                drop="up"
                id="language-dropdown-button"
                title={[Interpretation, Title, ArrowUp]}
                disabled={isInMeeting}
            >
                <div className="dropdown-box__content">
                    {Object.keys(langs).map((code) => (
                        <Dropdown.Item
                            key={code}
                            eventKey={code}
                            className={Lang === code ? 'active' : ''}
                            onClick={() => onClick(code)}
                        >
                            {langs[code]}
                        </Dropdown.Item>
                    ))}
                </div>
            </DropdownButton>
        </div>
    );
};
export default Language;
