import { initLaplace } from '../logger/laplace';
import { DBManager } from '../utils/db';

if (window.PwaConfig?.pwaDataStorationEncryptedKeys?.length > 0) {
    DBManager.init(window.PwaConfig?.pwaDataStorationEncryptedKeys);

    // depend on pwaDataStorationEncryptedKeys
    initLaplace().catch((e) => {
        console.error('init laplace failed!!!', e);
    });
}
