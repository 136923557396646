import { RoutePath } from '../routes';
import { replaceRoute } from '../routes/history-utils';
import serviceContainer from '../services/container';
import { getAppLoadUrl } from '../utils/captureAppLoadUrl';
import { getJoinMeetingPath, isJoinMeetingUrlPath, isStartMeetingUrlPath } from '../utils/meeting-url';

/**
 * this is intended for case:
 * 1. you join a meeting that reuqires attendee to sign in, but you don't sign in
 * 2. we load iframe (webclient), and web reload current page to sign in page
 * 3. after you signin, web server will redirect to pwa with
 * wc?backurl=https://zoomdev.us/xxx/join?tk=xxx&name=yyyyy'
 * 4. we need to parse backurl and continue that meeting
 */
export const maybeJoinMeetingWhenSignedin = (): boolean => {
    const meetingAgent = serviceContainer.getMeetingAgent();
    const url: URL = getAppLoadUrl();
    let meetingUrl;

    try {
        // when you get a param from searchParams, it's automatically decoded!!!
        const backUrl = url.searchParams.get('backurl');
        if (!backUrl) {
            return false;
        }
        meetingUrl = new URL(backUrl);
    } catch (e) {
        return false;
    }

    // only accepts url
    // https://zoomvideo.atlassian.net/browse/ZOOM-361107
    if (meetingUrl.protocol !== 'https:') {
        return false;
    }

    replaceRoute({
        pathname: RoutePath.Index,
        search: 'from=pwa',
    });

    const { pathname } = meetingUrl;
    meetingUrl.host = url.host; // make sure when we get into iframe, we set its doamin as pwa's domain

    if (!isJoinMeetingUrlPath(pathname) && !isStartMeetingUrlPath(pathname)) return false;
    if (isJoinMeetingUrlPath(pathname)) {
        meetingAgent?.joinMeetingWithMeetingUrl(meetingUrl.toString());
    }
    if (isStartMeetingUrlPath(pathname)) {
        meetingAgent?.startMeetingWithUrl(meetingUrl.toString());
    }
    return true;
};

/**
 * this is intended for cases:
 * 1. zoom's launch page tries to launch pwa client
 * 2. tesla launches pwa client
 *
 * /wc?mn=xxxxxxxx&others=params
 */
export const maybeLinkCaptureJoinMeeting = (): boolean => {
    const meetingAgent = serviceContainer.getMeetingAgent();
    const url: URL = getAppLoadUrl();

    const meetingNo = url.searchParams.get('mn');
    if (!meetingNo) {
        return false;
    }

    url.searchParams.delete('mn');
    const restSearch = url.searchParams.toString();

    let meetingUrl = getJoinMeetingPath(meetingNo);
    if (restSearch) {
        meetingUrl = meetingUrl + '&' + restSearch;
    }
    meetingAgent?.joinMeetingWithMeetingUrl(meetingUrl, { replaceUrl: true });
    return true;
};
