import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, List, Index, ListRowRenderer } from 'react-virtualized';
import '../../../../assets/styles/main.scss';
import './ContactList.scss';
import {
    setGroupFolded,
    IRenderType,
    IContactRender,
    selectContactRenderList,
    setScrollTop,
    IGroupRender,
} from '../../redux';
import { getUcsGroupInfoLazyThunk } from '../../redux/ucs-thunk';
import { getContactsInfoThunk } from '../../redux/contact-thunk';
import { IContact, JID } from '../../types';
import Group from './Group';
import { CONTACTS_LOADING, CONTACTS_NO_RESULT } from '../../../../resource';
import { useAppDispatch, useAppSelector } from '../../../../store';
import ContactWrapper from './ContactWrapper';
import Sentinel from './Sentinel';
import Label from './Label';
import { isPendingContactJid } from '../../utils/stringUtils';
import { withA11y, keyBoardEventHandler, A11Y_POLICY } from '../../a11y';
import { makeA11yListInfoInjector } from '../../../../utils';

const ID = 'contactListWrapper';
const a11yBase = '0';

const Group_Height = 40;
const Contact_Height = 40;
const Label_Height = 50;
const Contact_List_Overscan_Count = 5;

interface IProps {
    // https://stackoverflow.com/questions/65799316/why-cant-an-interface-be-assigned-to-recordstring-unknown
    [setContact: string]: (jid: JID) => void;
}

const ContactList = React.memo(({ setContact }: IProps) => {
    const dispatch = useAppDispatch();
    const totalList = useAppSelector(selectContactRenderList);
    const isReceivedGroupsData = useAppSelector((state) => state.contacts.isReceivedGroupsData);
    const scrollTop = useAppSelector((state) => state.contacts.scrollTop);
    const [currentRowRenderStartIndex, setCurrentRowRenderStartIndex] = useState(0);
    const [stickyGroupId, setStickyGroupId] = useState(null);

    const containerRef = useRef(null);
    const { dataALGenerator } = makeA11yListInfoInjector('0', containerRef);

    const a11yGroupIndex = useRef(null);
    const [stickGroupAriaIndex, setStickGroupAriaIndex] = useState('');

    const contactListRef = useRef(null);
    const scrollTopRef = useRef(scrollTop);

    const toggleGroupFold = (id: string, flag: boolean) => {
        dispatch(setGroupFolded({ id, flag }));
    };

    const getRowHeight = ({ index }: Index) => {
        const item = totalList[index];
        if (item.renderType === IRenderType.GROUP) {
            return Group_Height;
        }

        if (item.renderType === IRenderType.SENTINEL) {
            return 0;
        }

        if (item.renderType === IRenderType.LABEL) {
            return Label_Height;
        }

        return Contact_Height;
    };

    const noRowsRenderer = () => {
        let empty = null;
        if (!isReceivedGroupsData) {
            empty = (
                <div className="contacts-lists-loading ">
                    <i></i>
                    <span>{CONTACTS_LOADING}</span>
                </div>
            );
        } else {
            empty = <div className="contacts-lists-empty">{CONTACTS_NO_RESULT}</div>;
        }

        return empty;
    };

    const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
        const item = totalList[index];
        const renderIndex = index - currentRowRenderStartIndex;

        if (item.renderType === IRenderType.LABEL) {
            return (
                <Label
                    a11yIndex={dataALGenerator()}
                    renderIndex={renderIndex}
                    key={item.labelId}
                    style={style}
                    data={item}
                />
            );
        }

        if (item.renderType === IRenderType.GROUP) {
            a11yGroupIndex.current = dataALGenerator();
            return (
                <Group
                    a11yIndex={a11yGroupIndex.current}
                    renderIndex={renderIndex}
                    style={style}
                    key={key}
                    onClickGroup={toggleGroupFold}
                    groupId={item.groupId}
                />
            );
        }

        if (item.renderType === IRenderType.CONTACT) {
            // contact
            return (
                <ContactWrapper
                    style={style}
                    key={key}
                    contact={item}
                    setContact={setContact}
                    a11yIndex={dataALGenerator()}
                    renderIndex={renderIndex}
                    a11yGroupIndex={a11yGroupIndex.current}
                />
            );
        }

        if (item.renderType === IRenderType.SENTINEL) {
            return <Sentinel sentinel={item} style={style} key={'sentinel_' + item.groupId} />;
        }

        return null;
    };

    /**
     * debounce may not work inside this method，because '<List/>' maybe recreat when scroll contact list，
     * then debounce be called many times, debounce lost its meaning.
     * */
    const onRowsRenderedImmidiate = ({ startIndex = 0, stopIndex = 0 }) => {
        setCurrentRowRenderStartIndex(startIndex);
        onRowsRendered({ startIndex, stopIndex });
    };

    const onRowsRendered = ({ startIndex = 0, stopIndex = 0 }) => {
        const showContactData: Array<IContact | IContactRender> = [];
        const showGroupData: Array<IGroupRender> = [];

        totalList.slice(startIndex, stopIndex + 1).forEach((item) => {
            if (item.renderType === IRenderType.CONTACT) {
                if (!isPendingContactJid(item.jid)) {
                    showContactData.push(item);
                }
            }

            if (item.renderType === IRenderType.GROUP) {
                showGroupData.push(item);
            }
        });

        // query contact presence status and profile info
        dispatch(getContactsInfoThunk(showContactData));

        // get group member info from ucs
        dispatch(getUcsGroupInfoLazyThunk(showGroupData));
    };

    useEffect(() => {
        const stickItem = totalList[currentRowRenderStartIndex + 1];
        if (stickItem?.renderType === IRenderType.CONTACT) {
            setStickyGroupId(stickItem.groupId);
            setStickGroupAriaIndex(a11yGroupIndex.current);
        } else {
            setStickyGroupId(null);
        }
    }, [currentRowRenderStartIndex]);

    useEffect(() => {
        // why scrollTopRef.current is always 0 ?
        contactListRef.current?.scrollToPosition(scrollTop);
        return () => {
            dispatch(setScrollTop(scrollTopRef.current));
        };
    }, []);

    return (
        <div
            className="contacts-list"
            id={ID}
            tabIndex={0}
            role="tree"
            ref={containerRef}
            data-a-l={a11yBase}
            data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_POLICY}
        >
            <div className="contacts-list__sticky-header">
                {stickyGroupId && (
                    <Group
                        style={{}}
                        onClickGroup={toggleGroupFold}
                        groupId={stickyGroupId}
                        isStickGroup={true}
                        a11yIndex={stickGroupAriaIndex}
                    />
                )}
            </div>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        className="contacts-list-container"
                        tabIndex={-1}
                        role="treeitem"
                        containerRole="tree"
                        height={height - 1} // reason same as why 'width - 1'
                        ref={contactListRef}
                        onScroll={({ scrollTop }) => {
                            scrollTopRef.current = scrollTop;
                        }}
                        defaultWidth={true}
                        width={width - 1} // browser will have 77.45px, but react-virtualized will have 78px. scrollbar will appear
                        overscanRowCount={Contact_List_Overscan_Count}
                        noRowsRenderer={noRowsRenderer}
                        rowCount={totalList.length}
                        rowHeight={getRowHeight}
                        rowRenderer={rowRenderer}
                        onRowsRendered={onRowsRenderedImmidiate}
                    />
                )}
            </AutoSizer>
        </div>
    );
});

export default withA11y(ContactList, ID, keyBoardEventHandler);
