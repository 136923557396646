import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import './SignedIn.scss';
import Header from './Header/Header';
import Home from '../Home/Home';
import { RoutePath } from '../../routes/routePath';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { getUserWebSettingsThunk, setUserWebSettings } from '../../store/common/common-store';
import { useInitMeetingList, useInitXmpp, useInitZpns, useShowJoinOnlyToast } from './init-hooks';
import { useObserveWebLoginSession } from '../../utils/observe-web-login-session';
import { shallowEqual } from 'react-redux';

export default function SignedInTesla() {
    const dispatch = useAppDispatch();
    const { userInfo, hashedUserId } = useAppSelector((state) => {
        const {
            common: { userInfo, hashedUserId },
        } = state;
        return { userInfo, hashedUserId };
    }, shallowEqual);

    const props = { dispatch, userInfo, hashedUserId };

    useInitMeetingList(props);
    useShowJoinOnlyToast(props);
    useInitZpns(props);
    useObserveWebLoginSession(props);
    useInitXmpp(props);

    useEffect(() => {
        dispatch(getUserWebSettingsThunk())
            .then((data) => {
                dispatch(setUserWebSettings(data));
            })
            .catch((_) => {
                console.error('get web settings error');
            });
    }, []);

    return (
        <div className="home">
            <Header userInfo={userInfo} />
            <Switch>
                <Route exact path={RoutePath.Home}>
                    <Home />
                </Route>
            </Switch>
        </div>
    );
}
