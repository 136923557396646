const appLoadURL = new URL(window.location.href);

export const getAppLoadUrl = () => {
    return new URL(appLoadURL);
};

export const isPwaLoadedFromWebPortal = () => {
    return appLoadURL?.searchParams.get('webp') === '1';
};

export const getAppLoadPathname = () => {
    return appLoadURL?.pathname;
};
