// about synchronously unmount issue
// https://github.com/facebook/react/issues/25675

import React, { useEffect, useRef } from 'react';
import { chatAgent } from '../ChatAgent';
import { CONTACTS_LOADING } from '../../../resource';

export default () => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // just provide a dom element for chat, chat will render the content by dom.
        chatAgent.toRenderSetting({ container: containerRef.current });
        return () => {
            // when container is null, send meesage to chat, chat will unmount the dom element
            setTimeout(() => {
                chatAgent.toRenderSetting({ container: null });
            });
        };
    }, []);

    return <div ref={containerRef}>{CONTACTS_LOADING}</div>;
};
