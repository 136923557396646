import React, { useEffect, useRef } from 'react';
import { meetingAgent } from '../../app-init';
import { RootState, useAppSelector } from '../Phone/types';
import { getZoomMeetingStatus } from '../ContactCenter/otherModuleUtils';
import { EClientZmCallStatusType } from '../ContactCenter/types';
import { connect, useDispatch } from 'react-redux';
import { setIsMeetingMinimized, setMeetingInfo } from './redux';
import { isNoMeetingNumberUrl } from '../../utils/meeting-url';
import useLatest from '../../hooks/useLatest';
import { PWAMeetingEvent } from './meeting-agent-msg-type';

interface IMeetingStarter {
    isMeetingMinimized: boolean;
}

const getUrlForWebclient = () => {
    const url = new URL(window.location.href);
    if (isNoMeetingNumberUrl(url.pathname)) {
        url.pathname = url.pathname.replace('/wc', '');
    }

    url.searchParams.set('from', 'pwa');

    if (url.searchParams.has('fromPWA')) {
        url.searchParams.delete('fromPWA');
    }

    return url.href;
};

export const getUrlForPWAPortal = () => {
    const url = new URL(window.location.href);

    if (url.searchParams.has('from')) {
        url.searchParams.delete('from');
    }

    if (!url.searchParams.has('fromPWA')) {
        url.searchParams.append('fromPWA', '1');
    }

    return url.href;
};

const MeetingStarter = ({ isMeetingMinimized }: IMeetingStarter) => {
    const meetingStatus = useAppSelector(getZoomMeetingStatus);
    const showWebclient = useAppSelector(state => state.meeting.showWebclient);
    const isMeetingMinimizedRef = useRef(isMeetingMinimized);
    const meetingStatusRef = useRef(meetingStatus);
    const dispatch = useDispatch();

    const latestShowWebclient = useLatest(showWebclient);

    isMeetingMinimizedRef.current = isMeetingMinimized;
    meetingStatusRef.current = meetingStatus;

    useEffect(() => {
        if (meetingStatusRef.current === EClientZmCallStatusType.MeetingStatus_Idle) {
            meetingAgent.dispatchLaunchMeeting({
                meetingUrl: getUrlForWebclient(),
                showWebclient: true,
                isInvitedByXmpp: window.location.search.includes('isInvitedByXmpp') ? 1 : 0,
            });
            dispatch(setIsMeetingMinimized(false));
        } else {
            dispatch(setIsMeetingMinimized(false));
            meetingAgent.sendMsgToWebClient(PWAMeetingEvent.BACK_TO_MEETING);
        }

        return () => {
            if (!isMeetingMinimizedRef.current) {
                const iframe = document.getElementById('webclient') as HTMLIFrameElement;
                // @ts-ignore
                if (!iframe?.contentWindow?.MeetingConfig?.isStart) {
                    // iframe now is in name/password page
                    if(latestShowWebclient.current) {
                        // if meetingAgent.handleMeetingEnd was called just before, we ignore this call.
                        // handleMeetingEnd sets showWebclient to false
                        meetingAgent.handleMeetingEnd(null);
                    }
                } else {
                    // iframe now is in meeting page
                    meetingAgent.sendMsgToWebClient(PWAMeetingEvent.PWA_LEAVE_MEETING);
                }
                dispatch(setMeetingInfo(null));
            }
        };
    }, []);
    return <></>;
};

const mapStateToProps = (state: RootState) => {
    const {
        meeting: { isMeetingMinimized },
    } = state;

    return {
        isMeetingMinimized,
    };
};

export default connect(mapStateToProps)(MeetingStarter);
