import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getBareJidFromJid } from '../../../utils/index';
import './invitation.scss';
import Avatar from '../../../components/Avatar/Avatar';
import { ringType } from '../../../utils/audio';
import Draggable from 'react-draggable';
import useInvitationNotification from '../../../hooks/useInvitationNotification';
import { Modal } from 'Modal';
import { useAppSelector } from '../../../store';
import { selectContactByJid } from '../../Contacts/redux';
import {
    Decline_Text,
    IS_INVITING_YOU,
    JOIN,
    LEAVE_AND_JOIN,
    LEAVE_JOIN_NEW_MEETING,
    ONE_IS_INVITING_YOU,
    ZOOM_VIDEO_MEETING,
} from '../../../resource';
import { Someone_Text } from '../../Phone/LanguageResource';
import { NOTIFICATION_TAGS } from '../../../utils/BrowserNotification.ts';
import useHIDBinding from '../../../hooks/useHIDBinding';

const mapState = (state, props) => {
    const {
        invitation: { fromJid },
    } = props;
    const jid = getBareJidFromJid(fromJid);
    const contact = selectContactByJid(state, jid);
    return {
        picUrl: contact?.picUrl,
        jid,
    };
};

const Invitation = ({ accept, decline, timeoutDecline, closeMe, invitation, style, isDuringMeeting, picUrl, jid }) => {
    const styleRef = useRef(style); // only accepts the initial style. ignore future style updates
    let { fromName } = invitation;
    let acceptText = JOIN;
    let showText = IS_INVITING_YOU;
    if (isDuringMeeting) {
        acceptText = LEAVE_AND_JOIN;
        showText = LEAVE_JOIN_NEW_MEETING;
    }
    let declineText = Decline_Text;

    useInvitationNotification({
        onTimeout: () => {
            timeoutDecline(invitation);
        },
        notificationConfig: {
            tag: NOTIFICATION_TAGS.MEETING_INVITATION, // 'meeting-invitation',
            title: ZOOM_VIDEO_MEETING,
            body: ONE_IS_INVITING_YOU(fromName || Someone_Text),
        },
    });

    const _closeMe = () => closeMe(invitation);

    const draggableProps = {
        cancel: '.invitation-join, .invitation-decline, .invitation-close',
        defaultPosition: {
            x: +style.left || 0,
            y: +style.top || 0,
        },
    };

    useHIDBinding(invitation.fromJid, {
        accept: () => accept(invitation),
        decline: () => decline(invitation),
    });

    return (
        <Modal
            needShowAboveBase={true}
            isOpen={true}
            centered={false}
            needBackdrop={false}
            isDraggable={true}
            draggableProps={draggableProps}
            onRequestClose={_closeMe}
        >
            <div className="invitation">
                <i className="invitation-close" tabIndex={0} onClick={_closeMe}></i>
                <div className="invitation-content">
                    <Avatar
                        picUrl={picUrl}
                        jid={jid}
                        displayName={fromName}
                        extraClass="invitation-avatar"
                        imgClass="invitation-avatar-img"
                    />
                    <div className="invitation-detail">
                        <p title={fromName}>{fromName}</p>
                        <p>{showText}</p>
                    </div>
                </div>
                <div className="invitation-footer">
                    <button className="invitation-join" onClick={() => accept(invitation)}>
                        {acceptText}
                    </button>
                    <button className="invitation-decline" onClick={() => decline(invitation)}>
                        {declineText}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default connect(mapState)(Invitation);
