import React, { useEffect, useState } from 'react';
import { getNoticeTxt } from './utils';
import { CalendarEvent, MeetingItem } from './types';

interface IProp {
    timeTxt: string;
    data: MeetingItem | CalendarEvent;
}

const MeetingDetailTime = ({ timeTxt, data }: IProp) => {
    const [notice, setNotice] = useState(() => getNoticeTxt(data));

    useEffect(() => {
        const timer = setInterval(() => {
            setNotice(getNoticeTxt(data));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [data]);

    const { noticeTxt, noticeCls } = notice;

    return (
        <div className="meetings__detail-time">
            {timeTxt ? <span>{timeTxt}</span> : null}

            {noticeTxt ? (
                <>
                    <span> | </span>
                    <span className={noticeCls}>{noticeTxt}</span>
                </>
            ) : null}
        </div>
    );
};

export default MeetingDetailTime;
